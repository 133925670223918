import {
  createMuiTheme,
  CssBaseline,
  Drawer,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core";
import React from "react";
import Header from "../../../../components/Admin/Header";
import red from "@material-ui/core/colors/red";
import MyDrawer from "../../../../components/Admin/Drawer";
import Abouts from "./Abouts";
import { AboutProvider } from "../../../../components/About/AboutContext";
import { ImageProvider } from "../../../../components/Image/ImageContext";
import { useStyles, theme } from "../../theme.js";

const AboutAdminPage = (props) => {
  const classes = useStyles();

  return (
    <ImageProvider>
      <AboutProvider>
        <ThemeProvider theme={theme}>
          <Drawer
            className={classes.drawer}
            variant="permanent"
            classes={{
              paper: classes.drawerPaper,
            }}
            anchor="left"
          >
            <MyDrawer></MyDrawer>
          </Drawer>
          <div className={classes.adminMain}>
            <Header></Header>
            <Abouts></Abouts>
          </div>
          <CssBaseline></CssBaseline>
        </ThemeProvider>
      </AboutProvider>
    </ImageProvider>
  );
};

export default AboutAdminPage;
