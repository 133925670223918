import React, { useContext } from "react";
import { LanguageContext } from "../../LanguageContext";
import { HeroPositionContainerContemp, HeroH1 } from "../HeroSpecPageElements";

const ConTempHero = (props) => {
  const [language, setLanguage] = useContext(LanguageContext);
  return (
    <HeroPositionContainerContemp>
      <HeroH1>{props.title}</HeroH1>
    </HeroPositionContainerContemp>
  );
};

export default ConTempHero;
