import React, { useState } from "react";
import {
  makeStyles,
  Table,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  narrowCell: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "40px",
  },
  table: {
    marginTop: theme.spacing(0),
    "& thead th": {
      fontWeight: "600",
      color: "#fff",
      backgroundColor: "#5d688c",
    },
    "& tbody td": {
      padding: "15px",
      fontWeight: "300",
      fontSize: "14px",
    },
    "& tbody tr:hover": {
      backgroundColor: "#fffbf2",
    },
  },
}));

export default function useTable(records, headCells, filterFn) {
  const classes = useStyles();
  const pages = [200, 400, 600];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(pages[page]);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("date");

  const TblPagination = () => (
    <TablePagination
      component="div"
      page={page}
      rowsPerPageOptions={pages}
      rowsPerPage={rowsPerPage}
      count={records.length}
      onChangePage={handleChangePage}
      onChangeRowsPerPage={handleChangeRowsPerPage}
    />
  );

  const TblContainer = (props) => (
    <Table className={classes.table}>{props.children}</Table>
  );

  const TblHead = (props) => {
    const handleSortRequest = (cellId) => {
      const isAsc = orderBy === cellId && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(cellId);
    };

    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              {headCell.disableSorting ? (
                headCell.label
              ) : (
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={() => {
                    handleSortRequest(headCell.id);
                  }}
                >
                  {headCell.label}
                </TableSortLabel>
              )}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] === undefined || b[orderBy] === null) return 1;
    if (a[orderBy] === undefined || a[orderBy] === null) return -1;

    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  const recordsAfterPagingAndSorting = (orderByProp) => {
    let orderByVar;
    if (orderByProp) orderByVar = orderByProp;
    else orderByVar = orderBy;

    // Separate defined and undefined records
    const definedRecords = [];
    const undefinedRecords = [];

    filterFn.fn(records).forEach((record) => {
      if (record[orderByVar] !== undefined) {
        definedRecords.push(record);
      } else {
        undefinedRecords.push(record);
      }
    });

    // Sort the defined records
    const sortedDefinedRecords = stableSort(
      definedRecords,
      getComparator(order, orderByVar)
    );

    // Concatenate the defined and undefined records
    const allRecords = [...sortedDefinedRecords, ...undefinedRecords];

    return allRecords.slice(page * rowsPerPage, (page + 1) * rowsPerPage);
  };

  return {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting,
  };
}
