import http from "./http-auth";

export function InsertItem(data) {
  return http.post("/interviews", data).then((res) => {});
}

export function DeleteItem(data) {
  return http.delete(`/interviews/${data}`).then((res) => {});
}

export function UpdateItem(data) {
  return http.put(`/interviews/${data.id}`, data).then((res) => {});
}

export function GetItem(data) {
  return http.get(`/interviews/${data.id}`, data).then((res) => {});
}

export const GetAllItems = () => {
  return http.get("/interviews");
};
