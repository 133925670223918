import React, { useRef, useState } from "react";
import { Form, Button, Card, Alert } from "react-bootstrap";
import { useAuth } from "../../../auth/AuthContext";
import { Link } from "react-router-dom";
import navbar_logo_hu from "../../images/navbar_logo_hu.png";
import {
  FormWrap,
  Container,
  Icon,
  FormContent,
  FormZone,
} from "../SigninElements";

export default function ForgotPassword() {
  const emailRef = useRef();
  const { resetPassword } = useAuth();
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      setMessage("");
      setError("");
      setLoading(true);
      await resetPassword(emailRef.current.value);
      setMessage(
        "Küldtünk egy emailt! Kérlek kövesd az emailben kapott utasításokat."
      );
    } catch {
      setError("Sikertelen jelszóváltoztatás.");
    }

    setLoading(false);
  }

  return (
    <>
      <Container>
        <FormWrap>
          <FormContent>
            <FormZone>
              <Icon to="/">
                {" "}
                <img
                  src={navbar_logo_hu}
                  style={{ maxWidth: "75%" }}
                  alt="navbar_logo_Hu"
                  className="navbar-icon"
                />
              </Icon>
              <Card border="0">
                <Card.Body>
                  <h2 className="text-center mb-4">Jelszó változtatás</h2>
                  {error && <Alert variant="danger">{error}</Alert>}
                  {message && <Alert variant="success">{message}</Alert>}
                  <Form onSubmit={handleSubmit}>
                    <Form.Group id="email">
                      <Form.Label>Email</Form.Label>
                      <Form.Control type="email" ref={emailRef} required />
                    </Form.Group>
                    <Button
                      variant="dark"
                      disabled={loading}
                      className="w-100 mt-5"
                      type="submit"
                    >
                      Tovább
                    </Button>
                  </Form>
                  <div className="w-100 text-center mt-3">
                    <Link to="/login">Bejelentkezés</Link>
                  </div>
                </Card.Body>
              </Card>
            </FormZone>
          </FormContent>
        </FormWrap>
      </Container>
    </>
  );
}
