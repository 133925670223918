import { Grid } from "@material-ui/core";
import React from "react";
import { useForm } from "../useForm";
import Controls from "../controls/Controls";
import { FormsRef } from "../formsRef";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
const initialFValues = {
  id: 0,
  caption: "",
  src: "",
  thumbnail: "",
  thumbnailHeight: 212,
  thumbnailWidth: 320,
};

const GalleryForm = (props) => {
  const { upload, showModal, handleClose, pageTitle, carry, files, images } =
    props;

  const { caption, link, linkENG } = FormsRef();

  const { values, setValues, handleInputChange, resetForm } =
    useForm(initialFValues);

  const handleInputChangeImageSmall = (e) => {
    const { value } = e.target;
    link.current = value;
  };

  const handleInputChangeImageBig = (e) => {
    const { value } = e.target;
    linkENG.current = value;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    upload({
      id: carry.id,
      caption: caption.current.value,
      LinkSmall: link.current,
      LinkLarge: linkENG.current,
    });
  };

  return (
    <Modal show={showModal} onHide={handleClose} size="lg">
      <Modal.Header>
        <Modal.Title>{pageTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Form.Group className="mb-3">
                <Form.Label>Caption</Form.Label>
                <Form.Control
                  ref={caption}
                  defaultValue={carry.caption || ""}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Controls.Select
                  name="image"
                  label="ImageSmall"
                  values={images}
                  onChange={handleInputChangeImageSmall}
                  defaultValue={carry.link}
                ></Controls.Select>
              </Form.Group>
              <Form.Group className="mb-3">
                <Controls.Select
                  name="image"
                  label="ImageLarge"
                  values={images}
                  onChange={handleInputChangeImageBig}
                  defaultValue={carry.linkENG}
                ></Controls.Select>
              </Form.Group>
            </Grid>
          </Grid>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Bezárás
        </Button>
        <Button variant="primary" onClick={handleSubmit} type="submit">
          Mentés
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default GalleryForm;
