import styled from "styled-components";

export const HeroH1 = styled.p`
  color: #fff;
  display: flex;
  font-size: 50px;
  text-align: center;
  position: relative;
  left: 60rem;
  top: 5rem;
  font-weight: 300;
  text-transform: uppercase;

  @media screen and (max-width: 768px) {
    font-size: 42px;
    position: relative;
    top: 10rem;
    left: 3rem;
  }
`;

export const HeroP = styled.p`
  margin-top: 24px;
  display: flex;
  color: #fff;
  font-size: 24px;
  text-align: left;
  position: relative;
  left: 65rem;
  top: 2rem;
  max-width: 400px;

  @media screen and (max-width: 768px) {
    font-size: 24px;
    position: relative;
    top: 5rem;
    left: 0;
  }
`;

export const HeroP2 = styled.p`
  margin-top: 0px;
  display: flex;
  color: #fff;
  font-size: 24px;
  text-align: left;
  position: relative;
  left: 70rem;
  top: 1rem;
  max-width: 400px;

  @media screen and (max-width: 768px) {
    font-size: 24px;
    position: relative;
    top: 5rem;
    left: 0;
  }
`;

export const HeroBtnWrapper = styled.div`
  margin-top: 72px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  left: 10rem;
  top: 5rem;

  @media screen and (max-width: 768px) {
    position: relative;
    top: 10rem;
    left: 3rem;
  }

  @media screen and (max-width: 480px) {
    position: relative;
    top: 5rem;
    left: 0rem;
  }
`;
