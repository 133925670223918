import React, { useState, createContext } from "react";
import blogBg from "../images/carousel3.jpg";
import mindenBg from "../images/carousel2.jpg";
import hirekBg from "../images/carousel1.jpg";

export const CarouselContext = createContext();

export const CarouselProvider = (props) => {
  const [carousels, setCarousels] = useState([
    {
      id: "whats_up",
      image: blogBg,
      title: "Specialities",
      text:
        " Driven by a curiosity to find the most colourful and personal methods of musical communication, my own career as a musician has been defined by continuous experimentation. The desire to stretch the borders of my flute and myself have become a way of life for me, comprising an attitude that keeps me growing and fills me with energy day after day.Here I have gathered interesting facts and information on my current noteworthy activities.",
      btnLabel: "Tell me more!",
    },
    {
      id: "whats_up",
      image: mindenBg,
      title: "News",
      text:
        "This is my personal bulletin board. Here you can read about anything and everything that excites me, be it a brand-new project, a fresh collaboration, or an upcoming invitation.",
      btnLabel: "Tell me more!",
    },
    {
      id: "whats_up",
      image: hirekBg,
      title: "Blog",
      text:
        "What is the life of a classical musician like? What happens behind the scenes? This is where I publish my thoughts from time to time for a sneak peek into my personal world.",
      btnLabel: "Show me more!",
    },
  ]);
  return (
    <CarouselContext.Provider value={[carousels, setCarousels]}>
      {props.children}
    </CarouselContext.Provider>
  );
};
