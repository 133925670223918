import React, { useContext } from "react";
import { LanguageContext } from "../../LanguageContext";
import {
  HeroH1Doc,
  HeroP2Doc,
  HeroPContainerRight,
  HeroPDoc,
  HeroPositionContainerDoc,
} from "../HeroSpecPageElements";

const DoctoralHero = (props) => {
  const [language, setLanguage] = useContext(LanguageContext);
  return (
    <HeroPositionContainerDoc>
      <HeroPContainerRight>
        {language[0].eng === "true" ? (
          <HeroH1Doc eng={true}>{props.title}</HeroH1Doc>
        ) : (
          <HeroH1Doc eng={false}>{props.title}</HeroH1Doc>
        )}
      </HeroPContainerRight>
      <HeroPContainerRight>
        {language[0].eng === "true" ? (
          <HeroPDoc eng={true}>{props.doctext}</HeroPDoc>
        ) : (
          <HeroPDoc eng={false}>{props.doctext}</HeroPDoc>
        )}
      </HeroPContainerRight>
      <HeroPContainerRight>
        {language[0].eng === "true" ? (
          <HeroP2Doc eng={true}>{props.docsmall}</HeroP2Doc>
        ) : (
          <HeroP2Doc eng={false}>{props.docsmall}</HeroP2Doc>
        )}
      </HeroPContainerRight>
    </HeroPositionContainerDoc>
  );
};

export default DoctoralHero;
