import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import Controls from "../controls/Controls";
import AddPostEventAbout from "../../../components/Quill/AddPostEventAbout";
import AddPostEventAboutENG from "../../../components/Quill/AddPostEventAboutENG";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useForm } from "../useForm";

const initialFValues = {
  id: 0,
  title: "",
  subtitle: "",
  titleENG: "",
  subtitleENG: "",
  about: "",
  aboutENG: "",
  place: "",
  date: new Date().toISOString().slice(0, 16), // Adjusted format to match input type datetime-local
  image: "",
};

const EventFromRefactored = ({
  upload,
  showModal,
  handleClose,
  pageTitle,
  carry,
  images,
}) => {
  const { values, setValues, handleInputChange, resetForm } =
    useForm(initialFValues);

  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    setModalOpen(showModal);
    if (carry) {
      setValues({
        id: carry.id || 0,
        title: carry.title || "",
        subtitle: carry.subtitle || "",
        titleENG: carry.titleENG || "",
        subtitleENG: carry.subtitleENG || "",
        about: carry.about || "",
        aboutENG: carry.aboutENG || "",
        place: carry.place || "",
        date: carry.date
          ? new Date(carry.date).toISOString().slice(0, 16)
          : new Date().toISOString().slice(0, 16), // Set to today if date is not provided
        image: carry.image || "",
      });
    }
  }, [showModal, carry, setValues]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await upload({
        id: values.id !== 0 ? values.id : null, // Send null id for new events, existing id for updates
        title: values.title,
        subtitle: values.subtitle,
        titleENG: values.titleENG,
        subtitleENG: values.subtitleENG,
        about: values.about,
        aboutENG: values.aboutENG,
        place: values.place,
        date: values.date,
        image: values.image,
      });

      // Close the modal on successful upload
      handleClose();
    } catch (error) {
      console.error("Error uploading event:", error);
      // Handle error if needed
    }
  };
  console.log(images);
  return (
    <Modal show={modalOpen} onHide={handleClose} size="lg">
      <Modal.Header>
        <Modal.Title>{pageTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Form.Group className="mb-3">
                <Form.Label>Title</Form.Label>
                <Form.Control
                  name="title"
                  value={values.title}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Subtitle</Form.Label>
                <Form.Control
                  name="subtitle"
                  value={values.subtitle}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <AddPostEventAbout
                values={values}
                setValues={setValues}
                defaultValue={values.about}
                title="About"
              />
              <Form.Group className="mb-3">
                <Form.Label>Place</Form.Label>
                <Form.Control
                  name="place"
                  value={values.place}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Date (format: yyyy-mm-ddThh:mm)</Form.Label>
                <Form.Control
                  type="datetime-local"
                  name="date"
                  value={values.date}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Controls.Select
                name="image"
                label="Image"
                value={values.image}
                onChange={handleInputChange}
                values={images}
              />
            </Grid>
            <Grid item xs={6}>
              <Form.Group className="mb-3">
                <Form.Label>TitleENG</Form.Label>
                <Form.Control
                  name="titleENG"
                  value={values.titleENG}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>SubtitleENG</Form.Label>
                <Form.Control
                  name="subtitleENG"
                  value={values.subtitleENG}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <AddPostEventAboutENG
                values={values}
                setValues={setValues}
                defaultValue={values.aboutENG}
                title="AboutENG"
              />
            </Grid>
          </Grid>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSubmit} type="submit">
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EventFromRefactored;
