import React, { useState, useEffect, useContext, useCallback } from "react";
import PageHeader from "../../../components/Admin/PageHeader";
import EventIcon from "@material-ui/icons/Event";
import AddIcon from "@material-ui/icons/Add";
import Controls from "../controls/Controls";
import useTable from "../useTable";
import * as eventService from "../services/eventService";
import { Close, EditOutlined, Search } from "@material-ui/icons";
import {
  InputAdornment,
  Paper,
  TableBody,
  TableCell,
  TableRow,
  Toolbar,
} from "@material-ui/core";
import { EventContext } from "../../../components/Events/EventContext";
import Notification from "../controls/Notification";
import ConfirmDialog from "../controls/ConfirmDialog";
import { GetAllImages } from "../services/imageService";
import { ImageContext } from "../../../components/Image/ImageContext";
import { useStylesPaper } from "../theme.js";
import EventFromRefactored from "./EventFrom_refactored";
import { Forms } from "../forms";
import { LazyLoadImage } from "../../../components/LazyLoadImage";

const headCells = [
  { id: "title", label: "Title" },
  { id: "titleENG", label: "Title (ENG)" },
  { id: "subtitle", label: "Subtitle" },
  { id: "subtitleENG", label: "Subtitle (ENG)" },
  { id: "place", label: "Place" },
  { id: "date", label: "Date" },
  { id: "image", label: "Image" },
  { id: "actions", label: "Actions", disableSorting: true },
];

const Events = () => {
  const pageTitle = "Events";
  const [events, setEvents] = useContext(EventContext);
  const [images, setImages] = useContext(ImageContext);
  const classes = useStylesPaper();
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const { handleClose, handleShow, showModal, carry } = Forms();

  const [filterFn, setFilterFn] = useState({
    fn: (items) => items,
  });

  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
    useTable(events, headCells, filterFn);

  const handleSearch = (e) => {
    let target = e.target;
    setFilterFn({
      fn: (items) => {
        if (target.value === "") return items;
        return items.filter((x) =>
          x.titleENG.toLowerCase().includes(target.value.toLowerCase())
        );
      },
    });
  };

  const upload = async (event) => {
    try {
      if (!event.id) {
        // Insert new event
        await eventService.InsertEvent(event);
      } else {
        // Update existing event
        await eventService.UpdateEvent(event);
      }

      setNotify({
        isOpen: true,
        message: "Submitted Successfully",
        type: "success",
      });

      fetchEvents();
    } catch (error) {
      console.error("Error uploading event:", error);
      setNotify({
        isOpen: true,
        message: "Failed to submit event",
        type: "error",
      });
    }
  };

  const onDelete = async (id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    await eventService.DeleteEvent(id);
    setNotify({
      isOpen: true,
      message: "Deleted Successfully",
      type: "error",
    });
    fetchEvents();
  };

  const fetchEvents = useCallback(async () => {
    try {
      const res = await eventService.GetAllEvents();
      setEvents(res.data);
    } catch (error) {
      console.error("Error fetching events:", error);
      setNotify({
        isOpen: true,
        message: "Failed to fetch events",
        type: "error",
      });
    }
  }, [setEvents]);

  const fetchImages = useCallback(async () => {
    try {
      const res = await GetAllImages();
      setImages(res.data);
    } catch (error) {
      console.error("Error fetching images:", error);
      setNotify({
        isOpen: true,
        message: "Failed to fetch images",
        type: "error",
      });
    }
  }, [setImages]);

  useEffect(() => {
    fetchEvents();
    fetchImages();
  }, [fetchEvents, fetchImages]);

  return (
    <>
      <PageHeader title={pageTitle} icon={<EventIcon />}></PageHeader>
      <Paper className={classes.pageContent}>
        <Toolbar>
          <Controls.Input
            label="Search"
            className={classes.searchInput}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            onChange={handleSearch}
          ></Controls.Input>
          <Controls.Button
            text="Add New"
            variant="outlined"
            startIcon={<AddIcon />}
            className={classes.newButton}
            onClick={() => {
              carry.current = {};
              handleShow();
            }}
          ></Controls.Button>
        </Toolbar>
        <TblPagination></TblPagination>
        <TblContainer>
          <TblHead></TblHead>
          <TableBody>
            {recordsAfterPagingAndSorting().map((event) => (
              <TableRow key={event.id}>
                <TableCell className={classes.narrowCell}>
                  {event.title}
                </TableCell>
                <TableCell className={classes.narrowCell}>
                  {event.titleENG}
                </TableCell>
                <TableCell className={classes.narrowCell}>
                  {event.subtitle}
                </TableCell>
                <TableCell className={classes.narrowCell}>
                  {event.subtitleENG}
                </TableCell>
                <TableCell className={classes.narrowCell}>
                  {event.place}
                </TableCell>
                <TableCell className={classes.narrowCell}>
                  {new Date(event.date).toDateString()}
                  {" - "}
                  {new Date(event.date).toLocaleTimeString("en-US", {
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                </TableCell>
                <TableCell className={classes.narrowCell}>
                  <LazyLoadImage
                    src={event.image}
                    alt="img"
                    style={{ maxWidth: "50px", maxHeight: "50px" }}
                    className={classes.Image}
                  />
                </TableCell>
                <TableCell>
                  <Controls.ActionButton
                    color="primary"
                    onClick={() => {
                      carry.current = event;
                      handleShow();
                    }}
                  >
                    <EditOutlined fontSize="small"></EditOutlined>
                  </Controls.ActionButton>
                  <Controls.ActionButton
                    color="secondary"
                    onClick={() => {
                      setConfirmDialog({
                        isOpen: true,
                        title: "Are you sure to delete this record?",
                        subTitle: "You can't undo this operation",
                        onConfirm: () => {
                          onDelete(event.id);
                        },
                      });
                    }}
                  >
                    <Close fontSize="small"></Close>
                  </Controls.ActionButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </TblContainer>
      </Paper>
      <EventFromRefactored
        showModal={showModal}
        handleClose={handleClose}
        upload={upload}
        pageTitle={pageTitle}
        carry={carry.current}
        images={images}
      />
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
};

export default Events;
