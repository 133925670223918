import http from "./http-auth";

export function InsertItem(data) {
  return http.post("/media", data).then((res) => {});
}

export function DeleteItem(data) {
  return http.delete(`/media/${data}`).then((res) => {});
}

export function UpdateItem(data) {
  return http.put(`/media/${data.id}`, data).then((res) => {});
}

export function GetItem(data) {
  return http.get(`/media/${data.id}`, data).then((res) => {});
}

export const GetAllItems = () => {
  return http.get("/media");
};
