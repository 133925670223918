import React, { useState, createContext } from "react";

export const EventContext = createContext();

export const EventProvider = (props) => {
  const [events, setEvents] = useState([
    {
      id: "db_not_connected",
      title: "Database not connected",
      subtitle: "",
      text: "Database not connected",
      buttonLabel: "",
      dark: true,
      primary: true,
      link: "home",
    },
    {
      id: "events_hero",
      title: "Events",
      subtitle: "",
      text: "Let me show you my world of music in person.",
      buttonLabel: "",
      dark: true,
      primary: true,
      link: "home",
    },
  ]);
  return (
    <EventContext.Provider value={[events, setEvents]}>
      {props.children}
    </EventContext.Provider>
  );
};
