import {
  createMuiTheme,
  CssBaseline,
  Drawer,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core";
import React from "react";
import Header from "../../../components/Admin/Header";
import red from "@material-ui/core/colors/red";

import MyDrawer from "../../../components/Admin/Drawer";
import { ProgramProvider } from "../../Media/Programs/ProgramContext";
import Programs from "./Programs";
import { useStyles, theme } from "../theme.js";

const ProgramAdminPage = (props) => {
  const classes = useStyles();

  return (
    <ProgramProvider>
      <ThemeProvider theme={theme}>
        <Drawer
          className={classes.drawer}
          variant="permanent"
          classes={{
            paper: classes.drawerPaper,
          }}
          anchor="left"
        >
          <MyDrawer></MyDrawer>
        </Drawer>
        <div className={classes.adminMain}>
          <Header></Header>
          <Programs></Programs>
        </div>
        <CssBaseline></CssBaseline>
      </ThemeProvider>
    </ProgramProvider>
  );
};

export default ProgramAdminPage;
