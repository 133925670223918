import React from "react";
import {
  Card,
  CardContainer,
  CardDate,
  CardWrapper,
  CardAbout,
} from "./BigBlogCardElements";
import { BackgroundImageTop } from "../BackgroundImage";
import parse from "html-react-parser";

const BigEventCardSection = (props) => {
  const date = new Date(props.date);

  return (
    <CardContainer>
      <BackgroundImageTop backgroundImage={props.image}></BackgroundImageTop>
      <CardWrapper>
        <Card>
          <CardDate>Date: {date.toDateString()}</CardDate>
          <CardAbout>{parse(props.aboutENG)}</CardAbout>
        </Card>
      </CardWrapper>
    </CardContainer>
  );
};

export default BigEventCardSection;
