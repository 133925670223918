import React, { useContext, useEffect } from "react";
import { CardContext } from "./CardContext";
import {
  Card,
  CardContainer,
  CardH1,
  CardP,
  CardLine,
  CardWrapper,
} from "./SmallCardElements";
import { BackgroundImage, BackgroundImageFluteCard } from "../BackgroundImage";
import { LanguageContext } from "../LanguageContext";
import * as service from "../../pages/Admin/services/homeCardService";

const SmallCardSection = (props) => {
  const [cards, setCards] = useContext(CardContext);
  const [language, setLanguage] = useContext(LanguageContext);
  const getItems = () => {
    let get = service.GetAllItems();
    get.then((res) => {
      let data = res.data;
      setCards(data);
    });
  };

  useEffect(() => {
    (async () => {
      getItems();
    })();
  }, []);

  return (
    <CardContainer>
      <BackgroundImageFluteCard backgroundImage={props.backgroundImage}>
        <CardWrapper>
          {cards.map((item) => {
            if (item.scroll === props.scroll) {
              return (
                <Card>
                  <CardH1>
                    {language[0].eng === "true" ? item.titleENG : item.title}
                  </CardH1>
                  <hr
                    style={{
                      backgroundColor: "#000",
                      color: "#000",
                      height: "1px",
                      width: "2rem",
                    }}
                  ></hr>
                  <CardP>
                    {language[0].eng === "true"
                      ? item.subtitleENG
                      : item.subtitle}
                  </CardP>
                  <hr
                    style={{
                      backgroundColor: "#000",
                      color: "#000",
                      height: "1px",
                      width: "2rem",
                    }}
                  ></hr>
                </Card>
              );
            }
          })}
        </CardWrapper>
      </BackgroundImageFluteCard>
    </CardContainer>
  );
};

export default SmallCardSection;
