import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import "firebase/storage";
import "firebase/auth";
import "firebase/firestore";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCuvrHKm3ajCyx-_gU-X1gsrOTFAIo3cVg",
  authDomain: "noemigyori.firebaseapp.com",
  projectId: "noemigyori",
  storageBucket: "noemigyori.appspot.com",
  messagingSenderId: "179529627492",
  appId: "1:179529627492:web:9a9a0babb282d4dd4cec71",
  measurementId: "G-2H25HBM7P0",
};

export const firebaseApp = initializeApp(firebaseConfig);

export const auth = getAuth(firebaseApp);
export const firebaseStorage = getStorage(firebaseApp);
