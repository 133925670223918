import styled from "styled-components";

export const HeroContainer = styled.div`
  margin-top: 80px;
  background: #e5e5e5;
  display: flex;
  justify-content: center;
  align-item: center;
  height: auto;
  position: relative;
  z-index: 1;

  @media screen and (max-width: 1680px) {
  }
  @media screen and (max-width: 1280px) {
  }
  @media screen and (max-width: 980px) {
  }
  @media screen and (max-width: 736px) {
  }
  @media screen and (max-width: 480px) {
  }
`;

export const HeroContent = styled.div`
  z-index: 3;
  width: 100%;
  height: 100%;
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  @media screen and (max-width: 480px) {
    height: auto;
  }
`;

export const HeroContentEventsCard = styled.div`
  z-index: 3;
  margin-left: auto;
  margin-right: auto;
  width: 60.9375vw;
  max-width: 1140px;
  height: 100%;
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  @media screen and (max-width: 980px) {
    width: 88.88vw;
    max-width: unset;
  }

  @media screen and (max-width: 480px) {
    height: min-content;
    width: 100%;
  }
`;

export const HeroTextWrapper = styled.div`
  z-index: 3;
  height: min-content;
  padding: 8px 24px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  @media screen and (max-width: 480px) {
    height: 100%;
    width: 100%;
  }
`;

export const HeroH1 = styled.h1`
  margin-top: 1.5rem;
  color: #000;
  display: flex;
  font-size: 3rem;
  text-align: center;
  font-family: playfairDisplay !important;

  @media screen and (max-width: 480px) {
    font-size: 36px;
  }
`;

export const HeroH2 = styled.h1`
  margin-top: 0.5rem;
  color: #000;
  display: flex;
  font-size: 20px;
  text-align: center;

  font-family: playfairDisplay !important;

  @media screen and (max-width: 480px) {
    font-size: 20px;
  }
`;

export const HeroP = styled.p`
  margin-top: 2.5rem;
  display: flex;
  color: #000;
  font-size: 20px;
  text-align: center;
  max-width: 50%;
  font-style: italic;
  font-family: playfairDisplay !important;

  @media screen and (max-width: 480px) {
    font-size: 20px;
    max-width: 100%;
  }
`;

export const HeroBtnWrapper = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
