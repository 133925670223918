import React, { useContext } from "react";
import {
  Card,
  CardContainer,
  CardWrapper,
  CardAbout,
} from "./BigSpecCardElements";
import parse from "html-react-parser";
import { LanguageContext } from "../LanguageContext";

const BigSpecCardSection = (props) => {
  const [language, setLanguage] = useContext(LanguageContext);
  return (
    <CardContainer>
      <CardWrapper>
        <Card>
          <CardAbout>
            {language[0].eng === "true"
              ? parse(props.bio[0].content)
              : parse(props.bio[1].content)}
          </CardAbout>
        </Card>
      </CardWrapper>
    </CardContainer>
  );
};

export default BigSpecCardSection;
