import React, { useState, createContext } from "react";

export const InfoContext = createContext();

export const InfoProvider = (props) => {
  const [abouts, setAbouts] = useState([
    {
      id: "events",
      lightBg: true,
      lightText: false,
      lightTextDesc: true,
      topLine: "Premium",
      headline: "Unlimited trans",
      description: "get get eget get get",
      buttonLabel: "Get started",
      imgStart: false,
      dark: true,
      primary: true,
      darkText: true,
    },
  ]);
  return (
    <InfoContext.Provider value={[abouts, setAbouts]}>
      {props.children}
    </InfoContext.Provider>
  );
};
