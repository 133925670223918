import React from "react";
import {
  Card,
  CardContainer,
  CardWrapper,
  CardAbout,
} from "./BigSpecCardElements";
import parse from "html-react-parser";
import { PlayerWrap } from "../Specialities/BigSpecCardElements";
import ReactPlayer from "react-player";

const BigSpecCardSection = (props) => {
  return (
    <CardContainer>
      <CardWrapper>
        <Card>
          <CardAbout>{parse(props.aboutENG)}</CardAbout>
          {props.titleENG === "THE SOLOIST" ? (
            <PlayerWrap>
              <ReactPlayer url="https://www.youtube.com/watch?v=Ry40SabmOVE" />
            </PlayerWrap>
          ) : (
            ""
          )}
          {props.titleENG === "SZÓLISTA" ? (
            <PlayerWrap>
              <ReactPlayer url="https://www.youtube.com/watch?v=Ry40SabmOVE" />
            </PlayerWrap>
          ) : (
            ""
          )}
        </Card>
      </CardWrapper>
    </CardContainer>
  );
};

export default BigSpecCardSection;
