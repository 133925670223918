import React, { useContext, useEffect } from "react";
import HeroPageSection from "../../components/HeroSection/HeroPage";
import {
  HeroText,
  Text,
  ShopBox,
  ShopWrap,
  HeroTextP,
  TextP,
  AlbumIcon,
  AlbumIconWrapper,
  TextWrapper,
  HeroPositionContainer,
} from "../../components/ShopSection";
import { ShopContext } from "./ShopContext";
import HeroBgImg from "../../components/images/shop.jpg";
import { LanguageContext } from "../../components/LanguageContext";
import * as service from "../../pages/Admin/services/shopService";
import { LazyLoadImage } from "../../components/LazyLoadImage";

const ShopPage = () => {
  const [language] = useContext(LanguageContext);
  const [shop, setShop] = useContext(ShopContext);

  useEffect(() => {
    (async () => {
      const getItems = () => {
        let get = service.GetAllItems();
        get.then((res) => {
          let data = res.data;
          setShop(data);
        });
      };
      getItems();
    })();
  }, [setShop]);

  return (
    <>
      <HeroPageSection backgroundImage={HeroBgImg}>
        <HeroPositionContainer>
          <HeroText>{language[0].eng === "true" ? "Shop" : "Bolt"}</HeroText>
          <HeroTextP>
            {language[0].eng === "true"
              ? "Have some music with your morning coffee."
              : "Vidd haza a fuvola hangjait!"}
          </HeroTextP>
          <HeroTextP>
            {language[0].eng === "true"
              ? "Pepper your lunch with notes."
              : "Hallgass, nézz, olvass, és add át magad"}
          </HeroTextP>
          <HeroTextP>
            {language[0].eng === "true"
              ? "Watch an opera on a Saturday."
              : " az érzésnek, hogy igazán átéld a zenémet!"}
          </HeroTextP>
        </HeroPositionContainer>
      </HeroPageSection>
      <ShopBox>
        {shop.map((item) => {
          if (
            item.title === "Haydn and Mozart Quartets" ||
            item.title ===
              "Doppler and Kuhlau: Romantic and Virtuoso Music for Flutes and Piano"
          ) {
            return (
              <a
                rel="noreferrer"
                target="_blank"
                href={item.link}
                style={{ textDecoration: "none", border: "none" }}
              >
                <ShopWrap>
                  <AlbumIconWrapper>
                    <AlbumIcon>
                      <img
                        src={item.image}
                        alt="img"
                        style={{ maxWidth: "100%" }}
                      />
                    </AlbumIcon>
                  </AlbumIconWrapper>
                  <TextWrapper>
                    <Text>
                      {language[0].eng === "true" ? item.titleENG : item.title}
                    </Text>
                    <TextP>
                      {language[0].eng === "true"
                        ? item.subtitleENG
                        : item.subtitle}
                    </TextP>
                    <TextP>{item.price}</TextP>
                  </TextWrapper>
                </ShopWrap>
              </a>
            );
          }
          return null;
        })}
        {shop.map((item) => {
          if (
            item.title !== "Haydn and Mozart Quartets" &&
            item.title !==
              "Doppler and Kuhlau: Romantic and Virtuoso Music for Flutes and Piano"
          ) {
            return (
              <a
                rel="noreferrer"
                href={item.link}
                style={{ textDecoration: "none", border: "none" }}
              >
                <ShopWrap>
                  <AlbumIconWrapper>
                    <AlbumIcon>
                      <img
                        src={item.image}
                        alt="img"
                        style={{ maxWidth: "100%" }}
                      />
                    </AlbumIcon>
                  </AlbumIconWrapper>
                  <TextWrapper>
                    <Text>
                      {language[0].eng === "true" ? item.titleENG : item.title}
                    </Text>
                    <TextP>
                      {language[0].eng === "true"
                        ? item.subtitleENG
                        : item.subtitle}
                    </TextP>
                    <TextP>{item.price}</TextP>
                  </TextWrapper>
                </ShopWrap>
              </a>
            );
          }
          return null;
        })}
      </ShopBox>
    </>
  );
};

export default ShopPage;
