import React from "react";
import { Container } from "react-bootstrap";
import {
  AuthContainer,
  BackgroundImage,
  ContentHolder,
} from "../../components/Signin/NewSingin/AuthElements";
import { AuthProvider } from "../../auth/AuthContext";
import ForgotPassword from "../../components/Signin/NewSingin/ForgotPasswordContent";

export default function ForgotPasswordPage() {
  return (
    <ContentHolder>
      <AuthContainer>
        <Container className="d-flex align-items-center justify-content-center">
          <div className="w-100" style={{ maxWidth: "400px" }}>
            <AuthProvider>
              <ForgotPassword />
            </AuthProvider>
          </div>
        </Container>
      </AuthContainer>
    </ContentHolder>
  );
}
