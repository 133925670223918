import http from "./http-auth";

export function InsertItem(data) {
  return http.post("/carousels", data).then((res) => {});
}

export function DeleteItem(data) {
  return http.delete(`/carousels/${data}`).then((res) => {});
}

export function UpdateItem(data) {
  return http.put(`/carousels/${data.id}`, data).then((res) => {});
}

export function GetItem(data) {
  return http.get(`/carousels/${data.id}`, data).then((res) => {});
}

export const GetAllItems = () => {
  return http.get("/carousels");
};
