import {
  createMuiTheme,
  CssBaseline,
  Drawer,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core";
import React from "react";
import Header from "../../../components/Admin/Header";
import MyDrawer from "../../../components/Admin/Drawer";
import { PressProvider } from "../../Media/Press/PressContext";
import Press from "./Press";
import { ImageProvider } from "../../../components/Image/ImageContext";
import { FileProvider } from "../../../components/File/FileContext";

import { useStyles, theme } from "../theme.js";

const PressAdminPage = (props) => {
  const classes = useStyles();

  return (
    <ImageProvider>
      <FileProvider>
        <PressProvider>
          <ThemeProvider theme={theme}>
            <Drawer
              className={classes.drawer}
              variant="permanent"
              classes={{
                paper: classes.drawerPaper,
              }}
              anchor="left"
            >
              <MyDrawer></MyDrawer>
            </Drawer>
            <div className={classes.adminMain}>
              <Header></Header>
              <Press></Press>
            </div>
            <CssBaseline></CssBaseline>
          </ThemeProvider>
        </PressProvider>
      </FileProvider>
    </ImageProvider>
  );
};

export default PressAdminPage;
