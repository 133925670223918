import React, { useContext } from "react";
import {
  ButtonAboutMeBlackChamber,
  ButtonAboutMeBlackOrch,
} from "../../ButtonElement";
import { LanguageContext } from "../../LanguageContext";
import { HeroBtnWrapperAlignEnd } from "../HeroPageElements";
import { HeroH1Orch, HeroPositionContainer } from "../HeroSpecPageElements";

const OrchestralHero = (props) => {
  const [language, setLanguage] = useContext(LanguageContext);
  return (
    <HeroPositionContainer id="HeroPosition">
      {language[0].eng === "true" ? (
        <HeroH1Orch eng={true}>{props.title}</HeroH1Orch>
      ) : (
        <HeroH1Orch eng={false}>{props.title}</HeroH1Orch>
      )}
      <HeroBtnWrapperAlignEnd>
        <ButtonAboutMeBlackOrch to={"/bio"}>
          {language[0].eng === "true" ? "See full BIO here" : "Teljes életrajz"}
        </ButtonAboutMeBlackOrch>
      </HeroBtnWrapperAlignEnd>
    </HeroPositionContainer>
  );
};

export default OrchestralHero;
