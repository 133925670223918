import React, { useState, createContext } from "react";
import quote1Bg from "../images/quote1.png";
import quote2Bg from "../images/quote2.png";

export const CarouselQuoteContext = createContext();

export const CarouselQuoteProvider = (props) => {
  const [carouselQuotes, setCarouselQuotes] = useState([
    {
      id: "quotes",
      image: quote1Bg,
      title: `"…stylistically idiomatic, devoted, gripping, accurate and masterful performance…"`,
      subtitle: "Zoltán Kocsis about Noemi Gyori",
    },
    {
      id: "quotes",
      image: quote2Bg,
      title: `"…especially refined and concentrated, attentive musicality, resulting in true chamber music playing."`,
      text: "Revizor about Noemi Gyori",
    },
  ]);
  return (
    <CarouselQuoteContext.Provider value={[carouselQuotes, setCarouselQuotes]}>
      {props.children}
    </CarouselQuoteContext.Provider>
  );
};
