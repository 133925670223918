import React, { useState, createContext } from "react";
import Icon1 from "../images/icon_about1.png";
import Icon2 from "../images/icon_about2.png";
import Icon3 from "../images/icon_about3.png";
import Icon4 from "../images/icon_about4.png";

export const AboutContext = createContext();

export const AboutProvider = (props) => {
  const [abouts, setAbouts] = useState([
    {
      headline: "The Soloist",
      description:
        "As a solo artist, I highlight the flute’s wittiness, virtuosity, sensitivity and drive in the most colourful way possible.",
      buttonLabel: "Tell me more",
      dark: true,
      primary: true,
      icon: Icon1,
      link: "/the-solo-musician",
    },
    {
      headline: "The Chamber Musician",
      description:
        "To me, chamber music is all about connection. It is a very personal and fascinating way to make music, with each player shaping their own strand in a lively conversation.",
      buttonLabel: "Tell me more",
      dark: true,
      primary: true,
      icon: Icon2,
      link: "/the-chamber-musician",
    },
    {
      headline: "The Orchestral Flutist",
      description:
        "Orchestral playing to me is about flexibility, resonance, and discovering the capacity and joy of creating a unified interpretation.",
      buttonLabel: "Tell me more",
      dark: true,
      primary: true,
      icon: Icon3,
      link: "/the-orchestral-flutist",
    },
    {
      headline: "The Teacher",
      description:
        "Sharing knowledge is vital to my art, which is built upon interaction, exploration, refining expression and pushing music's boundaries.",
      buttonLabel: "Tell me more",
      dark: true,
      primary: true,
      icon: Icon4,
      link: "/the-flute-teacher",
    },
  ]);
  return (
    <AboutContext.Provider value={[abouts, setAbouts]}>
      {props.children}
    </AboutContext.Provider>
  );
};
