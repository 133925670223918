import React from "react";
import { BioProvider } from "../../components/Bio/BioContext";
import { LanguageProvider } from "../../components/LanguageContext";
import BioPage from "./BioPage";
import { animateScroll as scroll } from "react-scroll";

const Bio = () => {
  scroll.scrollToTop();
  return (
    <LanguageProvider>
      <BioProvider>
        <BioPage></BioPage>
      </BioProvider>
    </LanguageProvider>
  );
};

export default Bio;
