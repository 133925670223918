import React, { useContext, useEffect } from "react";
import { CardContext } from "./CardContext";
import {
  Card,
  CardContainer,
  CardH1,
  CardP,
  CardWrapper,
} from "./ShopCardElements";
import {
  BackgroundImage,
  BackgroundImageShopOpacity,
} from "../BackgroundImage";
import { BtnWrap } from "../InfoSection/InfoElements";
import { Button, ButtonShop } from "../ButtonElement";
import { LanguageContext } from "../LanguageContext";
import * as service from "../../pages/Admin/services/homeCardService";
import { EmptySection } from "./BioCardElements";

const ShopCardSection = (props) => {
  const [cards, setCards] = useContext(CardContext);
  const [language, setLanguage] = useContext(LanguageContext);

  const getItems = () => {
    let get = service.GetAllItems();
    get.then((res) => {
      let data = res.data;
      setCards(data);
    });
  };

  useEffect(() => {
    (async () => {
      getItems();
    })();
  }, []);
  let textArray;
  let textArrayENG;
  return (
    <CardContainer id={props.scroll}>
      <BackgroundImageShopOpacity backgroundImage={props.backgroundImage}>
        <CardWrapper>
          {cards.map((item) => {
            if (item.scroll === props.scroll) {
              {
                textArray = item.subtitle.replaceAll(".", ".\n").split("\n");
              }
              {
                textArrayENG = item.subtitleENG
                  .replaceAll(".", ".\n")
                  .split("\n");
              }
              return (
                <Card>
                  <CardH1>
                    {language[0].eng === "true" ? item.titleENG : item.title}
                  </CardH1>
                  <CardP>
                    {language[0].eng === "true"
                      ? textArrayENG[0]
                      : textArray[0]}
                  </CardP>
                  <CardP>
                    {language[0].eng === "true"
                      ? textArrayENG[1]
                      : textArray[1]}
                  </CardP>
                  <CardP>
                    {language[0].eng === "true"
                      ? textArrayENG[2]
                      : textArray[2]}
                  </CardP>
                  <CardP>
                    {language[0].eng === "true"
                      ? textArrayENG[3]
                      : textArray[3]}
                  </CardP>
                  <BtnWrap style={{ padding: "0px" }}>
                    <ButtonShop
                      to={item.link}
                      smooth="true"
                      duration={500}
                      spy="true"
                      offset={-80}
                      primary="false"
                      dark={false}
                    >
                      {language[0].eng === "true"
                        ? item.buttonLabelENG
                        : item.buttonLabel}
                    </ButtonShop>
                  </BtnWrap>
                </Card>
              );
            }
          })}
        </CardWrapper>
      </BackgroundImageShopOpacity>
    </CardContainer>
  );
};

export default ShopCardSection;
