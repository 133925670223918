import React, { useContext, useEffect } from "react";
import { Button, ButtonSimple } from "../ButtonElement";
import {
  AboutCard,
  AboutH1,
  AboutIcon,
  AboutP,
  BtnWrap,
} from "./AboutElements";
import { LanguageContext } from "../LanguageContext";

const AboutCards = ({
  title,
  subtitle,
  buttonLabel,
  titleENG,
  subtitleENG,
  buttonLabelENG,
  image,
  keyProp,
}) => {
  const [language, setLanguage] = useContext(LanguageContext);

  useEffect(() => {
    (async () => {
      // getItems();
    })();
  }, []);
  return (
    <AboutCard key={keyProp}>
      <AboutIcon>
        <img src={image} style={{ maxWidth: "100%" }} alt="soloist" />
      </AboutIcon>
      <AboutH1>{language[0].eng === "true" ? titleENG : title}</AboutH1>
      {titleENG === "The Chamber Musician" ? (
        <AboutP style={{ width: "14rem" }}>
          {language[0].eng === "true" ? subtitleENG : subtitle}
        </AboutP>
      ) : (
        <AboutP>{language[0].eng === "true" ? subtitleENG : subtitle}</AboutP>
      )}
      {titleENG === "The Soloist" ? (
        <BtnWrap>
          <ButtonSimple
            to={`/${language[0].eng === "true" ? "the-soloist" : "szolista"}`}
            smooth="true"
            duration={500}
            spy="true"
            offset={-80}
            primary="true"
            dark="true"
          >
            {language[0].eng === "true" ? buttonLabelENG : buttonLabel}
          </ButtonSimple>
        </BtnWrap>
      ) : (
        ""
      )}
      {titleENG === "The Chamber Musician" ? (
        <BtnWrap>
          <ButtonSimple
            to={`/${
              language[0].eng === "true"
                ? "the-chamber-musician"
                : "kamarazenesz"
            }`}
            smooth="true"
            duration={500}
            spy="true"
            offset={-80}
            primary="true"
            dark="true"
          >
            {language[0].eng === "true" ? buttonLabelENG : buttonLabel}
          </ButtonSimple>
        </BtnWrap>
      ) : (
        ""
      )}
      {titleENG === "The Orchestral Flutist" ? (
        <BtnWrap>
          <ButtonSimple
            to={`/${
              language[0].eng === "true"
                ? "the-orchestral-flutist"
                : "zenekari-fuvolas"
            }`}
            smooth="true"
            duration={500}
            spy="true"
            offset={-80}
            primary="true"
            dark="true"
          >
            {language[0].eng === "true" ? buttonLabelENG : buttonLabel}
          </ButtonSimple>
        </BtnWrap>
      ) : (
        ""
      )}
      {titleENG === "The Teacher" ? (
        <BtnWrap>
          <ButtonSimple
            to={`/${
              language[0].eng === "true" ? "the-flute-teacher" : "tanar"
            }`}
            smooth="true"
            duration={500}
            spy="true"
            offset={-80}
            primary="true"
            dark="true"
          >
            {language[0].eng === "true" ? buttonLabelENG : buttonLabel}
          </ButtonSimple>
        </BtnWrap>
      ) : (
        ""
      )}
    </AboutCard>
  );
};

export default AboutCards;
