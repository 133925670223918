import React, { useContext } from "react";
import {
  HeroContainer,
  HeroContent,
  HeroH1,
  HeroTextWrapper,
  HeroPBio,
  HeroBtnWrapper,
  HeroH1Bio,
  HeroBeyonP,
  HeroH1Black,
  HeroPTeacher,
  HeroH1Beyond,
  HeroH1Teacher,
  HeroH1Solo,
  HeroPSolo,
  HeroP2Solo,
  HeroBtnWrapperTeacher,
  HeroBtnWrapperSolo,
  HeroBtnWrapperBeyond,
  HeroH1Chamber,
  HeroH1Orch,
  HeroH1Doc,
  HeroP2Doc,
  HeroPDoc,
  HeroPositionContainer,
  HeroPositionContainerTeacher,
  HeroPositionContainerSolo,
  HeroPContainerRight,
  HeroPositionContainerBeyond,
  HeroPositionContainerContemp,
  HeroPositionContainerBaroque,
  HeroPositionContainerDoc,
  HeroPositionContainerBio,
  HeroBtnWrapperBio,
  HeroPositionContainerChamber,
} from "./HeroSpecPageElements";
import { BackgroundImageTopOpacity } from "../BackgroundImage";
import { LanguageContext } from "../LanguageContext";
import {
  ButtonAboutMeBlackTeacher,
  ButtonAboutMeBlackSolo,
  ButtonAboutMeBlackChamber,
  ButtonBio,
  ButtonAboutMeBeyond,
  ButtonAboutMeBlackOrch,
} from "../ButtonElement";
import BioHero from "./SpecHeroes/BioHero";
import BaroqueHero from "./SpecHeroes/BaroqueHero";
import TeacherHero from "./SpecHeroes/TeacherHero";
import SoloistHero from "./SpecHeroes/SoloistHero";
import ChamberHero from "./SpecHeroes/ChamberHero";
import OrchestralHero from "./SpecHeroes/OrchestralHero";
import DoctoralHero from "./SpecHeroes/DoctoralHero";
import BeyondMusicHero from "./SpecHeroes/BeyondMusicHero";
import ConTempHero from "./SpecHeroes/ConTempHero";

const HeroSpecPageSection = (props) => {
  const [language, setLanguage] = useContext(LanguageContext);
  let biotext;
  let biotitle;
  let doctext;
  let docsmall;

  language[0].eng === "true"
    ? (doctext = `“Enchanting new transcriptions for an ideal instrumental pairing.”`)
    : (doctext = `“Elbűvölő új átiratok, egy ideális hangszerpárosításra.”`);

  language[0].eng === "true"
    ? (docsmall = `Michael Beek, BBC Music Magazine, UK on Transforming Traditions`)
    : (docsmall = `Michael Beek, BBC Music Magazine, Anglia a Transforming Traditions albumról`);

  if (props.bioText) {
    {
      biotitle =
        language[0].eng === "true" ? props.bio[0].title : props.bio[1].title;
    }
    {
      biotext =
        language[0].eng === "true"
          ? "Hailed for her passionate and vibrant performances as well as the creative, elaborate interpretations that have captivated audiences worldwide, Noemi Gyori is quickly establishing a name in the classical music scene as an exceptional and versatile flutist, equally in demand as soloist, chamber musician, orchestral flutist and pedagogue."
          : "Szenvedélyes, vibráló előadásai, valamint kreatív és kidolgozott zenei interpretációi világszerte elbűvölik a közönséget. Nem csoda, hogy Győri Noémi hamar elismerésre tett szert a komolyzenei szcénában, ahol olyan kivételes tehetségű, sokoldalú művészként tartják számon, aki egyszerre keresett szólista, kamarazenész, zenekari fuvolás és pedagógus.";
    }
  }
  return (
    <HeroContainer id={props.id}>
      <BackgroundImageTopOpacity
        backgroundImage={props.backgroundImage}
      ></BackgroundImageTopOpacity>
      <HeroContent>
        {props.bioText ? (
          <BioHero biotitle={biotitle} biotext={biotext} />
        ) : props.title == "BAROQUE & CLASSICAL FLUTE" ||
          props.title == "BAROKK & KLASSZIKUS FUVOLA" ? (
          <BaroqueHero title={props.title} />
        ) : props.titleENG == "THE TEACHER" || props.titleENG == "TANÁR" ? (
          <TeacherHero title={props.titleENG} />
        ) : props.titleENG == "THE SOLOIST" || props.titleENG == "SZÓLISTA" ? (
          <SoloistHero title={props.titleENG} />
        ) : props.titleENG == "THE CHAMBER MUSICIAN" ||
          props.titleENG == "KAMARAZENÉSZ" ? (
          <ChamberHero title={props.titleENG} />
        ) : props.titleENG == "THE ORCHESTRAL FLUTIST" ||
          props.titleENG == "ZENEKARI FUVOLÁS" ? (
          <OrchestralHero title={props.titleENG} />
        ) : props.title == "DOCTORAL STUDIES AT THE ROYAL ACADEMY OF MUSIC" ||
          props.title ==
            "DOKTORI TANULMÁNYOK A LONDONI KIRÁLYI ZENEAKADÉMIÁN" ? (
          <DoctoralHero
            title={props.title}
            doctext={doctext}
            docsmall={docsmall}
          />
        ) : props.title == "BEYOND MUSIC" || props.title == "A ZENÉN IS TÚL" ? (
          <BeyondMusicHero title={props.title} />
        ) : (
          <ConTempHero title={props.title} />
        )}
      </HeroContent>
    </HeroContainer>
  );
};

export default HeroSpecPageSection;
