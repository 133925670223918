import React, { useContext, useEffect } from "react";
import HeroPageSection from "../../../components/HeroSection/HeroPage";
import {
  HeroText,
  Text,
  PressBox,
  PressWrap,
  PressIcon,
} from "../../../components/PressSection";
import { PressContext } from "./PressContext";
import HeroBgImg from "../../../components/images/press_hero.jpg";
import { LanguageContext } from "../../../components/LanguageContext";
import { ButtonInterview } from "../../../components/ButtonElement";
import * as service from "../../Admin/services/pressService";
import { HeroPositionContainerMedia } from "../../../components/InterviewSection";

const PressPage = () => {
  const [language] = useContext(LanguageContext);
  const [press, setPress] = useContext(PressContext);

  useEffect(() => {
    (async () => {
      const getItems = () => {
        let get = service.GetAllItems();
        get.then((res) => {
          let data = res.data;
          setPress(data);
        });
      };

      getItems();
    })();
  }, [setPress]);

  return (
    <>
      <HeroPageSection backgroundImage={HeroBgImg}>
        <HeroPositionContainerMedia>
          <HeroText>{language[0].eng === "true" ? "Press" : "Sajtó"}</HeroText>{" "}
        </HeroPositionContainerMedia>
      </HeroPageSection>
      {press.map((item) => {
        return (
          <PressBox>
            <PressWrap>
              <PressIcon>
                <img src={item.image} alt="img" style={{ maxWidth: "100%" }} />
              </PressIcon>
              <Text>
                {language[0].eng === "true" ? item.titleENG : item.title}
              </Text>
              {language[0].eng === "true" ? (
                <ButtonInterview
                  onClick={() => {
                    window.open(item.linkENG);
                  }}
                >
                  {language[0].eng === "true"
                    ? item.buttonLabelENG
                    : item.buttonLabel}
                </ButtonInterview>
              ) : (
                <ButtonInterview
                  onClick={() => {
                    window.open(item.link);
                  }}
                >
                  {language[0].eng === "true"
                    ? item.buttonLabelENG
                    : item.buttonLabel}
                </ButtonInterview>
              )}
            </PressWrap>
          </PressBox>
        );
      })}
    </>
  );
};

export default PressPage;
