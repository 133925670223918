import React, { useState } from "react";
import Sidebar from "../../components/Sidebar";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import AboutPageDynamic from "../../components/AboutPage/AboutPageDynamic";
import { LanguageProvider } from "../../components/LanguageContext";
import { AboutPageProvider } from "../../components/AboutPage/AboutPageContext";
import { animateScroll as scroll } from "react-scroll";
import { OverflowContainer } from "../Home/HomeElements";

const AboutPage = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  scroll.scrollToTop();
  return (
    <LanguageProvider>
      <AboutPageProvider>
        <Sidebar isOpen={isOpen} toggle={toggle}></Sidebar>
        <Navbar toggle={toggle}></Navbar>
        <OverflowContainer>
          <AboutPageDynamic></AboutPageDynamic>
        </OverflowContainer>
        <Footer></Footer>
      </AboutPageProvider>
    </LanguageProvider>
  );
};

export default AboutPage;
