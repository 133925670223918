import React, { useContext } from "react";
import { ButtonAboutMeBeyond } from "../../ButtonElement";
import { LanguageContext } from "../../LanguageContext";
import {
  HeroBeyonP,
  HeroBtnWrapperBeyond,
  HeroH1Beyond,
  HeroPositionContainerBeyond,
} from "../HeroSpecPageElements";

const BeyondMusicHero = (props) => {
  const [language, setLanguage] = useContext(LanguageContext);
  return (
    <HeroPositionContainerBeyond>
      <HeroH1Beyond>{props.title}</HeroH1Beyond>
      <HeroBeyonP>
        {" "}
        {language[0].eng === "true"
          ? "Something personal about me"
          : "Néhány személyes érdekesség"}
      </HeroBeyonP>
      <HeroBtnWrapperBeyond>
        <ButtonAboutMeBeyond
          onClick={() => {
            window.open("https://www.instagram.com/noemiflute/");
          }}
        >
          {language[0].eng === "true"
            ? "See more on my Instagram"
            : "További érdekességek az Instagram-on"}
        </ButtonAboutMeBeyond>
      </HeroBtnWrapperBeyond>
    </HeroPositionContainerBeyond>
  );
};

export default BeyondMusicHero;
