import React, { useContext, useEffect } from "react";
import HeroEventPageSection from "../HeroSection/HeroEventPage";
import BigBlogCardSection from "../Blog/BigBlogCard";
import { useParams } from "react-router-dom";
import { LanguageContext } from "../LanguageContext";
import { BlogContext } from "./BlogContext";
import { GetAllItems } from "../../pages/Admin/services/blogService";

const BlogPosts = (props) => {
  let eventId = useParams();
  const [language, setLanguage] = useContext(LanguageContext);
  const [posts, setPosts] = useContext(BlogContext);
  const getItems = () => {
    let get = GetAllItems();
    get.then((res) => {
      let data = res.data;
      setPosts(data);
    });
  };
  useEffect(() => {
    (async () => {
      getItems();
    })();
  }, []);
  return (
    <>
      {posts.map((event) => {
        if (event.id == eventId.eventId)
          return (
            <>
              <HeroEventPageSection
                id="events_hero"
                backgroundImage={event.img}
                date={event.createDate}
                titleENG={event.title}
              ></HeroEventPageSection>
              <BigBlogCardSection
                date={event.createDate}
                place={event.place}
                aboutENG={event.content}
                image={event.image}
              ></BigBlogCardSection>
            </>
          );
      })}
    </>
  );
};

export default BlogPosts;
