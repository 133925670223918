import {
  createMuiTheme,
  CssBaseline,
  Drawer,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core";
import React from "react";
import Header from "../../../../components/Admin/Header";
import red from "@material-ui/core/colors/red";
import MyDrawer from "../../../../components/Admin/Drawer";
import HomeCards from "./HomeCards";
import { CardProvider } from "../../../../components/CardSection/CardContext";
import { useStyles, theme } from "../../theme.js";

const HomeCardAdminPage = (props) => {
  const classes = useStyles();

  return (
    <CardProvider>
      <ThemeProvider theme={theme}>
        <Drawer
          className={classes.drawer}
          variant="permanent"
          classes={{
            paper: classes.drawerPaper,
          }}
          anchor="left"
        >
          <MyDrawer></MyDrawer>
        </Drawer>
        <div className={classes.adminMain}>
          <Header></Header>
          <HomeCards></HomeCards>
        </div>
        <CssBaseline></CssBaseline>
      </ThemeProvider>
    </CardProvider>
  );
};

export default HomeCardAdminPage;
