import { Grid } from "@material-ui/core";
import React, { useEffect, useContext } from "react";
import { useForm, Form } from "../useForm";
import Controls from "../controls/Controls";
import { FileContext } from "../../../components/File/FileContext";
import * as service from "../services/fileService";
import { TypeContext } from "../../../components/File/TypeContext";

const initialFValues = {
  id: 0,
  title: "",
  type: "",
  link: "",
};

const FileForm = (props) => {
  const [files, setFiles] = useContext(FileContext);
  const [types] = useContext(TypeContext);
  const { addOrEdit, recordForEdit } = props;
  const { values, setValues, handleInputChange, resetForm } =
    useForm(initialFValues);

  const handleSubmit = (e) => {
    e.preventDefault();
    addOrEdit(values, resetForm);
  };

  useEffect(() => {
    (async () => {
      const getItems = () => {
        let get = service.GetAllItems();
        get.then((res) => {
          let data = res.data;
          setFiles(data);
        });
      };
      getItems();
      setValues({
        ...recordForEdit,
      });
    })();
  }, [recordForEdit, setFiles, setValues]);

  return (
    <Form>
      <Grid container>
        <Grid item xs={6}>
          <Controls.Input
            name="title"
            label="Title"
            value={values.title}
            onChange={handleInputChange}
          ></Controls.Input>
          <Controls.Input
            name="link"
            label="Link"
            value={values.link}
            onChange={handleInputChange}
          ></Controls.Input>
        </Grid>
        <Grid item xs={6}>
          <Controls.Select
            name="type"
            label="Type"
            images={types}
            onChange={handleInputChange}
          ></Controls.Select>
          <Controls.Button
            type="submit"
            text="Submit"
            value="Upload Photo"
            onClick={handleSubmit}
          ></Controls.Button>
          <Controls.Button
            text="Reset"
            color="default"
            onClick={resetForm}
          ></Controls.Button>{" "}
        </Grid>
      </Grid>
    </Form>
  );
};

export default FileForm;
