import styled from "styled-components";

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fff;
  font-family: playfairDisplay !important;

  @media screen and (max-width: 768px) {
    max-height: 300vh;
    width: 100%;
  }
`;

export const CardWrapper = styled.div`
  width: 40%;
  padding: 20px;
  margin: 0 auto;
  display: grid;
  grid-template-rows: minmax(40rem, max-content);
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.04);
    transition: all 0.2s ease-in-out;
  }

  @media screen and (max-width: 768px) {
    left: 0rem;
    padding: 0.5rem;
    width: 100%;
    &:hover {
      transform: scale(1);
      transition: all 0.2s ease-in-out;
    }
  }
`;

export const Card = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);

  @media screen and (max-width: 768px) {
    max-height: 200vh;
  }
`;

export const CardDate = styled.h1`
  font-size: 1rem;
  font-weight: 100;
  color: #000;
  font-style: italic;
  padding-top: 2rem;
  padding-bottom: 0.1rem;
  font-family: playfairDisplay !important;

  @media screen and (max-width: 768px) {
    font-size: 1.2rem;
  }
`;
export const CardPlace = styled.h1`
  font-size: 1rem;
  font-weight: 100;
  color: #000;
  white-space: nowrap;
  width: 25rem;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  padding-bottom: 1rem;
  font-family: playfairDisplay !important;
  font-style: italic;

  @media screen and (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

export const CardTitle = styled.p`
  font-size: 1.8rem;
  text-align: center;
  white-space: normal;
  min-width: 30rem;

  text-align: center;
  font-family: playfairDisplay !important;

  @media screen and (max-width: 768px) {
    min-width: 100%;
    max-width: 100%;
  }
`;

export const CardSubtitle = styled.p`
  font-size: 1.2rem;
  padding: 1rem;
  text-align: center;

  font-style: italic;
  font-family: playfairDisplay !important;

  @media screen and (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

export const BtnWrap = styled.div`
  padding-top: 1rem;
  padding-bottom: 2rem;
  display: flex;
  justify-content: center;
`;
