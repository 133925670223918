import React from "react";
import { Card, CardContainer, CardWrapper } from "./GalleryElements";
import GalleryLi from "./GalleryLi";

const GallerySection = (props) => {
  return (
    <>
      <CardContainer>
        <CardWrapper>
          <Card>
            <ul id="hexGrid">
              {props.gallery.map((item) => {
                return <GalleryLi gallery={item}></GalleryLi>;
              })}
            </ul>
          </Card>
        </CardWrapper>
      </CardContainer>
    </>
  );
};
export default GallerySection;
