import React, { useState } from "react";
import Sidebar from "../../components/Sidebar";
import Navbar from "../../components/Navbar";
import HeroPageSpecMain from "../../components/HeroSection/HeroPageSpecMain";
import Footer from "../../components/Footer";
import BgImg from "../../components/images/spec_hero_background.jpg";
import { HeroH1 } from "../../components/Specialities/HeroSpecElements";
import SpecialitiesSection from "../../components/Specialities";
import { OverflowContainer } from "../Home/HomeElements";

const SpecialitiesPage = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle}></Sidebar>
      <Navbar toggle={toggle}></Navbar>
      <OverflowContainer>
        <HeroPageSpecMain backgroundImage={BgImg}>
          <HeroH1>Specialities</HeroH1>
        </HeroPageSpecMain>
        <SpecialitiesSection></SpecialitiesSection>
      </OverflowContainer>

      <Footer></Footer>
    </>
  );
};

export default SpecialitiesPage;
