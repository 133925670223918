import React, { useState, createContext } from "react";

export const AboutPageContext = createContext();

export const AboutPageProvider = (props) => {
  const [aboutPages, setAboutPages] = useState([
    {
      id: "db_not_connected",
      title: "Database not connected",
      subtitle: "",
      text: "Database not connected",
      buttonLabel: "",
      dark: true,
      primary: true,
      link: "home",
    },
  ]);
  return (
    <AboutPageContext.Provider value={[aboutPages, setAboutPages]}>
      {props.children}
    </AboutPageContext.Provider>
  );
};
