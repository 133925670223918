import { Grid } from "@material-ui/core";
import React, { useEffect } from "react";
import { useForm, Form } from "../../useForm";
import Controls from "../../controls/Controls";

const initialFValues = {
  id: 0,
  title: "",
  titleENG: "",
  subtitle: "",
  subtitleENG: "",
  buttonLabel: "",
  buttonLabelENG: "",
  link: "",
};

const CarouselFrom = (props) => {
  const { addOrEdit, recordForEdit } = props;
  const { values, setValues, handleInputChange, resetForm } =
    useForm(initialFValues);

  const handleSubmit = (e) => {
    e.preventDefault();
    addOrEdit(values, resetForm);
    window.location.reload();
  };

  useEffect(() => {
    (async () => {
      setValues({
        ...recordForEdit,
      });
    })();
  }, [recordForEdit, setValues]);

  return (
    <Form>
      <Grid container>
        <Grid item xs={6}>
          <Controls.Input
            name="title"
            label="Title"
            value={values.title}
            onChange={handleInputChange}
          ></Controls.Input>{" "}
          <Controls.Input
            name="subtitle"
            label="Subtitle"
            value={values.subtitle}
            onChange={handleInputChange}
          ></Controls.Input>{" "}
          <Controls.Input
            name="buttonLabel"
            label="Button label"
            value={values.buttonLabel}
            onChange={handleInputChange}
          ></Controls.Input>{" "}
          <Controls.Input
            name="link"
            label="Link"
            value={values.link}
            onChange={handleInputChange}
          ></Controls.Input>
        </Grid>
        <Grid item xs={6}>
          <Controls.Input
            name="titleENG"
            label="TitleENG"
            value={values.titleENG}
            onChange={handleInputChange}
          ></Controls.Input>
          <Controls.Input
            name="subtitleENG"
            label="SubtitleENG"
            value={values.subtitleENG}
            onChange={handleInputChange}
          ></Controls.Input>
          <Controls.Input
            name="buttonLabelENG"
            label="ButtonLabelENG"
            value={values.buttonLabelENG}
            onChange={handleInputChange}
          ></Controls.Input>
          <Controls.Button
            type="submit"
            text="Submit"
            value="submit"
            onClick={handleSubmit}
          ></Controls.Button>
          <Controls.Button
            text="Reset"
            color="default"
            onClick={resetForm}
          ></Controls.Button>
        </Grid>
      </Grid>
    </Form>
  );
};

export default CarouselFrom;
