import React, { useState } from "react";
import Sidebar from "../../components/Sidebar";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import Bio from "../../components/Bio";
import { OverflowContainer } from "../Home/HomeElements";

const BioPage = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle}></Sidebar>
      <Navbar toggle={toggle}></Navbar>{" "}
      <OverflowContainer>
        <Bio></Bio>
      </OverflowContainer>
      <Footer></Footer>
    </>
  );
};

export default BioPage;
