import React, { useState } from "react";
import Sidebar from "../../../components/Sidebar";
import Navbar from "../../../components/Navbar";
import Footer from "../../../components/Footer";
import { ReviewProvider } from "./ReviewContext";
import ReviewPage from "./ReviewPage";
import { LanguageProvider } from "../../../components/LanguageContext";
import { OverflowContainer } from "../../Home/HomeElements";
import { animateScroll as scroll } from "react-scroll";
const Reviews = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  scroll.scrollToTop();
  return (
    <LanguageProvider>
      <ReviewProvider>
        <Sidebar isOpen={isOpen} toggle={toggle}></Sidebar>
        <Navbar toggle={toggle}></Navbar>{" "}
        <OverflowContainer>
          <ReviewPage></ReviewPage>
        </OverflowContainer>
        <Footer></Footer>
      </ReviewProvider>
    </LanguageProvider>
  );
};

export default Reviews;
