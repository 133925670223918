import React, { useContext, useEffect, useState } from "react";
import HeroSpecPageSection from "../HeroSection/HeroSpecPage";
import { useLocation } from "react-router";
import { LanguageContext } from "../LanguageContext";
import { SpecContext } from "./SpecContext";
import { GetAllItems } from "../../pages/Admin/services/specialitiesService";
import BigSpecCardSection from "./BigSpecCard";

const SpecPage = (props) => {
  const [language, setLanguage] = useContext(LanguageContext);
  const [specPages, setSpecPages] = useContext(SpecContext);
  const [pageName, setPageName] = useState();
  const location = useLocation();

  const getItems = () => {
    let get = GetAllItems();
    get.then((res) => {
      let data = res.data;
      setSpecPages(data);
    });
  };
  useEffect(() => {
    (async () => {
      getItems();
      getPageName();
    })();
  }, [language]);

  const getPageName = () => {
    if (
      location.pathname === "/doktori-tanulmanyok" ||
      location.pathname === "/doctoralstudies"
    )
      setPageName(
        `${
          language[0].eng === "true"
            ? "DOCTORAL STUDIES AT THE ROYAL ACADEMY OF MUSIC"
            : "DOKTORI TANULMÁNYOK A LONDONI KIRÁLYI ZENEAKADÉMIÁN"
        }`
      );
    else if (
      location.pathname === "/kortars-zene" ||
      location.pathname === "/contemporary-music"
    )
      setPageName(
        `${language[0].eng === "true" ? "CONTEMPORARY MUSIC" : "KORTÁRS ZENE"}`
      );
    else if (
      location.pathname === "/a-zenen-is-tul" ||
      location.pathname === "/beyond-music"
    )
      setPageName(
        `${language[0].eng === "true" ? "BEYOND MUSIC" : "A ZENÉN IS TÚL"}`
      );
    else if (
      location.pathname === "/korhu-hangszerek" ||
      location.pathname === "/period-flute"
    )
      setPageName(
        `${
          language[0].eng === "true"
            ? "BAROQUE & CLASSICAL FLUTE"
            : "BAROKK & KLASSZIKUS FUVOLA"
        }`
      );
  };

  return (
    <>
      {specPages.map((item) => {
        if (item.title === pageName)
          return (
            <>
              <HeroSpecPageSection
                id="events_hero"
                backgroundImage={item.image}
                titleENG={item.title}
                title={item.title}
              ></HeroSpecPageSection>
              <BigSpecCardSection
                title={item.title}
                aboutENG={item.content}
                image={item.image}
              ></BigSpecCardSection>
            </>
          );
      })}
    </>
  );
};

export default SpecPage;
