import styled from "styled-components";
export const CardLaneContainer = styled.div`
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between; /* Distribute elements evenly */
  align-items: center;
  background: #fff;
  margin: 0 5%; /* Same distance from the sides of the page */

  & > * {
    flex: 0 0 calc(30% - 10px); /* Adjust the width and gap as needed */
  }

  @media screen and (max-width: 1024px) {
    /* Styles for tablets or similar resolutions */
    flex-direction: row; /* Keep the row layout for tablets */
    margin: 0 2%; /* Reduce side margins for tablets */

    & > * {
      flex: 0 0 calc(35% - 10px); /* Adjust the width and gap for tablets */
    }
  }

  @media screen and (max-width: 480px) {
    /* Styles for smaller screens (e.g., mobile) */
    flex-direction: column; /* Stack elements vertically */
    align-items: center; /* Center items horizontally and vertically */
    margin: 0;

    & > * {
      flex: 0 0 100%; /* Full width for smaller screens */
      margin-bottom: 10px; /* Add some spacing between stacked elements */
    }
  }
`;

export const CardContainer = styled.div`
  display: flex;
  min-width: 10rem;
  padding-top: 1%;
  padding-bottom: 1%;
  justify-content: center;
  align-items: center;
  background: #fff;
  font-family: playfairDisplay !important;
  outline: none !important;

  @media screen and (max-width: 1680px) {
  }
  @media screen and (max-width: 1366px) {
  }
  @media screen and (max-width: 1280px) {
  }
  @media screen and (max-width: 980px) {
  }
  @media screen and (max-width: 736px) {
  }

  @media screen and (max-width: 480px) {
  }
`;

export const CardWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: grid;
  column-gap: 5%;
  padding-left: 5%;
  padding-right: 15%;
  transition: all 0.2s ease-in-out;
  z-index: 1;
  justify-items: center;
  margin-left: auto;
  margin-right: auto;

  @media screen and (max-width: 1680px) {
  }
  @media screen and (max-width: 1280px) {
  }
  @media screen and (max-width: 1024px) {
    padding-left: 1%;
    column-gap: 2.5%;
    padding-right: 2.5%;
    grid-template-columns: 23.5% 23.5% 23.5% 23.5%;
  }
  @media screen and (max-width: 980px) {
    grid-template-columns: 50% 50%;
    padding-left: 5%;
    padding-right: 10%;
    column-gap: 5%;
  }
  @media screen and (max-width: 480px) {
    grid-template-columns: 1fr;
    left: 0rem;
    padding: 0.5rem;
  }
`;

export const Card = styled.div`
  display: grid;
  grid-template-rows: 50% 20% 1fr 1fr 1f;
  background: #fff;
  height: 100%;
  width: 100%;

  @media screen and (max-width: 1680px) {
  }
  @media screen and (max-width: 1280px) {
  }
  @media screen and (max-width: 980px) {
  }
  @media screen and (max-width: 736px) {
  }
  @media screen and (max-width: 480px) {
  }
`;

export const CardH1 = styled.p`
  font-size: 4rem;
  font-weight: 100;
  position: relative;
  left: 10rem;
  top: 2.5rem;
  color: #fff;
  margin-bottom: 2rem;
  text-transform: uppercase;
  font-family: Roboto !important;

  @media screen and (max-width: 1680px) {
  }
  @media screen and (max-width: 1280px) {
  }
  @media screen and (max-width: 980px) {
  }
  @media screen and (max-width: 736px) {
  }
  @media screen and (max-width: 480px) {
    font-size: 1.5rem;
  }
`;
export const CardH2 = styled.p`
  font-size: 1.2rem;
  font-weight: 100;
  color: #000;
  margin-bottom: 0.5rem;
  font-style: italic;

  @media screen and (max-width: 1680px) {
  }
  @media screen and (max-width: 1280px) {
  }
  @media screen and (max-width: 980px) {
  }
  @media screen and (max-width: 736px) {
  }
  @media screen and (max-width: 480px) {
    font-size: 1.2rem;
  }
`;

export const CardP = styled.p`
  font-size: 1rem;
  text-align: center;
  min-height: 15rem;
`;

export const AlbumIconWrapper = styled.div`
  padding-bottom: 5%;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  &:hover {
    transform: scale(1.04);
  }
`;

export const AudioWrapper = styled.div`
  @media screen and (max-width: 480px) {
    width: 90%;
    padding-left: 10%;
    &:active {
      border: none !important;
      outline: none !important;
    }

    &:focus {
      outline: none !important;
    }
  }
`;

export const AlbumIcon = styled.h1`
  height: 250px;
  width: 250px;
  text-align: center;
  marigin: 0;
`;

export const TextWrap = styled.div`
  height: 4.5rem;
  max-width: 100%;
  display: grid;
  padding: 0;
  margin: 0;
  padding-bottom: 2rem;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  grid-template-rows: 1fr 1fr;
`;

export const Text = styled.p`
  font-size: 18px;
  padding: 0 !important;
  color: #000;
  margin: 0;
  font-family: playfairDisplay !important;
  margin: 0 !important;
  line-height: 1em !important;
  text-align: center !important;

  @media screen and (max-width: 480px) {
    font-size: 16px;
  }
`;

export const TextP = styled.p`
  font-size: 14px;
  padding-top: 0;
  margin: 0;
  color: #000;
  font-family: playfairDisplay !important;
  margin: 0 !important;
  line-height: 1em !important;
  text-align: center !important;
  @media screen and (max-width: 480px) {
    font-size: 13px;
  }
`;
