import React, { useContext } from "react";
import {
  Card,
  CardContainer,
  CardDate,
  CardPlace,
  CardSubtitle,
  CardTitle,
  CardWrapper,
} from "./EventCardElements";
import { BackgroundImageEventsCard } from "../BackgroundImage";
import { BtnWrapEvents } from "../InfoSection/InfoElements";
import { Button } from "../ButtonElement";
import { LanguageContext } from "../LanguageContext";

const EventCardSection = (props) => {
  const [language] = useContext(LanguageContext);
  const date = new Date(props.date);
  const buttonLabel =
    language[0].eng === "true" ? "I am interested!" : "Érdekel";

  return (
    <CardContainer>
      <CardWrapper>
        <BackgroundImageEventsCard
          backgroundImage={props.image}
        ></BackgroundImageEventsCard>
        <Card>
          <CardDate>{date.toDateString()}</CardDate>
          <CardPlace>{props.place}</CardPlace>
          <CardTitle>{props.titleENG}</CardTitle>
          <CardSubtitle>{props.subtitleENG}</CardSubtitle>
          <BtnWrapEvents>
            <Button
              to={`/events/${props.eventId}`}
              smooth="true"
              duration={500}
              spy="true"
              offset={-80}
              primary={0}
              dark={0}
            >
              {buttonLabel}
            </Button>
          </BtnWrapEvents>
        </Card>
      </CardWrapper>
    </CardContainer>
  );
};

export default EventCardSection;
