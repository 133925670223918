import {
  createMuiTheme,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import red from "@material-ui/core/colors/red";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import { useHistory } from "react-router-dom";
const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#00c853",
    },

    secondary: {
      main: red[500],
    },

    background: {
      default: "#f4f5fd",
    },
  },
  overrides: {
    MuiAppBar: {
      root: {
        transform: "translateZ(0)",
      },
    },
  },
});
const drawerWidth = 220;
const useStyles = makeStyles({
  adminMain: {
    paddingLeft: "320px",
    width: "100%",
  },
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  dividerFullWidth: {
    margin: `5px 0 0 ${theme.spacing(2)}px`,
    fontSize: "18px",
  },
  dividerInset: {
    margin: `5px 0 0 ${theme.spacing(9)}px`,
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
});

const MyDrawer = () => {
  const history = useHistory();
  const [updatables] = useState([
    { text: "Events", link: "/admin/events" },
    { text: "Interviews", link: "/admin/interviews" },
    { text: "Reviews", link: "/admin/reviews" },
    { text: "Videos", link: "/admin/videos" },
    { text: "Programs", link: "/admin/programs" },
    { text: "Shop", link: "/admin/shops" },
    { text: "Gallery", link: "/admin/gallery" },
  ]);

  const [upload] = useState([
    { text: "Images", link: "/admin/images" },
    { text: "Files", link: "/admin/files" },
  ]);

  const [post] = useState([
    { text: "Bio", link: "/admin/bio" },
    { text: "AboutPages", link: "/admin/aboutpages" },
    { text: "Blog", link: "/admin/blog" },
    { text: "Specialities", link: "/admin/specialities" },
  ]);

  const [home] = useState([
    { text: "Media", link: "/admin/media" },
    { text: "Press", link: "/admin/press" },
    { text: "HomeCard", link: "/admin/homecards" },
    { text: "Instagram", link: "/admin/instagram" },
    { text: "Abouts", link: "/admin/abouts" },
    { text: "Quotes", link: "/admin/carouselquotes" },
  ]);

  const [email] = useState([
    { text: "Subscribers", link: "/admin/subscribers" },
  ]);

  const classes = useStyles();

  return (
    <>
      <div className={classes.toolbar} />
      <Divider />{" "}
      <Typography
        className={classes.dividerFullWidth}
        color="textSecondary"
        display="block"
        variant="caption"
      >
        Bejegyzések
      </Typography>
      <List>
        {updatables.map((page) => {
          const link = page.link;
          return (
            <ListItem
              button
              key={page.text}
              onClick={() => {
                history.push(link);
              }}
            >
              <ListItemIcon>
                <InboxIcon />
              </ListItemIcon>
              <ListItemText primary={page.text} />
            </ListItem>
          );
        })}
      </List>
      <Divider />
      <Typography
        className={classes.dividerFullWidth}
        color="textSecondary"
        display="block"
        variant="caption"
      >
        Feltöltés
      </Typography>
      <List>
        {upload.map((page) => {
          const link = page.link;
          return (
            <ListItem
              button
              key={page.text}
              onClick={() => {
                history.push(link);
              }}
            >
              <ListItemIcon>
                <InboxIcon />
              </ListItemIcon>
              <ListItemText primary={page.text} />
            </ListItem>
          );
        })}
      </List>
      <Divider />
      <Typography
        className={classes.dividerFullWidth}
        color="textSecondary"
        display="block"
        variant="caption"
      >
        Hosszú bejegyzések
      </Typography>
      <List>
        {post.map((page) => {
          const link = page.link;
          return (
            <ListItem
              button
              key={page.text}
              onClick={() => {
                history.push(link);
              }}
            >
              <ListItemIcon>
                <InboxIcon />
              </ListItemIcon>
              <ListItemText primary={page.text} />
            </ListItem>
          );
        })}
      </List>
      <Divider />
      <Typography
        className={classes.dividerFullWidth}
        color="textSecondary"
        display="block"
        variant="caption"
      >
        Oldal beállítások
      </Typography>
      <List>
        {home.map((page) => {
          const link = page.link;
          return (
            <ListItem
              button
              key={page.text}
              onClick={() => {
                history.push(link);
              }}
            >
              <ListItemIcon>
                <InboxIcon />
              </ListItemIcon>
              <ListItemText primary={page.text} />
            </ListItem>
          );
        })}
      </List>
      <Divider />
      <Typography
        className={classes.dividerFullWidth}
        color="textSecondary"
        display="block"
        variant="caption"
      >
        Email
      </Typography>
      <List>
        {email.map((page) => {
          const link = page.link;
          return (
            <ListItem
              button
              key={page.text}
              onClick={() => {
                history.push(link);
              }}
            >
              <ListItemIcon>
                <InboxIcon />
              </ListItemIcon>
              <ListItemText primary={page.text} />
            </ListItem>
          );
        })}
      </List>
    </>
  );
};

export default MyDrawer;
