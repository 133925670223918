import React, { useState } from "react";
import PlayerCard from "../PlayerCard"; // Import the common PlayerCard component
import transformationCover from "../../../albums/transformation_cover.jpg";
import transforming_song1 from "../../../music/transforming-song1.mp3";
import transforming_song2 from "../../../music/transforming-song2.mp3";
import transforming_song3 from "../../../music/transforming-song3.mp3";

const Transforming = {
  cover: transformationCover,
  songs: [
    {
      name: "Mozart",
      subtitle: "Noémi Győri, Katalin Koltai",
      mp3: transforming_song1,
    },
    {
      name: "Haydn D II.",
      subtitle: "Noémi Győri, Katalin Koltai",
      mp3: transforming_song2,
    },
    {
      name: "Beethoven I.",
      subtitle: "Noémi Győri, Katalin Koltai",
      mp3: transforming_song3,
    },
  ],
};

export const TransformingAlbum = () => {
  const [language, setLanguage] = useState({ eng: "true" }); // Replace with your language context

  return (
    <PlayerCard
      cover={Transforming.cover}
      songs={Transforming.songs}
      buyLink="https://music.apple.com/gb/album/transforming-traditions/1452612604"
      language={language}
    />
  );
};
