import styled from "styled-components";

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fff;
  font-family: playfairDisplay !important;
  max-height: 100%;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const CardWrapper = styled.div`
width: 70%;
max-height: 100%;
transition: all 0.2s ease-in-out;
z-index: 1;

  &:hover {
    transform: scale(1);
    transition: all 0.2s ease-in-out;
  }

  @media screen and (max-width: 768px) {
    left: 0rem;
    padding: 0;
    width: 100%;
    min-height: 100rem;
    &:hover {
      transform: scale(1);
      transition: all 0.2s ease-in-out;
    }
`;

export const Card = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-height: 100%;

  @media screen and (max-width: 768px) {
    max-height: none;
    box-shadow: 0;
    padding: 0.5rem;
    width: 100%;
  }
`;

export const CardDate = styled.h1`
  font-size: 1.2rem;
  font-weight: 100;
  color: #000;
  font-style: italic;

  font-family: playfairDisplay !important;

  @media screen and (max-width: 768px) {
    font-size: 1.5rem;
  }
`;
export const CardPlace = styled.h1`
  font-size: 1.2rem;
  font-weight: 100;
  color: #000;

  font-family: playfairDisplay !important;
  font-style: italic;

  @media screen and (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

export const CardAbout = styled.p`
  padding-top: 2rem;
  font-size: 1.3rem;
  font-family: playfairDisplay !important;
  @media screen and (max-width: 768px) {
    padding-top: 1rem;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const CardAboutTitle = styled.p`
  font-size: 2.5rem;
  display: flex !important;
  text-align: right !important;
  font-family: playfairDisplay !important;
`;

export const BtnWrap = styled.div`
  padding-top: 2rem;
  padding-bottom: 1rem;
  display: flex;
  justify-content: center;
`;
