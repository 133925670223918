import React, { useContext, useEffect } from "react";
import { CardContext } from "../CardSection/CardContext";
import { Card, CardContainer, CardWrapper } from "./specialitiesElements";
import { BackgroundImageTopSpec } from "../BackgroundImage";
import { LanguageContext } from "../LanguageContext";
import * as service from "../../pages/Admin/services/homeCardService";
import * as serviceLanguage from "../../pages/Admin/services/languageService";
import * as serviceSpec from "../../pages/Admin/services/specialitiesService";
import { ButtonSpecialities, ButtonSpecialitiesBlack } from "../ButtonElement";
import bgImg1 from "../images/spec_1.jpg";
import bgImg2 from "../images/spec_2.jpg";
import bgImg3 from "../images/spec_3.jpg";
import bgImg4 from "../images/spec_4.jpg";
import { SpecContext } from "./SpecContext";
const SpecialitiesSection = (props) => {
  const [cards, setCards] = useContext(CardContext);
  const [specPages, setSpecPages] = useContext(SpecContext);
  const [language, setLanguage] = useContext(LanguageContext);

  const getItems = () => {
    let get = service.GetAllItems();
    get.then((res) => {
      let data = res.data;
      setCards(data);

      /* let get = serviceLanguage.GetAllItems();
      get.then((res) => {
        let data = res.data;
        setLanguage(data);*/

      let get = serviceSpec.GetAllItems();
      get.then((res) => {
        let data = res.data;
        setSpecPages(data);
      });
    });
  };

  useEffect(() => {
    (async () => {
      getItems();
    })();
  }, []);

  return (
    <CardContainer id="specialities">
      <CardWrapper>
        <BackgroundImageTopSpec id="a" backgroundImage={bgImg1}>
          <Card>
            <ButtonSpecialities
              to={`/${
                language[0].eng === "true"
                  ? "contemporary-music"
                  : "kortars-zene"
              }`}
            >
              {language[0].eng === "true"
                ? "Contemporary music"
                : "Kortárs zene"}
            </ButtonSpecialities>
          </Card>
        </BackgroundImageTopSpec>
        <BackgroundImageTopSpec id="b" backgroundImage={bgImg2}>
          <Card>
            <ButtonSpecialities to={`/blog`}>
              {" "}
              {language[0].eng === "true" ? "Blog" : "Blog"}
            </ButtonSpecialities>
          </Card>
        </BackgroundImageTopSpec>{" "}
        <BackgroundImageTopSpec id="c" backgroundImage={bgImg3}>
          <Card>
            <ButtonSpecialitiesBlack
              to={`/${
                language[0].eng === "true" ? "beyond-music" : "a-zenen-is-tul"
              }`}
            >
              {language[0].eng === "true" ? "Beyond music" : "A zenén is túl"}
            </ButtonSpecialitiesBlack>
          </Card>
        </BackgroundImageTopSpec>{" "}
        <BackgroundImageTopSpec id="d" backgroundImage={bgImg4}>
          <Card>
            <ButtonSpecialities
              to={`/${
                language[0].eng === "true" ? "period-flute" : "korhu-hangszerek"
              }`}
            >
              {language[0].eng === "true" ? "Period flute" : "Korhű hangszerek"}
            </ButtonSpecialities>
          </Card>
        </BackgroundImageTopSpec>
      </CardWrapper>
    </CardContainer>
  );
};

export default SpecialitiesSection;
