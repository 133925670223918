import styled from "styled-components";

export const TextWrap = styled.div`
  padding-top: 1rem;
  padding-bottom: 2rem;
  display: flex;
  justify-content: center;
`;

export const Text = styled.h1`
  padding: 0.5rem 0.5rem 0.1rem 0.5rem;
  font-weight: 100;
  text-transform: uppercase;
  font-size: 32px;
  color: #000;
  font-family: playfairDisplay !important;
`;
