import React, { useState } from "react";
import Sidebar from "../../components/Sidebar";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import GalleryPage from "./GalleryPage";
import { LanguageProvider } from "../../components/LanguageContext";
import { GalleryProvider } from "./GalleryContext";
import { OverflowContainer } from "../Home/HomeElements";
import { animateScroll as scroll } from "react-scroll";
const Gallery = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  scroll.scrollToTop();
  return (
    <LanguageProvider>
      <GalleryProvider>
        <Sidebar isOpen={isOpen} toggle={toggle}></Sidebar>
        <Navbar toggle={toggle}></Navbar>{" "}
        <OverflowContainer>
          <GalleryPage></GalleryPage>
        </OverflowContainer>
        <Footer></Footer>
      </GalleryProvider>
    </LanguageProvider>
  );
};

export default Gallery;
