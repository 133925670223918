import http from "./http-auth";

export function InsertItem(data) {
  return http.post("/subscribers", data).then((res) => {});
}

export function DeleteItem(data) {
  return http.delete(`/subscribers/${data}`).then((res) => {});
}

export function UpdateItem(data) {
  return http.put(`/subscribers/${data.id}`, data).then((res) => {});
}

export function GetItem(data) {
  return http.get(`/subscribers/${data.id}`, data).then((res) => {});
}

export const GetAllItems = () => {
  return http.get("/subscribers");
};
