import React, { useContext, useState, useEffect } from "react";
import Sidebar from "../../components/Sidebar";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import eventBgImg from "../../components/images/blog.jpg";
import BlogList from "../../components/Blog/BlogList";
import { animateScroll as scroll } from "react-scroll";
import { BackgroundImageTopOpacityBlog } from "../../components/BackgroundImage";
import {
  HeroH1White,
  HeroPBlog,
  HeroContainer,
  HeroContent,
  HeroPositionContainerBlog,
} from "../../components/Specialities/HeroSpecElements";
import { LanguageContext } from "../../components/LanguageContext";
import { OverflowContainer } from "../Home/HomeElements";

const EventsPage = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [language, setLanguage] = useContext(LanguageContext);
  const toggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    (() => {
      scroll.scrollToTop();
    })();
  }, []);

  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle}></Sidebar>
      <Navbar toggle={toggle}></Navbar>{" "}
      <OverflowContainer>
        <HeroContainer>
          <BackgroundImageTopOpacityBlog
            backgroundImage={eventBgImg}
          ></BackgroundImageTopOpacityBlog>
          <HeroContent>
            <HeroPositionContainerBlog>
              <HeroH1White>
                {language[0].eng === "true" ? "blog" : "blog"}
              </HeroH1White>{" "}
              <HeroPBlog>
                {language[0].eng === "true"
                  ? "What is the life of a classical musician like?"
                  : "Milyen egy klasszikus zenész élete?"}
              </HeroPBlog>
              <HeroPBlog>
                {language[0].eng === "true"
                  ? "What happens behind the scenes?"
                  : "Mi történik a színfalak mögött?"}
              </HeroPBlog>
              <HeroPBlog>
                {language[0].eng === "true"
                  ? "This is where I publish my thoughts from time to time for a sneak peek into my personal world."
                  : "Itt osztom meg a személyes gondolataimat, benyomásaimat, élményeimet."}
              </HeroPBlog>
            </HeroPositionContainerBlog>
          </HeroContent>
        </HeroContainer>
        <BlogList></BlogList>{" "}
      </OverflowContainer>
      <Footer></Footer>
    </>
  );
};

export default EventsPage;
