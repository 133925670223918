import styled from "styled-components";

export const CardContainer = styled.div`
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fff;

  @media screen and (max-width: 768px) {
    height: auto;
  }
`;

export const CardWrapper = styled.div`
  position: relative;
  margin: 0 auto;
  padding: 1rem;

  @media screen and (max-width: 480px) {
    grid-template-columns: 1fr 1fr;
    left: 0rem;
    padding: 0.5rem;
  }
`;

export const Card = styled.div`
  background: transparent;
  opacity: 0.9;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 20px;
  margin-top: 0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.04);
    transition: all 0.2s ease-in-out;
  }

  @media screen and (max-width: 480px) {
    max-height: 65vh;
  }
`;

export const CardH1 = styled.p`
  font-size: 2.8rem;
  font-weight: 300;
  color: #fff;
  margin-bottom: 1rem;
  text-transform: uppercase;

  @media screen and (max-width: 768px) {
    font-size: 2rem;
  }
`;
export const CardH2 = styled.p`
  font-size: 1.2rem;
  font-weight: 100;
  color: #fff;
  margin-bottom: 0.5rem;

  font-style: italic;
`;

export const CardP = styled.p`
  font-size: 1.4rem;
  font-weight: 300;
  color: #fff;
  text-align: center;
  min-height: 2rem;
  padding: 0;
  margin: 0;
  @media screen and (max-width: 768px) {
  }
`;
