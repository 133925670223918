import http from "./http-auth";

export function InsertItem(data) {
  return http.post("/carouselquotes", data).then((res) => {});
}

export function DeleteItem(data) {
  return http.delete(`/carouselquotes/${data}`).then((res) => {});
}

export function UpdateItem(data) {
  return http.put(`/carouselquotes/${data.id}`, data).then((res) => {});
}

export function GetItem(data) {
  return http.get(`/carouselquotes/${data.id}`, data).then((res) => {});
}

export const GetAllItems = () => {
  return http.get("/carouselquotes");
};
