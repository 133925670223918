import {
  createMuiTheme,
  CssBaseline,
  Drawer,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core";
import React from "react";
import Header from "../../../components/Admin/Header";
import red from "@material-ui/core/colors/red";
import MyDrawer from "../../../components/Admin/Drawer";
import { GalleryProvider } from "../../Gallery/GalleryContext";
import GalleryPosts from "./GalleryPosts";
import { ImageProvider } from "../../../components/Image/ImageContext";
import { useStyles, theme } from "../theme.js";

const GalleryAdminPage = (props) => {
  const classes = useStyles();

  return (
    <ImageProvider>
      <GalleryProvider>
        <ThemeProvider theme={theme}>
          <Drawer
            className={classes.drawer}
            variant="permanent"
            classes={{
              paper: classes.drawerPaper,
            }}
            anchor="left"
          >
            <MyDrawer></MyDrawer>
          </Drawer>
          <div className={classes.adminMain}>
            <Header></Header>
            <GalleryPosts></GalleryPosts>
          </div>
          <CssBaseline></CssBaseline>
        </ThemeProvider>
      </GalleryProvider>
    </ImageProvider>
  );
};

export default GalleryAdminPage;
