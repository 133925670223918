import React, { useState, createContext } from "react";

export const MediaContext = createContext();

export const MediaProvider = (props) => {
  const [medias, setMedias] = useState([
    {
      id: "gallery",
      title: "gallery",
      link: "/gallery",
      align: "true",
    },
    {
      id: "videos",
      title: "videos",
      link: "/videos",
      align: "false",
    },
    {
      id: "interviews",
      title: "interviews",
      link: "/interviews",
      align: "true",
    },
    {
      id: "reviews",
      title: "reviews",
      link: "/reviews",
      align: "false",
    },
    {
      id: "program_proposals",
      title: "program proposals",
      link: "/programproposals",
      align: "true",
    },
    {
      id: "press",
      title: "press",
      link: "/press",
      align: "false",
    },
  ]);
  return (
    <MediaContext.Provider value={[medias, setMedias]}>
      {props.children}
    </MediaContext.Provider>
  );
};
