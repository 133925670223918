import http from "./http-auth";

export function InsertItem(data) {
  return http.post("/videos", data).then((res) => {});
}

export function DeleteItem(data) {
  return http.delete(`/videos/${data}`).then((res) => {});
}

export function UpdateItem(data) {
  return http.put(`/videos/${data.id}`, data).then((res) => {});
}

export function GetItem(data) {
  return http.get(`/videos/${data.id}`, data).then((res) => {});
}

export const GetAllItems = () => {
  return http.get("/videos");
};
