import React, { useEffect } from "react";
import { useContext } from "react";
import { FaFacebook, FaInstagram, FaYoutube } from "react-icons/fa";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { LanguageContext } from "../LanguageContext";
import {
  FooterContainer,
  FooterLink,
  FooterLinkItems,
  FooterLinksContainer,
  FooterLinksWrapper,
  FooterLinkTitle,
  FooterWrapper,
  SocialIconLinkFB,
  SocialIconLinkI,
  SocialIconLinkYT,
  SocialIcons,
  SocialLogo,
  SocialMedia,
  SocialMediaWrapper,
} from "./FooterElements";
import CookieConsent from "react-cookie-consent";
import ReactGA from "react-ga";
ReactGA.initialize("UA-190810932-1");

function Footer() {
  const history = useHistory();
  const [language, setLanguage] = useContext(LanguageContext);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  return (
    <FooterContainer>
      <FooterWrapper>
        <FooterLinksContainer>
          <FooterLinksWrapper>
            <FooterLinkItems>
              <SocialMedia>
                <SocialMediaWrapper>
                  <SocialLogo to="/"></SocialLogo>
                  <SocialIcons>
                    <SocialIconLinkYT
                      target="_blank"
                      aira_label="Youtube"
                      href="//www.youtube.com/channel/UCbGzRbHKmbcp1l4lmZzKK_w"
                    >
                      <FaYoutube />
                    </SocialIconLinkYT>
                    <SocialIconLinkFB
                      href="//www.facebook.com/noemigyoriflute/?hc_ref=SEARCH"
                      target="_blank"
                      aira_label="Facebook"
                    >
                      <FaFacebook />
                    </SocialIconLinkFB>
                    <SocialIconLinkI
                      href="//www.instagram.com/noemiflute/"
                      target="_blank"
                      aira_label="Instagram"
                    >
                      <FaInstagram />
                    </SocialIconLinkI>
                  </SocialIcons>
                </SocialMediaWrapper>
              </SocialMedia>
              <FooterLinkTitle>
                ©{new Date().getFullYear()} All rights reserved.
              </FooterLinkTitle>{" "}
              <a
                rel="noreferrer"
                target="_blank"
                href={
                  language[0].eng === "true"
                    ? "https://firebasestorage.googleapis.com/v0/b/noemigyori.appspot.com/o/Files%2FNoemi%20Gyori%20_%20Data%20Management%20Information.pdf?alt=media&token=fd08266d-a6fb-497c-8266-5fcee0d84b2e"
                    : "https://firebasestorage.googleapis.com/v0/b/noemigyori.appspot.com/o/Files%2FGy%C5%91ri%20No%C3%A9mi%20_%20Adatkezel%C3%A9si%20T%C3%A1j%C3%A9koztat%C3%B3.pdf?alt=media&token=4b272702-394a-4620-b3eb-9cfcde647fff"
                }
                style={{ textDecoration: "none" }}
              >
                <FooterLink>
                  {language[0].eng === "true"
                    ? "Data Management Information"
                    : "Adatkezelési Tájékoztató"}
                </FooterLink>{" "}
              </a>
              <a
                rel="noreferrer"
                target="_blank"
                href={
                  language[0].eng === "true"
                    ? "https://firebasestorage.googleapis.com/v0/b/noemigyori.appspot.com/o/Files%2FNoemi%20Gyori%20_%20Data%20Management%20Information.pdf?alt=media&token=fd08266d-a6fb-497c-8266-5fcee0d84b2e"
                    : "https://firebasestorage.googleapis.com/v0/b/noemigyori.appspot.com/o/Files%2FGy%C5%91ri%20No%C3%A9mi%20_%20Adatkezel%C3%A9si%20T%C3%A1j%C3%A9koztat%C3%B3.pdf?alt=media&token=4b272702-394a-4620-b3eb-9cfcde647fff"
                }
                style={{ textDecoration: "none" }}
              >
                <FooterLink>
                  {language[0].eng === "true"
                    ? "Use of Cookies"
                    : "Cookies Tájékoztató"}
                </FooterLink>{" "}
              </a>
              <FooterLink
                onClick={() => {
                  history.push("/login");
                }}
              >
                {language[0].eng === "true" ? "Login" : "Belépés"}
              </FooterLink>
            </FooterLinkItems>
          </FooterLinksWrapper>
        </FooterLinksContainer>
      </FooterWrapper>
      <CookieConsent
        expires={60}
        buttonText={
          language[0].eng === "true" ? "Accept All Cookies" : "Elfogadom"
        }
        style={{ background: "#2B373B" }}
        buttonStyle={{ background: "white", color: "black", fontSize: "14px" }}
      >
        {language[0].eng === "true"
          ? "The Data Controller uses cookies on this website. A cookie is a small file containing a string of characters that is sent to your browser by our website to save settings and to make the use of the website easier, while helping to collect certain relevant and statistical information on users."
          : " Az Adatkezelő a honlap látogatása során cookie-kat (sütiket) használ. A cookie-t honlapunk az Ön böngészőjének küld el azzal a céllal, hogy megkönnyítse a honlap használatát és közreműködik abban, hogy néhány releváns, statisztikai jellegű információt gyűjtsön a látogatókról."}
      </CookieConsent>
    </FooterContainer>
  );
}

export default Footer;
