import React, { useState, createContext } from "react";

export const InterviewContext = createContext();

export const InterviewProvider = (props) => {
  const [interviews, setInterviews] = useState([
    {
      title:
        "Interjú a Clarino magazin július/augusztusi számában Klaus Härtel főszerkesztővel. (Németül)",
      titleENG:
        "07.2018 Interview in the July/August issue of Clarino Magazine, with Klaus Härtel editor in chief (German)",
      buttonLabel: "Megnézem",
      buttonLabelENG: "Read full interview",
      link: "",
    },
    {
      title:
        "03. 2018. Címlap interjú a német Eurowinds márciusi számában (németül)",
      titleENG:
        "03.2018 Cover interview in the March issue of Eurowinds (German)",
      buttonLabel: "Megnézem",
      buttonLabelENG: "Read full interview",
      link: "",
    },
    {
      title:
        '12. 01. 2017. "Teljes energiával élni" - Belinszky Annával beszélgettem, kultura.hu (magyarul)',
      titleENG: `"17.07.2017 "Living with full energy", kultura.hu (Hungarian)"`,
      buttonLabel: "Megnézem",
      buttonLabelENG: "Read full interview",
      link: "",
    },
    {
      title:
        '12. 01. 2017. "Éltető kihívások", interjú a Marie Claire Magazinban (magyarul)',
      titleENG: `"12.01.2017 "Vital challenges", interview with me in Marie Claire Magazine (Hungarian)"`,
      buttonLabel: "Megnézem",
      buttonLabelENG: "Read full interview",
      link: "",
    },
    {
      title: "10. 01. 2017. Viva de la Musica, Pepita Magazin (magyarul)",
      titleENG: "10.01.2017 Viva de la Musica, Pepita Magazin (Hungarian)",
      buttonLabel: "Megnézem",
      buttonLabelENG: "Read full interview",
      link: "",
    },
    {
      title:
        "25. 12. 2016. Interjú a Glowing Sonoritiesről az M5 kulturális tévécsatornán, 56:25-től látható. (magyarul)",
      titleENG:
        "25.12.2016 M5 Hungarian Cultural TV interview about Glowing Sonorities, from 56:25 sec (Hungarian)",
      buttonLabel: "Megnézem",
      buttonLabelENG: "Watch full interview",
      link: "",
    },
    {
      title:
        "10. 12. 2016. Glowing Sonorities a Personal Branding Blog Karácsonyi történetek ajánlójában (magyarul)",
      titleENG:
        "10.12.2016 Glowing Sonorities among the special Christmas art choices of PB Blog (Hungarian)",
      buttonLabel: "Megnézem",
      buttonLabelENG: "Read full blogpost",
      link: "",
    },
    {
      title:
        "08. 12. 2016. A manchesteri fuvolaosztályom bemutatkozása és interjú velem a Flute View Magazine decemberi számában (angolul)",
      titleENG:
        "08.12.2016 Feature of my Studio in the December issue of Flute View Magazine (English)",
      buttonLabel: "Megnézem",
      buttonLabelENG: "Read full interview",
      link: "",
    },
    {
      title:
        "15. 11. 2016. Miyazawa művészinterjú – a Miyazawa cég novemberi hírlevelében. Átfogó beszélgetés a szakmai tevékenységeimről, tanításról, koncertekről Shivhan Dohse-szal (angolul)",
      titleENG:
        "15.11.2016 Miyazawa Artist interview – November issue. A comprehensive conversation on my artistry with Shivhan Dohse (English)",
      buttonLabel: "Megnézem",
      buttonLabelENG: "Read full interview",
      link: "",
    },
    {
      title:
        "12. 11. 2016. Cikk a Classical Flute and Guitar Projectről az Angol Fuvolás Társaság Pan Magazine-jének novemberi számában (angolul)",
      titleENG:
        "12.11.2016 An article about my Classical Flute and Guitar Project in the November issue of the British Flute Society’s Pan Magazine (English)",
      buttonLabel: "Megnézem",
      buttonLabelENG: "Read full interview",
      link: "",
    },
    {
      title:
        "28. 10. 2016. A D1 kulturális televízió interjúja a Glowing Sonoritiesről (magyarul)",
      titleENG:
        "28.10.2016 D1 Cultural TV interview about my CD, Glowing Sonorities (Hungarian)",
      buttonLabel: "Megnézem",
      buttonLabelENG: "Watch full interview",
      link: "",
    },
    {
      title:
        "26. 10. 2016. A Fidelio interjúja a Glowing Sonorities című új lemezemmel kapcsolatban (magyarul)",
      titleENG:
        "26.10.2016 Interview about my new CD, Glowing Sonorities in the Fidelio Magazine (Hungarian)",
      buttonLabel: "Megnézem",
      buttonLabelENG: "Read full interview",
      link: "",
    },
    {
      title: "02. 09. 2016. The Heroine’s Journey-interjú (angolul)",
      titleENG: "02.09.2016 Interview for the Heroine’s Journey (English)",
      buttonLabel: "Megnézem",
      buttonLabelENG: "Read full interview",
      link: "",
    },
    {
      title:
        "03. 08. 2016. Fidelio-interjú arról, milyen a londoni Royal Academy of Music történetében első fuvolásként részt venni az intézmény doktori képzésén (magyarul)",
      titleENG:
        "03.08.2016 Interview in Fidelio Magazine about being the first flutist in the history of the doctoral course of the Royal Academy of Music in London (Hungarian)",
      buttonLabel: "Megnézem",
      buttonLabelENG: "Read full interview",
      link: "",
    },
    {
      title:
        "06. 07. 2016. A Népszabadságban tett nyilatkozatom a brexitről (magyarul)",
      titleENG:
        "06.07.2016 My statement in the Hungarian daily paper, Népszabadság about Brexit (Hungarian)",
      buttonLabel: "Megnézem",
      buttonLabelENG: "Read full statement",
      link: "",
    },
    {
      title: "2015. Interjú a Flûtiste d’Ille-et-Vilaine-ben (angolul)",
      titleENG: "2015 Interview for Flûtiste d’Ille-et-Vilaine (English)",
      buttonLabel: "Megnézem",
      buttonLabelENG: "Read full interview",
      link: "",
    },
    {
      title:
        "05. 2015. Közös interjú a férjemmel, Madaras Gergellyel a Gramofonban (magyarul)",
      titleENG:
        "05.2015 Feature interview in Gramofon Magazine with my husband Gergely Madaras (Hungarian)",
      buttonLabel: "Megnézem",
      buttonLabelENG: "Read full interview",
      link: "",
    },
    {
      title:
        "24. 09. 2013. Interjú László Mónival a Personal Branding Blogon (magyarul)",
      titleENG:
        "24.09.2013 Interview on Personal Branding with Móni László (Hungarian)",
      buttonLabel: "Megnézem",
      buttonLabelENG: "Read full interview",
      link: "",
    },
    {
      title:
        "06. 2012. „Varázslatos fuvolák”, interjú a Zeneakadémia Baráti Körének Legato Magazinjában (magyarul)",
      titleENG:
        "06.2012 ’Magic Flutes’ an interview for the Legato Magazine of the Friends of the Franz Liszt Academy of Music Budapest (Hungarian)",
      buttonLabel: "Megnézem",
      buttonLabelENG: "Read full interview",
      link: "",
    },
    {
      title:
        "21. 04. 2012. „Már fiatalon a csúcson” – interjú az Ötvenentúl-on (magyarul)",
      titleENG:
        "21.04.2012 Young, but already on the top – Interview for Ötvenentúl (Hungarian)",
      buttonLabel: "Megnézem",
      buttonLabelENG: "Read full interview",
      link: "",
    },
    {
      title:
        "2011. „Egy gyerekkori álom”, sztárinterjú az ELITE Magazinban (magyarul)",
      titleENG: "2011 Star Interview for ELITE Magazin (Hungarian)",
      buttonLabel: "Megnézem",
      buttonLabelENG: "Read full interview",
      link: "",
    },
    {
      title:
        "06. 11. 2010. „Hogyan terem a tehetség ma Magyarországon” – Csermely Péter blogposztja (magyarul)",
      titleENG:
        "06.11.2010 Blogpost by Peter Csermely – How does talent thrive in Hungary (Hungarian)",
      buttonLabel: "Megnézem",
      buttonLabelENG: "Read full blogpost",
      link: "",
    },
    {
      title:
        "09. 05. 2010. Kisinterjú a Dívány.hu-n a KLASSZ Tehetségek Gála kapcsán (magyarul)",
      titleENG:
        "09.05.2010 A spot interview on Dívány.hu in regard to being selected as a KLASSZ Talent (Hungarian)",
      buttonLabel: "Megnézem",
      buttonLabelENG: "Read full interview",
      link: "",
    },
    {
      title:
        "27. 01. 2010. „Hegyben álló partitúrák” – közös interjú a férjemmel, Madaras Gergely karmesterrel a Népszabadságban (magyarul)",
      titleENG:
        "27.01.2010 Scores Piling up – Interview with my husband, Gergely Madaras conductor  in the Hungarian daily paper, Népszabadság (Hungarian)",
      buttonLabel: "Megnézem",
      buttonLabelENG: "Read full interview",
      link: "",
    },
    {
      title:
        "20. 01. 2010. Nyilatkozatom a tehetségről a Magyar Géniusz Portálon (magyarul)",
      titleENG:
        "20.01.2010 My testament about ’talent’ for the Hungarian Genius Programme (Hungarian)",
      buttonLabel: "Megnézem",
      buttonLabelENG: "Read full testament",
      link: "",
    },
    {
      title:
        "05. 03. 2009. Népszabadság-interjú az IKZE, az Ifjúsági Komolyzenei Estek Fesztivállal kapcsolatban (magyarul)",
      titleENG:
        "05.03.2009 An Interview in the daily paper Népszabadság about The IKZE, Contemporary Music Festival of Young Composers",
      buttonLabel: "Megnézem",
      buttonLabelENG: "Read full interview",
      link: "",
    },
    {
      title:
        "2009 interjú a Német Fuvolás Társaság Flöte Aktuell magazinjának áprilisi számában (németül)",
      titleENG: "April 2009 An Interview in the Flöte Aktuell Magazine",
      buttonLabel: "Megnézem",
      buttonLabelENG: "Read full interview",
      link: "",
    },
  ]);
  return (
    <InterviewContext.Provider value={[interviews, setInterviews]}>
      {props.children}
    </InterviewContext.Provider>
  );
};
