import styled from "styled-components";
import { Link } from "react-router-dom";

export const TextBox = styled.div`
  display: flex;
  justify-content: center;
  background: #fff;
  width: 100%;
  max-height: 800px;
  @media screen and (max-width: 768px) {
    width: 100%;
    max-height: auto;
  }
`;

export const TextWrap = styled.div`
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
  width: 70%;
  justify-content: center;
  @media screen and (max-width: 768px) {
    left: 0rem;
    padding: 0.5rem;
    width: 100%;
    min-height: none;
  }
`;

export const Text = styled.p`
  padding: 0.5rem 0.1rem 0.1rem 0.5rem;
  font-size: 20px;
  color: #000;
`;

export const TextLink = styled(Link)`
  padding: 0.5rem 0.1rem 0.1rem 0.5rem;
  font-size: 20px;
  color: #000;
`;

export const TextBoxWithImage = styled.div`
  display: flex;
  justify-content: center;
  background: #fff;
  width: 100%;
  min-height: 40vh;
  @media screen and (max-width: 768px) {
    width: 100%;
    min-height: none;
  }
`;
export const TextWrapWithImage = styled.div`
  display: flex;
  justify-content: center;
  width: 80%;
  @media screen and (max-width: 768px) {
    width: 100%;
    min-height: none;
  }
`;

export const TextWithImage = styled.p`
  padding-top: 7.5rem;
  padding-left: 1rem;
  width: 40rem;
  font-size: 28px;
  color: #fff;
  @media screen and (max-width: 768px) {
    width: 100%;
    min-height: none;
    padding-top: 2rem;
  }
`;

export const TextWrapGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
  justify-content: center;
  width: 70%;
  min-height: 35rem;
  @media screen and (max-width: 768px) {
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
    min-height: none;
  }
`;
