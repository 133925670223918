import React, { useState, createContext, useEffect } from "react";
import * as service from "../pages/Admin/services/languageService";

export const LanguageContext = createContext();

export const LanguageProvider = (props) => {
  const [language, setLanguage] = useState([
    {
      eng: true,
    },
  ]);

  return (
    <LanguageContext.Provider value={[language, setLanguage]}>
      {props.children}
    </LanguageContext.Provider>
  );
};
