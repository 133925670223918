import React from "react";

const GalleryLi = (props) => {
  return (
    <>
      <li className="hex">
        <a href={`/gallery/${props.gallery.id}`} className="hexIn">
          <img src={props.gallery.LinkSmall} alt="" />
        </a>
      </li>
    </>
  );
};
export default GalleryLi;
