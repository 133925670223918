import React, { useState, createContext } from "react";

export const ImageContext = createContext();

export const ImageProvider = (props) => {
  const [images, setImages] = useState([
    {
      id: "db_not_connected",
      title: "Database not connected",
      subtitle: "",
      text: "Database not connected",
      buttonLabel: "",
      dark: true,
      primary: true,
      link: "home",
    },
  ]);
  return (
    <ImageContext.Provider value={[images, setImages]}>
      {props.children}
    </ImageContext.Provider>
  );
};
