import React, { useContext, useEffect, useState } from "react";

import EventCardSection from "./EventCard";
import { EventContext } from "./EventContext";
import { GetAllEvents } from "../../pages/Admin/services/eventService";
import { BtnWrap } from "../Events/EventCardElements";
import { Button } from "../ButtonElement";
import { Text, TextWrap } from "../Text/TextElement";
import { LanguageContext } from "../LanguageContext";
import { animateScroll as scroll } from "react-scroll";

const EventList = (props) => {
  const [language] = useContext(LanguageContext);
  const [events, setEvents] = useContext(EventContext);
  const [eventQuantity, setEventQuantity] = useState(8);

  const getEvents = () => {
    let get = GetAllEvents();
    get.then((res) => {
      let data = res.data;
      setEvents(data);
    });
  };

  const today = new Date();
  let upcoming = [];

  const sortDates = () => {
    events.sort(function (a, b) {
      return new Date(b.date) - new Date(a.date);
    });
    setEvents(events);
  };

  const sortDatesForUpcoming = () => {
    events.map((event) => {
      if (new Date(event.date) >= today) {
        upcoming.push(event);
      }
      upcoming.sort(function (a, b) {
        return new Date(a.date) - new Date(b.date);
      });
    });
  };

  const writeTextUpcoming = () => {
    if (new Date(events[0].date) >= today)
      return (
        <TextWrap>
          <Text>
            {" "}
            {language[0].eng === "true"
              ? "Upcoming events"
              : "Következő események"}
          </Text>
        </TextWrap>
      );
  };

  const moreEvents = () => {
    setEventQuantity(eventQuantity + 25);
  };

  useEffect(() => {
    (async () => {
      scroll.scrollToTop();
      getEvents();
    })();
  }, []);
  sortDates();
  sortDatesForUpcoming();
  return (
    <>
      {writeTextUpcoming()}
      {upcoming.map((event) => {
        return (
          <>
            <EventCardSection
              eventId={event.id}
              date={event.date}
              place={event.place}
              titleENG={
                language[0].eng === "true" ? event.titleENG : event.title
              }
              subtitleENG={
                language[0].eng === "true" ? event.subtitleENG : event.subtitle
              }
              image={event.image}
            ></EventCardSection>
          </>
        );
      })}

      {events.slice(0, eventQuantity).map((event, i, arr) => {
        var prevEvent;
        if (arr[i - 1] != null) {
          prevEvent = arr[i - 1];

          if (new Date(event.date) < today && new Date(prevEvent.date) >= today)
            return (
              //Ha van upcoming is
              <>
                <TextWrap>
                  <Text>
                    {language[0].eng === "true"
                      ? "Past events"
                      : "Korábbi események"}
                  </Text>
                </TextWrap>
                <EventCardSection
                  eventId={event.id}
                  date={event.date}
                  place={event.place}
                  titleENG={
                    language[0].eng === "true" ? event.titleENG : event.title
                  }
                  subtitleENG={
                    language[0].eng === "true"
                      ? event.subtitleENG
                      : event.subtitle
                  }
                  image={event.image}
                ></EventCardSection>
              </>
            );

          //Ha nincs semmi előtte
        } else {
          if (new Date(event.date) < today)
            return (
              <>
                <TextWrap>
                  <Text>
                    {language[0].eng === "true"
                      ? "Past events"
                      : "Korábbi események"}
                  </Text>
                </TextWrap>
                <EventCardSection
                  eventId={event.id}
                  date={event.date}
                  place={event.place}
                  titleENG={
                    language[0].eng === "true" ? event.titleENG : event.title
                  }
                  subtitleENG={
                    language[0].eng === "true"
                      ? event.subtitleENG
                      : event.subtitle
                  }
                  image={event.image}
                ></EventCardSection>
              </>
            );
        }

        if (new Date(event.date) < today)
          return (
            <>
              <EventCardSection
                eventId={event.id}
                date={event.date}
                place={event.place}
                titleENG={
                  language[0].eng === "true" ? event.titleENG : event.title
                }
                subtitleENG={
                  language[0].eng === "true"
                    ? event.subtitleENG
                    : event.subtitle
                }
                image={event.image}
              ></EventCardSection>
            </>
          );
      })}

      <BtnWrap>
        <Button
          onClick={moreEvents}
          to={props.link}
          smooth="true"
          duration={500}
          spy="true"
          offset={-80}
          primary={0}
          dark={0}
        >
          {language[0].eng === "true" ? "More events!" : "Több esemény"}
        </Button>
      </BtnWrap>
    </>
  );
};

export default EventList;
