import React from "react";
import { AuthProvider } from "../../auth/AuthContext";
import Login from "../../components/Signin/NewSingin/LoginContent";
const SignInPage = () => {
  return (
    <>
      <AuthProvider>
        <Login></Login>
      </AuthProvider>
    </>
  );
};

export default SignInPage;
//<SignIn form={useForm()}></SignIn>
