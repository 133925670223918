import styled from "styled-components";
import { FaTimes } from "react-icons/fa";
import { Link as LinkS } from "react-scroll";

export const SidebarContainer = styled.aside`
  display: none;
  @media screen and (max-width: 980px) {
    position: fixed;
    z-index: 999;
    height: 100%;
    background: #fff;
    display: grid;
    align-items: center;
    top: 0;
    left: 0;
    transition: 3 ease-in-out;
    top: ${({ isOpen }) => (isOpen ? "0" : "-100%")};
    opacity: ${({ isOpen }) => (isOpen ? "100%" : "0%")};
  }
`;

export const CloseIcon = styled(FaTimes)`
  color: #000;
`;

export const Icon = styled.div`
  position: absolute;
  top: 1.2rem;
  right: 1.5rem;
  background: transparent;
  font-size: 2rem;
  cursor: pointer;
  outline: none;
`;

export const SidebarWrapper = styled.div`
  color: #000;
  text-align: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
`;

export const SidebarMenu = styled.ul`
  display: grid;
  grid-template-columns: 1rf;
  grid-template-rows: repeat(12, 60px);
  text-align: center;
  padding-left: 0 !important;
  align-items: center;
  justify-items: center;

  @media screen and (max-width: 480px) {
    grid-template-rows: repeat(12, 2.5rem);
  }
`;

export const SidebarLogo = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 1rem;

  > img {
    max-width: 70%;
  }
`;

export const SidebarLink = styled(LinkS)`
  border: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  text-transform: uppercase;
  text-decoration: none;
  list-style-type: none;
  transition: 0.2s ease-in-out;
  text-decoration: none;
  color: #000;
  cursor: pointer;
`;

// export const SidebarRoute = styled 1:02:00 yt
