import { Grid } from "@material-ui/core";
import React, { useEffect } from "react";
import { useForm, Form } from "../useForm";
import Controls from "../controls/Controls";

const initialFValues = {
  id: 0,
  eng: true,
};

const LanguageForm = (props) => {
  const { addOrEdit, recordForEdit } = props;
  const { values, setValues, handleInputChange, resetForm } =
    useForm(initialFValues);

  const handleSubmit = (e) => {
    e.preventDefault();
    addOrEdit(values, resetForm);
    window.location.reload();
  };

  useEffect(() => {
    (async () => {
      setValues({
        ...recordForEdit,
      });
    })();
  }, [recordForEdit, setValues]);

  return (
    <Form>
      <Grid container>
        <Grid item xs={6}>
          <Controls.Input
            name="eng"
            label="ENG"
            value={values.eng}
            onChange={handleInputChange}
          ></Controls.Input>{" "}
          <Grid item xs={6}>
            <Controls.Button
              type="submit"
              text="Submit"
              value="submit"
              onClick={handleSubmit}
            ></Controls.Button>
            <Controls.Button
              text="Reset"
              color="default"
              onClick={resetForm}
            ></Controls.Button>
          </Grid>
        </Grid>
      </Grid>
    </Form>
  );
};

export default LanguageForm;
