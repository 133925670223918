import React, { useState } from "react";
import PlayerCard from "../PlayerCard"; // Import the common PlayerCard component
import romanticCover from "../../../albums/romantic_cover.jpg";
import romantic_song1 from "../../../music/romantic-song1.mp3";
import romantic_song2 from "../../../music/romantic-song2.mp3";
import romantic_song3 from "../../../music/romantic-song3.mp3";

const Romantic = {
  cover: romanticCover,
  songs: [
    {
      name: "Fantaisie hongrois Op.35",
      subtitle: "Noémi Győri, Gergely Madaras, Alexander Ullman",
      mp3: romantic_song1,
    },
    {
      name: "Chanson d’amour Op.20",
      subtitle: "Noémi Győri, Gergely Madaras, Alexander Ullman",
      mp3: romantic_song2,
    },
    {
      name: "Rondo Op.25",
      subtitle: "Noémi Győri, Gergely Madaras, Alexander Ullman",
      mp3: romantic_song3,
    },
  ],
};

export const RomanticAlbum = () => {
  const [language, setLanguage] = useState({ eng: "true" }); // Replace with your language context

  return (
    <PlayerCard
      cover={Romantic.cover}
      songs={Romantic.songs}
      buyLink="https://music.apple.com/gb/album/doppler-and-kuhlau-romantic-and-virtuoso-music-for/1616888863"
      language={language}
    />
  );
};
