import http from "./http-auth";

export function InsertItem(data) {
  return http.post("/abouts", data).then((res) => {});
}

export function DeleteItem(data) {
  return http.delete(`/abouts/${data}`).then((res) => {});
}

export function UpdateItem(data) {
  return http.put(`/abouts/${data.id}`, data).then((res) => {});
}

export function GetItem(data) {
  return http.get(`/abouts/${data.id}`, data).then((res) => {});
}

export const GetAllItems = () => {
  return http.get("/abouts");
};
