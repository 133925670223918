import styled from "styled-components";

export const CardContainer = styled.div`
  display: flex;
  padding-top: 1%;
  padding-bottom: 1%;
  height: 55rem;
  justify-content: center;
  align-items: center;
  background: #fff;
  font-family: playfairDisplay !important;
  outline: none !important;

  @media screen and (max-width: 1680px) {
    height: 50rem;
  }
  @media screen and (max-width: 1366px) {
    height: 40rem;
  }
  @media screen and (max-width: 1280px) {
    height: 38rem;
  }
  @media screen and (max-width: 980px) {
    height: 30rem;
  }
  @media screen and (max-width: 736px) {
    height: 23rem;
  }

  @media screen and (max-width: 480px) {
    height: auto;
  }
`;

export const CardWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 35% 35% 35%;
  column-gap: 5%;
  padding-left: 5%;
  padding-right: 15%;
  transition: all 0.2s ease-in-out;
  z-index: 1;

  @media screen and (max-width: 1280px) {
  }

  @media screen and (max-width: 480px) {
    grid-template-columns: 1fr;
    left: 0rem;
    padding: 0.5rem;
    justify-content: center;
    max-height: 100%;
  }
`;

export const Card = styled.div`
  display: flex;
  background: #fff;
  overflow: hidden;
  max-height: auto;
  width: 100%;
  box-shadow: 0 1px 0px rgba(0, 0, 0, 0.2);

  @media screen and (max-width: 1680px) {
    &:hover {
      transform: scale(1.04);
      transition: all 0.1s ease-in-out;
    }
  }
  @media screen and (max-width: 1280px) {
    &:hover {
      transform: scale(1.04);
      transition: all 0.1s ease-in-out;
    }
  }
  @media screen and (max-width: 980px) {
    &:hover {
      transform: scale(1);
      transition: all 0.1s ease-in-out;
    }
  }
  @media screen and (max-width: 736px) {
    &:hover {
      transform: scale(1);
      transition: all 0.1s ease-in-out;
    }
  }
  @media screen and (max-width: 480px) {
    height: 80vh;
    &:hover {
      transform: scale(1);
      transition: all 0.1s ease-in-out;
    }
  }
`;

export const CardH1 = styled.h1`
  font-size: 4rem;
  font-weight: 100;
  position: relative;
  left: 10rem;
  top: 2.5rem;
  color: #fff;
  margin-bottom: 2rem;
  text-transform: uppercase;
  font-family: roboto !important;
`;
export const CardH2 = styled.h1`
  font-size: 1.2rem;
  font-weight: 100;
  color: #000;
  margin-bottom: 0.5rem;
  font-style: italic;
`;

export const CardP = styled.p`
  font-size: 1rem;
  text-align: center;
  min-height: 15rem;
`;

export const AlbumIconWrapper = styled.div`
  padding-top: 10%;

  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

export const AlbumIcon = styled.h1`
  height: 250px;
  width: 250px;
  text-align: center;
  marigin: 0;
`;

export const TextWrap = styled.div`
  height: 6rem;
  max-width: 100%;
  display: grid;
  padding: 0;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  grid-template-rows: 1fr 1fr;
`;

export const Text = styled.h1`
  font-size: 20px;
  padding: 0;
  color: #000;
  font-family: playfairDisplay !important;
`;

export const TextP = styled.p`
  font-size: 16px;
  padding: 0;

  color: #000;
  font-family: playfairDisplay !important;
`;
