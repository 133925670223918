import React, { useContext, useEffect } from "react";
import { Carousel } from "react-bootstrap";
import { CarouselQuoteContext } from "./CarouselQuoteContext";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { LanguageContext } from "../LanguageContext";
import * as service from "../../pages/Admin/services/carouselQuoteService";

const CarouselQuoteContainer = () => {
  const [carouselQuotes, setCarouselQuotes] = useContext(CarouselQuoteContext);
  const [language] = useContext(LanguageContext);

  const getItems = () => {
    let get = service.GetAllItems();
    get.then((res) => {
      let data = res.data;
      setCarouselQuotes(data);
    });
  };

  useEffect(() => {
    (async () => {
      getItems();
    })();
  }, []);

  return (
    <Carousel controls={false} id="whats_up">
      {carouselQuotes.map((carousel) => (
        <Carousel.Item
          interval={5000}
          style={{ height: "300px", backgroundColor: "#000" }}
        >
          <img
            src={carousel.image}
            alt="img"
            style={{ width: "100%", opacity: "0.5" }}
          />
          <Carousel.Caption>
            <h1
              style={{
                width: "100%",
                textTransform: "none",
                fontSize: "2.4rem",
                fontWeight: "700",
                fontFamily: "Roboto",
              }}
            >
              {language[0].eng === "true" ? carousel.titleENG : carousel.title}
            </h1>
            <p
              style={{
                fontWeight: "100",
                fontFamily: "Roboto",
              }}
            >
              {language[0].eng === "true"
                ? carousel.subtitleENG
                : carousel.subtitle}
            </p>
          </Carousel.Caption>
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

export default CarouselQuoteContainer;
