import React, { useRef, useState } from "react";
import { Form, Button, Card, Alert } from "react-bootstrap";
import { useAuth } from "../../../auth/AuthContext";
import { Link, useHistory } from "react-router-dom";
import navbar_logo_hu from "../../images/navbar_logo_hu.png";
import {
  FormWrap,
  Container,
  Icon,
  FormContent,
  FormZone,
} from "../SigninElements";

export default function Login() {
  const emailRef = useRef();
  const passwordRef = useRef();
  const { login } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  let history = useHistory();

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      setError("");
      setLoading(true);
      await login(emailRef.current.value, passwordRef.current.value);
      history.push("/admin");
    } catch (e) {
      console.log(e);
      setError("Sikertelen bejelentkezés");
    }

    setLoading(false);
  }

  return (
    <>
      <Container>
        <FormWrap>
          <FormContent>
            <FormZone>
              <Icon to="/">
                {" "}
                <img
                  src={navbar_logo_hu}
                  style={{ maxWidth: "75%" }}
                  alt="navbar_logo_Hu"
                  className="navbar-icon"
                />
              </Icon>
              <Card border="0">
                <Card.Body>
                  <h2 className="text-center mb-4">Bejelentkezés</h2>
                  {error && <Alert variant="danger">{error}</Alert>}
                  <Form onSubmit={handleSubmit}>
                    <Form.Group id="email">
                      <Form.Label>Email</Form.Label>
                      <Form.Control type="email" ref={emailRef} required />
                    </Form.Group>
                    <Form.Group id="password">
                      <Form.Label>Jelszó</Form.Label>
                      <Form.Control
                        type="password"
                        ref={passwordRef}
                        required
                      />
                    </Form.Group>
                    <Button
                      variant="dark"
                      disabled={loading}
                      className="w-100 mt-4"
                      type="submit"
                    >
                      Bejelentkezés
                    </Button>
                  </Form>
                  <div className="w-100 text-center mt-3 text-white">
                    <Link to="/forgot-password">
                      Elfelejtetted a jelszavad?
                    </Link>
                  </div>
                </Card.Body>
              </Card>
            </FormZone>
          </FormContent>
        </FormWrap>
      </Container>
    </>
  );
}
