import styled from "styled-components";

export const CardContainer = styled.div`
  height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fff;

  @media screen and (max-height: 1680px) {
    height: auto;
  }

  @media screen and (max-height: 480px) {
    height: auto;
  }
`;

export const CardWrapper = styled.div`
  max-width: 305px;
  height: 100%;
  margin: 0 auto;
  padding: 0 50px;

  @media screen and (max-width: 1680px) {
    height: 100%;
  }
  @media screen and (max-width: 480px) {
    max-width: 80%;
    height: 100%;
  }
`;

export const Card = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  padding: 20px;
  position: relative;
  left: 30rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-in-out;

  @media screen and (max-width: 1680px) {
    height: 100%;
    position: relative;
  }

  @media screen and (max-width: 1024px) {
    left: 27.5rem;
  }

  @media screen and (max-width: 768px) {
    left: 17.5rem;
  }

  @media screen and (max-width: 480px) {
    height: 100%;
    position: relative;
    left: 0rem;
  }
`;

export const CardH1 = styled.p`
  font-size: 2rem;
  font-weight: 300;
  color: #000;
  text-transform: uppercase;
  margin: 0;

  @media screen and (max-width: 480px) {
    font-size: 1.5rem;
  }
`;

export const CardP = styled.p`
  padding-top: 1rem;
  font-size: 1.2rem;
  text-align: center;
`;

export const CardLine = styled.p`
  font-size: 1.5rem;
  text-align: center;
  font-weight: bold;
  margin: 0;
`;
