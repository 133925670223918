import React, { useState, createContext } from "react";

export const VideoContext = createContext();

export const VideoProvider = (props) => {
  const [videos, setVideos] = useState([
    {
      title: "Doppler: Fantaisie Pastorale Hongroise by Noemi Gyori - flute",
      link: "https://www.youtube.com/watch?v=1QaJNWpCkvA",
    },
    {
      title: "Debussy Syrinx flute solo by Noemi Gyori",
      link: "https://www.youtube.com/watch?v=H9usVez6JQE",
    },
    {
      title:
        "C.Ph.E. Bach: d minor Flute concerto 3rd movement by Noemi Gyori on Flute",
      link: "https://www.youtube.com/watch?v=DtZYxITIdjg",
    },
    {
      title:
        "J.S. Bach: Brandenburg Concerto No. 4, 1. movement by Noemi Gyori & Gergely Madaras",
      link: "https://www.youtube.com/watch?v=CW75JGN5jMA",
    },
    {
      title:
        "Sztojanov: Flute Concerto, 1. movement by Noemi Gyori & Amadinda Percussion Group",
      link: "https://www.youtube.com/watch?v=w7otPQHYGwg",
    },
    {
      title:
        "Antonio Nava Flute and Guitar Duos by Noemi Gyori and Katalin Koltai",
      link: "https://www.youtube.com/watch?v=JxS1tWDL8fI",
    },
  ]);
  return (
    <VideoContext.Provider value={[videos, setVideos]}>
      {props.children}
    </VideoContext.Provider>
  );
};
