import { ContactSupportOutlined } from "@material-ui/icons";
import http from "./http-auth";

export function InsertItem(data) {
  return http.post("/blog", data).then((res) => {});
}

export function DeleteItem(data) {
  return http.delete(`/blog/${data}`).then((res) => {});
}

export function UpdateItem(data) {
  return http.put(`/blog/${data.id}`, data).then((res) => {});
}

export function GetItem(data) {
  return http.get(`/blog/${data.id}`, data).then((res) => {});
}

export const GetAllItems = () => {
  return http.get("/blog");
};
