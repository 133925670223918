import http from "./http-auth";

export function InsertEvent(data) {
  return http.post("/events", data).then((res) => {});
}

export function DeleteEvent(data) {
  return http.delete(`/events/${data}`).then((res) => {});
}

export function UpdateEvent(data) {
  return http.put(`/events/${data.id}`, data).then((res) => {});
}

export const GetAllEvents = () => {
  return http.get("/events");
};
