import React, { useEffect, useContext } from "react";
import { useForm, Form } from "../useForm";
import * as service from "../services/bioService";
import { BioContext } from "../../../components/Bio/BioContext";
import AddPostBio from "../../../components/Quill/AddPostBio";

const initialFValues = {
  id: 0,
  title: "",
  content: "",
};

const BioForm = (props) => {
  const { addOrEdit, recordForEdit } = props;
  const [bio, setBio] = useContext(BioContext);
  const { values, setValues, handleInputChange, resetForm } =
    useForm(initialFValues);

  useEffect(() => {
    (async () => {
      const getItems = () => {
        let get = service.GetAllItems();
        get.then((res) => {
          let data = res.data;
          setBio(data);
        });
      };
      getItems();
      setValues({
        ...recordForEdit,
      });
    })();
  }, [recordForEdit, setBio, setValues]);

  return (
    <Form>
      <AddPostBio values={values} setValues={setValues}></AddPostBio>
    </Form>
  );
};

export default BioForm;
