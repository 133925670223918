import React, { useContext, useEffect } from "react";
import HeroPageSection from "../../../components/HeroSection/HeroPage";
import {
  HeroText,
  Text,
  InterviewBox,
  InterviewWrap,
  HeroPositionContainerMedia,
} from "../../../components/InterviewSection";
import * as service from "../../../pages/Admin/services/interviewService";
import { InterviewContext } from "./InterviewContext";
import HeroBgImg from "../../../components/images/iw_hero_background.jpg";
import { ButtonInterview } from "../../../components/ButtonElement";
import { LanguageContext } from "../../../components/LanguageContext";

const InterviewPage = () => {
  const [interviews, setInterviews] = useContext(InterviewContext);
  const [language] = useContext(LanguageContext);

  const sortDates = () => {
    interviews.sort(function (a, b) {
      return new Date(b.date) - new Date(a.date);
    });
    setInterviews(interviews);
  };

  useEffect(() => {
    (() => {
      const getItems = () => {
        let get = service.GetAllItems();
        get.then((res) => {
          let data = res.data;
          setInterviews(data);
        });
      };

      getItems();
    })();
  }, [setInterviews]);

  sortDates();
  return (
    <>
      <HeroPageSection backgroundImage={HeroBgImg}>
        <HeroPositionContainerMedia>
          <HeroText>
            {language[0].eng === "true" ? "Interviews" : "Interjúk"}
          </HeroText>
        </HeroPositionContainerMedia>
      </HeroPageSection>
      {interviews.map((item) => {
        return (
          <InterviewBox>
            <InterviewWrap>
              <Text>
                {` ${
                  language[0].eng === "true" ? item.titleENG : item.title
                } ${new Date(item.date).toLocaleDateString("en-US", {
                  year: "numeric",
                })}`}
              </Text>
              <ButtonInterview
                onClick={() => {
                  window.open(item.link);
                }}
              >
                {language[0].eng === "true"
                  ? item.buttonLabelENG
                  : item.buttonLabel}
              </ButtonInterview>
            </InterviewWrap>
          </InterviewBox>
        );
      })}
    </>
  );
};

export default InterviewPage;
