import styled from "styled-components";

export const BackgroundImageHomeHero = styled.div`
  position: absolute;
  background: url(${(props) => props.backgroundImage}) no-repeat;
  background-position: center top;
  background-size: cover;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

  @media screen and (max-width: 1680px) {
  }
  @media screen and (max-width: 1280px) {
  }
  @media screen and (max-width: 980px) {
  }
  @media screen and (max-width: 736px) {
  }
  @media screen and (max-width: 480px) {
    background-position: 85% 50%;
  }
`;
export const Content = styled.div`
  height: 100%;
  width: 100%;
`;
export const BackgroundImageDoc = styled.div`
  position: absolute;
  background: url(${(props) => props.backgroundImage}) no-repeat;
  background-position: 50% 30%;
  background-size: cover;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

  @media screen and (max-width: 1680px) {
  }
  @media screen and (max-width: 1280px) {
  }
  @media screen and (max-width: 980px) {
  }
  @media screen and (max-width: 736px) {
  }
  @media screen and (max-width: 480px) {
    background-position: 47.5% 50%;
  }
`;

export const BackgroundImage = styled.image`
  background: url(${(props) => props.backgroundImage}) center right/cover
    no-repeat;
  height: 100%;
  width: 100%;
  object-fit: contain;
  z-index: 0;
  max-height: 28.5rem;
  overflow: hidden;
  @media screen and (max-width: 1680px) {
  }

  @media screen and (max-width: 1280px) {
  }

  @media screen and (max-width: 480px) {
    background-position: 50% 50%;
    max-width: 100vw;
    height: 100%;
  }
`;

export const BackgroundImageEventsCard = styled.div`
  width: 100%;
  height: 100%;
  background: ${(props) =>
    props.backgroundImage
      ? `url(${props.backgroundImage}) center / cover no-repeat`
      : "linear-gradient(45deg, #e0e0e0, #f5f5f5)"};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  z-index: 0;

  @media screen and (max-width: 480px) {
    background-position: 50% 50%;
    max-width: 100vw;
  }
`;

export const BackgroundImageBlog = styled.image`
  background: url(${(props) => props.backgroundImage}) center right/cover
    no-repeat;
  height: 100%;
  width: 100%;
  object-fit: contain;
  z-index: 0;
  background-position: 50% 22%;
  overflow: hidden;
  @media screen and (max-width: 768px) {
    background-position: 50% 50%;
    max-width: 100vw;
  }
`;

export const BackgroundImageFluteCard = styled.image`
  background: url(${(props) => props.backgroundImage}) center right/cover
    no-repeat;
  height: 100%;
  width: 100%;
  object-fit: contain;
  z-index: 0;

  overflow: hidden;
  @media screen and (max-width: 768px) {
    background-position: 50% 50%;
  }
`;

export const BackgroundImageEvents = styled.image`
  background: url(${(props) => props.backgroundImage}) center right/cover
    no-repeat;
  height: 100%;
  width: 100%;
  object-fit: contain;
  z-index: 0;
  overflow: hidden;
  background-position: 40% 40%;

  @media screen and (max-width: 768px) {
    background-position: 47% 50%;
  }
`;
export const BackgroundImageFluteHomeCard = styled.image`
  background: url(${(props) => props.backgroundImage}) center right/cover
    no-repeat;
  height: 100%;
  width: 100%;
  object-fit: contain;
  z-index: 0;
  overflow: hidden;
  background-position: 40% 40%;

  @media screen and (max-width: 1680px) {
    background-position: 40% 50% !important;
  }

  @media screen and (max-width: 480px) {
    background-position: 60% 50% !important;
  }
`;

export const BackgroundImageEvents3 = styled.image`
  background: url(${(props) => props.backgroundImage}) center right/cover
    no-repeat;
  height: 100%;
  width: 100%;
  object-fit: contain;
  z-index: 0;
  overflow: hidden;
  background-position: 40% 40%;

  @media screen and (max-width: 1680px) {
    background-position: 40% 60% !important;
  }

  @media screen and (max-width: 480px) {
    background-position: 32.5% 50% !important;
  }
`;

export const BackgroundImageIw = styled.image`
  background: url(${(props) => props.backgroundImage}) center right/cover
    no-repeat;
  height: 100%;
  width: 100%;
  object-fit: contain;
  z-index: 0;
  overflow: hidden;

  @media screen and (max-width: 1280px) {
    background-position: 70% 50%;
  }
  @media screen and (max-width: 480px) {
    background-position: 70% 50%;
  }
`;

export const BackgroundImageEvents2 = styled.image`
  background: url(${(props) => props.backgroundImage}) center right/cover
    no-repeat;
  height: 100%;
  width: 100%;
  object-fit: contain;
  z-index: 0;
  overflow: hidden;
  @media screen and (max-width: 1680px) {
    background-position: 75% 0rem;
  }
  @media screen and (max-width: 1366px) {
    background-position: 70% 0rem;
  }
  @media screen and (max-width: 1280px) {
    background-position: 70% 0rem;
  }
  @media screen and (max-width: 1024px) {
    background-position: 55% 0rem;
  }
  @media screen and (max-width: 980px) {
    background-position: 55% 0rem;
  }
  @media screen and (max-width: 480px) {
    background-position: 45% 0rem;
  }
`;

export const BackgroundImageEventsCardBig = styled.image`
  background: ${(props) =>
    props.backgroundImage
      ? `url(${props.backgroundImage}) center / cover no-repeat`
      : "linear-gradient(45deg, #e0e0e0, #f5f5f5)"};
  height: 100%;
  width: 100%;
  object-fit: contain;
  z-index: 0;
  overflow: hidden;

  @media screen and (max-width: 980px) {
  }
  @media screen and (max-width: 480px) {
  }
`;

export const BackgroundImageTop = styled.image`
  background: url(${(props) => props.backgroundImage}) center top/cover
    no-repeat;
  height: 100%;
  width: 100%;
  object-fit: contain;
  z-index: 0;
  overflow: hidden;
  @media screen and (max-width: 768px) {
    height: auto;
    min-height: 60vh;
    width: 100%;
    background-position: 70% 50%;
  }
`;

export const BackgroundImageTopSpec = styled.image`
  background: url(${(props) => props.backgroundImage}) center top/cover
    no-repeat;
  height: 100%;
  width: 100%;
  object-fit: contain;
  z-index: 0;
  overflow: hidden;
  @media screen and (max-width: 768px) {
    height: 100%;
    width: 100%;
    background-position: 70% 0%;
  }
`;

export const BackgroundImageTopDoc = styled.image`
  background: url(${(props) => props.backgroundImage}) center top/cover
    no-repeat;
  height: 100%;
  width: 100%;
  object-fit: contain;
  z-index: 0;
  overflow: hidden;
  background-position: 50% 30%;
  @media screen and (max-width: 768px) {
    height: 100%;
    width: 100%;
    background-position: 70% 50%;
  }
`;

export const BackgroundImageTopHome = styled.image`
  background: url(${(props) => props.backgroundImage}) center top/cover
    no-repeat;
  height: 100%;
  width: 100%;
  object-fit: contain;
  z-index: 0;
  overflow: hidden;
  @media screen and (max-width: 768px) {
    height: auto;
    width: 100%;
    background-position: 82.5% 50%;
  }
`;

export const BackgroundImageFlute = styled.image`
  background: url(${(props) => props.backgroundImage}) center top/cover
    no-repeat;
  height: 100%;
  width: 100%;
  object-fit: scale-down;
  z-index: 0;
  overflow: hidden;
`;

export const BackgroundImageTopOpacity = styled.image`
  position: absolute;
  background: url(${(props) => props.backgroundImage}) no-repeat;
  background-position: 50% 10%;
  background-size: cover;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

  @media screen and (max-width: 1680px) {
  }
  @media screen and (max-width: 1280px) {
  }
  @media screen and (max-width: 980px) {
  }
  @media screen and (max-width: 736px) {
  }
  @media screen and (max-width: 480px) {
    background-position: 50% 50%;
  }
`;

export const BackgroundImageTopOpacityBlog = styled.image`
  position: absolute;
  background: url(${(props) => props.backgroundImage}) no-repeat;
  background-position: 50% 20%;
  background-size: cover;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

  @media screen and (max-width: 1680px) {
  }
  @media screen and (max-width: 1280px) {
  }
  @media screen and (max-width: 980px) {
  }
  @media screen and (max-width: 736px) {
  }
  @media screen and (max-width: 480px) {
    background-position: 50% 50%;
  }
`;

export const BackgroundImageShopOpacity = styled.image`
  background: url(${(props) => props.backgroundImage}) center top/cover
    no-repeat;
  height: 100%;
  width: 100%;
  object-fit: contain;
  z-index: 0;
  overflow: hidden;
  opacity: 1;
`;
