import { Grid } from "@material-ui/core";
import React, { useEffect, useContext } from "react";
import { useForm, Form } from "../useForm";
import Controls from "../controls/Controls";
import { ImageContext } from "../../../components/Image/ImageContext";
import * as service from "../services/imageService";

const initialFValues = {
  id: 0,
  title: "",
  link: "",
};

const ImageForm = (props) => {
  const [images, setImages] = useContext(ImageContext);
  const { addOrEdit, recordForEdit } = props;
  const { values, setValues, handleInputChange, resetForm } =
    useForm(initialFValues);

  const handleSubmit = (e) => {
    e.preventDefault();
    addOrEdit(values, resetForm);
  };

  useEffect(() => {
    (async () => {
      setValues({
        ...recordForEdit,
      });
    })();
  }, [recordForEdit, setImages, setValues]);

  return (
    <Form>
      <Grid container>
        <Grid item xs={6}>
          <Controls.Input
            name="title"
            label="Title"
            value={values.title}
            onChange={handleInputChange}
          ></Controls.Input>
          <Controls.Input
            name="link"
            label="Link"
            value={values.link}
            onChange={handleInputChange}
          ></Controls.Input>
        </Grid>
        <Grid item xs={6}>
          <Controls.Button
            type="submit"
            text="Submit"
            value="Upload Photo"
            onClick={handleSubmit}
          ></Controls.Button>
          <Controls.Button
            text="Reset"
            color="default"
            onClick={resetForm}
          ></Controls.Button>{" "}
        </Grid>
      </Grid>
    </Form>
  );
};

export default ImageForm;
