import React, { useContext, useEffect, useState } from "react";

import BlogCardSection from "./BlogCard";
import { BlogContext } from "./BlogContext";
import { GetAllItems } from "../../pages/Admin/services/blogService";
import { BtnWrap } from "../Events/EventCardElements";
import { Button } from "../ButtonElement";
import { LanguageContext } from "../LanguageContext";

const BlogList = (props) => {
  const [language, setLanguage] = useContext(LanguageContext);
  const [posts, setPosts] = useContext(BlogContext);
  const [postQuantity, setPostQuantity] = useState(8);

  const getItems = () => {
    let get = GetAllItems();
    get.then((res) => {
      let data = res.data;
      setPosts(data);
    });
  };

  const sortDates = () => {
    posts.sort(function (a, b) {
      return new Date(b.date) - new Date(a.date);
    });
    setPosts(posts);
  };

  const moreEvents = () => {
    setPostQuantity(postQuantity + 10);
  };

  useEffect(() => {
    (async () => {
      getItems();
    })();
  }, []);

  sortDates();
  return (
    <>
      {posts.slice(0, postQuantity).map((event, i, arr) => {
        if (language[0].eng === "true") {
          if (event.eng === "true")
            return (
              <>
                <BlogCardSection
                  eventId={event.id}
                  date={event.createDate}
                  title={event.title}
                  image={event.image}
                  aboutENG={event.content}
                ></BlogCardSection>
              </>
            );
        } else if (event.eng === "false")
          return (
            <>
              <BlogCardSection
                eventId={event.id}
                date={event.createDate}
                title={event.title}
                image={event.image}
                aboutENG={event.content}
              ></BlogCardSection>
            </>
          );
        return null;
      })}
      <BtnWrap>
        <Button
          onClick={moreEvents}
          to={props.link}
          smooth="true"
          duration={500}
          spy="true"
          offset={-80}
          primary={0}
          dark={0}
        >
          {language[0].eng === "true" ? "More posts!" : "Régebbi posztok"}
        </Button>
      </BtnWrap>
    </>
  );
};

export default BlogList;
