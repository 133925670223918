import React, { useContext, useEffect } from "react";
import { IoIosMenu } from "react-icons/io";
import { animateScroll as scroll } from "react-scroll";
import {
  MobileIcon,
  Nav,
  NavbarContainer,
  NavItem,
  NavLanguage,
  NavLanguageWrap,
  NavLinks,
  NavLinksImgEng,
  NavLinksImgHu,
  NavMenu,
} from "./NavbarElements";
import navbar_logo_hu from "../images/navbar_logo_hu.png";
import navbar_logo_eng from "../images/navbar_logo_eng.png";
import { LanguageContext } from "../LanguageContext";
import * as service from "../../pages/Admin/services/languageService";
import { useHistory } from "react-router-dom";

const Navbar = ({ toggle }) => {
  const toggleHome = () => {
    scroll.scrollToTop();
  };
  const [language, setLanguage] = useContext(LanguageContext);
  const history = useHistory();
  const getItems = () => {
    let get = service.GetAllItems();
    get.then((res) => {
      let data = res.data;
      setLanguage(data);
    });
  };

  useEffect(() => {
    (async () => {
      let lang = localStorage.getItem("eng");
      let data = { eng: lang };
      setLanguage([data]);
    })();
  }, []);
  return (
    <>
      <Nav>
        <NavbarContainer>
          <NavLinks
            onClick={() => {
              history.push("/#home");
            }}
          >
            {language[0].eng === "true" ? (
              <NavLinksImgEng
                src={navbar_logo_eng}
                alt="navbar_logo_Hu"
                className="navbar-icon"
              />
            ) : (
              <NavLinksImgHu
                src={navbar_logo_hu}
                alt="navbar_logo_Hu"
                className="navbar-icon"
              />
            )}
          </NavLinks>
          <MobileIcon onClick={toggle}>
            <IoIosMenu></IoIosMenu>
          </MobileIcon>
          <NavLanguageWrap>
            <NavLanguage
              onClick={() => {
                if (localStorage.getItem("eng") === "true") {
                  localStorage.removeItem("eng");
                  localStorage.setItem("eng", false);
                } else {
                  localStorage.removeItem("eng");
                  localStorage.setItem("eng", true);
                }

                service.UpdateItem(language[0]);
                window.location.reload();
              }}
              style={{ textTransform: "none", fontWeight: "bold" }}
            >
              {localStorage.getItem("eng") === "true" ? "hu/EN" : "HU/en"}
            </NavLanguage>
          </NavLanguageWrap>
          <NavMenu>
            <NavItem>
              <NavLinks
                to="about"
                smooth="true"
                duration={500}
                spy="true"
                exact="true"
                offset={-125}
              >
                {language[0].eng === "true" ? "About me" : "Rólam"}
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks
                style={{ textTransform: "none" }}
                to="doctoral"
                smooth="true"
                duration={500}
                spy="true"
                exact="true"
                offset={-145}
              >
                {language[0].eng === "true" ? "PhD" : "PhD"}
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks
                to="music"
                smooth="true"
                duration={500}
                spy="true"
                exact="true"
                offset={-125}
              >
                {language[0].eng === "true" ? "My music" : "Zene"}
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks
                to="specialities"
                smooth="true"
                duration={500}
                spy="true"
                exact="true"
                offset={-125}
              >
                {language[0].eng === "true" ? "Specialities" : "Érdekességek"}
              </NavLinks>
            </NavItem>{" "}
            <NavItem>
              <NavLinks
                to="events"
                smooth="true"
                duration={500}
                spy="true"
                exact="true"
                offset={-170}
              >
                {language[0].eng === "true" ? "Events" : "Események"}
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks
                to="media"
                smooth="true"
                duration={500}
                spy="true"
                exact="true"
                offset={-125}
              >
                {language[0].eng === "true" ? "Media" : "Média"}
              </NavLinks>
            </NavItem>{" "}
            <NavItem>
              <NavLinks
                to="collection"
                smooth="true"
                duration={500}
                spy="true"
                exact="true"
                offset={-150}
              >
                {language[0].eng === "true" ? "Collection" : "Collection"}
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks
                to="bio"
                smooth="true"
                duration={500}
                spy="true"
                exact="true"
                offset={-125}
              >
                {language[0].eng === "true" ? "Bio" : "Életrajz"}
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks
                to="shop"
                smooth="true"
                duration={500}
                spy="true"
                exact="true"
                offset={-125}
              >
                {language[0].eng === "true" ? "Shop" : "Bolt"}
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks
                to="contact"
                smooth="true"
                duration={500}
                spy="true"
                exact="true"
                offset={-120}
              >
                {language[0].eng === "true" ? "Contact" : "Kapcsolat"}
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks
                onClick={() => {
                  if (localStorage.getItem("eng") === "true") {
                    localStorage.removeItem("eng");
                    localStorage.setItem("eng", false);
                  } else {
                    localStorage.removeItem("eng");
                    localStorage.setItem("eng", true);
                  }

                  service.UpdateItem(language[0]);
                  window.location.reload();
                }}
                style={{ textTransform: "none", fontWeight: "bold" }}
              >
                {localStorage.getItem("eng") === "true" ? "hu/EN" : "HU/en"}
              </NavLinks>
            </NavItem>
          </NavMenu>
        </NavbarContainer>
      </Nav>
    </>
  );
};

export default Navbar;
