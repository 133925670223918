import React, { useState, createContext } from "react";

export const ReviewContext = createContext();

export const ReviewProvider = (props) => {
  const [reviews, setReviews] = useState([
    {
      title: `“Not only has all this work and research proven to be a godsend for those of you who dislike the sound of the piano, it's a breath of fresh air for those of us who do."`,
      titleENG: `“Not only has all this work and research proven to be a godsend for those of you who dislike the sound of the piano, it's a breath of fresh air for those of us who do."`,
      subtitle: `03.2019  Jean-Yves Duperron, Classical Music Sentinel, Canada on "Transforming Traditions" (Angol)`,
      subtitleENG: `03.2019  Jean-Yves Duperron, Classical Music Sentinel, Canada on "Transforming Traditions" (English)`,
      buttonLabel: "Elolvasom a cikket!",
      buttonLabelENG: "See full article",
      link: "",
    },
    {
      title: `„All of it played outstandingly with a tremendously beautiful sound. A musician, who is obviously extremely thoughtful of phrasing, shaping and articulation in her playing, while simultaneously liberating a wide range of emotions"`,
      titleENG: `„All of it played outstandingly with a tremendously beautiful sound. A musician, who is obviously extremely thoughtful of phrasing, shaping and articulation in her playing, while simultaneously liberating a wide range of emotions"`,
      subtitle: `2017 április, Ines Pasz, SWR Südwestrundfunk CD-Tipp (német és angol nyelven)`,
      subtitleENG: `04.2017  Ines Pasz, SWR Südwestrundfunk CD-Tipp (German & English)`,
      buttonLabel: "Elolvasom a cikket!",
      buttonLabelENG: "See full article",
      link: "",
    },
    {
      title: `„…in Gyori's capable hands these works shine...” „…an excellent, high-quality album featuring beautiful playing and clear phrasing."`,
      titleENG: `„…in Gyori's capable hands these works shine...” „…an excellent, high-quality album featuring beautiful playing and clear phrasing."`,
      subtitle: `2017 június, The Flute View (Egyesült Államok) a ’Glowing Sonorities’ lemezről (angol nyelven)`,
      subtitleENG: `06.2017  The Flute View (USA) on ’Glowing Sonorities’ (English)`,
      buttonLabel: "Elolvasom a cikket!",
      buttonLabelENG: "See full article",
      link: "",
    },
    {
      title: `„…the performances are exquisitely convincing. Gyori's play is immensely colourful and full of fantasy. Listening to her sound, I was immediately mesmerized by her flawless techniques and her brilliant tone.”`,
      titleENG: `„…the performances are exquisitely convincing. Gyori's play is immensely colourful and full of fantasy. Listening to her sound, I was immediately mesmerized by her flawless techniques and her brilliant tone.”`,
      subtitle: `2017 március,  FLUIT Magazine a ’Glowing Sonorities’ lemezről (holland nyelven)`,
      subtitleENG: `03.2017  FLUIT Magazine on ’Glowing Sonorities’ (Dutch)`,
      buttonLabel: "Elolvasom a cikket!",
      buttonLabelENG: "See full article",
      link: "",
    },
    {
      title: `„…Győri Noémi kiegyenlített regiszterekkel, érzéki szépségű hangon, áradó dallamossággal, hajlékony legatókkal fuvolázik, és a szükséges manuális virtuo­zitásnak sincs híján, játéka élvezetes és illúziókeltő”`,
      titleENG: `„…Noemi Gyori plays consistently balanced in each register, with sensually beautiful tone, pouring melodiousness, smooth, flexible legatos, while she also doesn’t lack the necessary manual virtuosity”`,
      subtitle: `2017 július, Csengery Kristóf a ’Glowing Sonorities’ lemezről (magyar nyelven)`,
      subtitleENG: `07.2017  Kristóf Csengery on ’Glowing Sonorities’ (Hungarian)`,
      buttonLabel: "Elolvasom a cikket!",
      buttonLabelENG: "See full article",
      link: "",
    },
    {
      title: `„…this rendition of the Reinecke Flute Sonata is one of the best I've heard, even when compared against recordings by Pahud, Bezaly or Rampal.”`,
      titleENG: `„…this rendition of the Reinecke Flute Sonata is one of the best I've heard, even when compared against recordings by Pahud, Bezaly or Rampal.”`,
      subtitle: `2017 május, Classical Music Sentinel, Jean-Yves Duperron a ’Glowing Sonorities’ lemezről (angol nyelven)`,
      subtitleENG: `05.2017 Classical Music Sentinel, Jean-Yves Duperron on ’Glowing Sonorities’ (English)`,
      buttonLabel: "Elolvasom a cikket!",
      buttonLabelENG: "See full article",
      link: "",
    },
    {
      title: `„…Noemi possesses all the qualities of sensitivity and inventiveness to give new life to these famous and much loved works…”
      „...a young virtuoso player, the Hungarian flutist Noemi Gyori made me change my idea once again regarding the potentials of modern flute..."`,
      titleENG: `„…Noemi possesses all the qualities of sensitivity and inventiveness to give new life to these famous and much loved works…”
      „...a young virtuoso player, the Hungarian flutist Noemi Gyori made me change my idea once again regarding the potentials of modern flute..."`,
      subtitle: `2017 március, The Grey Panthers, Ferruccio Nuzzo a ’Glowing Sonorities’ lemezről (olasz nyelven)`,
      subtitleENG: `03.2017 The Grey Panthers, Ferruccio Nuzzo on ’Glowing Sonorities’ (Italian)`,
      buttonLabel: "Elolvasom a cikket!",
      buttonLabelENG: "See full article",
      link: "",
    },
    {
      title: `„…stylistically idiomatic, devoted, gripping, accurate and masterful performance…”`,
      titleENG: `„…stylistically idiomatic, devoted, gripping, accurate and masterful performance…”`,
      subtitle: `2016 november, Kocsis Zoltán a ’Glowing Sonorities’ lemezről (angol nyelven)`,
      subtitleENG: `11.2016 Zoltán Kocsis on ’Glowing Sonorities’ (English)`,
      buttonLabel: "Elolvasom a cikket!",
      buttonLabelENG: "See full article",
      link: "",
    },
    {
      title: `„…The interpretations often tend toward motion rather than lingering, but rank with the best."…”`,
      titleENG: `„…The interpretations often tend toward motion rather than lingering, but rank with the best."…”`,
      subtitle: `2017, Todd Gorman, American Record Guide a ’Glowing Sonorities’ lemezről (angol nyelven)`,
      subtitleENG: `09.2017 Todd Gorman, American Record Guide on ’Glowing Sonorities’ (English)`,
      buttonLabel: "Elolvasom a cikket!",
      buttonLabelENG: "See full article",
      link: "",
    },
    {
      title: `„…unveiled a remarkable technique as well as a moving sensibility.”  „…bewitching and captivating…”`,
      titleENG: `„…unveiled a remarkable technique as well as a moving sensibility.”  „…bewitching and captivating…”`,
      subtitle: `2016 Le Bien Public Mozart c-dúr fuvola-hárfa kettősversenyének előadásáról (francia nyelven)`,
      subtitleENG: `01.2016 Le Bien Public review of the Mozart C major Flute and Harp Concerto (French)`,
      buttonLabel: "Elolvasom a cikket!",
      buttonLabelENG: "See full article",
      link: "",
    },
    {
      title: `„…telt, gazdag, szárnyaló fuvolahangon játszott…”`,
      titleENG: `„…sonorous, rich and soaring sound…”`,
      subtitle: `2013.03.08. ZeneKar, Csengery Kristóf, Szervánszky Endre Fuvolaversenyének előadásáról`,
      subtitleENG: `08.03.2013 ZeneKar, Orchestra Magazine on the Ender Szervánszky Flute Concerto`,
      buttonLabel: "Elolvasom a cikket!",
      buttonLabelENG: "See full article",
      link: "",
    },
    {
      title: `„…káprázatosan virtuóz játék…”`,
      titleENG: `„…dazzlingly virtuosic playing…”`,
      subtitle: `2012. Nottingham Post Carl Nielsen Fuvolaversenyének előadásáról`,
      subtitleENG: `15.2012 Nottingham Post on the Nielsen Concerto`,
      buttonLabel: "Elolvasom a cikket!",
      buttonLabelENG: "See full article",
      link: "",
    },
    {
      title: `„…az idei évad legnagyobb zenei élményében lehetett részünk…”`,
      titleENG: `„…their concert was the greatest musical experience of the season…”`,
      subtitle: `2011.04.21. Newjsag a Madaras Gergellyel adott duó koncertünkről`,
      subtitleENG: `21.04.2011 Newjsag on the Flute Duo Concert with Gergely Madaras`,
      buttonLabel: "Elolvasom a cikket!",
      buttonLabelENG: "See full article",
      link: "",
    },
    {
      title: `„…Együttjátékuk kifejezetten csiszolt és koncentrált, egymásra figyelő muzsikálást, igazi kamarazenélést hozott.”`,
      titleENG: `„…especially refined and concentrated, attentive musicality, resulting in true chamber music playing.”`,
      subtitle: `2010.08.26 Revizor, Malina János a "Párizs fényeitől a Buenos Aires-i kávézókig" c. koncertről`,
      subtitleENG: `26.08.2010 Revizor review of ”From the lights of Paris to the Cafés of Buenos Aires”`,
      buttonLabel: "Elolvasom a cikket!",
      buttonLabelENG: "See full article",
      link: "",
    },
    {
      title: `„….szuggesztíven és intenzív összehangban játszott, emlékezetesen szép teljesítményt nyújtva…”`,
      titleENG: `„….she played suggestively and intensively, providing a beautiful, memorable performance…”`,
      subtitle: `2009.10.18 – Revizor, Malina János Sztojanov Georgi Fuvolaversenyének előadásáról `,
      subtitleENG: `18.10.2009 – Revizor on Georgi Sztojanov: Flute Concerto `,
      buttonLabel: "Elolvasom a cikket!",
      buttonLabelENG: "See full article",
      link: "",
    },
    {
      title: `„…A hallatlanul igényes és szuggesztív szólamot játszó Győri Noémi egészen kiválóan állt helyt hangi kvalitás, technikai biztonság és zenei megvalósítás szempontjából egyaránt…”`,
      titleENG: `„…unprecedented relevant interpretation in regard to sound qualities, technical control and musical realization, all at the same time…”`,
      subtitle: `2009.03. Muzsika, Kocsár Miklós: Capricorn concertójának előadásáról (Magyar nyelven)`,
      subtitleENG: `03.2009 Muzsika Magazine on Miklós Kocsár: Capricorn Concerto (Hungarian)`,
      buttonLabel: "Elolvasom a cikket!",
      buttonLabelENG: "See full article",
      link: "",
    },
  ]);
  return (
    <ReviewContext.Provider value={[reviews, setReviews]}>
      {props.children}
    </ReviewContext.Provider>
  );
};
