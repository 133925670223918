import styled from "styled-components";

export const HeroContainer = styled.div`
  margin-top: 80px;
  background: #000;
  display: flex;
  justify-content: center;
  align-item: center;
  height: 75vh;
  position: relative;
  z-index: 1;

  @media screen and (max-width: 1680px) {
  }
  @media screen and (max-width: 1280px) {
  }
  @media screen and (max-width: 980px) {
  }
  @media screen and (max-width: 736px) {
  }
  @media screen and (max-width: 480px) {
    height: 37.5rem;
  }
`;

export const HeroContent = styled.div`
  height: 100%;
  width: 100%;
`;

export const HeroPositionContainerBlog = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 60.9375vw;
  max-width: 1140px;
  height: 100%;
  justify-content: flex-start;
  display: grid;
  grid-template-rows: 12.5rem 2.5rem 2.5rem 7.75rem;

  @media screen and (max-width: 1680px) {
    grid-template-rows: 12.5rem 2.5rem 2.5rem 7.75rem;
  }

  @media screen and (max-width: 1366px) {
    grid-template-rows: 12.5rem 2.5rem 2.5rem 7.75rem;
  }
  @media screen and (max-width: 1280px) {
    width: 88.88vw;
    max-width: unset;
    grid-template-rows: 12.5rem 2.5rem 2.5rem 7.75rem;
  }
  @media screen and (max-width: 1024px) {
    width: 88.88vw;
    max-width: unset;
    grid-template-rows: 12.5rem 2.5rem 2.5rem 7.75rem;
  }
  @media screen and (max-width: 980px) {
    grid-template-rows: 12.5rem 2.5rem 2.5rem 7.75rem;
  }

  @media screen and (max-width: 480px) {
    grid-template-rows: 25rem 2.5rem 2.5rem 7.75rem;
  }
`;

export const HeroH1 = styled.h1`
  margin-top: 12vh;
  color: #000;
  display: flex;
  font-size: 70px;
  text-align: center;
  position: relative;
  font-weight: 500;
  font-family: playfairDisplay !important;
  text-transform: uppercase;

  @media screen and (max-width: 768px) {
    font-size: 48px;
    position: relative;
    right: 0;
    top: 0;
  }
`;
export const HeroH1White = styled.h1`
  color: #fff;
  display: flex;
  font-size: 42px;
  text-align: left;
  position: relative;
  font-weight: 300;
  justify-content: left;
  text-transform: uppercase;
  top: 7rem;
  margin: 0;
  font-family: Roboto;

  @media screen and (max-width: 1680px) {
    font-size: 40px;
  }
  @media screen and (max-width: 1440px) {
    left: -5rem;
  }
  @media screen and (max-width: 1366px) {
    left: 0;
    justify-content: flex-start;
    text-align: left;
  }
  @media screen and (max-width: 1280px) {
    font-size: 42px;
  }

  @media screen and (max-width: 1024px) {
    font-size: 36px;
    justify-content: flex-start;
    text-align: left;
    top: 1rem;
  }
`;
export const HeroPBlog = styled.p`
  display: flex;
  color: #fff;
  font-size: 24px;
  position: relative;
  text-align: left;
  font-weight: 300;
  max-width: 35rem;
  justify-content: left;
  margin-bottom: 0;

  @media screen and (max-width: 1680px) {
  }
  @media screen and (max-width: 1440px) {
    left: -5rem;
  }
  @media screen and (max-width: 1366px) {
    left: 0;
    justify-content: flex-start;
    text-align: left;
  }
  @media screen and (max-width: 1280px) {
  }
  @media screen and (max-width: 1024px) {
    font-size: 20px;
    justify-content: flex-start;
    text-align: left;
  }
  @media screen and (max-width: 480px) {
    font-size: 17px;
  }
`;

export const HeroH12 = styled.h1`
  color: #000;
  display: flex;
  font-size: 30px;
  text-align: center;
  position: relative;
  left: 15rem;
  top: 3rem;
  font-weight: 500;
  font-family: playfairDisplay !important;

  @media screen and (max-width: 768px) {
    font-size: 20px;
    position: relative;
    right: 11.8rem;
    top: 4rem;
  }
`;

export const HeroP = styled.p`
  margin-top: 24px;
  display: flex;
  color: #000;
  font-size: 24px;
  text-align: left;
  position: relative;
  left: 21.5rem;
  top: 2rem;
  max-width: 400px;

  @media screen and (max-width: 768px) {
    font-size: 20px;
    position: relative;
    right: 9rem;
    top: 3rem;
  }
`;

export const HeroBtnWrapper = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
