import React, { useState, createContext } from "react";

export const TypeContext = createContext();

export const TypeProvider = (props) => {
  const [types, setTypes] = useState([
    {
      title: "Interview",
      link: "Interview",
    },
    {
      title: "Review",
      link: "Review",
    },
  ]);
  return (
    <TypeContext.Provider value={[types, setTypes]}>
      {props.children}
    </TypeContext.Provider>
  );
};
