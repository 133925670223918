import React, { useRef } from "react";

export function FormsRef() {
  const title = useRef();
  const titleENG = useRef();
  const buttonLabel = useRef();
  const buttonLabelENG = useRef();
  const subtitle = useRef();
  const about = useRef();
  const subtitleENG = useRef();
  const aboutENG = useRef();
  const place = useRef();
  const date = useRef();
  const link = useRef();
  const linkENG = useRef();
  const price = useRef();
  const image = useRef("");
  const scroll = useRef();
  const align = useRef();
  const caption = useRef();

  return {
    title,
    titleENG,
    subtitle,
    about,
    subtitleENG,
    aboutENG,
    place,
    date,
    image,
    buttonLabel,
    buttonLabelENG,
    link,
    linkENG,
    price,
    scroll,
    align,
    caption,
  };
}
