import styled from "styled-components";

export const HeroH1 = styled.p`
  color: #fff;
  display: flex;
  font-size: 50px;
  text-align: center;
  position: relative;
  left: 30vh;
  top: 50vh;
  font-weight: 300;
  text-transform: uppercase;

  @media screen and (max-width: 768px) {
    font-size: 42px;
    position: relative;
    top: 7.5rem;
    left: 0;
  }
`;

export const HeroP = styled.p`
  margin-top: 24px;
  display: flex;
  color: #000;
  font-size: 24px;
  text-align: left;
  position: relative;
  left: 21.5rem;
  top: 2rem;
  max-width: 400px;

  @media screen and (max-width: 768px) {
    font-size: 24px;
    position: relative;
    top: 5rem;
    left: 0;
  }
`;

export const HeroBtnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  left: 30vh;
  top: 45vh;
  @media screen and (max-width: 768px) {
    position: relative;
    top: 10rem;
    left: 0;
  }

  @media screen and (max-width: 480px) {
    position: relative;
    top: 17.5rem;
    left: 0;
  }
`;
