import styled from "styled-components";

export const CardContainer = styled.div`
  height: 75vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fff;

  @media screen and (max-width: 1680px) {
    height: 85vh;
  }
  @media screen and (max-width: 1280px) {
    height: 85vh;
  }
  @media screen and (max-width: 980px) {
    height: auto;
  }
  @media screen and (max-width: 480px) {
    height: auto;
  }
`;

export const CardWrapper = styled.div`
  position: relative;
  max-width: 80vh;
  max-height: 65vh;
  left: -20rem;
  margin: 0 auto;
  padding: 3rem;

  @media screen and (max-width: 1680px) {
    height: auto;
  }
  @media screen and (max-width: 1280px) {
    height: auto;
  }
  @media screen and (max-width: 768px) {
    left: -10rem;
  }
  @media screen and (max-width: 480px) {
    position: static;
    max-width: 45vh;
    max-height: none;
    height: 100%;
    padding: 0.5rem;
    top: 5vh;
    left: -20rem;
  }
`;

export const Card = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
  opacity: 0.8;
  position: relative;
  width: 100%;
  left: ${(props) => (props.left ? "0rem" : "40rem")};
  max-height: none;

  &:hover {
    transform: scale(1.04);
    transition: all 0.2s ease-in-out;
  }

  @media screen and (max-width: 1680px) {
  }
  @media screen and (max-width: 1280px) {
    left: ${(props) => (props.left ? "1rem" : "37.5rem")};
  }

  @media screen and (max-width: 1024px) {
    left: ${(props) => (props.left ? "20rem" : "20rem")};
  }

  @media screen and (max-width: 768px) {
    left: ${(props) => (props.left ? "10rem" : "10rem")};
  }
  @media screen and (max-width: 480px) {
    max-height: 100%;
    opacity: 0.8;
    position: relative;
    top: 2.5rem;
    left: ${(props) => (props.left ? "-1rem" : "10rem")};
    width: 15rem;
  }
`;

export const CardH1 = styled.p`
  font-size: 2.25rem;
  font-weight: 300;
  color: #000;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
  text-align: center;

  @media screen and (max-width: 1366px) {
    font-size: 2rem;
  }

  @media screen and (max-width: 768px) {
    max-height: 65vh;
    font-size: 1.5rem;
  }
`;
export const CardH2 = styled.p`
  font-size: 1.2rem;
  font-weight: 300;
  color: #000;
  margin-bottom: 0.5rem;
  font-style: italic;
`;

export const CardP = styled.p`
  font-size: 1.1rem;
  text-align: center;
`;
