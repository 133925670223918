import React, { useState } from "react";
import Sidebar from "../../components/Sidebar";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import BlogPageDynamic from "../../components/Blog/BlogPageDynamic";
import { LanguageProvider } from "../../components/LanguageContext";
import { BlogProvider } from "../../components/Blog/BlogContext";
import { animateScroll as scroll } from "react-scroll";
import { OverflowContainer } from "../Home/HomeElements";
const BlogPage = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  scroll.scrollToTop();
  return (
    <LanguageProvider>
      <BlogProvider>
        <Sidebar isOpen={isOpen} toggle={toggle}></Sidebar>
        <Navbar toggle={toggle}></Navbar>{" "}
        <OverflowContainer>
          <BlogPageDynamic></BlogPageDynamic>{" "}
        </OverflowContainer>
        <Footer></Footer>
      </BlogProvider>
    </LanguageProvider>
  );
};

export default BlogPage;
