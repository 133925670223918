import http from "./http-auth";

export function InsertItem(data) {
  return http.post("/specialities", data).then((res) => {});
}
export function DeleteItem(data) {
  return http.delete(`/specialities/${data}`).then((res) => {});
}

export function UpdateItem(data) {
  return http.put(`/specialities/${data.id}`, data).then((res) => {});
}

export function GetItem(data) {
  return http.get(`/specialities/${data.id}`, data).then((res) => {});
}

export const GetAllItems = () => {
  return http.get("/specialities");
};
