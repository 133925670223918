import React, { useState } from "react";
import PlayerCard from "../PlayerCard"; // Import the common PlayerCard component
import expolrationCover from "../../../albums/exploration_cover.jpeg";
import exploration_song1 from "../../../music/exploration_borne.mp3";
import exploration_song2 from "../../../music/exploration_beethoven.mp3";
import exploration_song3 from "../../../music/exploration_schubert.mp3";

const Exploration = {
  cover: expolrationCover,
  songs: [
    {
      name: "François Borne",
      subtitle: "Noémi Győri, Suzana Bartal",
      mp3: exploration_song1,
    },
    {
      name: "Beethoven",
      subtitle: "Noémi Győri, Suzana Bartal",
      mp3: exploration_song2,
    },
    {
      name: "Franz Schubert",
      subtitle: "Noémi Győri, Suzana Bartal",
      mp3: exploration_song3,
    },
  ],
};

export const ExplorationAlbum = () => {
  const [language, setLanguage] = useState({ eng: "true" }); // Replace with your language context

  return (
    <PlayerCard
      cover={Exploration.cover}
      songs={Exploration.songs}
      buyLink="https://music.apple.com/gb/album/exploration-works-by-schubert-blahetka-beethoven-borne/1704282966"
      language={language}
    />
  );
};
