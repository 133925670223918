import styled from "styled-components";

export const ShopBox = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  justify-items: center;
  align-items: center;
  background: #fff;
  height: auto;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  margin-left: auto;
  margin-right: auto;
  width: 60.9375vw;
  max-width: 1140px;

  a:hover {
    background-color: transparent !important;
    border: none !important;
    color: none !important;
  }

  &:hover {
    background-color: white !important;
  }
  @media screen and (max-width: 1680px) {
    width: unset;
  }

  @media screen and (max-width: 1280px) {
    width: unset;
    max-width: unset;
  }
  @media screen and (max-width: 1024px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    padding-top: 2rem;
    padding-bottom: 0rem;
  }
  @media screen and (max-width: 480px) {
    display: grid;
    grid-template-columns: 1fr;
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    padding-top: 2rem;
    padding-bottom: 0rem;
  }
`;

export const ShopWrap = styled.div`
  opacity: 0.9;
  display: grid;
  background: #fff;
  justify-items: center;
  align-items: center;
  width: 100%;
  min-width: 300px;
  min-height: 350px;
  max-width: 300px;
  max-height: 350px;
  padding: 1rem;
  padding-bottom: 2rem;
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.08);
    transition: all 0.2s ease-in-out;
  }

  @media screen and (max-width: 480px) {
    justify-content: center;
    grid-template-rows: 1fr 1fr;
    min-width: 300px;
    max-width: 300px;
    min-height: 425px;
    max-height: 50vh;

    &:hover {
      transform: none;
      transition: none;
    }
  }
`;

export const AlbumIconWrapper = styled.div`
  overflow: hidden;
  @media screen and (max-width: 480px) {
    height: 300px;
    width: 300px;
  }
`;

export const AlbumIcon = styled.div`
  height: 225px;
  width: 225px;
  text-align: center;
  padding-bottom: 2rem;
  marigin: 0;
  @media screen and (max-width: 480px) {
    height: 300px;
    width: 300px;
  }
`;

export const TextWrapper = styled.div`
  width: 220px;
  height: 70px;
  @media screen and (max-width: 480px) {
    width: auto;
  }
`;

export const Text = styled.h1`
  margin: 0 !important;
  text-align: center !important;
  line-height: 1em !important;
  padding: 0.2rem 0.1rem 0.3rem !important;
  font-size: 16px;
  font-weight: bold;
  color: #000;
  @media screen and (max-width: 480px) {
    font-size: 18px;
    text-align: center !important;
  }
`;
export const TextP = styled.p`
  margin: 0 !important;
  padding: 0 !important;
  text-align: center !important;
  line-height: 1em !important;
  font-size: 14px;
  color: #000;
  margin-bottom: 0.5rem !important;

  @media screen and (max-width: 480px) {
    font-size: 14px;
    text-align: center !important;
    width: 25rem;
  }
`;

export const HeroText = styled.h1`
  color: #fff;
  display: flex;
  font-size: 42px;
  text-align: center;
  position: relative;
  font-weight: 100;
  justify-content: flex-start;
  text-transform: uppercase;
  top: 1rem;
  margin: 0;
  margin-bottom: 1rem;

  @media screen and (max-width: 1680px) {
  }
  @media screen and (max-width: 1280px) {
  }

  @media screen and (max-width: 980px) {
    font-size: 34px;
  }
`;

export const HeroTextP = styled.p`
  display: flex;
  color: #fff;
  font-size: 24px;
  position: relative;
  text-align: left;
  font-weight: 500;
  max-width: 35rem;
  top: 1rem;
  margin-bottom: 0;

  @media screen and (max-width: 1680px) {
  }
  @media screen and (max-width: 1280px) {
  }
  @media screen and (max-width: 480px) {
    font-size: 18px;
    max-width: unset;
  }
`;
export const HeroPositionContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 60.9375vw;
  height: 100%;
  max-width: 1140px;
  justify-content: flex-start;
  display: grid;
  grid-template-rows: 15% 5% 5% 5%;
  align-items: end;

  @media screen and (max-width: 1280px) {
    width: 88.88vw;
    max-width: unset;
    grid-template-rows: 15% 5% 5% 5%;
  }
  @media screen and (max-width: 980px) {
    grid-template-rows: 7.5% 7.5% 5% 5%;
  }
  @media screen and (max-width: 480px) {
    grid-template-rows: 7.5% 10% 5% 5%;
  }
`;
