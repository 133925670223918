import {
  createMuiTheme,
  CssBaseline,
  Drawer,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core";
import React from "react";
import Header from "../../../../components/Admin/Header";
import red from "@material-ui/core/colors/red";
import MyDrawer from "../../../../components/Admin/Drawer";
import Media from "./Media";
import { LanguageProvider } from "../../../../components/LanguageContext";
import { MediaProvider } from "../../../../components/MediaSection/MediaContext";
import { useStyles, theme } from "../../theme.js";

const MediaAdminPage = (props) => {
  const classes = useStyles();

  return (
    <LanguageProvider>
      <MediaProvider>
        <ThemeProvider theme={theme}>
          <Drawer
            className={classes.drawer}
            variant="permanent"
            classes={{
              paper: classes.drawerPaper,
            }}
            anchor="left"
          >
            <MyDrawer></MyDrawer>
          </Drawer>
          <div className={classes.adminMain}>
            <Header></Header>
            <Media></Media>
          </div>
          <CssBaseline></CssBaseline>
        </ThemeProvider>
      </MediaProvider>
    </LanguageProvider>
  );
};

export default MediaAdminPage;
