import React, { useContext, useEffect, useState } from "react";
import HeroEventPageSection from "../HeroSection/HeroSpecPage";
import { useLocation } from "react-router";
import { GetAllItems } from "../../pages/Admin/services/aboutpageService";
import BigSpecCardSection from "./BigSpecCard";
import { AboutPageContext } from "./AboutPageContext";
import { LanguageContext } from "../LanguageContext";
const AboutPage = (props) => {
  const [aboutPages, setAboutPages] = useContext(AboutPageContext);
  const [language, setLanguage] = useContext(LanguageContext);
  const [pageName, setPageName] = useState();
  const location = useLocation();

  useEffect(() => {
    (async () => {
      const getItems = () => {
        let get = GetAllItems();
        get.then((res) => {
          let data = res.data;
          setAboutPages(data);
        });
      };
      getItems();
      getPageName();
    })();
  }, [language, setAboutPages]);

  const getPageName = () => {
    if (
      location.pathname === "/szolista" ||
      location.pathname === "/the-soloist"
    )
      setPageName(`${language[0].eng === "true" ? "THE SOLOIST" : "SZÓLISTA"}`);
    else if (
      location.pathname === "/kamarazenesz" ||
      location.pathname === "/the-chamber-musician"
    )
      setPageName(
        `${
          language[0].eng === "true" ? "THE CHAMBER MUSICIAN" : "KAMARAZENÉSZ"
        }`
      );
    else if (
      location.pathname === "/zenekari-fuvolas" ||
      location.pathname === "/the-orchestral-flutist"
    )
      setPageName(
        `${
          language[0].eng === "true"
            ? "THE ORCHESTRAL FLUTIST"
            : "ZENEKARI FUVOLÁS"
        }`
      );
    else if (
      location.pathname === "/tanar" ||
      location.pathname === "/the-flute-teacher"
    )
      setPageName(`${language[0].eng === "true" ? "THE TEACHER" : "TANÁR"}`);
  };

  return (
    <>
      {aboutPages.map((item) => {
        if (item.title === pageName)
          return (
            <>
              <HeroEventPageSection
                id="events_hero"
                backgroundImage={item.image}
                titleENG={item.title}
              ></HeroEventPageSection>
              <BigSpecCardSection
                titleENG={item.title}
                aboutENG={item.content}
                image={item.image}
              ></BigSpecCardSection>
            </>
          );
        return null;
      })}
    </>
  );
};

export default AboutPage;
