import React from "react";

function Imagetest() {
  return (
    <div>
      <form action="/images" enctype="multipart/form-data" method="POST">
        <input type="file" name="myImage" accept="image/*" />
        <input type="submit" value="Upload Photo" />
      </form>
    </div>
  );
}

export default Imagetest;
