import React from "react";
import {
  FormControl,
  InputLabel,
  Select as MuiSelect,
  MenuItem,
} from "@material-ui/core";
import { LazyLoadImage } from "../../../components/LazyLoadImage";
import { baseUrl } from "../../../utils/baseUrl";

export default function Select(props) {
  const data = Array.isArray(props.values) ? props.values.reverse() : [];

  return (
    <FormControl variant="outlined" fullWidth={true}>
      <InputLabel>{props.label}</InputLabel>
      <MuiSelect
        onChange={props.onChange}
        label={props.label}
        name={props.name}
        defaultValue={props.defaultValue}
      >
        <MenuItem value="">No image</MenuItem>
        {data.map((item) => {
          console.log(item); // Log item.link here
          return (
            <MenuItem key={item.id} value={item.link}>
              <LazyLoadImage
                src={`${baseUrl}/images/${item.id}`}
                alt="noemi_image"
                width={50}
              />
              {item.title}
            </MenuItem>
          );
        })}
      </MuiSelect>
    </FormControl>
  );
}
