import styled from "styled-components";

export const ProgramBox = styled.div`
  display: flex;
  justify-content: center;
  background: #272733;
  width: 100%;
  height: auto;
  padding-top: 1rem;
  padding-bottom: 0.5rem;
  @media screen and (max-width: 768px) {
    width: 100%;
    max-height: none;
  }
`;

export const ProgramWrap = styled.div`
display: grid;
background: #fff;
justify-items: center;
margin-left: auto;
margin-right: auto;
width: 60.9375vw;
max-width: 1140px;
padding: 1rem;
padding-bottom: 2rem;
transition: all 0.2s ease-in-out;
box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);

&:hover {
  transform: scale(1.04);
  transition: all 0.2s ease-in-out;
}

  @media screen and (max-width: 1280px) {
    width: 88.88vw;    
    &:hover {
      transform: scale(1);
      transition: all 0.2s ease-in-out;
    }
  }
  @media screen and (max-width: 980px) {

  }
  @media screen and (max-width: 480px) {
    width: 100%;
  }
}
`;

export const PressIcon = styled.div`
  height: 50px;
  width: 50px;
  text-align: center;
  padding: 0;
  marigin: 0;
`;
export const TextWrap = styled.div`
  width: 100%;
`;

export const Text = styled.h1`
  padding: 0.5rem 0.1rem 0.1rem 0.5rem;
  font-size: 12px;
  font-weight: 500;
  color: #000;
  margin-bottom: 0;
  text-transform: uppercase;
`;

export const TextP = styled.p`
  padding: 0.5rem 0.1rem 0.1rem 0.5rem;
  font-size: 20px;
  font-weight: 500;
  color: #000;
`;

export const HeroText = styled.p`
  color: #fff;
  display: flex;
  position: relative;
  font-size: 50px;
  top: 1.5rem;
  font-weight: 300;
  text-transform: uppercase;
  justify-content: flex-end;
  text-align: left;

  @media screen and (max-width: 1680px) {
  }
  @media screen and (max-width: 1440px) {
    top: 2.5rem;
  }
  @media screen and (max-width: 1280px) {
  }

  @media screen and (max-width: 480px) {
    font-size: 36px;
    position: relative;
  }
`;

export const HeroText2 = styled.h1`
  position: relative;
  top: 15rem;
  left: 25rem;
  font-size: 60px;
  color: #fff;
  text-transform: uppercase;
  opacity: 0.9;
`;
export const HeroPositionContainerProposals = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 60.9375vw;
  height: 100%;
  max-width: 1140px;
  justify-content: flex-start;
  display: grid;
  grid-template-rows: 10%;
  align-items: end;

  @media screen and (max-width: 1680px) {
  }
  @media screen and (max-width: 1280px) {
    width: 88.88vw;
    max-width: unset;
  }

  @media screen and (max-width: 980px) {
  }

  @media screen and (max-width: 480px) {
    grid-template-rows: 90%;
  }
`;
