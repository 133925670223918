import React, { useState } from "react";
import PlayerCard from "../PlayerCard"; // Import the common PlayerCard component
import glowingCover from "../../../albums/glowing_cover.jpg";
import glowing_song1 from "../../../music/glowing-song1.mp3";
import glowing_song2 from "../../../music/glowing-song2.mp3";
import glowing_song3 from "../../../music/glowing-song3.mp3";

const Glowing = {
  cover: glowingCover,
  songs: [
    {
      name: "Franck IV.",
      subtitle: "Noémi Győri, Katalin Csillagh",
      mp3: glowing_song1,
    },
    {
      name: "Reinecke I.",
      subtitle: "Noémi Győri/GLOWING SONORITIES",
      mp3: glowing_song2,
    },
    {
      name: "Schubert I.",
      subtitle: "Noémi Győri/GLOWING SONORITIES",
      mp3: glowing_song3,
    },
  ],
};

export const GlowingAlbum = () => {
  const [language, setLanguage] = useState({ eng: "true" }); // Replace with your language context

  return (
    <PlayerCard
      cover={Glowing.cover}
      songs={Glowing.songs}
      buyLink="https://music.apple.com/gb/album/glowing-sonorities-works-by-schubert-reinecke-franck/1188778193"
      language={language}
    />
  );
};
