import http from "./http-auth";

export function InsertItem(data) {
  return http.post("/reviews", data).then((res) => {});
}

export function DeleteItem(data) {
  return http.delete(`/reviews/${data}`).then((res) => {});
}

export function UpdateItem(data) {
  return http.put(`/reviews/${data.id}`, data).then((res) => {});
}

export function GetItem(data) {
  return http.get(`/reviews/${data.id}`, data).then((res) => {});
}

export const GetAllItems = () => {
  return http.get("/reviews");
};
