import http from "./http-auth";

export function InsertItem(data) {
  console.log(data);
  return http.post("/gallery", data).then((res) => {});
}

export function DeleteItem(data) {
  return http.delete(`/gallery/${data}`).then((res) => {});
}

export function UpdateItem(data) {
  return http.put(`/gallery/${data.id}`, data).then((res) => {});
}

export function GetItem(data) {
  return http.get(`/gallery/${data.id}`, data).then((res) => {});
}

export const GetAllItems = () => {
  return http.get("/gallery");
};
