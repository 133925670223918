import React, { useContext, useEffect } from "react";
import { Card, CardContainer, CardWrapper } from "./InstagramElements";
import { InstagramContext } from "./InstagramContext";
import * as service from "../../pages/Admin/services/instagramService";

const Instagram = () => {
  const [posts, setPosts] = useContext(InstagramContext);

  const getItems = () => {
    let get = service.GetAllItems();
    get.then((res) => {
      let data = res.data;
      setPosts(data);
    });
  };

  useEffect(() => {
    (async () => {
      getItems();
    })();
  }, []);

  return (
    <CardContainer>
      <CardWrapper>
        {posts.map((post) => (
          <Card key={post.id}>
            <iframe
              key={post.id}
              src={`${post.link}embed`}
              width="100%"
              height="auto"
              frameBorder="0"
              scrolling="no"
              allowtransparency="true"
            ></iframe>
          </Card>
        ))}
      </CardWrapper>
      <script src="https://platform.instagram.com/en_US/embeds.js"></script>
    </CardContainer>
  );
};

export default Instagram;
