import { React, useState, useEffect, useContext } from "react";
import PageHeader from "../../../components/Admin/PageHeader";
import EventIcon from "@material-ui/icons/Event";
import Controls from "../controls/Controls";
import Popup from "../Popup";
import useTable from "../useTable";
import * as service from "../services/blogService";
import { Close, EditOutlined, Search } from "@material-ui/icons";
import {
  InputAdornment,
  makeStyles,
  Paper,
  TableBody,
  TableCell,
  TableRow,
  Toolbar,
} from "@material-ui/core";
import Notification from "../controls/Notification";
import ConfirmDialog from "../controls/ConfirmDialog";
import AddPostQuill from "../../../components/Quill/AddPost";
import { BlogContext } from "../../../components/Blog/BlogContext";
import BlogForm from "./BlogForm";
import { useStylesPaper } from "../theme.js";
import { LazyLoadImage } from "../../../components/LazyLoadImage";

const headCells = [
  { id: "title", label: "Title" },
  { id: "content", label: "content" },
  { id: "eng", label: "eng" },
  { id: "image", label: "image" },
  { id: "actions", label: "Actions", disableSorting: true },
];

const Blog = () => {
  const [posts, setPosts] = useContext(BlogContext);
  const classes = useStylesPaper();
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [openPopup, setOpenPopup] = useState(false);
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });

  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
    useTable(posts, headCells, filterFn);

  const handleSearch = (e) => {
    let target = e.target;
    setFilterFn({
      fn: (items) => {
        if (target.value === "") return items;
        else
          return items.filter((x) =>
            x.title.toLowerCase().includes(target.value)
          );
      },
    });
  };

  const addOrEdit = async (item, resetForm) => {
    if (item.id == null) await service.InsertItem(item);
    else await service.UpdateItem(item);
    resetForm();
    setRecordForEdit(null);
    setOpenPopup(false);
    setNotify({
      isOpen: true,
      message: "Submitted Succesfully",
      type: "success",
    });
    window.location.reload();
  };

  const openInPopup = (item) => {
    setRecordForEdit(item);
    setOpenPopup(true);
  };

  const onDelete = async (id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    await service.DeleteItem(id);
    setNotify({
      isOpen: true,
      message: "Deleted Successfully",
      type: "error",
    });
    window.location.reload();
  };

  useEffect(() => {
    (async () => {
      const getItems = () => {
        let get = service.GetAllItems();
        get.then((res) => {
          let data = res.data;
          setPosts(data);
        });
      };
      getItems();
    })();
  }, [setPosts]);

  return (
    <>
      <PageHeader
        title="Blog"
        subtitle="Manage Blog"
        icon={<EventIcon />}
      ></PageHeader>
      <Paper className={classes.pageContent}>
        <TblContainer>
          <AddPostQuill></AddPostQuill>
        </TblContainer>
      </Paper>
      <Paper className={classes.pageContent}>
        <Toolbar>
          <Controls.Input
            label="Search"
            className={classes.searchInput}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            onChange={handleSearch}
          ></Controls.Input>
        </Toolbar>
        <TblPagination></TblPagination>
        <TblContainer>
          <TblHead></TblHead>
          <TableBody>
            {recordsAfterPagingAndSorting().map((item) => (
              <TableRow key={item.id}>
                <TableCell className={classes.narrowCell}>
                  {item.title}
                </TableCell>
                <TableCell className={classes.narrowCell}>
                  {item.content}
                </TableCell>
                <TableCell className={classes.narrowCell}>
                  {item.eng == "true" ? "english" : "hungarian"}
                </TableCell>
                <TableCell className={classes.narrowCell}>
                  <LazyLoadImage
                    src={item.image}
                    alt="img"
                    style={{ maxWidth: "100px", maxHeight: "100px" }}
                    className={classes.Image}
                  />
                </TableCell>
                <TableCell>
                  <Controls.ActionButton
                    color="primary"
                    onClick={() => {
                      openInPopup(item);
                    }}
                  >
                    <EditOutlined fontSize="small"></EditOutlined>
                  </Controls.ActionButton>
                  <Controls.ActionButton
                    color="secondary"
                    onClick={() => {
                      setConfirmDialog({
                        isOpen: true,
                        title: "Are you sure to delete this record?",
                        subTitle: "You can't undo this operation",
                        onConfirm: () => {
                          onDelete(item.id);
                        },
                      });
                    }}
                  >
                    <Close fontSize="small"></Close>
                  </Controls.ActionButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </TblContainer>
      </Paper>
      <Popup openPopup={openPopup} setOpenPopup={setOpenPopup} title="New Blog">
        <BlogForm
          recordForEdit={recordForEdit}
          addOrEdit={addOrEdit}
        ></BlogForm>
      </Popup>
      <Notification notify={notify} setNotify={setNotify}></Notification>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
};

export default Blog;
