import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuth } from "../../../auth/AuthContext";

export const Private = ({ component: C, ...props }) => {
  const { currentUser } = useAuth();
  return (
    <Route
      {...props}
      render={(routeProps) =>
        currentUser ? <C {...routeProps} /> : <Redirect to="/login" />
      }
    />
  );
};
