import http from "./http-auth";

export function InsertItem(data) {
  return http.post("/press", data).then((res) => {});
}

export function DeleteItem(data) {
  return http.delete(`/press/${data}`).then((res) => {});
}

export function UpdateItem(data) {
  return http.put(`/press/${data.id}`, data).then((res) => {});
}

export function GetItem(data) {
  return http.get(`/press/${data.id}`, data).then((res) => {});
}

export const GetAllItems = () => {
  return http.get("/press");
};
