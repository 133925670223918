import React, { useEffect, useContext } from "react";
import { useForm, Form } from "../useForm";
import * as service from "../services/specialitiesService";
import { SpecContext } from "../../../components/Specialities/SpecContext";
import AddPostQuillSpec from "../../../components/Quill/AddPostSpec";

const initialFValues = {
  id: 0,
  title: "",
  content: "",
};

const SpecForm = (props) => {
  const { addOrEdit, recordForEdit } = props;
  const [specPages, setSpecPages] = useContext(SpecContext);
  const { values, setValues, handleInputChange, resetForm } =
    useForm(initialFValues);

  useEffect(() => {
    (() => {
      const getItems = () => {
        let get = service.GetAllItems();
        get.then((res) => {
          let data = res.data;
          setSpecPages(data);
        });
      };
      getItems();
      setValues({
        ...recordForEdit,
      });
    })();
  }, [recordForEdit, setSpecPages, setValues]);

  return (
    <Form>
      <AddPostQuillSpec
        values={values}
        setValues={setValues}
      ></AddPostQuillSpec>
    </Form>
  );
};

export default SpecForm;
