import React, { useState } from "react";
import Sidebar from "../../components/Sidebar";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import EventPageDynamic from "../../components/EventPage/EventPageDynamic";
import { EventProvider } from "../../components/Events/EventContext";
import { LanguageProvider } from "../../components/LanguageContext";
import { OverflowContainer } from "../Home/HomeElements";
const EventPage = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <LanguageProvider>
      <EventProvider>
        <Sidebar isOpen={isOpen} toggle={toggle}></Sidebar>
        <Navbar toggle={toggle}></Navbar>{" "}
        <OverflowContainer>
          <EventPageDynamic></EventPageDynamic>{" "}
        </OverflowContainer>
        <Footer></Footer>
      </EventProvider>
    </LanguageProvider>
  );
};

export default EventPage;
