import styled from "styled-components";

export const AboutContainer = styled.div`
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fff;

  @media screen and (max-width: 1280px) {
  }

  @media screen and (max-width: 480px) {
    height: auto;
  }
`;

export const AboutWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  text-align: center;
  grid-gap: 0px;
  padding: 0 25px;
  padding-left: 17.5%;
  padding-right: 17.5%;

  @media screen and (max-width: 1680px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 0px;
    padding-left: 0;
    padding-right: 0;
    padding: 0 12.5rem;
  }

  @media screen and (max-width: 1280px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 10px;
    padding-left: 0;
    padding-right: 0;
    padding: 0 25px;
  }

  @media screen and (max-width: 480px) {
    grid-template-columns: 1fr;
    grid-gap: 2px;
    padding-left: 0;
    padding-right: 0;
    padding: 0 25px;
  }
`;

export const AboutCard = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  max-height: auto;
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.04);
    transition: all 0.2s ease-in-out;
  } 
  @media screen and (max-width: 980px) {
    &:hover {
      transform: scale(1);
      transition: all 0.2s ease-in-out;
  }
`;

export const AboutIcon = styled.div`
  position: relative;
  top: 1.25rem;
  height: 75px;
  width: 75px;
  text-align: center;
  padding: 0;
  marigin: 0;
`;

export const AboutH1 = styled.p`
  font-size: 1.6rem !important;
  font-weight: 300 !important;
  color: #000 !important;
  min-height: 2rem !important;

  @media screen and (max-width: 1280px) {
    font-size: 1.5rem !important;
    min-height: 2rem !important;
    padding-bottom: 0.3em !important;
  }

  @media screen and (max-width: 480px) {
    font-size: 2rem !important;
    min-height: 2rem !important;
    padding-bottom: 0rem !important;
    padding-top: 0.6rem !important;
  }
`;

export const AboutP = styled.p`
  font-size: 1.1rem !important;
  text-align: center !important;
  margin: 0 !important;
  line-height: 1.75em !important;
  padding: 0 !important;
  padding-bottom: 1rem !important;
  width: 11rem;
  height: 12rem;

  @media screen and (max-width: 480px) {
    min-height: 5rem;
    padding-bottom: 0.5rem !important;
    width: 20rem !important;
    height: auto;
  }
`;
export const BtnWrap = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 0;
  margin: 0;
  padding-top: 4rem !important;

  @media screen and (max-width: 480px) {
    padding-top: 0rem !important;
  }
`;

export const EmptySection = styled.div`
  height: 50px;
  @media screen and (max-width: 480px) {
    height: 15px;
  }
`;
