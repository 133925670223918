import styled from "styled-components";

export const ContentHolder = styled.div.attrs((props) => ({
  className: "ContentHolder",
}))`
  background-color: #000000;
  width: 100%;
  min-height: 100vh;
`;

export const AuthContainer = styled.div.attrs((props) => ({
  className: "ContentHolder",
}))`
  position: absolute;
  width: 100%;
`;

export const BackgroundImage = styled.div.attrs((props) => ({
  className: "BackgroundImage",
}))`
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: #000000;
  background-image: url(${(props) => props.backgroundImage});
  background-size: contain;
  z-index: 0;
  opacity: 0.2;
`;
