import {
  createMuiTheme,
  CssBaseline,
  Drawer,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core";
import React from "react";
import Header from "../../../components/Admin/Header";
import red from "@material-ui/core/colors/red";

import MyDrawer from "../../../components/Admin/Drawer";
import { ShopProvider } from "../../Shop/ShopContext";
import Shop from "./Shop";
import { ImageProvider } from "../../../components/Image/ImageContext";
import { useStyles, theme } from "../theme.js";

const ShopAdminPage = (props) => {
  const classes = useStyles();

  return (
    <ShopProvider>
      <ImageProvider>
        <ThemeProvider theme={theme}>
          <Drawer
            className={classes.drawer}
            variant="permanent"
            classes={{
              paper: classes.drawerPaper,
            }}
            anchor="left"
          >
            <MyDrawer></MyDrawer>
          </Drawer>
          <div className={classes.adminMain}>
            <Header></Header>
            <Shop></Shop>
          </div>
          <CssBaseline></CssBaseline>
        </ThemeProvider>
      </ImageProvider>
    </ShopProvider>
  );
};

export default ShopAdminPage;
