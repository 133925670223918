import React, { useContext } from "react";
import { ButtonAboutMeBlackSolo } from "../../ButtonElement";
import { LanguageContext } from "../../LanguageContext";
import {
  HeroBtnWrapperSolo,
  HeroH1Solo,
  HeroP2Solo,
  HeroPContainerRight,
  HeroPositionContainerSolo,
  HeroPSolo,
} from "../HeroSpecPageElements";

const SoloistHero = (props) => {
  const [language, setLanguage] = useContext(LanguageContext);
  return (
    <HeroPositionContainerSolo>
      <HeroH1Solo>{props.title}</HeroH1Solo>
      <HeroPContainerRight>
        <HeroPSolo>
          {language[0].eng === "true"
            ? `" unveiled a remarkable technique as well as a moving sensibility. "                  `
            : "„…figyelemre méltó technikai tudással és megkapó érzékenységgel játszott.”"}
        </HeroPSolo>
      </HeroPContainerRight>
      <HeroPContainerRight>
        <HeroP2Solo>
          {language[0].eng === "true" ? `Le Bien Public ` : "Le Bien Public "}
        </HeroP2Solo>
      </HeroPContainerRight>
      <HeroBtnWrapperSolo>
        <ButtonAboutMeBlackSolo to={"/bio"}>
          {language[0].eng === "true" ? "See full BIO here" : "Teljes életrajz"}
        </ButtonAboutMeBlackSolo>
      </HeroBtnWrapperSolo>
    </HeroPositionContainerSolo>
  );
};

export default SoloistHero;
