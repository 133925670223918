// AlbumCard.js

import React, { useState } from "react";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import { ButtonPlayer, ButtonPlayerBuy } from "../ButtonElement";
import {
  CardContainer,
  CardWrapper,
  Card,
  AlbumIconWrapper,
  AlbumIcon,
  TextWrap,
  Text,
  TextP,
  AudioWrapper,
} from "./PlayerElements";
import { LazyLoadImage } from "../LazyLoadImage";

const PlayerCard = ({ cover, songs, buyLink, language }) => {
  const [currentSong, setCurrentSong] = useState({
    song: songs[0].name,
    songsub: songs[0].subtitle,
    songmp3: songs[0].mp3,
  });

  return (
    <CardContainer id="music">
      <CardWrapper>
        <Card>
          <AlbumIconWrapper>
            <AlbumIcon>
              <img src={cover} alt="img" style={{ maxWidth: "100%" }} />
            </AlbumIcon>
          </AlbumIconWrapper>
          <TextWrap>
            <Text>{currentSong.song}</Text>
            <TextP>{currentSong.songsub}</TextP>
          </TextWrap>
          <AudioWrapper>
            <AudioPlayer
              style={{
                boxShadow: "0 0 0 0",
                paddingBottom: "2rem",
                paddingTop: "0rem",
                outline: "none !important",
              }}
              src={currentSong.songmp3}
            />
          </AudioWrapper>
          {songs.map((song, index) => (
            <ButtonPlayer
              key={index}
              onClick={() => {
                setCurrentSong({
                  song: song.name,
                  songsub: song.subtitle,
                  songmp3: song.mp3,
                });
              }}
            >
              {song.name}
            </ButtonPlayer>
          ))}
          <ButtonPlayerBuy
            onClick={() => {
              window.open(buyLink);
            }}
          >
            {language.eng === "true"
              ? "Buy the full album or individual tracks"
              : "Megveszem az albumot vagy az egyes számokat"}
          </ButtonPlayerBuy>
        </Card>
      </CardWrapper>
    </CardContainer>
  );
};

export default PlayerCard;
