import http from "./http-auth";

export function InsertItem(data) {
  return http.post("/homecards", data).then((res) => {});
}

export function DeleteItem(data) {
  return http.delete(`/homecards/${data}`).then((res) => {});
}

export function UpdateItem(data) {
  return http.put(`/homecards/${data.id}`, data).then((res) => {});
}

export function GetItem(data) {
  return http.get(`/homecards/${data.id}`, data).then((res) => {});
}

export const GetAllItems = () => {
  return http.get("/homecards");
};
