import React, { useContext } from "react";
import {
  Card,
  CardContainer,
  CardDate,
  CardSubtitle,
  CardTitle,
  CardWrapper,
} from "./BlogCardElements";
import { BackgroundImage } from "../BackgroundImage";
import { BtnWrapBlog } from "../InfoSection/InfoElements";
import { Button } from "../ButtonElement";
import { LanguageContext } from "../LanguageContext";
import parse from "html-react-parser";
const BlogCardSection = (props) => {
  const [language, setLanguage] = useContext(LanguageContext);
  const date = new Date(props.date);
  const buttonLabel =
    language[0].eng === "true" ? "I am interested!" : "Érdekel";

  return (
    <CardContainer>
      <CardWrapper>
        <BackgroundImage backgroundImage={props.image}></BackgroundImage>
        <Card>
          <CardDate>{date.toDateString()}</CardDate>
          <CardTitle>{props.title}</CardTitle>
          <CardSubtitle>{parse(props.aboutENG)}</CardSubtitle>
          <BtnWrapBlog style={{ padding: "20px" }}>
            <Button
              to={`/blog/${props.eventId}`}
              smooth="true"
              duration={500}
              spy="true"
              offset={-80}
              primary={0}
              dark={0}
            >
              {buttonLabel}
            </Button>
          </BtnWrapBlog>
        </Card>
      </CardWrapper>
    </CardContainer>
  );
};

export default BlogCardSection;
