import http from "./http-auth";

export function InsertItem(data) {
  return http.post("/files", data).then((res) => {});
}

export function DeleteItem(data) {
  return http.delete(`/files/${data}`).then((res) => {});
}

export function UpdateItem(data) {
  return http.put(`/files/${data.id}`, data).then((res) => {});
}

export function GetItem(data) {
  return http.get(`/files/${data.id}`, data).then((res) => {});
}

export const GetAllItems = () => {
  return http.get("/files");
};
