import React, { useState, createContext } from "react";

export const InstagramContext = createContext();

export const InstagramProvider = (props) => {
  const [posts, setPosts] = useState([
    {
      title: "post1",
      link: "",
    },
  ]);
  return (
    <InstagramContext.Provider value={[posts, setPosts]}>
      {props.children}
    </InstagramContext.Provider>
  );
};
