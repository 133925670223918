import React, { useContext } from "react";
import { LanguageContext } from "../../LanguageContext";
import {
  HeroH1Black,
  HeroPositionContainerBaroque,
} from "../HeroSpecPageElements";

const BaroqueHero = (props) => {
  const [language, setLanguage] = useContext(LanguageContext);
  return (
    <HeroPositionContainerBaroque>
      {language[0].eng === "true" ? (
        <HeroH1Black eng={true}>{props.title}</HeroH1Black>
      ) : (
        <HeroH1Black eng={false}>{props.title}</HeroH1Black>
      )}
    </HeroPositionContainerBaroque>
  );
};

export default BaroqueHero;
