import React, { useState, createContext } from "react";

export const GalleryContext = createContext();

export const GalleryProvider = (props) => {
  const [gallery, setGallery] = useState([{ src: "" }]);
  return (
    <GalleryContext.Provider value={[gallery, setGallery]}>
      {props.children}
    </GalleryContext.Provider>
  );
};
