import React from "react";
import { CardProvider } from "../../components/CardSection/CardContext";
import { LanguageProvider } from "../../components/LanguageContext";
import { SpecProvider } from "../../components/Specialities/SpecContext";
import SpecialitiesPage from "./SpecialitiesPage";
import { animateScroll as scroll } from "react-scroll";
const Specialities = () => {
  scroll.scrollToTop();
  return (
    <LanguageProvider>
      <CardProvider>
        <SpecProvider>
          <SpecialitiesPage></SpecialitiesPage>{" "}
        </SpecProvider>
      </CardProvider>
    </LanguageProvider>
  );
};

export default Specialities;
