import React from "react";
import {
  HeroContainer,
  HeroBg,
  HeroContent,
  HeroPHome,
  HeroH1Home,
} from "./HeroHomeElements";
import bgImg from "../images/home_background_3.jpg";
import { BackgroundImageHomeHero } from "../BackgroundImage";

const HeroHomeSection = () => {
  return (
    <HeroContainer id="home">
      <BackgroundImageHomeHero
        backgroundImage={bgImg}
      ></BackgroundImageHomeHero>
      <HeroContent id="heroContent">
        <HeroH1Home>
          Connection. Expression.<br></br>Sensitivity.
        </HeroH1Home>
        <HeroPHome>THIS IS MY ESSENCE</HeroPHome>
      </HeroContent>
    </HeroContainer>
  );
};

export default HeroHomeSection;
