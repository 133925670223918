import React, { useContext } from "react";
import { ButtonAboutMeBlackChamber } from "../../ButtonElement";
import { LanguageContext } from "../../LanguageContext";
import { HeroBtnWrapper } from "../HeroPageElements";
import {
  HeroH1Chamber,
  HeroPositionContainerChamber,
} from "../HeroSpecPageElements";

const ChamberHero = (props) => {
  const [language, setLanguage] = useContext(LanguageContext);
  return (
    <HeroPositionContainerChamber id="HeroPosition">
      <HeroH1Chamber>{props.title}</HeroH1Chamber>
      <HeroBtnWrapper>
        <ButtonAboutMeBlackChamber to={"/bio"}>
          {language[0].eng === "true" ? "See full BIO here" : "Teljes életrajz"}
        </ButtonAboutMeBlackChamber>
      </HeroBtnWrapper>
    </HeroPositionContainerChamber>
  );
};

export default ChamberHero;
