import {
  createMuiTheme,
  CssBaseline,
  Drawer,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core";
import React from "react";
import Header from "../../../components/Admin/Header";
import red from "@material-ui/core/colors/red";

import MyDrawer from "../../../components/Admin/Drawer";
import { InterviewProvider } from "../../Media/Interviews/InterviewContext";
import Subscribers from "./Subscribers";
import { FileProvider } from "../../../components/File/FileContext";
import { useStyles, theme } from "../theme.js";

const SubscribersAdminPage = (props) => {
  const classes = useStyles();

  return (
    <InterviewProvider>
      <FileProvider>
        <ThemeProvider theme={theme}>
          <Drawer
            className={classes.drawer}
            variant="permanent"
            classes={{
              paper: classes.drawerPaper,
            }}
            anchor="left"
          >
            <MyDrawer></MyDrawer>
          </Drawer>
          <div className={classes.adminMain}>
            <Header></Header>
            <Subscribers></Subscribers>
          </div>
          <CssBaseline></CssBaseline>
        </ThemeProvider>
      </FileProvider>
    </InterviewProvider>
  );
};

export default SubscribersAdminPage;
