import React, { useContext, useEffect } from "react";
import { ButtonMedia } from "../ButtonElement";
import { MediaContext } from "./MediaContext";
import { Card, CardH1, CardContainer, CardWrapper } from "./MediaElements";
import { BackgroundImageTop } from "../BackgroundImage";
import { LanguageContext } from "../LanguageContext";
import * as service from "../../pages/Admin/services/mediaService";

const MediaSection = (props) => {
  const [medias, setMedias] = useContext(MediaContext);
  const [language] = useContext(LanguageContext);
  const getItems = () => {
    let get = service.GetAllItems();
    get.then((res) => {
      let data = res.data;
      setMedias(data);
    });
  };
  useEffect(() => {
    (async () => {
      getItems();
    })();
  }, []);

  return (
    <CardContainer id={props.scroll}>
      <CardWrapper>
        <BackgroundImageTop backgroundImage={props.backgroundImage}>
          <CardH1> {language[0].eng === "true" ? "Media" : "Média"}</CardH1>
        </BackgroundImageTop>
        <Card>
          {medias.map((media) => {
            let mediaBool = null;
            if (media.align === "true") mediaBool = "true";
            else mediaBool = "false";
            return (
              <ButtonMedia
                align={mediaBool}
                key={media.id}
                to={`${media.link}`}
              >
                {language[0].eng === "true" ? media.titleENG : media.title}
              </ButtonMedia>
            );
          })}
        </Card>
      </CardWrapper>
    </CardContainer>
  );
};

export default MediaSection;
