import React, { useState, useContext, useEffect } from "react";
import { LanguageProvider } from "../../components/LanguageContext";
import { GalleryContext, GalleryProvider } from "./GalleryContext";
import * as service from "../Admin/services/galleryService";
import { OverflowContainer } from "../Home/HomeElements";
import { useParams } from "react-router";
import {
  GalleryShowImage,
  GalleryShowText,
} from "../../components/Gallery/GalleryElements";

const GalleryShow = () => {
  let imageId = useParams();
  const [gallery, setGallery] = useContext(GalleryContext);

  useEffect(() => {
    (async () => {
      const getItems = () => {
        let get = service.GetAllItems();
        get.then((res) => {
          let data = res.data;
          setGallery(data);
        });
      };
      getItems();
    })();
  }, [setGallery]);

  return (
    <>
      {gallery.map((item) => {
        if (item.id === imageId.imageId)
          return (
            <>
              <GalleryShowImage src={item.LinkLarge}></GalleryShowImage>
              <GalleryShowText>{item.caption}</GalleryShowText>
            </>
          );
      })}
    </>
  );
};

export default GalleryShow;
