import styled from "styled-components";

export const VideoBox = styled.div`
  display: flex;
  justify-content: center;
  background: #fff;
  width: 100%;
  height: auto;
  padding-top: 5rem;
  @media screen and (max-width: 768px) {
    width: 100%;
    max-height: none;
  }
`;

export const VideoWrap = styled.div`
  display: grid;
  background: #fff;
  grid-template-rows: 1fr;
  justify-items: center;
  width: 60%;
  height: 22.5rem;
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.04);
    transition: all 0.2s ease-in-out;
  }

  @media screen and (max-width: 768px) {
    display:flex;
    grid-template-rows: none;
    left: 0rem;
    padding: 0.5rem;
    width: 100%;
  
    &:hover {
      transform: scale(1);
      transition: all 0.2s ease-in-out;
    }
`;

export const EmptySection = styled.div`
  padding-top: 5rem;
`;

export const Text = styled.h1`
  padding: 0.5rem 0.1rem 0.1rem 0.5rem;
  font-size: 20px;
  font-weight: bold;
  color: #000;
  
  @media screen and (max-width: 768px) {
    left: 0rem;
    padding: 0.5rem;
    width: 100%;
     &:hover {
      transform: scale(1);
      transition: all 0.2s ease-in-out;
    }
`;

export const HeroText = styled.h1`
  position: relative;
  top: 15rem;
  left: 25rem;
  font-size: 72px;
  color: #fff;
  text-transform: uppercase;
  opacity: 0.9;
  @media screen and (max-width: 768px) {
    top: 12.5rem;
    left: 9rem;
    font-size: 56px;
  }
`;
