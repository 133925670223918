import { Grid } from "@material-ui/core";
import React, { useEffect } from "react";
import { useForm } from "../../useForm";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { FormsRef } from "../../formsRef";

const initialFValues = {
  id: 0,
  title: "",
  titleENG: "",
  subtitle: "",
  subtitleENG: "",
  buttonLabel: "",
  buttonLabelENG: "",
  link: "",
  scroll: "",
};

const HomeCardForm = (props) => {
  const { upload, showModal, handleClose, pageTitle, carry, files } = props;
  const {
    title,
    titleENG,
    buttonLabel,
    buttonLabelENG,
    subtitle,
    subtitleENG,
    link,
    scroll,
  } = FormsRef();

  const { values, setValues, handleInputChange, resetForm } =
    useForm(initialFValues);

  const handleSubmit = (e) => {
    e.preventDefault();
    upload({
      id: carry.id,
      title: title.current.value,
      titleENG: titleENG.current.value,
      buttonLabel: buttonLabel.current.value,
      buttonLabelENG: buttonLabelENG.current.value,
      subtitle: subtitle.current.value,
      subtitleENG: subtitleENG.current.value,
      link: link.current.value,
      scroll: scroll.current.value,
    });
  };

  return (
    <Modal show={showModal} onHide={handleClose} size="lg">
      <Modal.Header>
        <Modal.Title>{pageTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Form.Group className="mb-3">
                <Form.Label>Title</Form.Label>
                <Form.Control ref={title} defaultValue={carry.title || ""} />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Subtitle</Form.Label>
                <Form.Control
                  ref={subtitle}
                  defaultValue={carry.subtitle || ""}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Button label</Form.Label>
                <Form.Control
                  ref={buttonLabel}
                  defaultValue={carry.buttonLabel || ""}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Link</Form.Label>
                <Form.Control ref={link} defaultValue={carry.link || ""} />
              </Form.Group>
            </Grid>
            <Grid item xs={6}>
              <Form.Group className="mb-3">
                <Form.Label>TitleENG</Form.Label>
                <Form.Control
                  ref={titleENG}
                  defaultValue={carry.titleENG || ""}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>SubtitleENG</Form.Label>
                <Form.Control
                  ref={subtitleENG}
                  defaultValue={carry.subtitleENG || ""}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Button labelENG</Form.Label>
                <Form.Control
                  ref={buttonLabelENG}
                  defaultValue={carry.buttonLabelENG || ""}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Scroll</Form.Label>
                <Form.Control ref={scroll} defaultValue={carry.scroll || ""} />
              </Form.Group>
            </Grid>
          </Grid>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Bezárás
        </Button>
        <Button variant="primary" onClick={handleSubmit} type="submit">
          Mentés
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default HomeCardForm;
