import React, { useState, createContext } from "react";
import icon1 from "../../../components/images/icon_press1.png";
import icon2 from "../../../components/images/icon_press2.png";
import icon3 from "../../../components/images/icon_press3.png";

export const PressContext = createContext();

export const PressProvider = (props) => {
  const [press, setPress] = useState([
    {
      title: "Read Full BIO",
      buttonLabel: "Show me!",
      icon: icon1,
      link: "",
    },
    {
      title: "Read Short BIO",
      buttonLabel: "Show me!",
      icon: icon2,
      link: "",
    },
    {
      title: "Images to download",
      buttonLabel: "Show me!",
      icon: icon3,
      link: "",
    },
  ]);
  return (
    <PressContext.Provider value={[press, setPress]}>
      {props.children}
    </PressContext.Provider>
  );
};
