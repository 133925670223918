import React, { useContext } from "react";
import {
  Card,
  CardContainer,
  CardDate,
  CardPlace,
  CardWrapper,
  CardAbout,
  CardAboutTitle,
  EmptyMargin,
  ImageContainer,
} from "./BigEventCardElements";
import { BackgroundImageEventsCardBig } from "../BackgroundImage";
import "./p.css";
import { LanguageContext } from "../LanguageContext";

const BigEventCardSection = (props) => {
  const [language] = useContext(LanguageContext);

  const date = new Date(props.date);
  var options = {
    hour: "2-digit",
    minute: "2-digit",
  };

  return (
    <>
      <CardContainer>
        <CardWrapper>
          <BackgroundImageEventsCardBig
            backgroundImage={props.image}
          ></BackgroundImageEventsCardBig>
          <Card>
            <CardAboutTitle>
              {language[0].eng === "true"
                ? "Time & Location"
                : "Dátum és Helyszín"}
            </CardAboutTitle>
            {language[0].eng === "true" ? (
              <CardDate>
                {date.toLocaleDateString("en-us", {
                  weekday: "long",
                  year: "numeric",
                  month: "short",
                  day: "numeric",
                })}
                {" - "}
                {date.toLocaleTimeString("en-US", options)}
              </CardDate>
            ) : (
              <CardDate>
                {date.toLocaleDateString("hu-hu", {
                  weekday: "long",
                  year: "numeric",
                  month: "short",
                  day: "numeric",
                })}
                {" - "}
                {date.toLocaleTimeString("hu-HU", options)}
              </CardDate>
            )}

            <CardPlace>{props.place}</CardPlace>
            <CardAboutTitle>
              {language[0].eng === "true" ? "About the Event" : "Az eseményről"}
            </CardAboutTitle>
            <CardAbout>{props.aboutENG}</CardAbout>
            <EmptyMargin></EmptyMargin>
          </Card>
          <EmptyMargin></EmptyMargin>
        </CardWrapper>
      </CardContainer>
    </>
  );
};

export default BigEventCardSection;
