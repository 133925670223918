import React, { useContext } from "react";
import { ButtonBio } from "../../ButtonElement";
import { LanguageContext } from "../../LanguageContext";
import {
  HeroBtnWrapperBio,
  HeroH1Bio,
  HeroPBio,
  HeroPContainerRight,
  HeroPositionContainerBio,
} from "../HeroSpecPageElements";

const BioHero = (props) => {
  const [language, setLanguage] = useContext(LanguageContext);
  return (
    <HeroPositionContainerBio>
      <HeroPContainerRight>
        <HeroH1Bio>{props.biotitle}</HeroH1Bio>
      </HeroPContainerRight>
      <HeroPContainerRight>
        <HeroPBio>{props.biotext}</HeroPBio>
      </HeroPContainerRight>
      <HeroPContainerRight>
        <HeroBtnWrapperBio>
          <ButtonBio
            onClick={() => {
              window.open(
                "https://firebasestorage.googleapis.com/v0/b/noemigyori.appspot.com/o/Files%2Ffull_bio.pdf?alt=media&token=8da0956c-566d-4144-a487-182abbb2a595"
              );
            }}
          >
            {language[0].eng === "true"
              ? "Download full bio"
              : "Teljes életrajz letöltése"}
          </ButtonBio>
        </HeroBtnWrapperBio>
      </HeroPContainerRight>
    </HeroPositionContainerBio>
  );
};

export default BioHero;
