import React, { useEffect, useContext } from "react";
import { useForm, Form } from "../useForm";
import * as service from "../services/aboutpageService";
import { AboutPageContext } from "../../../components/AboutPage/AboutPageContext";
import AddPostQuillAbout from "../../../components/Quill/AddPostAbout";

const initialFValues = {
  id: 0,
  title: "",
  content: "",
};

const AboutPageForm = (props) => {
  const { addOrEdit, recordForEdit } = props;
  const [aboutPages, setAboutPages] = useContext(AboutPageContext);
  const { values, setValues, handleInputChange, resetForm } =
    useForm(initialFValues);

  useEffect(() => {
    (() => {
      const getItems = () => {
        let get = service.GetAllItems();
        get.then((res) => {
          let data = res.data;
          setAboutPages(data);
        });
      };
      getItems();
      setValues({
        ...recordForEdit,
      });
    })();
  }, [recordForEdit, setAboutPages, setValues]);

  return (
    <AddPostQuillAbout
      values={values}
      setValues={setValues}
    ></AddPostQuillAbout>
  );
};

export default AboutPageForm;
