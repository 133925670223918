import React, { useContext, useEffect } from "react";
import { CardContext } from "./CardContext";
import {
  Card,
  CardContainer,
  CardH1,
  CardH2,
  CardP,
  CardWrapper,
} from "./BoxCardElements";
import {
  BackgroundImageEvents3,
  BackgroundImageFluteHomeCard,
} from "../BackgroundImage";
import { BtnWrap } from "../InfoSection/InfoElements";
import { ButtonSimple } from "../ButtonElement";
import { LanguageContext } from "../LanguageContext";
import * as serviceLanguage from "../../pages/Admin/services/languageService";
import { EmptySection } from "./BioCardElements";

const BoxCardSection = (props) => {
  const [language, setLanguage] = useContext(LanguageContext);
  const [cards] = useContext(CardContext);
  const getItems = () => {
    let get = serviceLanguage.GetAllItems();
    get.then((res) => {
      let data = res.data;
      setLanguage(data);
    });
  };

  useEffect(() => {
    (async () => {
      //getItems();
    })();
  }, []);
  const value = props.value;
  const height = props.height;
  let exp;
  if (props.experience) {
    exp = language[0].eng === "true" ? "A live experience" : "Valódi élmény";
  }
  return (
    <CardContainer>
      {props.scroll == "collection" ? (
        <BackgroundImageFluteHomeCard
          backgroundImage={props.backgroundImage}
          style={{ backgroundPosition: "65% 50%" }}
        >
          <CardWrapper left={props.left}>
            {cards.map((item) => {
              if (item.scroll === props.scroll) {
                return (
                  <>
                    <Card left={props.left} id={props.scroll}>
                      <CardH2>{exp}</CardH2>
                      <CardH1>
                        {language[0].eng === "true"
                          ? item.titleENG
                          : item.title}
                      </CardH1>
                      <CardP left={props.left}>
                        {language[0].eng === "true"
                          ? item.subtitleENG
                          : item.subtitle}
                      </CardP>
                      <BtnWrap>
                        <ButtonSimple
                          onClick={() => {
                            window.open(item.link);
                          }}
                          smooth="true"
                          duration={500}
                          spy="true"
                          offset={-80}
                          primary="true"
                          dark="true"
                        >
                          {language[0].eng === "true"
                            ? item.buttonLabelENG
                            : item.buttonLabel}
                        </ButtonSimple>
                      </BtnWrap>
                    </Card>{" "}
                  </>
                );
              }
            })}
          </CardWrapper>{" "}
          <EmptySection></EmptySection>
        </BackgroundImageFluteHomeCard>
      ) : (
        <BackgroundImageEvents3 backgroundImage={props.backgroundImage}>
          <CardWrapper style={{ left: value }} left={props.left}>
            {cards.map((item) => {
              if (item.scroll === props.scroll) {
                return (
                  <>
                    <Card id={props.scroll}>
                      <CardH2>{exp}</CardH2>
                      <CardH1>
                        {language[0].eng === "true"
                          ? item.titleENG
                          : item.title}
                      </CardH1>
                      <CardP left={props.left}>
                        {language[0].eng === "true"
                          ? item.subtitleENG
                          : item.subtitle}
                      </CardP>{" "}
                      <BtnWrap>
                        <ButtonSimple
                          to={`${item.link}`}
                          smooth="true"
                          duration={500}
                          spy="true"
                          offset={-80}
                          primary="true"
                          dark="true"
                        >
                          {language[0].eng === "true"
                            ? item.buttonLabelENG
                            : item.buttonLabel}
                        </ButtonSimple>
                      </BtnWrap>
                    </Card>{" "}
                  </>
                );
              }
            })}
          </CardWrapper>{" "}
          <EmptySection></EmptySection>
        </BackgroundImageEvents3>
      )}
    </CardContainer>
  );
};

export default BoxCardSection;
