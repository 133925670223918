import { Button, Grid } from "@material-ui/core";
import React, { useState, useEffect, useContext } from "react";
import { useForm, Form } from "../useForm";
import Controls from "../controls/Controls";
import * as service from "../services/blogService";
import { BlogContext } from "../../../components/Blog/BlogContext";
import AddPostQuill from "../../../components/Quill/AddPost";
import { Edit } from "@material-ui/icons";

const initialFValues = {
  id: 0,
  title: "",
  content: "",
};

const BlogForm = (props) => {
  const { addOrEdit, recordForEdit } = props;
  const [posts, setPosts] = useContext(BlogContext);
  const { values, setValues, handleInputChange, resetForm } =
    useForm(initialFValues);

  useEffect(() => {
    (async () => {
      const getItems = () => {
        let get = service.GetAllItems();
        get.then((res) => {
          let data = res.data;
          setPosts(data);
        });
      };
      getItems();
      setValues({
        ...recordForEdit,
      });
    })();
  }, [recordForEdit, setPosts, setValues]);

  return (
    <Form>
      <AddPostQuill values={values} setValues={setValues}></AddPostQuill>
    </Form>
  );
};

export default BlogForm;
