import React from "react";
import { HeroContainer, HeroContent } from "../HeroSection/HeroPageElements";
import { BackgroundImageIw } from "../BackgroundImage";

const HeroPageSection = (props) => {
  return (
    <HeroContainer id={props.id}>
      <BackgroundImageIw backgroundImage={props.backgroundImage}>
        <HeroContent>{props.children}</HeroContent>
      </BackgroundImageIw>
    </HeroContainer>
  );
};

export default HeroPageSection;
