import http from "./http-auth";

export function InsertImage(data) {
  return http
    .post("/images", data)
    .then((res) => console.log(res))
    .catch((err) => console.log(err));
}

export function DeleteImage(data) {
  return http.delete(`/images/${data}`).then((res) => {});
}

export function UpdateImage(data) {
  return http.put(`/images/${data.id}`, data).then((res) => {});
}

export function GetImage(data) {
  return http.get(`/images/${data.id}`, data).then((res) => {});
}

export const GetAllImages = () => {
  return http.get("/images");
};

export function InsertItem(data) {
  return http.post("/images", data).then((res) => {});
}

export function DeleteItem(data) {
  return http.delete(`/images/${data}`).then((res) => {});
}

export function UpdateItem(data) {
  return http.put(`/images/${data.id}`, data).then((res) => {});
}

export function GetItem(data) {
  return http.get(`/images/${data.id}`, data).then((res) => {});
}

export const GetAllItems = () => {
  return http.get("/images");
};
