import http from "./http-auth";

export function InsertItem(data) {
  return http.post("/instagram", data).then((res) => {});
}

export function DeleteItem(data) {
  return http.delete(`/instagram/${data}`).then((res) => {});
}

export function UpdateItem(data) {
  return http.put(`/instagram/${data.id}`, data).then((res) => {});
}

export function GetItem(data) {
  return http.get(`/instagram/${data.id}`, data).then((res) => {});
}

export const GetAllItems = () => {
  return http.get("/instagram");
};
