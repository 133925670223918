import React, { useState, createContext } from "react";

export const CardContext = createContext();

export const CardProvider = (props) => {
  const [cards, setCards] = useState([
    {
      id: "flute",
      title: "The flute",
      text:
        "I can clearly remember the thrill I felt when I picked up a flute for the very first time. I instantly knew that the notes coming from that instrument were going to narrate my life. Of all the instruments in the world, the sound of the flute is closest to the human voice; with its sensitivity and velvety sound, it sings to us in a language that reaches far beyond words.",
      buttonLabel: "",
      dark: true,
      primary: true,
      link: "home",
    },
    {
      id: "events",
      title: "Events",
      subtitle: "A live experience",
      text:
        "Technology enables us to stay connected on many different platforms and I am thrilled to share my music and thoughts with you through these wonderful and crucial channels. At the same time, I believe that nothing can substitute live experiences, which make up such a fundamental part of our human existence. Below you’ll find a list of my upcoming events, so that you can always stay in the know about what I am up to next. I hope to see you soon at one of these exciting occasions!",
      buttonLabel: "Show me upcoming events!",
      dark: true,
      primary: true,
      link: "/events",
    },
    {
      id: "bio",
      title: "Bio",
      subtitle: "",
      text:
        "This is a more traditional, comprehensive biography about my work, including details on awards, studies, the flute and much more!",
      buttonLabel: "View my full BIO!",
      dark: true,
      primary: true,
      link: "home",
    },
    {
      id: "shop",
      title: "Shop",
      subtitle: "",
      text:
        "Have some music with your morning coffee. Pepper your lunch with notes. Watch an opera on a Saturday.",
      buttonLabel: "CHECK OUT MY PRODUCTS",
      dark: true,
      primary: true,
      link: "home",
    },
  ]);
  return (
    <CardContext.Provider value={[cards, setCards]}>
      {props.children}
    </CardContext.Provider>
  );
};
