import styled from "styled-components";

export const ContactContainer = styled.div`
  height: auto;
  grid-template-rows: 1fr 2.5rem;
  justify-content: center;
  align-items: center;
  width: 100%;

  @media screen and (max-width: 1680px) {
  }

  @media screen and (max-width: 1280px) {
  }

  @media screen and (max-width: 480px) {
    height: auto;
    display: grid;
  }
`;

export const ContactWrapper = styled.div`
  width: 100%;
  margin: 0;
  display: grid;
  grid-template-rows: 1fr 1fr;
  text-align: center;
  grid-gap: 1rem;
  padding: 0 10%;

  @media screen and (max-width: 1280px) {
    padding: 0 5%;
  }

  @media screen and (max-width: 480px) {
    grid-template-columns: 1fr;
    grid-template-rows: 0.5fr 1fr;
    grid-gap: 1%;
    padding: 0 5%;
  }
`;

export const FormContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 50px;

  @media screen and (max-width: 480px) {
    grid-template-columns: 1fr;
  }
`;

export const ContactH1 = styled.h1`
  margin: 0;
  margin-top: 2rem !important;
  font-size: 28px;
  font-weight: 300;
  color: #000;
  text-transform: uppercase;

  @media screen and (max-width: 480px) {
    height: 2rem;
    margin-bottom: 2rem;
    font-size: 24px;
  }
`;

export const ContactP = styled.p`
  margin: 0;
  font-size: 20px;
  text-align: center;

  @media screen and (max-width: 480px) {
    margin-bottom: 2rem;
    max-height: 7rem;
    font-size: 18px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-item: center;
  margin-bottom: 1rem;
  @media screen and (max-width: 480px) {
    padding-top: 7.5%;
  }
`;

export const TextSentContainer = styled.div`
  margin-top: 0rem;
  margin-bottom: 1rem;
  width: 100%;
  @media screen and (max-width: 1280px) {
    margin-bottom: 2rem;
    width: 88.88vw;
  }
  @media screen and (max-width: 980px) {
    margin-bottom: 0rem;
  }
`;

export const TextSent = styled.p`
  font-size: 18px;
  text-align: left;
  color: ${(props) => (props.green ? "green" : props.white ? "white" : " red")};
  margin: 0;
  line-height: 1.1rem;
  display: flex;
  justify-content: center;

  @media screen and (max-width: 980px) {
    width: 88.8888888888888vw;
    line-height: 1;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 4.166666666666666vh;
  }
`;
