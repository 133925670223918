import React, { useState, createContext } from "react";

export const ProgramContext = createContext();

export const ProgramProvider = (props) => {
  const [programs, setPrograms] = useState([
    {
      title: "Fuvolaversenyek",
      titleENG: "Flute concerti",
      subtitle: "C. Ph. E. Bach: d-moll fuvolaverseny, H425 Wq168 (1747)",
      subtitleENG:
        "C. Ph. E. Bach: Flute Concerto in D minor, H425 Wq168 (1747)",
    },
    {
      title: "Fuvolaversenyek",
      titleENG: "Flute concerti",
      subtitle: "C. Ph. E. Bach: d-moll fuvolaverseny, H425 Wq168 (1747)",
      subtitleENG:
        "C. Ph. E. Bach: Flute Concerto in D minor, H425 Wq168 (1747)",
    },
  ]);
  return (
    <ProgramContext.Provider value={[programs, setPrograms]}>
      {props.children}
    </ProgramContext.Provider>
  );
};
