import React, { useState } from "react";
import Sidebar from "../../components/Sidebar";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import SpecPageDynamic from "../../components/Specialities/SpecPageDynamic";
import { LanguageProvider } from "../../components/LanguageContext";
import { SpecProvider } from "../../components/Specialities/SpecContext";
import { animateScroll as scroll } from "react-scroll";
import { OverflowContainer } from "../Home/HomeElements";
const BlogPage = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  scroll.scrollToTop();
  return (
    <LanguageProvider>
      <SpecProvider>
        <Sidebar isOpen={isOpen} toggle={toggle}></Sidebar>
        <Navbar toggle={toggle}></Navbar>
        <OverflowContainer>
          <SpecPageDynamic></SpecPageDynamic>
        </OverflowContainer>
        <Footer></Footer>
      </SpecProvider>
    </LanguageProvider>
  );
};

export default BlogPage;
