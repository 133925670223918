import { Grid } from "@material-ui/core";
import React, { useEffect, useContext } from "react";
import { useForm } from "../useForm";
import Controls from "../controls/Controls";
import * as service from "../services/imageService";
import * as fileservice from "../services/fileService";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { FormsRef } from "../formsRef";

const initialFValues = {
  id: 0,
  title: "",
  titleENG: "",
  buttonLabel: "",
  buttonLabelENG: "",
  image: "",
  link: "",
  linkENG: "",
};

const PressForm = (props) => {
  const { upload, showModal, handleClose, pageTitle, carry, files, images } =
    props;
  const { title, titleENG, buttonLabel, buttonLabelENG, image, link, linkENG } =
    FormsRef();

  const { values, setValues, handleInputChange, resetForm } =
    useForm(initialFValues);

  const handleInputChangeImage = (e) => {
    const { value } = e.target;
    image.current = value;
  };

  const handleInputChangeFile = (e) => {
    const { value } = e.target;
    link.current.value = value;
  };

  const handleInputChangeFileENG = (e) => {
    const { value } = e.target;
    linkENG.current.value = value;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    upload({
      id: carry.id,
      title: title.current.value,
      titleENG: titleENG.current.value,
      buttonLabel: buttonLabel.current.value,
      buttonLabelENG: buttonLabelENG.current.value,
      link: link.current.value,
      linkENG: linkENG.current.value,
      image: image.current,
    });
  };

  return (
    <Modal show={showModal} onHide={handleClose} size="lg">
      <Modal.Header>
        <Modal.Title>{pageTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Form.Group className="mb-3">
                <Form.Label>Title</Form.Label>
                <Form.Control ref={title} defaultValue={carry.title || ""} />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Button label</Form.Label>
                <Form.Control
                  ref={buttonLabel}
                  defaultValue={carry.buttonLabel || ""}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Link</Form.Label>
                <Form.Control ref={link} defaultValue={carry.link || ""} />
              </Form.Group>
              <Controls.Select
                name="link"
                label="Link"
                values={files}
                onChange={handleInputChangeFile}
                defaultValue={carry.link}
              ></Controls.Select>
              <Controls.Select
                name="image"
                label="Image"
                values={images}
                onChange={handleInputChangeImage}
                defaultValue={carry.image}
              ></Controls.Select>
            </Grid>
            <Grid item xs={6}>
              <Form.Group className="mb-3">
                <Form.Label>TitleENG</Form.Label>
                <Form.Control
                  ref={titleENG}
                  defaultValue={carry.titleENG || ""}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Button label ENG</Form.Label>
                <Form.Control
                  ref={buttonLabelENG}
                  defaultValue={carry.buttonLabelENG || ""}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>LinkENG</Form.Label>
                <Form.Control
                  ref={linkENG}
                  defaultValue={carry.linkENG || ""}
                />
              </Form.Group>
              <Controls.Select
                name="linkENG"
                label="LinkENG"
                values={files}
                onChange={handleInputChangeFileENG}
                defaultValue={carry.linkENG}
              ></Controls.Select>
            </Grid>
          </Grid>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Bezárás
        </Button>
        <Button variant="primary" onClick={handleSubmit} type="submit">
          Mentés
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PressForm;
