import React, { useContext, useEffect, useState } from "react";
import {
  ContactContainer,
  ContactH1,
  ContactP,
  ContactWrapper,
  FormContainer,
  ButtonContainer,
} from "./SubscribeElements";
import * as service from "../../pages/Admin/services/subscribersService";
import { TextArea, Button, Checkbox, Form } from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
import { LanguageContext } from "../LanguageContext";
import { sendMailtoNoemi } from "../../pages/Admin/services/emailService";
const SubscribeSection = (props) => {
  const [language, setLanguage] = useContext(LanguageContext);
  const [form, setForm] = useState({
    email: "",
  });

  const onChange = (e, { name, value }) => {
    setForm({ ...form, [name]: value });
  };

  useEffect(() => {
    (async () => {})();
  }, []);

  let Contact;
  let ContactText;
  let textcolor = "#000";
  let bgcolor = "#fff";

  Contact = language[0].eng === "true" ? "Subscribe" : "Hírek emailben";
  ContactText =
    language[0].eng === "true"
      ? "This is not a regular newsletter. I only send you emails, if I have something truly important to share."
      : "Ez nem egy szokványos hírlevél. Csak akkor írok, ha valami igazán fontosat szeretnék megosztani veled!";

  return (
    <div>
      <ContactContainer
        style={{ background: `url(${bgcolor})`, color: textcolor }}
        id="contact"
      >
        <ContactWrapper>
          <ContactH1 style={{ color: textcolor }}>{Contact}</ContactH1>
          <ContactP style={{ color: textcolor }}>{ContactText}</ContactP>
          <FormContainer>
            <Form>
              <Form.Field>
                <label style={{ color: textcolor }}>
                  {" "}
                  {language[0].eng === "true" ? "Your Email" : "Az Email címed"}
                </label>
                <Form.Input
                  onChange={onChange}
                  value={form.email || ""}
                  name="email"
                  placeholder={
                    language[0].eng === "true" ? "Your Email" : "Az Email címed"
                  }
                />
              </Form.Field>
            </Form>
          </FormContainer>
          <ButtonContainer>
            <Button
              color="black"
              onClick={() => {
                service.InsertItem(form);
                setForm({
                  email: "",
                });
              }}
            >
              {language[0].eng === "true" ? "Subscribe" : "Feliratkozom"}
            </Button>
          </ButtonContainer>
        </ContactWrapper>
      </ContactContainer>
      <hr></hr>
    </div>
  );
};
export default SubscribeSection;
/*      <Form.Field>
                <label style={{ color: textcolor }}>
                  {language[0].eng === "true" ? "Your Name" : "A neved"}
                </label>
                <Form.Input
                  onChange={onChange}
                  value={form.name || ""}
                  name="name"
                  placeholder={
                    language[0].eng === "true" ? "Your Name" : "A neved"
                  }
                />
              </Form.Field>*/
