import styled from "styled-components";

export const CardContainer = styled.div`
  height: 700px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fff;
  font-family: playfairDisplay !important;

  @media screen and (max-width: 480px) {
    height: auto;
    width: 100%;
    max-width: 100%;
  }
`;

export const CardWrapper = styled.div`
  height: 100%;
  min-width: 100%;
  max-width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  transition: all 0.2s ease-in-out;
  z-index: 1;

  @media screen and (max-width: 480px) {
    height: 100%;
    min-width: 100%;
    max-width: 100%;
    display: grid;
    grid-template-columns: none;

    grid-template-rows: 1fr 1fr;
    transition: all 0.2s ease-in-out;
    z-index: 1;
  }
`;

export const Card = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
  background: rgb(246, 239, 233);
  height: 100%;
  width: 100%;
  padding: 2rem;

  @media screen and (max-width: 480px) {
    max-width: 100%;
    grid-gap: 1rem;
  }
`;

export const CardH1 = styled.h1`
  font-size: 4rem;
  font-weight: 100;
  position: relative;
  left: 10rem;
  top: 2.5rem;
  color: #000;
  margin-bottom: 2rem;
  text-transform: uppercase;
  font-family: roboto !important;

  @media screen and (max-width: 1280px) {
    left: 5rem;
  }

  @media screen and (max-width: 768px) {
    left: 1rem;
    font-size: 3rem;
  }

  @media screen and (max-width: 480px) {
    font-size: 3.5rem;
    position: relative;
    left: 2.5%;
    top: 20%;
  }
`;
export const CardH2 = styled.h1`
  font-size: 1.2rem;
  font-weight: 100;
  color: #000;
  margin-bottom: 0.5rem;
  font-style: italic;
`;

export const CardP = styled.p`
  font-size: 1rem;
  text-align: center;
  min-height: 15rem;
`;
