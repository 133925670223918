import React, { useEffect, useState } from "react";
import Navbar from "../../components/NavbarHome";
import HeroHomeSection from "../../components/HeroSection/HeroHome";
import About from "../../components/About";
import Footer from "../../components/Footer";
import { CarouselProvider } from "../../components/Carousel/CarouselContext";
import { AboutProvider } from "../../components/About/AboutContext";
import { InfoProvider } from "../../components/InfoSection/InfoContext";
import SmallCardSection from "../../components/CardSection/SmallCard";
import { CardProvider } from "../../components/CardSection/CardContext";
import BoxCardSection from "../../components/CardSection/BoxCard";
import ShopCardSection from "../../components/CardSection/ShopCard";
import fluteBgImg from "../../components/images/flute_background.jpg";
import eventBgImg from "../../components/images/home_events_background.jpg";
import bioBgImg from "../../components/images/home_bio_background.png";
import shopBgImg from "../../components/images/home_shop_background.jpg";
import contactBgImg from "../../components/images/carousel3.jpg";
import mediaBgImg2 from "../../components/images/media.jpg";
import collectionBgImg from "../../components/images/home_collection_background2.jpg";
import phdBgImg from "../../components/images/home_doctoral_background.jpg";
import MediaSection from "../../components/MediaSection";
import { MediaProvider } from "../../components/MediaSection/MediaContext";
import {
  QuartetAlbum,
  AntonioAlbum,
  TransformingAlbum,
  RomanticAlbum,
  GlowingAlbum,
  ExplorationAlbum,
} from "../../components/Player";
import { CardLaneContainer } from "../../components/Player/PlayerElements";
import CarouselQuoteContainer from "../../components/Carousel_quote";
import { CarouselQuoteProvider } from "../../components/Carousel_quote/CarouselQuoteContext";
import Instagram from "../../components/Instagram";
import { LanguageProvider } from "../../components/LanguageContext";
import { InstagramProvider } from "../../components/Instagram/InstagramContext";
import SidebarHome from "../../components/SidebarHome";
import { SpecProvider } from "../../components/Specialities/SpecContext";
import SpecialitiesSection from "../../components/Specialities";
import BioCardSection from "../../components/CardSection/BioCard";
import ContactSection from "../../components/Contact";
import PartnersSection from "../../components/Partners";
import { AboutPageProvider } from "../../components/AboutPage/AboutPageContext";
import BoxCardDocSection from "../../components/CardSection/BoxCardDoc";
import SubscribeSection from "../../components/SubscribeNews";
import { OverflowContainer } from "./HomeElements";
import ReactGA from "react-ga";
ReactGA.initialize("UA-190810932-1");

const Home = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <LanguageProvider>
      <InstagramProvider>
        <AboutProvider>
          <AboutPageProvider>
            <InfoProvider>
              <CarouselProvider>
                <CarouselQuoteProvider>
                  <CardProvider>
                    <MediaProvider>
                      <SpecProvider>
                        <SidebarHome
                          isOpen={isOpen}
                          toggle={toggle}
                        ></SidebarHome>
                        <Navbar toggle={toggle}></Navbar>
                        <OverflowContainer>
                          <HeroHomeSection></HeroHomeSection>
                          <About></About>
                          <CarouselQuoteContainer></CarouselQuoteContainer>
                          <BoxCardDocSection
                            experience={false}
                            height="60vh"
                            value="-30rem"
                            left={false}
                            scroll={"doctoral"}
                            backgroundImage={phdBgImg}
                          ></BoxCardDocSection>
                          <CardLaneContainer>
                            <ExplorationAlbum></ExplorationAlbum>
                            <RomanticAlbum></RomanticAlbum>
                            <QuartetAlbum></QuartetAlbum>
                          </CardLaneContainer>
                          <CardLaneContainer>
                            <TransformingAlbum></TransformingAlbum>
                            <GlowingAlbum></GlowingAlbum>
                            <AntonioAlbum></AntonioAlbum>
                          </CardLaneContainer>

                          <SpecialitiesSection></SpecialitiesSection>
                          <SmallCardSection
                            scroll={"flute"}
                            backgroundImage={fluteBgImg}
                          ></SmallCardSection>
                          <BoxCardSection
                            experience={true}
                            height="60vh"
                            left={false}
                            scroll={"events"}
                            backgroundImage={eventBgImg}
                          ></BoxCardSection>
                          <MediaSection
                            scroll={"media"}
                            backgroundImage={mediaBgImg2}
                          ></MediaSection>
                          <Instagram></Instagram>
                          <BoxCardSection
                            height="40vh"
                            value="-50rem"
                            left={true}
                            scroll={"collection"}
                            backgroundImage={collectionBgImg}
                          ></BoxCardSection>
                          <BioCardSection
                            scroll={"bio"}
                            backgroundImage={bioBgImg}
                          ></BioCardSection>
                          <ShopCardSection
                            scroll={"shop"}
                            backgroundImage={shopBgImg}
                          ></ShopCardSection>
                          <ContactSection
                            green={true}
                            myContact={true}
                            myDescription={true}
                            bgcolor={"white"}
                            textcolor="black"
                          ></ContactSection>
                          <ContactSection
                            myContact={false}
                            myDescription={false}
                            bgcolor={contactBgImg}
                            textcolor="white"
                          ></ContactSection>
                          <SubscribeSection></SubscribeSection>
                          <PartnersSection></PartnersSection>
                        </OverflowContainer>
                        <Footer></Footer>
                      </SpecProvider>
                    </MediaProvider>
                  </CardProvider>
                </CarouselQuoteProvider>
              </CarouselProvider>
            </InfoProvider>
          </AboutPageProvider>
        </AboutProvider>
      </InstagramProvider>
    </LanguageProvider>
  );
};

export default Home;
// <SpecTextSection></SpecTextSection>{" "}
/*<BoxCardSection
                          height="40vh"
                          value="-20rem"
                          left={true}
                          scroll={"doctoral"}
                          backgroundImage={doctoralBgImg}
                        ></BoxCardSection>
                        
                        <DoctoralSection></DoctoralSection>
                        */
