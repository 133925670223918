import React, { useContext } from "react";
import { ButtonAboutMeBlackTeacher } from "../../ButtonElement";
import { LanguageContext } from "../../LanguageContext";
import {
  HeroBtnWrapperTeacher,
  HeroH1Teacher,
  HeroPositionContainerTeacher,
  HeroPTeacher,
} from "../HeroSpecPageElements";

const TeacherHero = (props) => {
  const [language, setLanguage] = useContext(LanguageContext);
  return (
    <HeroPositionContainerTeacher>
      {language[0].eng === "true" ? (
        <HeroH1Teacher eng={true}>{props.title}</HeroH1Teacher>
      ) : (
        <HeroH1Teacher eng={false}>{props.title}</HeroH1Teacher>
      )}
      <HeroPTeacher>
        {language[0].eng === "true"
          ? `" ...Sharing knowledge has become the most natural part of my existence..."`
          : "„…A tudás átadása létezésem alapvető részévé vált…”"}
      </HeroPTeacher>
      <HeroBtnWrapperTeacher>
        <ButtonAboutMeBlackTeacher to={"/bio"}>
          {language[0].eng === "true" ? "See full BIO here" : "Teljes életrajz"}
        </ButtonAboutMeBlackTeacher>
      </HeroBtnWrapperTeacher>
    </HeroPositionContainerTeacher>
  );
};

export default TeacherHero;
