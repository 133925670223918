import React, { useContext, useEffect } from "react";
import { AboutContainer, AboutWrapper, EmptySection } from "./AboutElements";
import { AboutContext } from "./AboutContext";
import AboutCards from "./AboutCards";
import * as service from "../../pages/Admin/services/aboutService";
import * as aboutPageService from "../../pages/Admin/services/aboutpageService";
import { LanguageContext } from "../LanguageContext";
import { AboutPageContext } from "../AboutPage/AboutPageContext";

const About = () => {
  const [language, setLanguage] = useContext(LanguageContext);
  const [abouts, setAbouts] = useContext(AboutContext);
  const [aboutpages, setAboutPages] = useContext(AboutPageContext);

  useEffect(() => {
    (async () => {
      const getItems = () => {
        let get = service.GetAllItems();
        let get2 = aboutPageService.GetAllItems();
        get.then((res) => {
          let data = res.data;
          setAbouts(data);
        });

        get2.then((res) => {
          let data = res.data;
          setAboutPages(data);
        });
      };

      getItems();
    })();
  }, [setAbouts, setAboutPages]);

  return (
    <AboutContainer id="about">
      <AboutWrapper>
        {abouts.map((about) => (
          <AboutCards key={about.id} keyProp={about.id} {...about}></AboutCards>
        ))}
        <EmptySection></EmptySection>
      </AboutWrapper>
    </AboutContainer>
  );
};

export default About;
