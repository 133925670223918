import React, { useContext, useEffect } from "react";
import { GalleryContext } from "./GalleryContext";
import * as service from "../Admin/services/galleryService";
import GallerySection from "../../components/Gallery";

const GalleryPage = () => {
  const [gallery, setGallery] = useContext(GalleryContext);

  useEffect(() => {
    (async () => {
      const getItems = () => {
        let get = service.GetAllItems();
        get.then((res) => {
          let data = res.data;
          setGallery(data);
        });
      };
      getItems();
    })();
  }, [setGallery]);

  return (
    <>
      <GallerySection gallery={gallery}></GallerySection>
    </>
  );
};

export default GalleryPage;
// <GallerySection gallery={gallery}></GallerySection>
