import React from "react";
import { BlogProvider } from "../../components/Blog/BlogContext";
import { LanguageProvider } from "../../components/LanguageContext";
import BlogPage from "./BlogPage";
import { animateScroll as scroll } from "react-scroll";
import { OverflowContainer } from "../Home/HomeElements";

const Blog = () => {
  scroll.scrollToTop();
  return (
    <LanguageProvider>
      <BlogProvider>
        <OverflowContainer>
          <BlogPage></BlogPage>
        </OverflowContainer>
      </BlogProvider>
    </LanguageProvider>
  );
};

export default Blog;
