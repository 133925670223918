import { CssBaseline, Drawer, ThemeProvider } from "@material-ui/core";
import React from "react";
import Header from "../../../components/Admin/Header";
import MyDrawer from "../../../components/Admin/Drawer";
import { ReviewProvider } from "../../Media/Reviews/ReviewContext";
import Reviews from "./Reviews";
import { FileProvider } from "../../../components/File/FileContext";
import { PressProvider } from "../../Media/Press/PressContext";
import { useStyles, theme } from "../theme.js";

const ReviewAdminPage = (props) => {
  const classes = useStyles();

  return (
    <ReviewProvider>
      <PressProvider>
        <FileProvider>
          <ThemeProvider theme={theme}>
            <Drawer
              className={classes.drawer}
              variant="permanent"
              classes={{
                paper: classes.drawerPaper,
              }}
              anchor="left"
            >
              <MyDrawer></MyDrawer>
            </Drawer>
            <div className={classes.adminMain}>
              <Header></Header>
              <Reviews></Reviews>
            </div>
            <CssBaseline></CssBaseline>
          </ThemeProvider>
        </FileProvider>
      </PressProvider>
    </ReviewProvider>
  );
};

export default ReviewAdminPage;
