import React, { Component } from "react";
import Form from "react-bootstrap/Form";
import { Container, Row, Col, FormGroup } from "reactstrap";
import Compressor from "compressorjs";
import * as service from "../../pages/Admin/services/eventService";
import * as imageservice from "../../pages/Admin/services/imageService";
import ReactQuill, { Quill } from "react-quill";
import { firebaseApp } from "../../config/firebase";
import { getStorage } from "firebase/storage";

const Block = Quill.import("blots/block");
Block.tagName = "DIV";
Quill.register(Block, true);
const storageRef = getStorage(firebaseApp);

class AddPostEventAbout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      article: {
        title: "",
        content: this.props.defaultValue,
        createDate: new Date(),
        eng: "false",
      },
    };
  }

  modules = {
    keyboard: {
      bindings: {
        linebreak: {
          key: 13,
          handler: function (range, _context) {
            this.quill.clipboard.dangerouslyPasteHTML(
              range.index,
              "<p><br/><br/></p>"
            );
          },
        },
      },
    },
    toolbar: {
      container: [
        [{ header: "1" }, { header: "2" }, { font: [] }],
        [{ size: [] }],
        ["bold", "italic", "underline", "strike", "blockquote"],

        [
          { align: [] },
          { list: "ordered" },
          { list: "bullet" },
          { indent: "-1" },
        ],

        ["link", "image"],
        ["clean", "code-block"],
      ],
      handlers: {
        image: () => this.quillImageCallBack(),
      },
    },
    clipboard: {
      matchVisual: false,
    },
  };

  formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "align",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
    "code-block",
  ];

  onChangeArticleTitle = (value) => {
    this.setState({
      article: {
        ...this.state.article,
        title: value,
      },
    });
  };

  onChangeArticleContent = (value) => {
    this.state.article.content = value;
    this.props.setValues({
      ...this.props.values,
      about: value,
    });
  };

  onChangeLanguage = (value) => {
    let lang = true;
    if (value === "English") lang = "true";
    else lang = "false";
    this.setState({
      article: {
        ...this.state.article,
        eng: lang,
      },
    });
  };

  submitArticle = () => {
    const article = this.state.article;
    service.InsertEvent(article);
  };

  fileCompress = (file) => {
    return new Promise((resolve, reject) => {
      new Compressor(file, {
        file: "File",
        quality: 0.8,
        maxWidth: 800,
        success(file) {
          return resolve({
            success: true,
            file: file,
          });
        },
        error(err) {
          return resolve({
            success: false,
            message: err.message,
          });
        },
      });
    });
  };

  quillImageCallBack = () => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      const compressState = await this.fileCompress(file);
      if (compressState.success) {
        const fileName = file.name;
        storageRef
          .ref()
          .child("ImagesAbout/" + fileName)
          .put(compressState.file)
          .then(async (snapshot) => {
            const downloadURL = await storageRef
              .ref()
              .child("ImagesAbout/" + fileName)
              .getDownloadURL();
            let quill = this.quill.getEditor();
            const range = quill.getSelection(true);
            quill.insertEmbed(range.index, "image", downloadURL);
          });
      }
    };
  };

  uploadImageCallBack = (e) => {
    return new Promise(async (resolve, reject) => {
      const file = e.target.files[0];
      const fileName = e.target.files[0].name;
      let downloadURL = null;
      storageRef
        .ref()
        .child("ImagesAbout/" + fileName)
        .put(file)
        .then(async (snapshot) => {
          downloadURL = await storageRef
            .ref()
            .child("ImagesAbout/" + fileName)
            .getDownloadURL();
          let data = {
            title: fileName,
            link: downloadURL,
          };
          imageservice.InsertImage(data);
          resolve({
            success: true,
            data: { link: downloadURL },
          });
        });
    });
  };

  render() {
    if (this.props.values.about != undefined) {
      return (
        <Container>
          <Row>
            <Col style={{ padding: "0", marginBottom: "1rem" }}>
              <Form.Label>{this.props.title}</Form.Label>
              <FormGroup>
                <ReactQuill
                  ref={(el) => (this.quill = el)}
                  value={this.state.article.content}
                  onChange={(e) => this.onChangeArticleContent(e)}
                  theme="snow"
                  modules={this.modules}
                  formats={this.formats}
                ></ReactQuill>
              </FormGroup>
            </Col>
          </Row>
        </Container>
      );
    } else {
      return (
        <Container>
          <Row>
            <Col style={{ padding: "0", marginBottom: "1rem" }}>
              <Form.Label>{this.props.title}</Form.Label>
              <FormGroup>
                <ReactQuill
                  ref={(el) => (this.quill = el)}
                  value={this.state.article.content || " "}
                  onChange={(e) => this.onChangeArticleContent(e)}
                  theme="snow"
                  modules={this.modules}
                  formats={this.formats}
                ></ReactQuill>
              </FormGroup>
            </Col>
          </Row>
        </Container>
      );
    }
  }
}

export default AddPostEventAbout;
