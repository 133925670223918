import axios from "axios";
import { baseUrl } from "../../../utils/baseUrl";

export default axios.create({
  baseURL: baseUrl,
  headers: {
    "Content-type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  },
});
