import React from "react";
import { HeroContainer, HeroContent } from "./HeroPageElements";
import { BackgroundImageEvents2 } from "../BackgroundImage";

const HeroPageSection = (props) => {
  return (
    <HeroContainer id={props.id}>
      <BackgroundImageEvents2 backgroundImage={props.backgroundImage}>
        <HeroContent>{props.children}</HeroContent>
      </BackgroundImageEvents2>
    </HeroContainer>
  );
};

export default HeroPageSection;
