import React, { useState, createContext } from "react";
import icon1 from "../../components/images/icon_shop_transforming.jpg";

export const ShopContext = createContext();

export const ShopProvider = (props) => {
  const [shop, setShop] = useState([
    {
      title: "Transforming Traditions",
      subtitle: "Works by Haydn, Mozart, Beethoven and Giuliani, 8.99 €",
      image: icon1,
      link: "",
    },
    /* {
      title: "Glowing Sonorities",
      subtitle: "Sonatas by Schubert, Reinecke and Franck, 8.99 €",
      image: icon2,
      link: "",
    },
    {
      title: "Antonio Nava Flute and Guitar Duos",
      subtitle: "8.99 €",
      image: icon3,
      link: "",
    },
    {
      title: "Aribert Reimann – Medea",
      subtitle: "Michael Boder, Vienna State Opera Orchestra, 16.94 €",
      image: icon4,
      link: "",
    },
    {
      title: "Haydn: D major Sonata Hob. XVI:37",
      subtitle: "transcribed for flute and guitar, 17.95 € ",
      image: icon5,
      link: "",
    },
    {
      title: "Haydn: Sonata in A major Hob. XVI:30,",
      subtitle: "transcribed for flute and guitar, 17.95 €",
      image: icon6,
      link: "",
    },
    {
      title: "Mozart: d minor Fantasy KV397",
      subtitle: "transcribed for flute and guitar, 13.95 €",
      image: icon7,
      link: "",
    },
    {
      title: "Beethoven: Sonata 'Les Adieux' Op.81a",
      subtitle: "transcribed for flute and guitar, 17.95 €",
      image: icon8,
      link: "",
    },*/
  ]);
  return (
    <ShopContext.Provider value={[shop, setShop]}>
      {props.children}
    </ShopContext.Provider>
  );
};
