import styled from "styled-components";

export const HeroContainer = styled.div`
  margin-top: 80px;
  background: #000;
  display: flex;
  justify-content: center;
  align-item: center;
  height: 75vh;
  position: relative;
  z-index: 1;

  @media screen and (max-width: 1680px) {
  }
  @media screen and (max-width: 1280px) {
  }
  @media screen and (max-width: 980px) {
  }
  @media screen and (max-width: 736px) {
  }
  @media screen and (max-width: 480px) {
    height: 37.5rem;
  }
`;

export const HeroContent = styled.div`
  height: 100%;
  width: 100%;
`;

export const HeroH1 = styled.h1`
  color: #000;
  display: flex;
  font-size: 50px;
  text-align: center;
  position: relative;
  left: 75%;
  top: 1rem;
  font-weight: 300;
  text-transform: uppercase;

  @media screen and (max-width: 1680px) {
    left: 45%;
  }
  @media screen and (max-width: 1280px) {
    top: 1rem;
    left: 45%;
  }
  @media screen and (max-width: 480px) {
    font-size: 36px;
    position: relative;
    left: 10%;
    top: 25rem;
    max-width: 10rem;
  }
`;

export const HeroP = styled.p`
  display: flex;
  color: #000;
  font-size: 20px;
  position: relative;
  text-align: left;
  top: 1rem;
  left: 21.5%;
  font-weight: 500;
  max-width: 20rem;

  @media screen and (max-width: 1680px) {
    left: 17.5%;
  }
  @media screen and (max-width: 1280px) {
    left: 10%;
  }
  @media screen and (max-width: 480px) {
    color: #000;
    font-size: 20px;
    position: relative;
    left: 15%;
    top: 24rem;
  }
`;

export const HeroH12 = styled.h1`
  color: #000;
  display: flex;
  font-size: 30px;
  text-align: center;
  position: relative;
  left: 20rem;
  top: 3rem;
  font-weight: 500;
  font-family: playfairDisplay !important;

  @media screen and (max-width: 768px) {
    font-size: 20px;
    position: relative;
    right: 11.8rem;
    top: 4rem;
  }
`;

export const HeroBtnWrapper = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const HeroBtnWrapperAlignEnd = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: end;
`;
