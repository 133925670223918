import React, { useState } from "react";
import Sidebar from "../../../components/Sidebar";
import Navbar from "../../../components/Navbar";
import Footer from "../../../components/Footer";
import { InterviewProvider } from "./InterviewContext";
import InterviewPage from "./InterviewPage";
import { LanguageProvider } from "../../../components/LanguageContext";
import { OverflowContainer } from "../../Home/HomeElements";
import { animateScroll as scroll } from "react-scroll";
const Interviews = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  scroll.scrollToTop();
  return (
    <LanguageProvider>
      <InterviewProvider>
        <Sidebar isOpen={isOpen} toggle={toggle}></Sidebar>
        <Navbar toggle={toggle}></Navbar>
        <OverflowContainer>
          <InterviewPage></InterviewPage>
        </OverflowContainer>
        <Footer></Footer>
      </InterviewProvider>
    </LanguageProvider>
  );
};

export default Interviews;
