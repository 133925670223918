import React, { useState } from "react";
import PlayerCard from "../PlayerCard"; // Import the common PlayerCard component
import quartetCover from "../../../albums/quartet_cover.png";
import quartet_song1 from "../../../music/quartet-song1.mp3";
import quartet_song2 from "../../../music/quartet-song2.mp3";
import quartet_song3 from "../../../music/quartet-song3.mp3";

const Quartet = {
  cover: quartetCover,
  songs: [
    {
      name: "Haydn D minor I.",
      subtitle: "Noémi Győri, Katalin Kokas, Péter Bársony, Dóra Kokas",
      mp3: quartet_song1,
    },
    {
      name: "Mozart C I.",
      subtitle: "Noémi Győri, Katalin Kokas, Péter Bársony, Dóra Kokas",
      mp3: quartet_song2,
    },
    {
      name: "Mozart D III.",
      subtitle: "Noémi Győri, Katalin Kokas, Péter Bársony, Dóra Kokas",
      mp3: quartet_song3,
    },
  ],
};

export const QuartetAlbum = () => {
  const [language, setLanguage] = useState({ eng: "true" }); // Replace with your language context

  return (
    <PlayerCard
      cover={Quartet.cover}
      songs={Quartet.songs}
      buyLink="https://music.apple.com/gb/album/haydn-mozart-flute-quartets/1553974813"
      language={language}
    />
  );
};
