import React, { useContext, useEffect } from "react";
import HeroEventPageSection from "../HeroSection/HeroEventPage";
import { EventContext } from "../Events/EventContext";
import { GetAllEvents } from "../../pages/Admin/services/eventService";
import BigEventCardSection from "../Events/BigEventCard";
import { useParams } from "react-router-dom";
import { LanguageContext } from "../LanguageContext";
import parse from "html-react-parser";

const Events = (props) => {
  let eventId = useParams();
  const [language] = useContext(LanguageContext);
  const [event, setEvents] = useContext(EventContext);

  const getEvents = () => {
    let get = GetAllEvents();
    get.then((res) => {
      let data = res.data;
      setEvents(data);
    });
  };

  useEffect(() => {
    (async () => {
      getEvents();
    })();
  }, []);

  return (
    <>
      {event.map((event) => {
        if (event.id === eventId.eventId)
          return (
            <>
              <HeroEventPageSection
                id="events_hero"
                backgroundImage={event.img}
                date={event.date}
                place={event.place}
                titleENG={
                  language[0].eng === "true" ? event.titleENG : event.title
                }
                subtitleENG={
                  language[0].eng === "true"
                    ? event.subtitleENG
                    : event.subtitle
                }
              ></HeroEventPageSection>
              <BigEventCardSection
                date={event.date}
                place={event.place}
                aboutENG={
                  language[0].eng === "true"
                    ? parse(event.aboutENG)
                    : parse(event.about)
                }
                image={event.image}
              ></BigEventCardSection>
            </>
          );
      })}
    </>
  );
};

export default Events;
