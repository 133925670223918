import { React, useState, useEffect, useContext } from "react";
import PageHeader from "../../../components/Admin/PageHeader";
import InterviewForm from "./InterviewForm";
import EventIcon from "@material-ui/icons/Event";
import AddIcon from "@material-ui/icons/Add";
import Controls from "../controls/Controls";
import useTable from "../useTable";
import * as service from "../services/interviewService";
import { Close, EditOutlined, Search } from "@material-ui/icons";
import {
  InputAdornment,
  Paper,
  TableBody,
  TableCell,
  TableRow,
  Toolbar,
} from "@material-ui/core";
import * as fileService from "../services/fileService";
import Notification from "../controls/Notification";
import ConfirmDialog from "../controls/ConfirmDialog";
import { InterviewContext } from "../../Media/Interviews/InterviewContext";
import { useStylesPaper } from "../theme.js";
import { Forms } from "../forms";
import { FileContext } from "../../../components/File/FileContext";

const headCells = [
  { id: "title", label: "Title" },
  { id: "titleENG", label: "Title (ENG)" },
  { id: "buttonLabel", label: "buttonLabel" },
  { id: "buttonLabelENG", label: "buttonLabel (ENG)" },
  { id: "link", label: "link" },
  { id: "date", label: "Date" },
  { id: "actions", label: "Actions", disableSorting: true },
];

const Interviews = () => {
  const pageTitle = "Interviews";
  const [interviews, setInterviews] = useContext(InterviewContext);
  const [files, setFiles] = useContext(FileContext);
  const classes = useStylesPaper();
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const { handleClose, handleShow, showModal, carry } = Forms();
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });

  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
    useTable(interviews, headCells, filterFn);

  const handleSearch = (e) => {
    let target = e.target;
    setFilterFn({
      fn: (items) => {
        if (target.value === "") return items;
        else
          return items.filter((x) =>
            x.titleENG.toLowerCase().includes(target.value)
          );
      },
    });
  };

  const upload = async (item) => {
    if (item.id == null) await service.InsertItem(item);
    else await service.UpdateItem(item);
    setNotify({
      isOpen: true,
      message: "Submitted Succesfully",
      type: "success",
    });
    window.location.reload();
  };

  const onDelete = async (id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    await service.DeleteItem(id);
    setNotify({
      isOpen: true,
      message: "Deleted Successfully",
      type: "error",
    });
    window.location.reload();
  };

  useEffect(() => {
    (() => {
      const getItems = () => {
        let get = service.GetAllItems();
        get.then((res) => {
          let data = res.data;
          setInterviews(data);
        });
      };

      const getFiles = () => {
        let get = fileService.GetAllItems();
        get.then((res) => {
          let data = res.data;
          setFiles(data);
        });
      };

      getItems();
      getFiles();
    })();
  }, [setInterviews, setFiles]);

  return (
    <>
      <PageHeader title={pageTitle} icon={<EventIcon />}></PageHeader>
      <Paper className={classes.pageContent}>
        <Toolbar>
          <Controls.Input
            label="Search"
            className={classes.searchInput}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            onChange={handleSearch}
          ></Controls.Input>
          <Controls.Button
            text="Add New"
            variant="outlined"
            startIcon={<AddIcon />}
            className={classes.newButton}
            onClick={() => {
              carry.current = {};
              handleShow();
            }}
          ></Controls.Button>
        </Toolbar>
        <TblPagination></TblPagination>
        <TblContainer>
          <TblHead></TblHead>
          <TableBody>
            {recordsAfterPagingAndSorting().map((item) => (
              <TableRow key={item.id}>
                <TableCell className={classes.narrowCell}>
                  {item.title}
                </TableCell>
                <TableCell className={classes.narrowCell}>
                  {item.titleENG}
                </TableCell>
                <TableCell className={classes.narrowCell}>
                  {item.buttonLabel}
                </TableCell>
                <TableCell className={classes.narrowCell}>
                  {item.buttonLabelENG}
                </TableCell>
                <TableCell className={classes.narrowCell}>
                  {item.link}
                </TableCell>
                <TableCell className={classes.narrowCell}>
                  {new Date(item.date).toDateString()}
                  {" - "}
                  {new Date(item.date).toLocaleTimeString("en-US", {
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                </TableCell>
                <TableCell>
                  <Controls.ActionButton
                    color="primary"
                    onClick={() => {
                      carry.current = item;
                      handleShow();
                    }}
                  >
                    <EditOutlined fontSize="small"></EditOutlined>
                  </Controls.ActionButton>
                  <Controls.ActionButton
                    color="secondary"
                    onClick={() => {
                      setConfirmDialog({
                        isOpen: true,
                        title: "Are you sure to delete this record?",
                        subTitle: "You can't undo this operation",
                        onConfirm: () => {
                          onDelete(item.id);
                        },
                      });
                    }}
                  >
                    <Close fontSize="small"></Close>
                  </Controls.ActionButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </TblContainer>
      </Paper>
      <InterviewForm
        showModal={showModal}
        handleClose={handleClose}
        upload={upload}
        pageTitle={pageTitle}
        carry={carry.current}
        files={files}
      ></InterviewForm>
      <Notification notify={notify} setNotify={setNotify}></Notification>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
};

export default Interviews;
