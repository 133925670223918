import styled from "styled-components";

export const HeroH1 = styled.h1`
  color: #000;
  display: flex;
  font-size: 50px;
  text-align: center;
  position: relative;
  left: 20vh;
  top: 10vh;
  font-weight: 500;
  text-transform: uppercase;

  @media screen and (max-width: 768px) {
    font-size: 42px;
    position: relative;
    top: 5rem;
    left: 0;
  }
`;

export const HeroP = styled.p`
  margin-top: 4rem;
  display: flex;
  color: #000;
  font-size: 24px;
  text-align: left;
  position: relative;
  left: 20vh;
  top: 5vh;
  max-width: 25rem;

  @media screen and (max-width: 768px) {
    font-size: 24px;
    position: relative;
    top: 5rem;
    left: 0;
  }
`;

export const HeroBtnWrapper = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  left: 10rem;
  top: 2rem;
  @media screen and (max-width: 768px) {
    position: relative;
    top: 5rem;
    left: 0;
  }
`;
