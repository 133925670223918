// imageUploader.js

import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { firebaseStorage } from "../../config/firebase";
import * as imageservice from "../../pages/Admin/services/imageService";

export async function uploadImageToFirestore(
  canvas,
  crop,
  filename,
  setProgress,
  setUploadComplete
) {
  // Create a canvas for scaled down image
  const fileCanvas = document.createElement("canvas");
  const pixelRatio = window.devicePixelRatio || 1;
  const scaleDown = 1 / pixelRatio;

  fileCanvas.width = Math.floor(canvas.width * scaleDown);
  fileCanvas.height = Math.floor(canvas.height * scaleDown);

  const ctx = fileCanvas.getContext("2d");
  if (!ctx) {
    throw new Error("2D context not supported");
  }

  ctx.imageSmoothingQuality = "high";
  ctx.scale(scaleDown, scaleDown);
  ctx.drawImage(canvas, 0, 0);

  // Convert canvas to blob and create a File object
  fileCanvas.toBlob(async (blob) => {
    const file = new File([blob], filename);

    // Define storage path in Firebase Storage
    const storagePath = "Images/" + filename;

    const storageRef = ref(firebaseStorage, storagePath);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Upload progress tracking
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress(progress); // Update progress state
      },
      (error) => {
        // Handle errors
        console.error("Upload error:", error);
        // You can set an error state or display an error message to the user
      },
      async () => {
        // Upload completed successfully
        try {
          const downloadURL = await getDownloadURL(storageRef);

          const data = {
            title: filename,
            link: downloadURL,
          };

          // Insert image metadata into Firestore using your imageservice function
          await imageservice.InsertImage(data, setProgress);

          // Reset progress and set upload complete flag
          setProgress(0);
          setUploadComplete(true); // Trigger a re-render in your component
        } catch (error) {
          console.error("Error fetching download URL:", error);
          // Handle error fetching download URL
        } finally {
          // Cleanup: Remove fileCanvas and any temporary resources
          URL.revokeObjectURL(blob);
        }
      }
    );
  });
}
