import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";
import classes from "./AddPost.module.css";
import Compressor from "compressorjs";
import * as service from "../../pages/Admin/services/newsService";
import ReactQuill from "react-quill";
import { firebaseApp } from "../../config/firebase";
import * as imageservice from "../../pages/Admin/services/imageService";
import { getStorage } from "firebase/storage";
import { LazyLoadImage } from "../LazyLoadImage";

const storageRef = getStorage(firebaseApp);

class AddPostQuillNews extends Component {
  constructor(props) {
    super(props);
    this.state = {
      article: {
        title: "",
        content: "",
        createDate: new Date(),
        image: "",
        eng: "true",
      },
    };
  }

  modules = {
    keyboard: {
      bindings: {
        linebreak: {
          key: 13,
          handler: function (range, _context) {
            this.quill.clipboard.dangerouslyPasteHTML(
              range.index,
              "<p><br/><br/></p>"
            );
          },
        },
      },
    },
    toolbar: {
      container: [
        [{ header: "1" }, { header: "2" }, { font: [] }],
        [{ size: [] }],
        ["bold", "italic", "underline", "strike", "blockquote"],

        [
          { align: [] },
          { list: "ordered" },
          { list: "bullet" },
          { indent: "-1" },
        ],

        ["link", "image"],
        ["clean", "code-block"],
      ],
      handlers: {
        image: () => this.quillImageCallBack(),
      },
    },
    clipboard: {
      matchVisual: false,
    },
  };

  formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "align",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
    "code-block",
  ];

  onChangeArticleTitle = (value) => {
    this.setState({
      article: {
        ...this.state.article,
        title: value,
      },
    });
  };
  onChangeArticleContent = (value) => {
    this.setState({
      article: {
        ...this.state.article,
        content: value,
      },
    });
  };

  onChangeLanguage = (value) => {
    let lang = true;
    if (value === "English") lang = "true";
    else lang = "false";
    this.setState({
      article: {
        ...this.state.article,
        eng: lang,
      },
    });
  };

  submitArticle = () => {
    const article = this.state.article;

    service.InsertItem(article);
  };

  fileCompress = (file) => {
    return new Promise((resolve, reject) => {
      new Compressor(file, {
        file: "File",
        quality: 0.8,
        maxWidth: 640,
        maxHeight: 640,
        success(file) {
          return resolve({
            success: true,
            file: file,
          });
        },
        error(err) {
          return resolve({
            success: false,
            message: err.message,
          });
        },
      });
    });
  };

  quillImageCallBack = () => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      const compressState = await this.fileCompress(file);
      if (compressState.success) {
        const fileName = file.name;
        storageRef
          .ref()
          .child("ImagesNews/" + fileName)
          .put(compressState.file)
          .then(async (snapshot) => {
            const downloadURL = await storageRef
              .ref()
              .child("ImagesNews/" + fileName)
              .getDownloadURL();
            let quill = this.quill.getEditor();
            const range = quill.getSelection(true);
            quill.insertEmbed(range.index, "image", downloadURL);
          });
      }
    };
  };

  uploadImageCallBack = (e) => {
    return new Promise(async (resolve, reject) => {
      const file = e.target.files[0];
      const fileName = e.target.files[0].name;
      let downloadURL = null;
      storageRef
        .ref()
        .child("ImagesNews/" + fileName)
        .put(file)
        .then(async (snapshot) => {
          downloadURL = await storageRef
            .ref()
            .child("ImagesNews/" + fileName)
            .getDownloadURL();
          let data = {
            title: fileName,
            link: downloadURL,
          };
          imageservice.InsertImage(data);
          resolve({
            success: true,
            data: { link: downloadURL },
          });
        });
    });
  };

  render() {
    return (
      <Container>
        <Row>
          <Col xl={9} lg={9} md={8} sm={12} xs={12}>
            <h2 className={classes.SectionTitle}>New Article</h2>
            <FormGroup>
              <Label className={classes.Label}>Title</Label>
              <Input
                type="text"
                name="articleTitle"
                id="title"
                placeholder=""
                onChange={(e) => this.onChangeArticleTitle(e.target.value)}
                value={this.state.article.title}
              ></Input>
            </FormGroup>
            <FormGroup>
              <ReactQuill
                ref={(el) => (this.quill = el)}
                value={this.state.article.content}
                onChange={(e) => this.onChangeArticleContent(e)}
                theme="snow"
                modules={this.modules}
                formats={this.formats}
              ></ReactQuill>
            </FormGroup>
          </Col>
          <Col xl={3} lg={3} md={4} sm={12} xs={12}>
            <Card>
              <CardHeader>Language Setting</CardHeader>
              <CardBody>
                <FormGroup>
                  <Label className={classes.Label}>Language</Label>
                  <Input
                    type="select"
                    name="eng"
                    id="eng"
                    onChange={(e) => this.onChangeLanguage()}
                  >
                    <option>English</option>
                    <option>Hungarian</option>
                  </Input>
                </FormGroup>
                <FormGroup>
                  <Label className={classes.Label}>Image</Label>
                  <Input
                    type="file"
                    accept="image/*"
                    className={classes.ImageUploader}
                    onChange={async (e) => {
                      const uploadState = await this.uploadImageCallBack(e);
                      if (uploadState.success) {
                        this.setState({
                          hasImage: true,
                          article: {
                            ...this.state.article,
                            image: uploadState.data.link,
                          },
                        });
                      }
                    }}
                  ></Input>
                  {this.state.hasImage ? (
                    <img
                      alt="img"
                      src={this.state.article.image}
                      className={classes.Image}
                    />
                  ) : (
                    ""
                  )}
                </FormGroup>
              </CardBody>
              <FormGroup>
                <Button color="success" onClick={(e) => this.submitArticle()}>
                  Submit
                </Button>
              </FormGroup>
            </Card>
          </Col>
          <Col xl={3} lg={3} md={4} sm={12} xs={12}></Col>
        </Row>
      </Container>
    );
  }
}

export default AddPostQuillNews;
