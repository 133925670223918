import styled from "styled-components";

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fff;
  font-family: playfairDisplay !important;
  margin: 0;
  height: min-content;

  @media screen and (max-width: 1680px) {
  }
  @media screen and (max-width: 1280px) {
  }

  @media screen and (max-width: 480px) {
    width: auto;
  }
`;

export const CardWrapper = styled.div`
  width: 60.9375vw;
  max-width: 1140px;
  height: min-content;
  padding: 20px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0;

  &:hover {
    transform: scale(1.04);
    transition: all 0.2s ease-in-out;
  }

  @media screen and (max-width: 1680px) {
  }
  @media screen and (max-width: 1280px) {
  }

  @media screen and (max-width: 1024px) {
    display: grid;
    grid-template-columns: none;
    grid-template-rows: 1fr 1fr;
    left: 0rem;
    padding: 0.5rem;
    min-height: 40rem;
    max-height: 50rem;
  }
  @media screen and (max-width: 980px) {
    width: 88.88vw;
    &:hover {
      transform: scale(1);
      transition: all 0.2s ease-in-out;
    }
  }
  @media screen and (max-width: 480px) {
    width: 100%;
  }
`;

export const Card = styled.div`
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  height: min-content;
  margin: 0;
  padding-left: 1rem;
  padding-right: 1rem;

  @media screen and (max-width: 1680px) {
  }
  @media screen and (max-width: 1280px) {
  }
  @media screen and (max-width: 980px) {
    justify-content: center;
    width: 100%;
    min-height: auto;
  }
  @media screen and (max-width: 480px) {
    width: 100%;
    min-height: auto;
  }
`;

export const CardDate = styled.h1`
  font-size: 1.5rem;
  font-weight: 100;
  color: #000;
  padding-top: 0;
  padding-bottom: 0;
  margin: 0;
  margin-top: 4rem !important;
  font-family: playfairDisplay !important;

  @media screen and (max-width: 1680px) {
  }
  @media screen and (max-width: 1280px) {
  }

  @media screen and (max-width: 480px) {
    font-size: 1.5rem;
    padding-top: 0;
    padding-bottom: 0;
    margin: 0;
    margin-top: 1rem !important;
  }
`;
export const CardPlace = styled.h1`
  font-size: 1.25rem;
  font-weight: 100;
  color: #000;
  white-space: nowrap;
  max-width: 25rem;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  padding-bottom: 1rem;
  font-family: playfairDisplay !important;
  font-style: italic;
  padding-top: 0;
  padding-bottom: 0;
  margin: 0;
  margin-top: 1rem !important;

  @media screen and (max-width: 1680px) {
  }
  @media screen and (max-width: 1280px) {
  }

  @media screen and (max-width: 480px) {
    font-size: 1rem;
    min-width: 100%;
    padding-top: 0;
    margin: 0;
    max-width: 100vw;
    margin-top: 0 !important;
    padding-bottom: 0;
    margin-bottom: 2rem !important;
  }
`;

export const CardTitle = styled.p`
  font-size: 1.8rem;
  text-align: center;
  white-space: normal;
  min-width: 30rem;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  font-family: playfairDisplay !important;
  padding-top: 0;
  padding-bottom: 0;
  margin: 0;
  margin-top: -0.5rem !important;
  margin-bottom: 1rem !important;

  @media screen and (max-width: 1680px) {
  }
  @media screen and (max-width: 1280px) {
  }

  @media screen and (max-width: 480px) {
    font-size: 1.75rem;
    min-width: 100%;
    padding-top: 0;
    max-width: 100vw;

    margin: 0;
    padding-bottom: 0;
    margin-bottom: 1rem;
  }
`;

export const CardSubtitle = styled.p`
  font-size: 1.25rem;
  text-align: center;
  font-style: italic;

  font-family: playfairDisplay !important;
`;

export const BtnWrap = styled.div`
  padding-top: 2rem;
  padding-bottom: 2rem;
  display: flex;
  justify-content: center;
`;
