import React, { useState, useContext, useEffect } from "react";
import Sidebar from "../../components/Sidebar";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import { LanguageProvider } from "../../components/LanguageContext";
import { GalleryProvider } from "./GalleryContext";
import { OverflowContainer } from "../Home/HomeElements";
import GalleryShow from "./GalleryShow";
import { animateScroll as scroll } from "react-scroll";
import { CardContainer } from "../../components/Gallery/GalleryElements";

const GalleryShowIndex = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  scroll.scrollToTop();
  return (
    <LanguageProvider>
      <GalleryProvider>
        <Sidebar isOpen={isOpen} toggle={toggle}></Sidebar>
        <Navbar toggle={toggle}></Navbar>
        <OverflowContainer>
          <CardContainer>
            <GalleryShow></GalleryShow>
          </CardContainer>
        </OverflowContainer>
        <Footer></Footer>
      </GalleryProvider>
    </LanguageProvider>
  );
};

export default GalleryShowIndex;
