import React from "react";
import { EventProvider } from "../../components/Events/EventContext";
import { LanguageProvider } from "../../components/LanguageContext";
import EventsPage from "./EventsPage";
import { OverflowContainer } from "../Home/HomeElements";
const Events = () => {
  return (
    <LanguageProvider>
      <EventProvider>
        <EventsPage></EventsPage>
      </EventProvider>
    </LanguageProvider>
  );
};

export default Events;
