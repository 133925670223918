import React, { useState, createContext } from "react";

export const BioContext = createContext();

export const BioProvider = (props) => {
  const [bio, setBio] = useState([
    {
      id: "db_not_connected",
      title: "Database not connected",
      content: "",
      text: "Database not connected",
      buttonLabel: "",
      dark: true,
      primary: true,
      link: "home",
    },
    {
      id: "db_not_connected",
      title: "Database not connected",
      content: "",
      text: "Database not connected",
      buttonLabel: "",
      dark: true,
      primary: true,
      link: "home",
    },
  ]);
  return (
    <BioContext.Provider value={[bio, setBio]}>
      {props.children}
    </BioContext.Provider>
  );
};
