import styled from "styled-components";
import { Link as LinkR } from "react-router-dom";
import { Link as LinkS } from "react-scroll";

export const Nav = styled.nav`
  background: #fff;
  margin-top: -80px;
  display: grid;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  position: sticky;
  top: 0;
  z-index: 100;
  @media screen and (max-width: 980px) {
    grid-template-rows: 120px auto;
  }
`;

export const NavbarContainer = styled.div`
  z-index: 1;
  width: 60.9375vw;
  max-width: 1140px;

  @media screen and (max-width: 1919px) {
    width: 88.88vw;
  }

  @media screen and (max-width: 1280px) {
    width: 95vw;
  }
  @media screen and (max-width: 980px) {
    width: 100vw;
    justify-content: space-between;
  }
  @media screen and (max-width: 480px) {
    width: 100vw;
    justify-content: space-between;
  }
`;

export const NavLogo = styled(LinkR)`
color: #fff
justify-self: flex-start;
cursor: pointer;
font-size: 2rem;
display: flex;
align-items: center;
margin-left: 0px;
font-weight: bold;
text-decoration: none; 
@media screen and (max-width: 980px) {

}
`;

export const MobileIcon = styled.div`
  display: none;
  @media screen and (max-width: 980px) {
    display: block;
    position: absolute;
    top: 14px;
    right: 10px;
    transfrom: translate(-100%, 60%);
    font-size: 2.5rem;
    cursor: pointer;
    color: black;
  }
`;

export const NavMenu = styled.ul`
  display: flex;
  align-item: center;
  justify-content: center;
  list-style-type: none;
  text-align: center;
  height: 2rem;

  @media screen and (max-width: 980px) {
    display: none;
  }
`;

export const NavLanguageWrap = styled.li`
  display: none;

  @media screen and (max-width: 980px) {
    display: flex;
    width: 100%;
    height: 20px;
    justify-content: center;
    align-items: center;
  }
`;

export const NavLanguage = styled.li`
  display: none;

  @media screen and (max-width: 980px) {
    display: flex;
    position: relative;
    height: 20px;
  }
  @media screen and (max-width: 480px) {
    display: flex;
    position: relative;
    height: 20px;
  }
`;

export const NavItem = styled.li`
  margin-bottom: 0;

  &:hover {
  }
`;

export const NavLinks = styled(LinkS)`
  color: #000;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1.4rem;
  height: 100%;
  font-size: 14px;
  text-transform: uppercase;
  cursor: pointer;
  font-family: "PT Sans", sans-serif !important;
  font-weight: 100;
  border-bottom: 3px solid transparent;
  justify-content: center;

  &.active {
    margin-top: 0.8rem;
    border-bottom: 3px solid black;
    padding-bottom: 2rem;
  }
  &:hover {
    color: #9c9c9c;
    text-decoration: none;
  }
  @media screen and (max-width: 1280px) {
  }
  @media screen and (max-width: 980px) {
    height: auto;
    padding: 0 1rem;
  }
`;

export const NavLinksImgEng = styled.img`
  max-width: 20%;
  padding-top: 1rem;

  @media screen and (max-width: 980px) {
    max-width: 32%;
    padding-top: 0rem;
  }
  @media screen and (max-width: 480px) {
    max-width: 60%;
    margin-top: 0%;
  }
`;

export const NavLinksImgHu = styled.img`
  max-width: 20%;
  padding-top: 1rem;

  @media screen and (max-width: 980px) {
    max-width: 32%;
    padding-top: 0rem;
  }
  @media screen and (max-width: 480px) {
    max-width: 60%;
    margin-top: 0%;
  }
`;
