import styled from "styled-components";
import { Link } from "react-router-dom";

export const Button = styled(Link)`
  background: ${({ primary }) => (primary ? "#fff" : "#000")};
  white-space: nowrap;
  padding: ${({ big }) => (big ? "12px 48px" : "10px 30px")};
  color: ${({ dark }) => (dark ? "#000" : "#fff")} !important;
  font-size: ${({ fontBig }) => (fontBig ? "20px" : "16px")};
  outline: solid;
  outline-width: 1px;
  cursor: pointer;
  display: flex !important;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  border: none !important;
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
  padding-right: 2rem !important;
  padding-left: 2rem !important;
  margin: 0 !important;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: ${({ primary }) => (primary ? "#000" : "#fff")} !important;
    color: ${({ dark }) => (dark ? "#fff" : "#000")} !important;
    text-decoration: none;
  }

  @media screen and (max-width: 480px) {
    font-size: 14px;
    margin-bottom: 2.5rem !important;
    padding: 0.5rem 1rem !important;
  }
`;

export const ButtonSimple = styled(Link)`
  background: ${({ primary }) => (primary ? "#fff" : "#000")};
  white-space: nowrap;
  padding: ${({ big }) => (big ? "12px 48px" : "10px 30px")};
  color: ${({ dark }) => (dark ? "#000" : "#fff")} !important;
  font-size: ${({ fontBig }) => (fontBig ? "20px" : "16px")};
  outline: solid;
  outline-width: 1px;
  cursor: pointer;
  display: flex !important;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  border: none !important;
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
  padding-right: 2rem !important;
  padding-left: 2rem !important;
  margin: 0 !important;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: ${({ primary }) => (primary ? "#000" : "#fff")} !important;
    color: ${({ dark }) => (dark ? "#fff" : "#000")} !important;
    text-decoration: none;
  }

  @media screen and (max-width: 480px) {
    font-size: 14px;
    margin-bottom: 0rem !important;
    padding: 0.5rem 1rem !important;
  }
`;

export const ButtonShop = styled(Link)`
  background: transparent;
  white-space: nowrap;
  padding: ${({ big }) => (big ? "12px 48px" : "10px 30px")};
  color: ${({ dark }) => (dark ? "#000" : "#fff")};
  font-size: ${({ fontBig }) => (fontBig ? "20px" : "16px")};
  outline: solid;
  outline-width: 1px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
  padding-right: 2rem !important;
  padding-left: 2rem !important;
  border: none !important;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: ${({ primary }) => (primary ? "#000" : "#fff")};
    color: ${({ dark }) => (dark ? "#fff" : "#000")};
    text-decoration: none;
  }
`;

export const ButtonInvisible = styled(Link)`
  background: transparent;
`;

export const ButtonMedia = styled(Link)`
  background: rgba(255, 255, 255, 1);
  position: relative;
  left: ${({ align }) => (align ? "0%" : "25%")};
  color: #000;
  max-width: 75%;
  height: 5rem;
  padding: 2rem;
  font-size: 36px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  font-weight: 100;

  &:hover {
    transform: scale(1.04);
    transition: all 0.2s ease-in-out;
    color: #794f0b;
    text-decoration: none;
  }

  @media screen and (max-width: 1280px) {
    height: 2rem;
    left: 0;
    max-width: 100%;
    font-size: 20px;
  }

  @media screen and (max-width: 480px) {
    height: 2rem;
    left: 0;
    max-width: 100%;
    font-size: 20px;
  }
`;

export const ButtonAboutMe = styled(Link)`
  background: transparent;
  position: relative;
  color: #fff;
  width: 10rem;
  height: 2.5rem;
  font-size: 16px;
  outline: solid;
  outline-width: 1px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);

  &:hover {
    transform: scale(1.04);
    transition: all 0.2s ease-in-out;
    color: #000;
    background: #fff;
    text-decoration: none;
    outline: none;
  }
`;

export const ButtonBio = styled(Link)`
  background: transparent;
  position: relative;
  color: #fff;
  width: 20rem;
  height: 2.5rem;
  font-size: 16px;
  outline: solid;
  outline-width: 1px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);

  &:hover {
    transform: scale(1.04);
    transition: all 0.2s ease-in-out;
    color: #000;
    background: #fff;
    text-decoration: none;
    outline: none;
  }

  @media screen and (max-width: 1680px) {
  }
  @media screen and (max-width: 1280px) {
  }
  @media screen and (max-width: 480px) {
  }
`;

export const ButtonSpecialities = styled(Link)`
  background: transparent;
  position: relative;
  color: #fff;
  width: 30rem;
  height: 5.5rem;
  font-size: 36px;
  outline: solid;
  outline-width: 1px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 9.5rem;
  font-weight: 300;
  text-transform: uppercase;

  &:hover {
    transition: all 0.5s ease-out;
    color: #000;
    background: #fff;
    text-decoration: none;
    outline: none;
  }

  @media screen and (max-width: 768px) {
    width: 20rem;
    top: 5rem;
    height: 4rem;
    font-size: 24px;
  }
`;

export const ButtonDoc = styled(Link)`
  background: transparent;
  position: relative;
  color: #fff;
  width: 30rem;
  height: 5.5rem;
  font-size: 36px;
  outline: solid;
  outline-width: 1px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 9.5rem;
  right: 25%;
  font-weight: 300;
  text-transform: uppercase;

  &:hover {
    transition: all 0.5s ease-out;
    color: #000;
    background: #fff;
    text-decoration: none;
    outline: none;
  }

  @media screen and (max-width: 768px) {
    width: 25rem;
    top: 12rem;
    height: 5rem;
    font-size: 32px;
    right: 0;
  }

  @media screen and (max-width: 480px) {
    width: 23rem;
    top: 12rem;
    height: 5rem;
    font-size: 28px;
    right: 0;
  }
`;

export const ButtonSpecialitiesBlack = styled(Link)`
  background: transparent;
  position: relative;
  color: #000;
  width: 30rem;
  height: 5.5rem;
  font-size: 36px;
  outline: solid;
  outline-width: 1px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 9.5rem;
  text-transform: uppercase;
  font-weight: 300;

  &:hover {
    transition: all 0.5s ease-out;
    color: #fff;
    background: #000;
    text-decoration: none;
    outline: none;
  }

  @media screen and (max-width: 768px) {
    width: 20rem;
    top: 5rem;
    height: 4rem;
    font-size: 24px;
  }
`;

export const ButtonAboutMeBlack = styled(Link)`
  background: transparent;
  position: relative;
  color: #000;
  width: 10rem;
  height: 2.5rem;
  font-size: 16px;
  outline: solid;
  outline-width: 1px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);

  &:hover {
    transform: scale(1.04);
    transition: all 0.2s ease-in-out;
    color: #fff;
    background: #000;
    text-decoration: none;
    outline: none;
  }
`;

export const ButtonAboutMeBlackTeacher = styled(Link)`
  background: transparent;
  position: relative;
  color: #000;
  width: 10rem;
  height: 2.5rem;
  font-size: 16px;
  outline: solid;
  outline-width: 1px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  transition: all 0.2s ease-in-out;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);

  &:hover {
    transform: scale(1.04);
    transition: all 0.2s ease-in-out;
    color: #fff;
    background: #000;
    text-decoration: none;
    outline: none;
  }

  @media screen and (max-width: 1680px) {
  }

  @media screen and (max-width: 1280px) {
  }
  @media screen and (max-width: 480px) {
  }
`;

export const ButtonAboutMeBlackSolo = styled(Link)`
  background: transparent;
  position: relative;
  color: #fff;
  width: 10rem;
  height: 2.5rem;
  font-size: 16px;
  outline: solid;
  outline-width: 1px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  transition: all 0.2s ease-in-out;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);

  &:hover {
    transform: scale(1.04);
    transition: all 0.2s ease-in-out;
    color: #fff;
    background: #000;
    text-decoration: none;
    outline: none;
  }

  @media screen and (max-width: 1680px) {
  }

  @media screen and (max-width: 1280px) {
  }
  @media screen and (max-width: 480px) {
  }
`;

export const ButtonAboutMeBlackChamber = styled(Link)`
  background: transparent;
  position: relative;
  width: 10rem;
  height: 2.5rem;
  font-size: 16px;
  outline: solid;
  outline-width: 1px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  margin: 0 !important;
  color: #fff !important;
  display: flex !important;
  transition: all 0.2s ease-in-out;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);

  &:hover {
    transform: scale(1.04);
    transition: all 0.2s ease-in-out;
    color: #fff;
    background: #000;
    text-decoration: none;
    outline: none;
  }

  @media screen and (max-width: 1680px) {
  }
  @media screen and (max-width: 1280px) {
  }
  @media screen and (max-width: 980px) {
  }
  @media screen and (max-width: 480px) {
  }
`;

export const ButtonAboutMeBlackOrch = styled(Link)`
  background: transparent;
  position: relative;
  width: 10rem;
  height: 2.5rem;
  font-size: 16px;
  outline: solid;
  outline-width: 1px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  margin: 0 !important;
  color: #fff !important;
  display: flex !important;
  transition: all 0.2s ease-in-out;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);

  &:hover {
    transform: scale(1.04);
    transition: all 0.2s ease-in-out;
    color: #fff !important;
    background: #000;
    text-decoration: none;
    outline: none;
  }

  @media screen and (max-width: 1680px) {
  }
  @media screen and (max-width: 1280px) {
  }
  @media screen and (max-width: 980px) {
  }
  @media screen and (max-width: 480px) {
  }
`;

export const ButtonAboutMeBeyond = styled(Link)`
  background: transparent;
  position: relative;
  max-width: 22rem;
  height: 3rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  font-size: 16px;
  outline: solid;
  outline-width: 1px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  margin: 0 !important;
  color: #fff !important;
  display: flex !important;
  transition: all 0.2s ease-in-out;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);

  &:hover {
    transform: scale(1.04);
    transition: all 0.2s ease-in-out;
    color: #fff;
    background: #000;
    text-decoration: none;
    outline: none;
  }

  @media screen and (max-width: 1680px) {
  }
  @media screen and (max-width: 1280px) {
  }
  @media screen and (max-width: 980px) {
  }
  @media screen and (max-width: 480px) {
    color: #000 !important;
    &:hover {
      transform: scale(1.04);
      transition: all 0.2s ease-in-out;
      color: #000;
      background: #fff;
      text-decoration: none;
      outline: none;
    }
  }
`;

export const ButtonInterview = styled(Link)`
  padding: 0 !important;
  margin: 0 !important;
  background: #fff;
  position: relative;
  color: #000;
  width: 10rem;
  height: 2.5rem;
  font-size: 16px;
  outline: solid;
  outline-width: 1px;
  cursor: pointer;
  display: flex !important;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  margin: 0 !important;
  border: none !important;

  &:hover {
    transition: all 0.2s ease-in-out;
    color: #fff;
    background: #000;
    text-decoration: none;
    outline: none;
  }

  @media screen and (max-width: 480px) {
    margin-top: 0 !important;
    border: none !important;
  }
`;

export const ButtonReview = styled(Link)`
  background: #fff;
  position: relative;
  color: #000;
  width: 10rem;
  height: 2.5rem;
  font-size: 16px;
  outline: solid;
  outline-width: 1px;
  cursor: pointer;
  display: flex !important;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  margin: 0 !important;
  border: none !important;

  &:hover {
    transition: all 0.2s ease-in-out;
    color: #fff;
    background: #000;
    text-decoration: none;
    outline: none;
  }

  @media screen and (max-width: 480px) {
    margin-top: 2vh;
  }
`;

export const ButtonPlayer = styled(Link)`
  width: 100% !important;
  border: none !important;
  padding: 0 !important;
  margin: 0 !important;
  background: transparent;
  position: relative;
  color: #000;
  height: 2.4rem;
  font-size: 15px;
  cursor: pointer;
  display: flex !important;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.04);
    transition: all 0.2s ease-in-out;
    color: #fff;
    background: #868686 !important;
    text-decoration: none;
    outline: none;
  }

  @media screen and (max-width: 1680px) {
  }
  @media screen and (max-width: 1280px) {
    height: 3.5rem;
  }
  @media screen and (max-width: 980px) {
  }
  @media screen and (max-width: 736px) {
  }
  @media screen and (max-width: 480px) {
    text-decoration: underline;
    font-size: 14px;
    height: 2rem;
  }
`;

export const ButtonPlayerBuy = styled(Link)`
  width: 100% !important;
  border: none !important;
  padding: 0 !important;
  margin: 0 !important;
  margin-top: 1rem !important;
  background: transparent;
  position: relative;
  color: #000;
  height: 2.4rem;
  font-size: 14px;
  cursor: pointer;
  display: flex !important;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  outline: solid;
  outline-width: 1px;
  margin-bottom: 1.5rem !important;

  &:hover {
    transform: scale(1.04);
    transition: all 0.2s ease-in-out;
    color: #fff;
    background: #000;
    text-decoration: none;
    outline: none;
  }

  @media screen and (max-width: 1700px) {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
    height: 3.5rem;
  }
  @media screen and (max-width: 1280px) {
    padding-left: 0 !important;
    padding-right: 0 !important;
    height: 3.5rem;
  }
  @media screen and (max-width: 980px) {
    margin-bottom: 0rem !important;
  }
  @media screen and (max-width: 736px) {
  }
  @media screen and (max-width: 480px) {
    font-size: 14px;
    height: 2rem;
    margin-top: 0.25rem !important;
  }
`;
