import React, { useState, useEffect, useContext } from "react";
import PageHeader from "../../../components/Admin/PageHeader";
import ImageForm from "./ImageForm";
import EventIcon from "@material-ui/icons/Event";
import AddIcon from "@material-ui/icons/Add";
import Controls from "../controls/Controls";
import Popup from "../Popup";
import useTable from "../useTable";
import * as service from "../services/imageService";
import { Close, EditOutlined, Search } from "@material-ui/icons";
import {
  InputAdornment,
  makeStyles,
  Paper,
  TableBody,
  TableCell,
  TableRow,
  Toolbar,
} from "@material-ui/core";
import ConfirmDialog from "../controls/ConfirmDialog";
import { ImageContext } from "../../../components/Image/ImageContext";
import ImageCropper from "../../../components/Image/ImageCropper";
import { useStylesPaper } from "../theme.js";
import { ProgressProvider } from "../../../components/Image/ProgressContext";
import { baseUrl } from "../../../utils/baseUrl";
import { LazyLoadImage } from "../../../components/LazyLoadImage";

const headCells = [
  { id: "title", label: "Title" },
  { id: "link", label: "link" },
  { id: "image", label: "image" },
  { id: "Uploaded at", label: "Uploaded at" },
  { id: "actions", label: "Actions", disableSorting: true },
];

const Images = () => {
  const [rerender, setRerender] = useState(false);

  const [images, setImages] = useContext(ImageContext);
  const classes = useStylesPaper();
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [openPopup, setOpenPopup] = useState(false);
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const [searchQuery, setSearchQuery] = useState(""); // State for search query

  const handleImageUploadComplete = () => {
    // Update the rerender state to trigger a re-render
    setRerender(!rerender);
  };

  const {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting,
    sort,
  } = useTable(images, headCells, filterFn);

  const handleSearch = (e) => {
    const target = e.target;
    setSearchQuery(target.value); // Update search query state
    setFilterFn({
      fn: (items) => {
        if (!searchQuery) {
          return items; // Return all items if searchQuery is not defined
        }
        return items.filter(
          (x) =>
            x.title?.toLowerCase()?.includes(searchQuery.toLowerCase()) ||
            x.link?.toLowerCase()?.includes(searchQuery.toLowerCase())
        );
      },
    });
  };

  const addOrEdit = async (item, resetForm) => {
    if (item.id == null) await service.InsertItem(item);
    else await service.UpdateItem(item);
    resetForm();
    setRecordForEdit(null);
    setOpenPopup(false);

    // Refresh the images after the update
    const updatedImages = await service.GetAllItems();
    setImages(updatedImages.data);

    // Sort the images by createdAt in descending order
    updatedImages.data.sort(
      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
    );
  };

  const openInPopup = (item) => {
    setRecordForEdit(item);
    setOpenPopup(true);
  };

  const onDelete = async (id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });

    try {
      await service.DeleteItem(id);

      const updatedImages = await service.GetAllItems();

      updatedImages.data.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );

      setImages(updatedImages.data);
    } catch (error) {
      console.error("Error deleting item:", error);
    }
  };

  useEffect(() => {
    (async () => {
      const getItems = () => {
        let get = service.GetAllItems();
        get.then((res) => {
          let data = res.data;
          setImages(data);
        });
      };
      getItems();
    })();
  }, [setImages]);

  return (
    <ProgressProvider>
      <PageHeader
        title="Images"
        subtitle="Manage images"
        icon={<EventIcon />}
      ></PageHeader>
      <ImageCropper onUploadComplete={handleImageUploadComplete} />
      <Paper className={classes.pageContent}>
        <Toolbar>
          <Controls.Input
            label="Search Images"
            className={classes.searchInput}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            onChange={handleSearch}
          ></Controls.Input>
          <Controls.Button
            text="Add New"
            variant="outlined"
            startIcon={<AddIcon />}
            className={classes.newButton}
            onClick={() => {
              setOpenPopup(true);
              setRecordForEdit(null);
            }}
          ></Controls.Button>
        </Toolbar>
        <TblPagination></TblPagination>
        <TblContainer>
          <TblHead></TblHead>
          <TableBody>
            {recordsAfterPagingAndSorting("createdAt").map((item) => (
              <TableRow key={item.id}>
                <TableCell className={classes.narrowCell}>
                  {item.title}
                </TableCell>
                <TableCell className={classes.narrowCell}>
                  {item.link}
                </TableCell>{" "}
                <TableCell className={classes.narrowCell}>
                  <LazyLoadImage
                    src={`${baseUrl}/images/${item.id}`}
                    alt="noemi_image"
                    width={100}
                    className={classes.Image}
                  />
                </TableCell>
                <TableCell className={classes.narrowCell}>
                  {new Date(item.createdAt).toDateString()}
                </TableCell>
                <TableCell>
                  <Controls.ActionButton
                    color="primary"
                    onClick={() => {
                      openInPopup(item);
                    }}
                  >
                    <EditOutlined fontSize="small"></EditOutlined>
                  </Controls.ActionButton>
                  <Controls.ActionButton
                    color="secondary"
                    onClick={() => {
                      setConfirmDialog({
                        isOpen: true,
                        title: "Are you sure to delete this record?",
                        subTitle: "You can't undo this operation",
                        onConfirm: () => {
                          onDelete(item.id);
                        },
                      });
                    }}
                  >
                    <Close fontSize="small"></Close>
                  </Controls.ActionButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </TblContainer>
      </Paper>
      <Popup
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        title="New Images"
      >
        <ImageForm
          recordForEdit={recordForEdit}
          addOrEdit={addOrEdit}
        ></ImageForm>
      </Popup>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </ProgressProvider>
  );
};

export default Images;
