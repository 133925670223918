import React, { useContext, useEffect } from "react";
import {
  PartnersContainer,
  PartnersLogo,
  PartnersWrapper,
  PartnersLogoContainer,
  PartnersH1,
  PartnersLink,
} from "./PartnersElements";
import "semantic-ui-css/semantic.min.css";
import nanushka from "../images/partners/nanushka.png";
import celeni from "../images/partners/celeni.jpg";
import hungaroton from "../images/partners/hungaroton.jpg";
import panni from "../images/partners/panni.png";
import ligne from "../images/partners/ligne.jpg";
import royal from "../images/partners/royal.png";
import musikhaus from "../images/partners/musikhaus.png";
import sanco from "../images/partners/sanco.png";
import philip from "../images/partners/philip.png";
import manier from "../images/partners/manier.png";
import rncm from "../images/partners/rncm.png";
import flutecenter from "../images/partners/flutecenter.jpg";
import gemma from "../images/partners/gemma.jpg";
import genuin from "../images/partners/genuin.png";
import jeanne from "../images/partners/jeanne.png";
import miyazawa from "../images/partners/miyazawa.jpg";
import rps from "../images/partners/rps.png";
import supportofculture from "../images/partners/supportofculture.png";
import white from "../images/partners/white.png";
import { LanguageContext } from "../LanguageContext";

const PartnersSection = (props) => {
  const [language, setLanguage] = useContext(LanguageContext);

  useEffect(() => {
    (async () => {})();
  }, []);

  return (
    <PartnersContainer>
      <PartnersWrapper>
        <PartnersH1>
          {language[0].eng === "true" ? "partners" : "partnerek"}
        </PartnersH1>
        <PartnersLogoContainer>
          <PartnersLink href="https://www.nanushka.com/" target="_blank">
            <PartnersLogo
              src={nanushka}
              style={{
                width: "100%",
              }}
              alt="logo"
              className="logo"
            ></PartnersLogo>
          </PartnersLink>
          <PartnersLink href="https://www.celeni.hu/" target="_blank">
            <PartnersLogo
              src={celeni}
              style={{
                width: "60%",
              }}
              alt="logo"
              className="logo"
            ></PartnersLogo>
          </PartnersLink>
          <PartnersLink href="https://hungarotonmusic.com/" target="_blank">
            <PartnersLogo
              src={hungaroton}
              style={{
                width: "80%",
              }}
              alt="logo"
              className="logo"
            ></PartnersLogo>{" "}
          </PartnersLink>
          <PartnersLink href="https://www.tonabi.com/home" target="_blank">
            <PartnersLogo
              src={panni}
              style={{
                width: "50%",
              }}
              alt="logo"
              className="logo"
            ></PartnersLogo>{" "}
          </PartnersLink>
          <PartnersLink href="https://www.ligne-roset.com/hu/" target="_blank">
            <PartnersLogo
              src={ligne}
              style={{
                width: "60%",
              }}
              alt="logo"
              className="logo"
            ></PartnersLogo>{" "}
          </PartnersLink>
          <PartnersLink href="https://www.ram.ac.uk/" target="_blank">
            <PartnersLogo
              src={royal}
              style={{
                width: "70%",
              }}
              alt="logo"
              className="logo"
            ></PartnersLogo>
          </PartnersLink>
          <PartnersLink
            href="https://www.doblinger.at/en/Home.htm"
            target="_blank"
          >
            <PartnersLogo
              src={musikhaus}
              style={{
                width: "50%",
              }}
              alt="logo"
              className="logo"
            ></PartnersLogo>
          </PartnersLink>
          <PartnersLink href="https://flutecenter.com/" target="_blank">
            <PartnersLogo
              src={flutecenter}
              style={{
                width: "35%",
              }}
              alt="logo"
              className="logo"
            ></PartnersLogo>
          </PartnersLink>
          <PartnersLink href="">
            <PartnersLogo
              src={sanco}
              style={{
                width: "35%",
              }}
              alt="logo"
              className="logo"
            ></PartnersLogo>
          </PartnersLink>
          <PartnersLink
            href="https://www.philiploubserfoundation.org/"
            target="_blank"
          >
            <PartnersLogo
              src={philip}
              style={{
                width: "100%",
              }}
              alt="logo"
              className="logo"
            ></PartnersLogo>
          </PartnersLink>
          <PartnersLink href="https://www.manier.hu/" target="_blank">
            <PartnersLogo
              src={manier}
              style={{
                width: "100%",
              }}
              alt="logo"
              className="logo"
            ></PartnersLogo>
          </PartnersLink>
          <PartnersLink href="https://www.rncm.ac.uk/" target="_blank">
            <PartnersLogo
              src={rncm}
              style={{
                width: "90%",
              }}
              alt="logo"
              className="logo"
            ></PartnersLogo>
          </PartnersLink>
          <PartnersLink href="http://www.gemmatrust.com/" target="_blank">
            <PartnersLogo
              src={gemma}
              style={{
                width: "50%",
              }}
              alt="logo"
              className="logo"
            ></PartnersLogo>
          </PartnersLink>{" "}
          <PartnersLink href="https://www.genuin.de/en/01.php" target="_blank">
            <PartnersLogo
              src={genuin}
              style={{
                width: "65%",
              }}
              alt="logo"
              className="logo"
            ></PartnersLogo>{" "}
          </PartnersLink>{" "}
          <PartnersLink href="https://jeannedart-stiftung.de/" target="_blank">
            <PartnersLogo
              src={jeanne}
              style={{
                width: "75%",
              }}
              alt="logo"
              className="logo"
            ></PartnersLogo>{" "}
          </PartnersLink>{" "}
          <PartnersLink href="https://miyazawa.com/" target="_blank">
            <PartnersLogo
              src={miyazawa}
              style={{
                width: "70%",
              }}
              alt="logo"
              className="logo"
            ></PartnersLogo>
          </PartnersLink>
          <PartnersLink
            href="https://royalphilharmonicsociety.org.uk/"
            target="_blank"
          >
            <PartnersLogo
              src={rps}
              style={{
                width: "40%",
              }}
              alt="logo"
              className="logo"
            ></PartnersLogo>
          </PartnersLink>
          <PartnersLink href="http://eufsc.eu/" target="_blank">
            <PartnersLogo
              src={supportofculture}
              style={{
                width: "40%",
              }}
              alt="logo"
              className="logo"
            ></PartnersLogo>
          </PartnersLink>
        </PartnersLogoContainer>
      </PartnersWrapper>
    </PartnersContainer>
  );
};

export default PartnersSection;
