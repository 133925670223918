import http from "./http-auth";

export function InsertItem(data) {
  return http.post("/programs", data).then((res) => {});
}

export function DeleteItem(data) {
  return http.delete(`/programs/${data}`).then((res) => {});
}

export function UpdateItem(data) {
  return http.put(`/programs/${data.id}`, data).then((res) => {});
}

export function GetItem(data) {
  return http.get(`/programs/${data.id}`, data).then((res) => {});
}

export const GetAllItems = () => {
  return http.get("/programs");
};
