import React, { useContext, useEffect } from "react";
import {
  Card,
  CardContainer,
  CardH1,
  CardP,
  CardWrapper,
  BtnWrap,
} from "./BoxCardElementsDoc";
import { BackgroundImageDoc } from "../BackgroundImage";
import { Button } from "../ButtonElement";
import { LanguageContext } from "../LanguageContext";
import * as serviceLanguage from "../../pages/Admin/services/languageService";
import { useHistory } from "react-router-dom";

import { EmptySection } from "./BioCardElements";
import { SpecContext } from "../Specialities/SpecContext";
import { Content } from "../BackgroundImage";

const BoxCardDocSection = (props) => {
  const [language, setLanguage] = useContext(LanguageContext);
  const [cards] = useContext(SpecContext);
  const history = useHistory();

  let doctoral = { link: "" };
  cards.map((item) => {
    if (
      language[0].eng === "true" &&
      item.title === "DOCTORAL STUDIES AT THE ROYAL ACADEMY OF MUSIC"
    ) {
      doctoral = item;
    } else if (
      language[0].eng === "false" &&
      item.title === "DOKTORI TANULMÁNYOK A LONDONI KIRÁLYI ZENEAKADÉMIÁN"
    ) {
      doctoral = item;
    }
  });

  useEffect(() => {
    (async () => {})();
  }, []);
  const value = props.value;
  const height = props.height;
  let exp;
  if (props.experience) {
    exp = language[0].eng === "true" ? "A live experience" : "Valódi élmény";
  }
  return (
    <CardContainer>
      <BackgroundImageDoc
        backgroundImage={props.backgroundImage}
      ></BackgroundImageDoc>
      <Content>
        <CardWrapper id="cardWrapper">
          <Card
            style={{
              maxHeight: height,
            }}
            id={props.scroll}
          >
            <CardH1>{language[0].eng === "true" ? "PhD" : "PhD"}</CardH1>
            <CardP left={props.left}>
              {language[0].eng === "true"
                ? "I find that combining art with cognition and in-depth academic understanding helps me to achieve a more expressive, colourful, faithful and honest performance. Creating spiritful interpretations with intellectual and artistic transparency are great enjoyment for me and I am thrilled to be the first flutist to hold a PhD in Flute Performance Practice from the Royal Academy of Music in London."
                : "Mindig úgy éreztem, hogy a művészetnek a megismeréssel és az elmélyült tudományos megértéssel való összekapcsolása segít még kifejezőbb, színesebb, pontosabb és stílushűbb interpretációkat kialakítanom. Különösen élvezem, ha lendületes, egyben művészileg és szellemileg is megalapozott előadásokat hozhatok létre. Így nagy öröm számomra, hogy a londoni Royal Academy of Music-on fuvolásként elsőnek szerezhettem előadóművészi Ph.D. fokozatot."}
            </CardP>{" "}
            <BtnWrap>
              <Button
                onClick={() => {
                  history.push(
                    `${
                      language[0].eng === "true"
                        ? "doctoralstudies"
                        : "doktori-tanulmanyok"
                    }`
                  );
                }}
                smooth="true"
                duration={500}
                spy="true"
                offset={-80}
                primary="true"
                dark="true"
              >
                {language[0].eng === "true" ? "Tell me more!" : "Mesélj még"}
              </Button>
            </BtnWrap>
          </Card>{" "}
        </CardWrapper>{" "}
      </Content>
      <EmptySection></EmptySection>
    </CardContainer>
  );
};

export default BoxCardDocSection;
