import React, { useState, createContext } from "react";

export const SpecContext = createContext();

export const SpecProvider = (props) => {
  const [specPages, setSpecPages] = useState([
    {
      id: "db_not_connected",
      title: "Database not connected",
      subtitle: "",
      text: "Database not connected",
      buttonLabel: "",
      dark: true,
      primary: true,
      link: "home",
    },
  ]);
  return (
    <SpecContext.Provider value={[specPages, setSpecPages]}>
      {props.children}
    </SpecContext.Provider>
  );
};
