import React, { useContext, useEffect } from "react";
import HeroPageSection from "../../../components/HeroSection/HeroPage";
import {
  HeroText,
  Text,
  TextP,
  TextWrap,
  ReviewBox,
  ReviewWrap,
  ButtonWrap,
} from "../../../components/ReviewSection";
import { ReviewContext } from "./ReviewContext";
import HeroBgImg from "../../../components/images/media_hero_background.jpg";
import { ButtonReview } from "../../../components/ButtonElement";
import * as service from "../../../pages/Admin/services/reviewService";
import { LanguageContext } from "../../../components/LanguageContext";
import { HeroPositionContainerMedia } from "../../../components/InterviewSection";

const ReviewPage = () => {
  const [reviews, setReviews] = useContext(ReviewContext);
  const [language] = useContext(LanguageContext);

  const sortDates = () => {
    reviews.sort(function (a, b) {
      return new Date(b.date) - new Date(a.date);
    });
    setReviews(reviews);
  };

  useEffect(() => {
    (async () => {
      const getItems = () => {
        let get = service.GetAllItems();
        get.then((res) => {
          let data = res.data;
          setReviews(data);
        });
      };

      getItems();
    })();
  }, [setReviews]);

  sortDates();
  return (
    <>
      <HeroPageSection backgroundImage={HeroBgImg}>
        <HeroPositionContainerMedia>
          <HeroText>
            {language[0].eng === "true" ? "Reviews" : "Kritikák"}
          </HeroText>{" "}
        </HeroPositionContainerMedia>
      </HeroPageSection>
      {reviews.map((item) => {
        return (
          <ReviewBox>
            <ReviewWrap>
              <Text>
                {` ${
                  language[0].eng === "true" ? item.titleENG : item.title
                } ${new Date(item.date).toLocaleDateString("en-US", {
                  year: "numeric",
                })}`}
              </Text>
              <TextP>
                {language[0].eng === "true" ? item.subtitleENG : item.subtitle}
              </TextP>
              <ButtonWrap>
                <ButtonReview
                  onClick={() => {
                    window.open(item.link);
                  }}
                >
                  {language[0].eng === "true"
                    ? item.buttonLabelENG
                    : item.buttonLabel}
                </ButtonReview>
              </ButtonWrap>
            </ReviewWrap>
          </ReviewBox>
        );
      })}
    </>
  );
};

export default ReviewPage;
