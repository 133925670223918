import React, { useRef, useState } from "react";

export function Forms() {
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);
  const carry = useRef("");

  return {
    showModal,
    carry,
    handleClose,
    handleShow,
  };
}
