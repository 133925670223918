import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { Button, ProgressBar } from "react-bootstrap";
import { cropPreview } from "./cropPreview";
import { UploadContainer, Text } from "./ImageCropperElements";
import { uploadImageToFirestore } from "./UploadToFirestore";
import { Paper } from "@material-ui/core";
import { useStylesPaper } from "../../pages/Admin/theme";

export default function ImageCropper() {
  const [imgSrc, setImgSrc] = useState("");
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();
  const [scale, setScale] = useState(1);
  const [filename, setFilename] = useState("image.jpg");
  const [rotate, setRotate] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [aspectRatio, setAspectRatio] = useState(16 / 9);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadComplete, setUploadComplete] = useState(false);
  const [newWidth, setNewWidth] = useState(0);
  const [newHeight, setNewHeight] = useState(0);
  const classes = useStylesPaper();

  // Memoize the onSelectFile function
  const onSelectFile = useCallback((e) => {
    if (e.target.files && e.target.files.length > 0) {
      setFilename(e.target.files[0].name);
      setCrop(undefined);
      setUploadComplete(false);
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setImgSrc(reader.result.toString() || "")
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  }, []);

  // Memoize the onImageLoad function
  const onImageLoad = useCallback((e) => {
    imgRef.current = e.currentTarget;

    const { naturalWidth, naturalHeight } = e.currentTarget;

    const maxWidth = 250;
    const maxHeight = 250;

    let calculatedWidth = naturalWidth;
    let calculatedHeight = naturalHeight;

    if (naturalWidth > maxWidth || naturalHeight > maxHeight) {
      const aspectRatio = naturalWidth / naturalHeight;

      if (naturalWidth > naturalHeight) {
        calculatedWidth = maxWidth;
        calculatedHeight = maxWidth / aspectRatio;
      } else {
        calculatedHeight = maxHeight;
        calculatedWidth = maxHeight * aspectRatio;
      }
    }

    setNewWidth(calculatedWidth);
    setNewHeight(calculatedHeight);

    const crop = calculateCrop(calculatedWidth, calculatedHeight, aspectRatio);

    setCrop(crop);
  }, []);

  // Memoize the updateCropPreview function
  const updateCropPreview = useCallback(() => {
    if (completedCrop && previewCanvasRef.current && imgRef.current) {
      cropPreview(
        imgRef.current,
        previewCanvasRef.current,
        completedCrop,
        scale,
        rotate
      );
    }
  }, [completedCrop, scale, rotate]);

  // Use useMemo for JSX elements to prevent re-rendering
  const lazyLoadImage = useMemo(
    () => (
      <img
        alt="Crop me"
        src={imgSrc}
        style={{
          transform: `scale(${scale}) rotate(${rotate}deg)`,
          marginLeft: "1rem",
          width: `${newWidth}px`,
          height: `${newHeight}px`,
        }}
        onLoad={onImageLoad}
      />
    ),
    [imgSrc, scale, rotate, newWidth, newHeight, onImageLoad]
  );

  // Calculate crop based on dimensions and aspect ratio
  const calculateCrop = (width, height, aspectRatio) => {
    const x = (width - width) / 2;
    const y = (height - height) / 2;

    return {
      unit: "%",
      width: (width / width) * 100,
      height: (height / height) * 100,
      x: (x / width) * 100,
      y: (y / height) * 100,
    };
  };

  useEffect(() => {
    updateCropPreview();
    setUploading(false);
  }, [updateCropPreview]);

  // Handle image upload
  const handleUpload = () => {
    setUploading(true);

    uploadImageToFirestore(
      previewCanvasRef.current,
      completedCrop,
      filename,
      setUploadProgress,
      (uploadedImage) => {
        setUploadComplete(true);
        window.location.reload();
      }
    );
  };

  return (
    <Paper className={classes.pageContent}>
      <div>
        <Text>Upload a new image</Text>
        <input
          type="file"
          accept="image/*"
          onChange={onSelectFile}
          style={{
            marginBottom: "1rem",
            marginLeft: "1rem",
          }}
        />
      </div>
      {Boolean(imgSrc) && (
        <React.Fragment>
          <Text>You can crop your image by dragging and resizing the box:</Text>
          <div
            style={{
              display: "flex",
              gap: "1rem",
              marginBottom: "1rem",
              marginLeft: "1rem",
            }}
          >
            <Button
              variant="primary"
              onClick={() => setAspectRatio(16 / 9)}
              type="submit"
            >
              16:9
            </Button>
            <Button
              variant="primary"
              onClick={() => setAspectRatio(4 / 3)}
              type="submit"
            >
              4:3
            </Button>
            <Button
              variant="primary"
              onClick={() => setAspectRatio(1)}
              type="submit"
            >
              1:1
            </Button>
          </div>
          <ReactCrop
            crop={crop}
            onChange={(_, percentCrop) => setCrop(percentCrop)}
            onComplete={(c) => setCompletedCrop(c)}
            aspect={aspectRatio}
          >
            {lazyLoadImage}
          </ReactCrop>
          <Text>Preview of the final image:</Text>
          <div>
            <canvas
              ref={previewCanvasRef}
              style={{
                marginLeft: "1rem",
                width: Math.floor(completedCrop?.width ?? 0),
                height: Math.floor(completedCrop?.height ?? 0),
              }}
            />
          </div>
        </React.Fragment>
      )}
      {uploading ? (
        <div>
          <Text>Uploading: {uploadProgress}%</Text>
          <ProgressBar
            style={{
              marginLeft: "1rem",
              marginRight: "1rem",
            }}
            now={uploadProgress}
            label={`${uploadProgress}%`}
          />
        </div>
      ) : (
        <div>
          {completedCrop ? (
            <Button
              style={{
                marginTop: "1rem",
                marginLeft: "1rem",
                marginBottom: "1rem",
              }}
              variant="success"
              onClick={handleUpload}
            >
              Upload cropped image
            </Button>
          ) : (
            <Button
              style={{
                marginTop: "1rem",
                marginLeft: "1rem",
                marginBottom: "1rem",
              }}
              variant="success"
              disabled
            >
              Upload cropped image
            </Button>
          )}
        </div>
      )}
      {uploadComplete && (
        <div>
          <Text>Upload complete!</Text>
        </div>
      )}
    </Paper>
  );
}
