import React, { useContext, useEffect } from "react";
import ReactPlayer from "react-player";
import { VideoBox, VideoWrap } from "../../../components/VideoSection";
import { VideoContext } from "./VideoContext";
import * as service from "../../../pages/Admin/services/videoService";

const VideoPage = () => {
  const [videos, setVideos] = useContext(VideoContext);

  useEffect(() => {
    (async () => {
      const getItems = () => {
        let get = service.GetAllItems();
        get.then((res) => {
          let data = res.data;
          setVideos(data);
        });
      };
      getItems();
    })();
  }, [setVideos]);
  return (
    <>
      {videos.map((item) => {
        return (
          <>
            <VideoBox>
              <VideoWrap>
                <ReactPlayer url={item.link}></ReactPlayer>
              </VideoWrap>
            </VideoBox>
          </>
        );
      })}
    </>
  );
};

export default VideoPage;
