import React from "react";
import {
  HeroContainer,
  HeroContent,
  HeroContentEventsCard,
  HeroH1,
  HeroH2,
  HeroP,
  HeroTextWrapper,
} from "./HeroEventPageElements";
import { BackgroundImage } from "../BackgroundImage";

const HeroEventPageSection = (props) => {
  return (
    <HeroContainer id={props.id}>
      <BackgroundImage backgroundImage={props.backgroundImage}>
        <HeroContentEventsCard>
          <HeroTextWrapper>
            <HeroH2>{props.place}</HeroH2>
            <HeroH1>{props.titleENG}</HeroH1>
            <HeroP>{props.subtitleENG}</HeroP>
          </HeroTextWrapper>
        </HeroContentEventsCard>
      </BackgroundImage>
    </HeroContainer>
  );
};

export default HeroEventPageSection;
