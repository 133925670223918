import React, { useContext, useEffect } from "react";
import { IoIosMenu } from "react-icons/io";
import { animateScroll as scroll } from "react-scroll";
import {
  MobileIcon,
  Nav,
  NavbarContainer,
  NavItem,
  NavLanguage,
  NavLanguageWrap,
  NavLinks,
  NavLinksImgEng,
  NavLinksImgHu,
  NavMenu,
} from "./NavbarElements";
import { useLocation } from "react-router";
import navbar_logo_hu from "../images/navbar_logo_hu.png";
import navbar_logo_eng from "../images/navbar_logo_eng.png";
import { LanguageContext } from "../LanguageContext";
import * as service from "../../pages/Admin/services/languageService";
import { useHistory } from "react-router-dom";

const Navbar = ({ toggle }) => {
  const location = useLocation();

  const toggleHome = () => {
    scroll.scrollToTop();
  };
  const [language, setLanguage] = useContext(LanguageContext);
  const history = useHistory();
  const getItems = () => {
    let get = service.GetAllItems();
    get.then((res) => {
      let data = res.data;

      setLanguage(data);
    });
  };

  const aboutClass =
    location.pathname.match("/the-soloist") ||
    location.pathname.match("/the-chamber-musician") ||
    location.pathname.match("/the-orchestral-flutist") ||
    location.pathname.match("/the-flute-teacher") ||
    location.pathname.match("/szolista") ||
    location.pathname.match("/kamarazenesz") ||
    location.pathname.match("/zenekari-fuvolas") ||
    location.pathname.match("/tanar")
      ? "active"
      : "";

  const doctoralClass =
    location.pathname.match("/doctoralstudies") ||
    location.pathname.match("/doktori-tanulmanyok")
      ? "active"
      : "";

  const specialitiesClass =
    location.pathname.match("/contemporary-music") ||
    location.pathname.match("/kortars-zene") ||
    location.pathname.match("/a-zenen-is-tul") ||
    location.pathname.match("/beyond-music") ||
    location.pathname.match("/korhu-hangszerek") ||
    location.pathname.match("/period-flute") ||
    location.pathname.match("/blog")
      ? "active"
      : "";
  const eventsClass = location.pathname.match("/events") ? "active" : "";
  const mediaClass =
    location.pathname.match("/gallery") ||
    location.pathname.match("/press") ||
    location.pathname.match("/reviews") ||
    location.pathname.match("/interviews") ||
    location.pathname.match("/videos") ||
    location.pathname.match("/programproposals")
      ? "active"
      : "";
  const bioClass = location.pathname.match("/bio") ? "active" : "";
  const shopClass = location.pathname.match("/shop") ? "active" : "";

  useEffect(() => {
    (async () => {
      let lang = localStorage.getItem("eng");
      let data = { eng: lang };
      setLanguage([data]);
    })();
  }, []);
  return (
    <>
      <Nav>
        <NavbarContainer>
          <NavLinks
            onClick={() => {
              history.push("/#home");
            }}
          >
            {language[0].eng === "true" ? (
              <NavLinksImgEng
                src={navbar_logo_eng}
                alt="navbar_logo_Hu"
                className="navbar-icon"
              />
            ) : (
              <NavLinksImgHu
                src={navbar_logo_hu}
                alt="navbar_logo_Hu"
                className="navbar-icon"
              />
            )}
          </NavLinks>
          <MobileIcon onClick={toggle}>
            <IoIosMenu></IoIosMenu>
          </MobileIcon>
          <NavLanguageWrap>
            <NavLanguage
              onClick={() => {
                if (localStorage.getItem("eng") === "true") {
                  localStorage.removeItem("eng");
                  localStorage.setItem("eng", false);
                } else {
                  localStorage.removeItem("eng");
                  localStorage.setItem("eng", true);
                }

                service.UpdateItem(language[0]);
                window.location.reload();
              }}
              style={{ textTransform: "none", fontWeight: "bold" }}
            >
              {localStorage.getItem("eng") === "true" ? "hu/EN" : "HU/en"}
            </NavLanguage>
          </NavLanguageWrap>
          <NavMenu>
            <NavItem>
              <NavLinks
                onClick={() => {
                  history.push("/#about");
                }}
                smooth="true"
                duration={500}
                spy="true"
                exact="true"
                offset={-125}
                className={aboutClass}
              >
                {language[0].eng === "true" ? "About me" : "Rólam"}
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks
                style={{ textTransform: "none" }}
                onClick={() => {
                  history.push("/#doctoral");
                }}
                smooth="true"
                duration={500}
                spy="true"
                exact="true"
                offset={-145}
                className={doctoralClass}
              >
                {language[0].eng === "true" ? "PhD" : "PhD"}
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks
                onClick={() => {
                  history.push("/#music");
                }}
                smooth="true"
                duration={500}
                spy="true"
                exact="true"
                offset={-125}
              >
                {language[0].eng === "true" ? "My music" : "Zene"}
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks
                onClick={() => {
                  history.push("/#specialities");
                }}
                smooth="true"
                duration={500}
                spy="true"
                exact="true"
                offset={-125}
                className={specialitiesClass}
              >
                {language[0].eng === "true" ? "Specialities" : "Érdekességek"}
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks
                onClick={() => {
                  history.push("/#events");
                }}
                smooth="true"
                duration={500}
                spy="true"
                exact="true"
                offset={-170}
                className={eventsClass}
              >
                {language[0].eng === "true" ? "Events" : "Események"}
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks
                onClick={() => {
                  history.push("/#media");
                }}
                smooth="true"
                duration={500}
                spy="true"
                exact="true"
                offset={-125}
                className={mediaClass}
              >
                {language[0].eng === "true" ? "Media" : "Média"}
              </NavLinks>
            </NavItem>{" "}
            <NavItem>
              <NavLinks
                onClick={() => {
                  history.push("/#collection");
                }}
                smooth="true"
                duration={500}
                spy="true"
                exact="true"
                offset={-150}
              >
                {language[0].eng === "true" ? "Collection" : "Collection"}
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks
                onClick={() => {
                  history.push("/#bio");
                }}
                smooth="true"
                duration={500}
                spy="true"
                exact="true"
                offset={-125}
                className={bioClass}
              >
                {language[0].eng === "true" ? "Bio" : "Életrajz"}
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks
                onClick={() => {
                  history.push("/#shop");
                }}
                to="shop"
                smooth="true"
                duration={500}
                spy="true"
                exact="true"
                offset={-125}
                className={shopClass}
              >
                {language[0].eng === "true" ? "Shop" : "Bolt"}
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks
                onClick={() => {
                  history.push("/#contact");
                }}
                smooth="true"
                duration={500}
                spy="true"
                exact="true"
                offset={-120}
              >
                {language[0].eng === "true" ? "Contact" : "Kapcsolat"}
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks
                onClick={() => {
                  if (localStorage.getItem("eng") === "true") {
                    localStorage.removeItem("eng");
                    localStorage.setItem("eng", false);
                  } else {
                    localStorage.removeItem("eng");
                    localStorage.setItem("eng", true);
                  }
                  service.UpdateItem(language[0]);
                  window.location.reload();
                }}
                style={{ textTransform: "none", fontWeight: "bold" }}
              >
                {localStorage.getItem("eng") === "true" ? "hu/EN" : "HU/en"}
              </NavLinks>
            </NavItem>
          </NavMenu>
        </NavbarContainer>
      </Nav>
    </>
  );
};

export default Navbar;
