import React from "react";
import { HeroContainer, HeroContent } from "../Specialities/HeroSpecElements";
import { BackgroundImageBlog } from "../BackgroundImage";

const HeroPageSection = (props) => {
  return (
    <HeroContainer id={props.id}>
      <BackgroundImageBlog backgroundImage={props.backgroundImage}>
        <HeroContent>{props.children}</HeroContent>
      </BackgroundImageBlog>
    </HeroContainer>
  );
};

export default HeroPageSection;
