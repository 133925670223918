import styled from "styled-components";

export const HeroContainer = styled.div`
  margin-top: 80px;
  background: #000;
  display: flex;
  justify-content: center;
  align-item: center;
  height: 50rem;
  position: relative;
  z-index: 1;

  @media screen and (max-width: 1680px) {
    height: 47.5rem;
  }
  @media screen and (max-width: 1440px) {
    height: 40.5rem;
  }
  @media screen and (max-width: 1280px) {
    height: 37.5rem;
  }
  @media screen and (max-width: 980px) {
  }
  @media screen and (max-width: 736px) {
  }
  @media screen and (max-width: 480px) {
    height: 37.5rem;
  }
`;

export const HeroContent = styled.div`
  height: 100%;
  width: 100%;
`;

export const HeroH1 = styled.p`
  color: #fff;
  display: flex;
  font-size: 42px;
  text-align: center;
  position: relative;
  font-weight: 300;
  justify-content: flex-start;
  text-transform: uppercase;
  top: 1rem;
  margin: 0;

  @media screen and (max-width: 1680px) {
  }
  @media screen and (max-width: 1280px) {
  }

  @media screen and (max-width: 980px) {
    font-size: 36px;
  }
  @media screen and (max-width: 480px) {
  }
`;

export const HeroH1Black = styled.p`
  color: #000;
  display: flex;
  font-size: 42px;
  text-align: center;
  position: relative;
  font-weight: 300;
  justify-content: flex-start;
  text-transform: uppercase;
  top: 1rem;
  margin: 0;

  @media screen and (max-width: 1680px) {
    max-width: 25rem;
    top: ${(props) => (props.eng ? "1rem" : "3.25rem")};
  }
  @media screen and (max-width: 1280px) {
  }

  @media screen and (max-width: 980px) {
    font-size: 36px;
  }
  @media screen and (max-width: 480px) {
    color: #000;
    font-size: 30px;
  }
`;

export const HeroH1Beyond = styled.p`
  color: #fff;
  display: flex;
  font-size: 42px;
  text-align: center;
  position: relative;
  font-weight: 300;
  justify-content: flex-start;
  text-transform: uppercase;
  top: 1rem;
  margin: 0;

  @media screen and (max-width: 1680px) {
  }
  @media screen and (max-width: 1280px) {
  }

  @media screen and (max-width: 980px) {
    font-size: 36px;
  }
  @media screen and (max-width: 480px) {
    color: #000;
  }
`;

export const HeroBeyonP = styled.p`
  display: flex;
  color: #fff;
  font-size: 24px;
  position: relative;
  text-align: right;
  font-weight: 300;
  max-width: 400px;
  margin-bottom: 0;

  @media screen and (max-width: 1680px) {
  }
  @media screen and (max-width: 1280px) {
  }
  @media screen and (max-width: 980px) {
    font-size: 20px;
  }
  @media screen and (max-width: 480px) {
    justify-content: center;
    color: #000;
  }
`;

export const HeroH1Bio = styled.p`
  color: #fff;
  display: flex;
  font-size: 42px;
  text-align: left;
  position: relative;
  font-weight: 300;
  text-transform: uppercase;
  margin-bottom: 0;
  width: 20rem;
  top: -3rem;

  @media screen and (max-width: 1680px) {
    top: 0rem;
  }
  @media screen and (max-width: 1440px) {
    top: -2rem;
  }
  @media screen and (max-width: 1366px) {
    top: -1rem;
  }
  @media screen and (max-width: 1024px) {
    top: 3rem;
  }
  @media screen and (max-width: 980px) {
    top: 7rem;
  }
  @media screen and (max-width: 480px) {
    top: -1rem;
    font-size: 36px;
    width: unset;
    text-align: right;
  }
`;

export const HeroPBio = styled.p`
  display: flex;
  color: #fff;
  font-size: 20px;
  position: relative;
  text-align: left;
  top: 1rem;
  font-weight: 500;
  width: 20rem;

  @media screen and (max-width: 1680px) {
  }
  @media screen and (max-width: 1280px) {
  }
  @media screen and (max-width: 1024px) {
    font-size: 16px;
    line-height: 1.3em !important;
  }
  @media screen and (max-width: 980px) {
    font-size: 16px;
  }
  @media screen and (max-width: 480px) {
    font-size: 16px;
    line-height: 1.4285em !important;
    width: unset;
    max-width: 25rem;
    text-align: right;
  }
`;

export const HeroH2 = styled.h1`
  margin-top: 0.5rem;
  color: #000;
  display: flex;
  font-size: 20px;
  text-align: center;

  font-family: playfairDisplay !important;

  @media screen and (max-width: 768px) {
    font-size: 20px;
  }
`;

export const HeroP = styled.p`
  margin-top: 2.5rem;
  display: flex;
  color: #000;
  font-size: 20px;
  text-align: center;
  max-width: 50%;
  font-style: italic;
  font-family: playfairDisplay !important;

  @media screen and (max-width: 768px) {
    font-size: 20px;
  }
`;

export const HeroBtnWrapper = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 60.9375vw;
  max-width: 1140px;
  justify-content: flex-start;
  display: flex;

  @media screen and (max-width: 1280px) {
    width: 88.88vw;
    max-width: unset;
  }

  @media screen and (max-width: 480px) {
  }
`;

export const HeroBtnWrapperBeyond = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 60.9375vw;
  max-width: 1140px;
  justify-content: flex-end;
  display: flex;

  @media screen and (max-width: 1280px) {
    width: 88.88vw;
    max-width: unset;
  }
  @media screen and (max-width: 980px) {
  }

  @media screen and (max-width: 480px) {
    justify-content: center;
  }
`;

export const HeroBtnWrapperBio = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 60.9375vw;
  max-width: 1140px;
  justify-content: flex-end;
  display: flex;

  @media screen and (max-width: 1280px) {
    width: 88.88vw;
    max-width: unset;
  }
  @media screen and (max-width: 980px) {
  }

  @media screen and (max-width: 480px) {
  }
`;

export const HeroBtnWrapperTeacher = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media screen and (max-width: 1680px) {
    align-items: flex-start;
  }
  @media screen and (max-width: 1280px) {
    align-items: flex-start;
  }

  @media screen and (max-width: 980px) {
    align-items: flex-start;
  }

  @media screen and (max-width: 480px) {
  }
`;

export const HeroBtnWrapperSolo = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 60.9375vw;
  max-width: 1140px;
  justify-content: flex-start;
  display: flex;

  @media screen and (max-width: 1280px) {
    width: 88.88vw;
    max-width: unset;
  }

  @media screen and (max-width: 480px) {
  }
`;

export const HeroH1Teacher = styled.h1`
  color: #000;
  display: flex;
  font-size: 42px;
  text-align: center;
  position: relative;
  font-weight: 300;
  text-transform: uppercase;
  top: 1rem;
  font-family: Roboto;

  @media screen and (max-width: 1680px) {
  }
  @media screen and (max-width: 1280px) {
  }

  @media screen and (max-width: 980px) {
    top: ${(props) => (props.eng ? "1rem" : "3.25rem")};
    font-size: 36px;
  }
`;

export const HeroPTeacher = styled.p`
  color: #000;
  display: flex;
  font-size: 24px;
  text-align: left;
  position: relative;
  font-weight: 300;
  max-width: 25rem;

  @media screen and (max-width: 1680px) {
    font-weight: 100;
    max-width: 20rem;
  }

  @media screen and (max-width: 1280px) {
    max-width: 25rem;
  }

  @media screen and (max-width: 980px) {
    font-size: 22px;
  }
`;

export const HeroH1Solo = styled.p`
  color: #fff;
  display: flex;
  font-size: 42px;
  text-align: center;
  position: relative;
  font-weight: 300;
  justify-content: flex-end;
  text-transform: uppercase;
  top: 1rem;
  margin: 0;

  @media screen and (max-width: 1680px) {
  }
  @media screen and (max-width: 1280px) {
  }

  @media screen and (max-width: 980px) {
    font-size: 36px;
  }
`;

export const HeroH1Doc = styled.p`
  color: #000;
  display: flex;
  font-size: 42px;
  text-align: right;
  position: relative;
  font-weight: 300;
  justify-content: flex-end;
  text-transform: uppercase;
  top: ${(props) => (props.eng ? "2.5rem" : "7rem")};
  margin: 0;
  max-width: 40rem;

  @media screen and (max-width: 1680px) {
    top: ${(props) => (props.eng ? "1rem" : "5rem")};
  }
  @media screen and (max-width: 1440px) {
    top: ${(props) => (props.eng ? "1.5rem" : "6rem")};
  }
  @media screen and (max-width: 1281px) {
    top: ${(props) => (props.eng ? "1rem" : "5rem")};
  }
  @media screen and (max-width: 1024px) {
    top: ${(props) => (props.eng ? "1rem" : "5rem")};
  }

  @media screen and (max-width: 980px) {
    font-size: 28px;
    justify-content: flex-end;
    max-width: 30rem;
    text-align: right;
    top: ${(props) => (props.eng ? "1rem" : "4rem")};
  }
  @media screen and (max-width: 480px) {
    text-align: right;
    font-size: 22px;
    max-width: 20rem;
    top: ${(props) => (props.eng ? "-1.25rem" : "1rem")};
  }
`;

export const HeroPDoc = styled.p`
  display: flex;
  color: #000;
  font-size: 24px;
  position: relative;
  text-align: right;
  font-weight: 300;
  max-width: 400px;
  margin-bottom: 0;
  top: ${(props) => (props.eng ? "2rem" : "6rem")};

  @media screen and (max-width: 1680px) {
    top: ${(props) => (props.eng ? "0rem" : "3rem")};
  }
  @media screen and (max-width: 1440px) {
    top: ${(props) => (props.eng ? "0rem" : "5rem")};
  }
  @media screen and (max-width: 1280px) {
    top: ${(props) => (props.eng ? "0rem" : "2rem")};
  }
  @media screen and (max-width: 980px) {
    font-size: 20px;
    justify-content: flex-end;
    text-align: right;
    max-width: 350px;
  }
`;

export const HeroP2Doc = styled.p`
  display: flex;
  color: #000;
  font-size: 18px;
  position: relative;
  text-align: right;
  font-weight: 300;
  width: 30rem;
  top: ${(props) => (props.eng ? "2rem" : "6rem")};

  @media screen and (max-width: 1680px) {
    top: ${(props) => (props.eng ? "0rem" : "3rem")};
  }
  @media screen and (max-width: 1440px) {
    top: ${(props) => (props.eng ? "0rem" : "5rem")};
  }
  @media screen and (max-width: 1280px) {
    top: ${(props) => (props.eng ? "0rem" : "2rem")};
  }
  @media screen and (max-width: 480px) {
    justify-content: flex-end;
    text-align: right;
  }
`;

export const HeroH1Orch = styled.p`
  color: #fff;
  display: flex;
  position: relative;
  font-size: 42px;
  top: 1rem;
  font-weight: 300;
  text-transform: uppercase;
  justify-content: flex-end;
  text-align: right;

  @media screen and (max-width: 1680px) {
  }
  @media screen and (max-width: 1440px) {
    top: 2rem;
  }
  @media screen and (max-width: 1280px) {
    top: 1rem;
  }

  @media screen and (max-width: 480px) {
    top: ${(props) => (props.eng ? "5rem" : "1rem")};
    font-size: 36px;
    position: relative;
  }
`;

export const HeroPositionContainerChamber = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 60.9375vw;
  height: 100%;
  max-width: 1140px;
  justify-content: flex-end;
  display: grid;
  grid-template-rows: 15% 80%;
  align-items: end;

  @media screen and (max-width: 1280px) {
    width: 88.88vw;
    max-width: unset;
    grid-template-rows: 20% 75%;
  }
  @media screen and (max-width: 980px) {
    grid-template-rows: 30% 65%;
  }
  @media screen and (max-width: 480px) {
    width: 87.5%;
    grid-template-rows: 10% 85%;
  }
`;

export const HeroPositionContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 60.9375vw;
  height: 100%;
  max-width: 1140px;
  justify-content: flex-end;
  display: grid;
  grid-template-rows: 15% 80%;
  align-items: end;

  @media screen and (max-width: 1280px) {
    width: 88.88vw;
    max-width: unset;
    grid-template-rows: 20% 75%;
  }
  @media screen and (max-width: 980px) {
    grid-template-rows: 30% 65%;
  }
  @media screen and (max-width: 480px) {
    grid-template-rows: 15% 80%;
  }
`;

export const HeroPositionContainerTeacher = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 60.9375vw;
  height: 100%;
  max-width: 1140px;
  justify-content: flex-start;
  display: grid;
  grid-template-rows: 5rem 5rem 3rem;

  @media screen and (max-width: 1680px) {
    grid-template-rows: 5rem 8rem 3rem;
  }

  @media screen and (max-width: 1280px) {
    width: 88.88vw;
    max-width: unset;
  }

  @media screen and (max-width: 980px) {
    grid-template-rows: 50% 35% 5%;
    align-items: end;
  }

  @media screen and (max-width: 480px) {
    grid-template-rows: 67.5% 22.5% 5%;
    font-size: 36px;
  }
`;

export const HeroPositionContainerBio = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 60.9375vw;
  height: 100%;
  max-width: 1140px;
  display: grid;
  grid-template-rows: 20% 40% 6%;
  align-items: end;

  @media screen and (max-width: 1680px) {
    grid-template-rows: 20% 47.5% 7.5%;
  }
  @media screen and (max-width: 1600px) {
    grid-template-rows: 20% 50% 7.5%;
  }
  @media screen and (max-width: 1440px) {
    grid-template-rows: 20% 52.5% 7.5%;
  }
  @media screen and (max-width: 1366px) {
    grid-template-rows: 20% 57.5% 7.5%;
  }
  @media screen and (max-width: 1280px) {
    width: 88.88vw;
    max-width: unset;
    grid-template-rows: 20% 60% 10%;
  }

  @media screen and (max-width: 1024px) {
    grid-template-rows: 20% 47.5% 12.5%;
  }

  @media screen and (max-width: 980px) {
    grid-template-rows: 20% 62.5% 12.5%;
  }

  @media screen and (max-width: 480px) {
    grid-template-rows: 60% 29% 8%;
  }
`;

export const HeroPositionContainerSolo = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 60.9375vw;
  height: 100%;
  max-width: 1140px;
  justify-content: flex-end;
  display: grid;
  grid-template-rows: 10% 15% 5% 65%;
  align-items: end;

  @media screen and (max-width: 1280px) {
    width: 88.88vw;
    max-width: unset;
    grid-template-rows: 15% 25% 10% 45%;
  }

  @media screen and (max-width: 980px) {
    grid-template-rows: 15% 25% 10% 45%;
  }

  @media screen and (max-width: 480px) {
    grid-template-rows: 10% 75% 5% 5%;
  }
`;

export const HeroPositionContainerEvents = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 60.9375vw;
  max-width: 1140px;
  height: 100%;
  justify-content: flex-end;
  display: grid;
  grid-template-rows: 4rem 4.5rem;
  align-items: end;

  @media screen and (max-width: 1280px) {
    width: 88.88vw;
    max-width: unset;
    grid-template-rows: 4rem 4.5rem;
  }
  @media screen and (max-width: 1024px) {
    grid-template-rows: 4rem 4.5rem;
  }

  @media screen and (max-width: 980px) {
    grid-template-rows: 4rem 3.5rem;
  }

  @media screen and (max-width: 480px) {
    grid-template-rows: 85% 10%;
  }
`;

export const HeroPositionContainerDoc = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 60.9375vw;
  height: 100%;
  max-width: 1140px;
  justify-content: flex-end;
  display: grid;
  grid-template-rows: 15% 12.5% 5%;
  align-items: end;

  @media screen and (max-width: 1680px) {
    grid-template-rows: 20% 17.5% 6%;
  }

  @media screen and (max-width: 1280px) {
    width: 88.88vw;
    max-width: unset;
    grid-template-rows: 25% 22.5% 7.5%;
  }

  @media screen and (max-width: 980px) {
    grid-template-rows: 25% 30% 12.5%;
  }

  @media screen and (max-width: 480px) {
    grid-template-rows: 20% 65% 7.5%;
  }
`;

export const HeroPositionContainerBeyond = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 60.9375vw;
  height: 100%;
  max-width: 1140px;
  justify-content: flex-end;
  display: grid;
  grid-template-rows: 10% 7.5% 80%;
  align-items: end;

  @media screen and (max-width: 1280px) {
    width: 88.88vw;
    max-width: unset;
    grid-template-rows: 10% 7.5% 80%;
  }

  @media screen and (max-width: 980px) {
    grid-template-rows: 12.5% 10% 72.5%;
  }

  @media screen and (max-width: 480px) {
    grid-template-rows: 7.5% 82.5% 9%;
  }
`;

export const HeroPositionContainerContemp = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 60.9375vw;
  height: 100%;
  max-width: 1140px;
  justify-content: flex-end;
  display: grid;
  grid-template-rows: 10%;
  align-items: end;

  @media screen and (max-width: 1280px) {
    width: 88.88vw;
    max-width: unset;
    grid-template-rows: 10%;
  }

  @media screen and (max-width: 980px) {
    grid-template-rows: 12.5%;
  }

  @media screen and (max-width: 480px) {
    grid-template-rows: 85%;
    justify-content: center;
  }
`;

export const HeroPositionContainerBaroque = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 60.9375vw;
  height: 100%;
  max-width: 1140px;
  justify-content: flex-start;
  display: grid;
  grid-template-rows: 10%;
  align-items: end;

  @media screen and (max-width: 1680px) {
    grid-template-rows: 22.5%;
  }
  @media screen and (max-width: 1280px) {
    width: 88.88vw;
    max-width: unset;
  }

  @media screen and (max-width: 980px) {
    grid-template-rows: 30%;
  }

  @media screen and (max-width: 480px) {
    grid-template-rows: 97.5%;
  }
`;

export const HeroH1Chamber = styled.p`
  color: #fff;
  display: flex;
  position: relative;
  font-size: 42px;
  top: 1rem;
  font-weight: 300;
  text-transform: uppercase;
  justify-content: flex-end;
  text-align: right;

  @media screen and (max-width: 1680px) {
  }
  @media screen and (max-width: 1440px) {
    top: 2rem;
  }
  @media screen and (max-width: 1280px) {
    top: 1rem;
  }

  @media screen and (max-width: 480px) {
    font-size: 28px;
    position: relative;
  }
`;

export const HeroPContainerRight = styled.p`
  display: flex;
  justify-content: flex-end;
`;

export const HeroPSolo = styled.p`
  display: flex;
  color: #fff;
  font-size: 24px;
  position: relative;
  text-align: right;
  font-weight: 300;
  max-width: 400px;
  margin-bottom: 0;

  @media screen and (max-width: 1680px) {
  }
  @media screen and (max-width: 1280px) {
  }
  @media screen and (max-width: 980px) {
    font-size: 20px;
  }
  @media screen and (max-width: 480px) {
  }
`;

export const HeroP2Solo = styled.p`
  display: flex;
  color: #fff;
  font-size: 20px;
  position: relative;
  text-align: right;
  font-weight: 300;

  @media screen and (max-width: 1680px) {
  }
  @media screen and (max-width: 1280px) {
  }
  @media screen and (max-width: 480px) {
  }
`;
