import React, { useState } from "react";
import PlayerCard from "../PlayerCard"; // Import the common PlayerCard component
import antonioCover from "../../../albums/antonio_cover.png";
import antonio_song1 from "../../../music/antonio-song1.mp3";
import antonio_song2 from "../../../music/antonio-song2.mp3";
import antonio_song3 from "../../../music/antonio-song3.mp3";

const Antonio = {
  cover: antonioCover,
  songs: [
    {
      name: "Serenata op. 16 - I.",
      subtitle: "Noémi Győri, Katalin Koltai",
      mp3: antonio_song1,
    },
    {
      name: "Serenata op. 16 - II.",
      subtitle: "Noémi Győri, Katalin Koltai",
      mp3: antonio_song2,
    },
    {
      name: "Serenata op. 63 - VII.",
      subtitle: "Noémi Győri, Katalin Koltai",
      mp3: antonio_song3,
    },
  ],
};

export const AntonioAlbum = () => {
  const [language, setLanguage] = useState({ eng: "true" }); // Replace with your language context

  return (
    <PlayerCard
      cover={Antonio.cover}
      songs={Antonio.songs}
      buyLink="https://music.apple.com/gb/album/flute-and-guitar-duos/466596979"
      language={language}
    />
  );
};
