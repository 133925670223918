import React, { useState, createContext } from "react";

export const FileContext = createContext();

export const FileProvider = (props) => {
  const [files, setFiles] = useState([
    {
      id: "db_not_connected",
      title: "Database not connected",
      link: "home",
    },
  ]);
  return (
    <FileContext.Provider value={[files, setFiles]}>
      {props.children}
    </FileContext.Provider>
  );
};
