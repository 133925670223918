import http from "./http-auth";

export function InsertItem(data) {
  return http.post("/languages", data).then((res) => {});
}

export function DeleteItem(data) {
  return http.delete(`/languages/${data}`).then((res) => {});
}

export function UpdateItem(data) {
  return http.put(`/languages/${data.id}`, data).then((res) => {});
}

export function GetItem(data) {
  return http.get(`/languages/${data.id}`, data).then((res) => {});
}

export const GetAllItems = () => {
  return http.get("/languages");
};
