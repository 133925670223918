import styled from "styled-components";

export const CardContainer = styled.div`
  max-height: auto;
  min-height: 80vh;
  display: flex;
  padding-top: 1%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fff;
  font-family: playfairDisplay !important;
  padding-top: 5rem;

  @media screen and (max-width: 1680px) {
  }

  @media screen and (max-width: 480px) {
    width: 100%;
    max-height: none;
  }
`;

export const CardWrapper = styled.div`
  height: 100%;
  width: 80%;
  display: flex;
  transition: all 0.2s ease-in-out;
  z-index: 1;

  @media screen and (max-width: 1680px) {
    width: 100%;
  }

  @media screen and (max-width: 480px) {
    width: 100%;
  }
`;

export const Card = styled.div`
  display: grid;
  background: #fff;
  height: 100%;
  width: 75%;
  margin-left: 12.5%;

  @media screen and (max-width: 1680px) {
    margin-right: 5%;
    margin-left: 5%;
    width: 100%;
  }
  @media screen and (max-width: 480px) {
    width: 100%;
  }
`;

export const GalleryShowImage = styled.img`
  width: 50%;
  display: block;
  margin-left: auto;
  margin-right: auto;

  @media screen and (max-width: 980px) {
    width: 100%;
  }
`;

export const GalleryShowText = styled.p`
  font-family: Roboto;
  margin-top: 2rem;
  padding-bottom: 2rem;
  font-size: 1.5rem;
  display: flex;
  text-align: center;
`;
