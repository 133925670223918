import React from "react";
import "./App.css";
import "react-notifications/lib/notifications.css";
import "react-quill/dist/quill.snow.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./pages/Home";
import Admin from "./pages/Admin";
import SigninPage from "./pages/SigninPage";
import Events from "./pages/Events";
import Blog from "./pages/Blog";
import News from "./pages/News";
import EventPage from "./pages/EventPage";
import BlogPage from "./pages/BlogPage";
import SpecPage from "./pages/SpecialitiesPage";
import Soloist from "./pages/AboutMe/soloist";
import Teacher from "./pages/AboutMe/teacher";
import Chamber from "./pages/AboutMe/chamber";
import Flutist from "./pages/AboutMe/flutist";
import Videos from "./pages/Media/Videos";
import Interviews from "./pages/Media/Interviews";
import Reviews from "./pages/Media/Reviews";
import Imagetest from "./pages/ImageTest";
import Press from "./pages/Media/Press";
import ProgramProp from "./pages/Media/Programs";
import Shop from "./pages/Shop";
import Gallery from "./pages/Gallery";
import Specialities from "./pages/Specialities";
import AboutPage from "./pages/AboutPage";
import QuillPage from "./pages/Quill";
import InterviewAdminPage from "./pages/Admin/Interviews";
import ReviewAdminPage from "./pages/Admin/Reviews";
import VideoAdminPage from "./pages/Admin/Videos";
import PressAdminPage from "./pages/Admin/Press";
import ShopAdminPage from "./pages/Admin/Shop";
import ProgramAdminPage from "./pages/Admin/Programs";
import AboutAdminPage from "./pages/Admin/Home/About";
import CarouselAdminPage from "./pages/Admin/Home/Carousel";
import HomeCardAdminPage from "./pages/Admin/Home/HomeCard";
import LanguageAdminPage from "./pages/Admin/Language";
import ImageAdminPage from "./pages/Admin/Images";
import CarouselQuoteAdminPage from "./pages/Admin/Home/CarouselQuote";
import MediaAdminPage from "./pages/Admin/Home/Media";
import InstagramAdminPage from "./pages/Admin/Home/Instagram";
import BlogAdminPage from "./pages/Admin/Blog";
import FileAdminPage from "./pages/Admin/Files";
import SpecAdminPage from "./pages/Admin/Specialitites";
import NewsAdminPage from "./pages/Admin/News";
import GalleryAdminPage from "./pages/Admin/Gallery";
import BioAdminPage from "./pages/Admin/Bio";
import BioPage from "./pages/Bio";
import AboutPageAdminPage from "./pages/Admin/AboutPage";
import { LanguageProvider } from "./components/LanguageContext";
import SubscribersAdminPage from "./pages/Admin/Subscribers";
import GalleryShowIndex from "./pages/Gallery/GalleryShowIndex";
import ForgotPasswordPage from "./pages/SigninPage/forgotPassword";
import { Private } from "./components/Signin/NewSingin/PrivateRoute";
import { AuthProvider } from "./auth/AuthContext";

function App() {
  const thesis =
    "https://firebasestorage.googleapis.com/v0/b/noemigyori.appspot.com/o/Files%2FNoemi_Gyori_Thesis.pdf?alt=media&token=1847d133-e422-4d6d-bbf8-7bd89d1335bd";

  if (localStorage.getItem("eng") === null) {
    localStorage.setItem("eng", true);
  }
  return (
    <LanguageProvider>
      <AuthProvider>
        <Router>
          <Switch>
            <Route path="/" exact component={Home} />
            <Private path="/admin" exact component={Admin} />
            <Private path="/admin/events" exact component={Admin} />
            <Private
              path="/admin/interviews"
              exact
              component={InterviewAdminPage}
            />
            <Private path="/admin/reviews" exact component={ReviewAdminPage} />
            <Private path="/admin/videos" exact component={VideoAdminPage} />
            <Private path="/admin/press" exact component={PressAdminPage} />
            <Private
              path="/admin/programs"
              exact
              component={ProgramAdminPage}
            />
            <Private path="/admin/abouts" exact component={AboutAdminPage} />
            <Private
              path="/admin/carousels"
              exact
              component={CarouselAdminPage}
            />
            <Private
              path="/admin/homecards"
              exact
              component={HomeCardAdminPage}
            />
            <Private path="/admin/shops" exact component={ShopAdminPage} />
            <Private
              path="/admin/language"
              exact
              component={LanguageAdminPage}
            />
            <Private path="/admin/images" exact component={ImageAdminPage} />
            <Private path="/admin/media" exact component={MediaAdminPage} />
            <Private
              path="/admin/instagram"
              exact
              component={InstagramAdminPage}
            />
            <Private path="/admin/blog" exact component={BlogAdminPage} />
            <Private path="/admin/bio" exact component={BioAdminPage} />
            <Private path="/admin/gallery" exact component={GalleryAdminPage} />
            <Private path="/admin/files" exact component={FileAdminPage} />
            <Private
              path="/admin/specialities"
              exact
              component={SpecAdminPage}
            />
            <Private
              path="/admin/subscribers"
              exact
              component={SubscribersAdminPage}
            />
            <Private
              path="/admin/aboutpages"
              exact
              component={AboutPageAdminPage}
            />
            <Private
              path="/admin/carouselquotes"
              exact
              component={CarouselQuoteAdminPage}
            />
            <Route path="/login" exact component={SigninPage} />
            <Route
              path="/forgot-password"
              exact
              component={ForgotPasswordPage}
            />
            <Route path="/events" exact component={Events} />
            <Route path="/blog" exact component={Blog} />
            <Route path="/videos" exact component={Videos} />
            <Route path="/interviews" exact component={Interviews} />
            <Route path="/reviews" exact component={Reviews} />
            <Route path="/press" exact component={Press} />
            <Route path="/shop" exact component={Shop} />
            <Route path="/gallery" exact component={Gallery} />
            <Route path="/specialities" exact component={Specialities} />
            <Route path="/programproposals" exact component={ProgramProp} />
            <Route path="/bio" exact component={BioPage} />
            <Route path="/events/:eventId" exact component={EventPage} />
            <Route path="/blog/:eventId" exact component={BlogPage} />
            <Route
              path="/gallery/:imageId"
              exact
              component={GalleryShowIndex}
            />
            <Route path="/szolista" exact component={AboutPage} />
            <Route path="/the-soloist" exact component={AboutPage} />
            <Route path="/kamarazenesz" exact component={AboutPage} />
            <Route path="/the-chamber-musician" exact component={AboutPage} />
            <Route path="/zenekari-fuvolas" exact component={AboutPage} />
            <Route path="/the-orchestral-flutist" exact component={AboutPage} />
            <Route path="/tanar" exact component={AboutPage} />
            <Route path="/the-flute-teacher" exact component={AboutPage} />
            <Route path="/doktori-tanulmanyok" exact component={SpecPage} />
            <Route path="/doctoralstudies" exact component={SpecPage} />
            <Route path="/kortars-zene" exact component={SpecPage} />
            <Route path="/contemporary-music" exact component={SpecPage} />
            <Route path="/a-zenen-is-tul" exact component={SpecPage} />
            <Route path="/beyond-music" exact component={SpecPage} />
            <Route path="/korhu-hangszerek" exact component={SpecPage} />
            <Route path="/period-flute" exact component={SpecPage} />
            <Route path="/thesis" render={() => (window.location = thesis)} />
            <Route path="/tezis" render={() => (window.location = thesis)} />
          </Switch>
        </Router>
      </AuthProvider>
    </LanguageProvider>
  );
}

export default App;
// <Route path="/news" exact component={News} />
// <Route path="/quill" exact component={QuillPage} />
//<Route path="/admin/news" exact component={NewsAdminPage} />;
