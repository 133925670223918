import styled from "styled-components";

export const ReviewBox = styled.div`
  display: flex;
  justify-content: center;
  background: #272733;
  width: 100%;
  height: auto;
  padding-top: 1.5rem;
  padding-bottom: 1rem;
  @media screen and (max-width: 768px) {
    width: 100%;
    max-height: none;
  }
`;

export const ReviewWrap = styled.div`
  display: grid;
  background: #fff;
  justify-items: center;
  margin-left: auto;
  margin-right: auto;
  width: 60.9375vw;
  max-width: 1140px;
  padding: 1rem;
  padding-bottom: 2rem;
  transition: all 0.2s ease-in-out;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);

  &:hover {
    transform: scale(1.04);
    transition: all 0.2s ease-in-out;
  }

    @media screen and (max-width: 1280px) {
      width: 88.88vw;    
      &:hover {
        transform: scale(1);
        transition: all 0.2s ease-in-out;
      }
    }
    @media screen and (max-width: 980px) {

    }
    @media screen and (max-width: 480px) {
      width: 100%;
    }
  }
`;

export const ButtonWrap = styled.div`
  margin-top: 0;
  @media screen and (max-width: 768px) {
    margin-top: 1rem;
  }
`;

export const Text = styled.p`
  padding: 0.5rem 0.1rem 0.1rem 0.5rem;
  font-size: 18px;
  font-style: italic;
  color: #000;
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
  margin-bottom: 0;
`;

export const TextP = styled.p`
  padding: 0.5rem 0.1rem 0.1rem 0.5rem !important;
  font-size: 14px;
  color: #000;
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
  padding-bottom: 0 !important;
  margin-bottom: 1rem !important;
`;

export const HeroText = styled.p`
  color: #fff;
  display: flex;
  position: relative;
  font-size: 50px;
  top: 1.5rem;
  font-weight: 300;
  text-transform: uppercase;
  justify-content: flex-end;
  text-align: right;

  @media screen and (max-width: 1680px) {
  }
  @media screen and (max-width: 1440px) {
    top: 2.5rem;
  }
  @media screen and (max-width: 1280px) {
  }

  @media screen and (max-width: 480px) {
    font-size: 36px;
    position: relative;
  }
`;
