import styled from "styled-components";

export const UploadContainer = styled.div`
  display: flex;
  background-color: #fff;
  width: 50;
`;

export const Text = styled.p`
  padding: 0.5rem 0.1rem 0.1rem 0.5rem;
  font-size: 16px;
`;
