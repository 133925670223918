import { React, useState, useEffect, useContext } from "react";
import PageHeader from "../../../components/Admin/PageHeader";
import EventIcon from "@material-ui/icons/Event";
import AddIcon from "@material-ui/icons/Add";
import Controls from "../controls/Controls";
import Popup from "../Popup";
import useTable from "../useTable";
import * as service from "../services/subscribersService";
import { Close, EditOutlined, Search } from "@material-ui/icons";
import {
  InputAdornment,
  makeStyles,
  Paper,
  TableBody,
  TableCell,
  TableRow,
  Toolbar,
} from "@material-ui/core";

import Notification from "../controls/Notification";
import ConfirmDialog from "../controls/ConfirmDialog";
import { useStylesPaper } from "../theme.js";

const headCells = [
  { id: "email", label: "Email" },
  { id: "actions", label: "Actions", disableSorting: true },
];

const Subscribers = () => {
  const [subscribers, setSubscribers] = useState([]);
  const classes = useStylesPaper();
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [openPopup, setOpenPopup] = useState(false);
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });

  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
    useTable(subscribers, headCells, filterFn);

  const handleSearch = (e) => {
    let target = e.target;
    setFilterFn({
      fn: (items) => {
        if (target.value === "") return items;
        else
          return items.filter((x) =>
            x.email.toLowerCase().includes(target.value)
          );
      },
    });
  };

  const onDelete = async (id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    await service.DeleteItem(id);
    setNotify({
      isOpen: true,
      message: "Deleted Successfully",
      type: "error",
    });
    window.location.reload();
  };

  useEffect(() => {
    (async () => {
      const getItems = () => {
        let get = service.GetAllItems();
        get.then((res) => {
          let data = res.data;
          setSubscribers(data);
        });
      };
      getItems();
    })();
  }, [setSubscribers]);

  return (
    <>
      <PageHeader
        title="Subscribers"
        subtitle="Manage subscribers"
        icon={<EventIcon />}
      ></PageHeader>
      <Paper className={classes.pageContent}>
        <Toolbar>
          <Controls.Input
            label="Search Subscribers"
            className={classes.searchInput}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            onChange={handleSearch}
          ></Controls.Input>
        </Toolbar>
        <TblPagination></TblPagination>
        <TblContainer>
          <TblHead></TblHead>
          <TableBody>
            {recordsAfterPagingAndSorting().map((item) => (
              <TableRow key={item.id}>
                <TableCell className={classes.narrowCell}>
                  {item.email}
                </TableCell>

                <TableCell>
                  <Controls.ActionButton
                    color="secondary"
                    onClick={() => {
                      setConfirmDialog({
                        isOpen: true,
                        title: "Are you sure to delete this record?",
                        subTitle: "You can't undo this operation",
                        onConfirm: () => {
                          onDelete(item.id);
                        },
                      });
                    }}
                  >
                    <Close fontSize="small"></Close>
                  </Controls.ActionButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </TblContainer>
      </Paper>
      <Notification notify={notify} setNotify={setNotify}></Notification>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
};

export default Subscribers;
