import React, { useContext, useEffect, useState } from "react";
import {
  ContactContainer,
  ContactH1,
  ContactP,
  ContactWrapper,
  FormContainer,
  ButtonContainer,
  TextSentContainer,
  TextSent,
} from "./ContactElements";
import { TextArea, Button, Form } from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
import { LanguageContext } from "../LanguageContext";
import { sendMailtoNoemi } from "../../pages/Admin/services/emailService";
const ContactSection = (props) => {
  const [language, setLanguage] = useContext(LanguageContext);
  const [sent, setSent] = useState(false);
  const [invalid, setInvalid] = useState(false);
  const [form, setForm] = useState({
    name: "",
    subject: "",
    message: "",
    from: "",
  });

  const onChange = (e, { name, value }) => {
    setForm({ ...form, [name]: value });
  };

  const sendMail = () => {
    sendMailtoNoemi(form);
    setSent(true);
    setForm({
      name: "",
      subject: "",
      message: "",
      from: "",
    });
  };

  useEffect(() => {
    (async () => {})();
  }, []);

  const bgcolor = props.bgcolor;
  const textcolor = props.textcolor;
  const green = props.green;

  let Contact;
  let ContactText;

  if (props.myContact) {
    Contact = language[0].eng === "true" ? "MY CONTACT" : "Kapcsolat";
  } else {
    Contact =
      language[0].eng === "true"
        ? "MANAGER CONTACT"
        : "KAPCSOLAT A MENEDZSEREMMEL";
  }

  if (props.myDescription) {
    ContactText =
      language[0].eng === "true"
        ? "I would be glad to hear from you! Don't hesitate to get in touch with me!"
        : "Örülök, ha hallok felőled. Itt tudsz kapcsolatba lépni velem. Írj bátran!";
  } else {
    ContactText =
      language[0].eng === "true"
        ? "Would you like to get in touch with my manager, Hedi Somogyi? You can contact her directly here +36 70 339 0127 or send her a message right away!"
        : "Szeretnél kapcsolatba lépni Somogyi Hédivel, a menedzseremmel? +36-70-339-0127 vagy írj neki üzenetet itt!";
  }

  return (
    <ContactContainer
      style={{ background: `url(${bgcolor})`, color: textcolor }}
      id="contact"
    >
      <ContactWrapper>
        <ContactH1 style={{ color: textcolor }}>{Contact}</ContactH1>
        <ContactP style={{ color: textcolor }}>{ContactText}</ContactP>
        <FormContainer>
          <Form>
            <Form.Field>
              <label style={{ color: textcolor }}>
                {language[0].eng === "true" ? "Your Name" : "A neved"}
              </label>
              <Form.Input
                onChange={onChange}
                value={form.name || ""}
                name="name"
                placeholder={
                  language[0].eng === "true" ? "Your Name" : "A neved"
                }
              />
            </Form.Field>
            <Form.Field>
              <label style={{ color: textcolor }}>
                {" "}
                {language[0].eng === "true" ? "Your Email" : "Az Email címed"}
              </label>
              <Form.Input
                onChange={onChange}
                value={form.from || ""}
                name="from"
                placeholder={
                  language[0].eng === "true" ? "Your Email" : "Az Email címed"
                }
              />
            </Form.Field>
          </Form>
          <Form>
            <Form.Field>
              <label style={{ color: textcolor }}>
                {" "}
                {language[0].eng === "true" ? "Subject" : "Tárgy"}
              </label>
              <Form.Input
                onChange={onChange}
                value={form.subject || ""}
                name="subject"
                placeholder={language[0].eng === "true" ? "Subject" : "Tárgy"}
              />
            </Form.Field>
            <Form.Field
              placeholder={language[0].eng === "true" ? "Message" : "Üzenet"}
            >
              <Form.Input
                control={TextArea}
                onChange={onChange}
                value={form.message || ""}
                name="message"
                placeholder={language[0].eng === "true" ? "Message" : "Üzenet"}
              />
            </Form.Field>
          </Form>
        </FormContainer>
        <ButtonContainer>
          <Button
            color="black"
            onClick={() => {
              if (
                form.name != "" &&
                form.name != null &&
                form.from != "" &&
                form.from != null &&
                form.subject != "" &&
                form.subject != null &&
                form.message != "" &&
                form.message != null
              ) {
                setInvalid(false);
                sendMail();
              } else {
                setSent(false);
                setInvalid(true);
              }
            }}
          >
            {language[0].eng === "true" ? "Send Email" : "Email küldése"}
          </Button>
        </ButtonContainer>
        <TextSentContainer>
          {green ? (
            sent ? (
              <TextSent green={true} white={false}>
                {language[0].eng === "true"
                  ? "Email delivered"
                  : "Email elküldve"}
              </TextSent>
            ) : (
              ""
            )
          ) : sent ? (
            <TextSent green={false} white={true}>
              {language[0].eng === "true"
                ? "Email delivered"
                : "Email elküldve"}
            </TextSent>
          ) : (
            ""
          )}

          {invalid ? (
            <TextSent>
              {language[0].eng === "true"
                ? "Please fill all fields to send an e-mail"
                : "Kérem töltse ki az összes mezőt az email elküldéséhez!"}
            </TextSent>
          ) : (
            ""
          )}
        </TextSentContainer>
      </ContactWrapper>
    </ContactContainer>
  );
};

export default ContactSection;
