import React, { useContext, useState } from "react";
import Sidebar from "../../components/Sidebar";
import Navbar from "../../components/Navbar";
import HeroPageSection from "../../components/HeroSection/HeroPageEvents";
import Footer from "../../components/Footer";
import EventList from "../../components/Events/EventList";
import { animateScroll as scroll } from "react-scroll";
import eventBgImg from "../../components/images/events_hero_background.jpg";
import { HeroH1, HeroP } from "./HeroPageElements";
import { LanguageContext } from "../../components/LanguageContext";
import { OverflowContainer } from "../Home/HomeElements";
import { HeroPositionContainerEvents } from "../../components/HeroSection/HeroSpecPageElements";
const item = {
  id: "events_hero",
  title: "Események",
  titleENG: "Events",
  subtitle: "Engedd, hogy személyesen is bevezesselek zenei világomba. ",
  subtitleENG: "Let me show you my world of music in person.",
  buttonLabel: "",
  dark: true,
  primary: true,
  link: "home",
};

const EventsPage = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [language] = useContext(LanguageContext);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle}></Sidebar>
      <Navbar toggle={toggle}></Navbar>
      <OverflowContainer>
        <HeroPageSection
          id="events_hero"
          backgroundImage={eventBgImg}
          data={item}
        >
          <HeroPositionContainerEvents>
            <HeroH1>
              {language[0].eng === "true" ? item.titleENG : item.title}
            </HeroH1>
            <HeroP>
              {language[0].eng === "true" ? item.subtitleENG : item.subtitle}
            </HeroP>
          </HeroPositionContainerEvents>
        </HeroPageSection>
        <EventList></EventList>
      </OverflowContainer>
      <Footer></Footer>
    </>
  );
};

export default EventsPage;
