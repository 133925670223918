import styled from "styled-components";

export const HeroContainer = styled.div`
  margin-top: 80px;
  background: #000;
  display: flex;
  justify-content: center;
  align-item: center;
  height: 85vh;
  position: relative;
  z-index: 1;
`;

export const HeroContent = styled.div`
  height: 100%;
  width: 100%;
`;

export const HeroH1Home = styled.h1`
  color: #fff;
  display: flex;
  font-size: 30px;
  text-align: left;
  position: relative;
  left: 10vh;
  top: 12.5vh;
  font-weight: 500;
  font-family: playfairDisplay !important;
  margin: 0;

  @media screen and (max-width: 1680px) {
    font-size: 28px;
    position: relative;
    left: 10%;
    top: 10%;
  }
  @media screen and (max-width: 1280px) {
    font-size: 20px;
    position: relative;
    left: 10%;
    top: 10%;
  }
  @media screen and (max-width: 980px) {
  }
  @media screen and (max-width: 736px) {
  }
  @media screen and (max-width: 480px) {
    font-size: 24px;
    position: relative;
    left: 5%;
    top: 60%;
  }
`;

export const HeroPHome = styled.p`
  margin: 0;
  display: flex;
  color: #fff;
  font-size: 30px;
  font-weight: 700;
  text-align: center;
  position: relative;
  left: 10vh;
  top: 15vh;
  max-width: 600px;

  @media screen and (max-width: 1680px) {
    font-size: 28px;
    position: relative;
    left: 10%;
    top: 10%;
  }
  @media screen and (max-width: 1280px) {
    font-size: 20px;
    position: relative;
    left: 10%;
    top: 10%;
  }
  @media screen and (max-width: 980px) {
  }
  @media screen and (max-width: 736px) {
  }
  @media screen and (max-width: 480px) {
    font-size: 28px;
    position: relative;
    left: 5%;
    top: 62.5%;
  }
`;
