import React, { useState } from "react";
import Sidebar from "../../../components/Sidebar";
import Navbar from "../../../components/Navbar";
import Footer from "../../../components/Footer";
import { PressProvider } from "./PressContext";
import { LanguageProvider } from "../../../components/LanguageContext";
import InterviewPage from "./PressPage";
import { OverflowContainer } from "../../Home/HomeElements";
import { animateScroll as scroll } from "react-scroll";
const Press = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  scroll.scrollToTop();
  return (
    <LanguageProvider>
      <PressProvider>
        <Sidebar isOpen={isOpen} toggle={toggle}></Sidebar>
        <Navbar toggle={toggle}></Navbar>{" "}
        <OverflowContainer>
          <InterviewPage></InterviewPage>
        </OverflowContainer>{" "}
        <Footer></Footer>{" "}
      </PressProvider>
    </LanguageProvider>
  );
};

export default Press;
