import React, { useState } from "react";
import Sidebar from "../../../components/Sidebar";
import Navbar from "../../../components/Navbar";
import Footer from "../../../components/Footer";
import { VideoProvider } from "./VideoContext";
import { LanguageProvider } from "../../../components/LanguageContext";
import VideoPage from "./VideoPage";
import { animateScroll as scroll } from "react-scroll";
import { EmptySection } from "../../../components/VideoSection";
import { OverflowContainer } from "../../Home/HomeElements";
const Videos = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  scroll.scrollToTop();
  return (
    <LanguageProvider>
      <VideoProvider>
        {" "}
        <Sidebar isOpen={isOpen} toggle={toggle}></Sidebar>
        <Navbar toggle={toggle}></Navbar>
        <OverflowContainer>
          <VideoPage></VideoPage>
          <EmptySection></EmptySection>
        </OverflowContainer>
        <Footer></Footer>{" "}
      </VideoProvider>
    </LanguageProvider>
  );
};

export default Videos;
