import {
  createMuiTheme,
  CssBaseline,
  Drawer,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core";
import React from "react";
import Header from "../../../../components/Admin/Header";
import red from "@material-ui/core/colors/red";
import MyDrawer from "../../../../components/Admin/Drawer";
import { ImageProvider } from "../../../../components/Image/ImageContext";
import { CarouselQuoteProvider } from "../../../../components/Carousel_quote/CarouselQuoteContext";
import CarouselQuotes from "./CarouselQuotes";
import { LanguageProvider } from "../../../../components/LanguageContext";
import { useStyles, theme } from "../../theme.js";

const CarouselQuoteAdminPage = (props) => {
  const classes = useStyles();

  return (
    <ImageProvider>
      <LanguageProvider>
        <CarouselQuoteProvider>
          <ThemeProvider theme={theme}>
            <Drawer
              className={classes.drawer}
              variant="permanent"
              classes={{
                paper: classes.drawerPaper,
              }}
              anchor="left"
            >
              <MyDrawer></MyDrawer>
            </Drawer>
            <div className={classes.adminMain}>
              <Header></Header>
              <CarouselQuotes></CarouselQuotes>
            </div>
            <CssBaseline></CssBaseline>
          </ThemeProvider>
        </CarouselQuoteProvider>
      </LanguageProvider>
    </ImageProvider>
  );
};

export default CarouselQuoteAdminPage;
