import styled from "styled-components";

export const CardContainer = styled.div`
  background: #fff;
  display: flex;
  justify-content: center;
  align-item: center;
  height: 75vh;
  position: relative;
  z-index: 1;

  @media screen and (max-width: 1280px) {
    height: 85vh;
  }

  @media screen and (max-width: 480px) {
    height: auto;
  }
`;

export const CardWrapper = styled.div`
  position: relative;
  max-width: 55rem;
  margin: 0;
  padding: 1rem;
  left: 10rem;

  @media screen and (max-width: 1280px) {
    left: 0;
    max-width: 40rem;
  }

  @media screen and (max-width: 1024px) {
    left: 0;
    max-width: 35rem;
  }

  @media screen and (max-width: 480px) {
    left: 0;
    max-width: 17.5rem;
  }
`;

export const Card = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  max-height: 65vh;
  padding: 1rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  opacity: 0.9;

  &:hover {
    transform: scale(1.04);
    transition: all 0.2s ease-in-out;
  }

  @media screen and (max-width: 1680px) {
    max-height: 65vh !important;
    width: 75%;
    opacity: 0.8;
    position: relative;
  }

  @media screen and (max-width: 1280px) {
    max-height: 65vh !important;
    opacity: 0.8;
    position: relative;
    width: 100%;
  }

  @media screen and (max-width: 480px) {
    max-height: 75vh !important;
    opacity: 0.8;
    position: relative;
    width: 17.5rem;
  }
`;

export const CardH1 = styled.p`
  font-size: 2.5rem;
  font-weight: 300;
  color: #000;
  margin-bottom: 0;
  text-transform: uppercase;
  padding: 0 !important;

  @media screen and (max-width: 480px) {
    font-weight: 300;
    max-height: 65vh;
    font-size: 1.5rem;
  }
`;

export const CardP = styled.p`
  font-size: 1.2rem;
  text-align: center !important;

  @media screen and (max-width: 1280px) {
    font-size: 1.2rem;
  }

  @media screen and (max-width: 480px) {
    font-size: 1.1rem;
  }
`;

export const BtnWrap = styled.div`
  padding-top: 1rem;
  display: flex;
  justify-content: flex-start;
`;
