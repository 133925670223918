import {
  createMuiTheme,
  CssBaseline,
  Drawer,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core";
import React from "react";

import Header from "../../components/Admin/Header";
import "./index.css";
import red from "@material-ui/core/colors/red";

import Events from "./Events/Events";
import { EventProvider } from "../../components/Events/EventContext";
import { ImageProvider } from "../../components/Image/ImageContext";
import MyDrawer from "../../components/Admin/Drawer";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#00c853",
    },

    secondary: {
      main: red[500],
    },

    background: {
      default: "#f4f5fd",
    },
  },
  overrides: {
    MuiAppBar: {
      root: {
        transform: "translateZ(0)",
      },
    },
  },
});
const drawerWidth = 180;
const useStyles = makeStyles({
  adminMain: {
    paddingLeft: "180px",
    width: "100%",
  },
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(1),
  },
});

const Admin = (props) => {
  const classes = useStyles();

  return (
    <ImageProvider>
      <EventProvider>
        <ThemeProvider theme={theme}>
          <Drawer
            className={classes.drawer}
            variant="permanent"
            classes={{
              paper: classes.drawerPaper,
            }}
            anchor="left"
          >
            <MyDrawer></MyDrawer>
          </Drawer>
          <div className={classes.adminMain}>
            <Header></Header>
            <Events></Events>
          </div>
          <CssBaseline></CssBaseline>
        </ThemeProvider>
      </EventProvider>
    </ImageProvider>
  );
};

export default Admin;
