import React, { useRef, useEffect, useState } from "react";

export const LazyLoadImage = ({ src, alt, width, style }) => {
  const imageRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target);
        }
      });
    });

    observer.observe(imageRef.current);

    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    if (isVisible && imageRef.current) {
      imageRef.current.style.width = width ? `${width}px` : "auto";
    }
  }, [isVisible, width]);

  const mergedStyle = {
    ...style,
    opacity: isVisible ? 1 : 0,
    transition: "opacity 0.5s",
  };

  return (
    <img
      ref={imageRef}
      src={isVisible ? src : ""}
      alt={alt}
      width={width}
      style={mergedStyle}
    />
  );
};
