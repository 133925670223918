import styled from "styled-components";

export const PressBox = styled.div`
  display: flex;
  justify-content: center;
  background: #fff;
  width: 100%;
  height: auto;
  padding-top: 1.5rem;
  padding-bottom: 1rem;
  @media screen and (max-width: 768px) {
    width: 100%;
    max-height: none;
  }
`;

export const PressWrap = styled.div`
  opacity: 0.9;
  display: grid;
  background: #fff;
  grid-template-rows: 35% 50% 15%;
  justify-items: center;
  width: 20%;
  min-height: 5rem;
  padding: 1rem;
  padding-bottom: 2rem;
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.08);
    transition: all 0.2s ease-in-out;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    max-height: none;
    &:hover {
      transform: scale(1);
      transition: all 0.2s ease-in-out;
    }
  }
`;

export const PressIcon = styled.div`
  height: 50px;
  width: 50px;
  text-align: center;
  padding: 0;
  marigin: 0;
`;
export const Text = styled.h1`
  padding: 0.5rem 0.1rem 0.1rem 0.5rem;
  font-size: 20px;
  font-weight: bold;
  color: #000;
  margin: 0;
  margin-top: 1rem;
`;

export const HeroText = styled.p`
  color: #fff;
  display: flex;
  position: relative;
  font-size: 50px;
  top: 1.5rem;
  font-weight: 300;
  text-transform: uppercase;
  justify-content: flex-end;
  text-align: right;

  @media screen and (max-width: 1680px) {
  }
  @media screen and (max-width: 1440px) {
    top: 2.5rem;
  }
  @media screen and (max-width: 1280px) {
  }

  @media screen and (max-width: 480px) {
    font-size: 36px;
    position: relative;
  }
`;
export const HeroText2 = styled.h1`
  position: relative;
  top: 15rem;
  left: 25rem;
  font-size: 60px;
  color: #fff;
  text-transform: uppercase;
  opacity: 0.9;
  @media screen and (max-width: 768px) {
    top: 15.5rem;
    left: 8.5rem;
    font-size: 48px;
  }
`;
