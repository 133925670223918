import React, { useContext, useEffect } from "react";
import { GetAllItems } from "../../pages/Admin/services/bioService";
import BigSpecCardSection from "./BigSpecCard";
import { BioContext } from "./BioContext";
import HeroSpecPageSection from "../HeroSection/HeroSpecPage";

const Bio = (props) => {
  const [bio, setBio] = useContext(BioContext);

  useEffect(() => {
    (async () => {
      const getItems = () => {
        let get = GetAllItems();
        get.then((res) => {
          let data = res.data;
          setBio(data);
        });
      };
      getItems();
    })();
  }, [setBio]);

  return (
    <>
      <HeroSpecPageSection
        id="events_hero"
        backgroundImage={bio[0].image}
        titleENG={bio[0].title}
        bio={bio}
        bioText={true}
      ></HeroSpecPageSection>
      <BigSpecCardSection bio={bio} image={bio[0].image}></BigSpecCardSection>
    </>
  );
};

export default Bio;
