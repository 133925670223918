import React from "react";
import ReactPlayer from "react-player";
import {
  Card,
  CardContainer,
  CardWrapper,
  CardAbout,
  PlayerWrap,
} from "./BigSpecCardElements";
import parse from "html-react-parser";

const BigSpecCardSection = (props) => {
  return (
    <CardContainer>
      <CardWrapper>
        <Card>
          <CardAbout className="myContainer">{parse(props.aboutENG)}</CardAbout>
          {props.title == "CONTEMPORARY MUSIC" ? (
            <PlayerWrap>
              <ReactPlayer url="https://www.youtube.com/watch?v=VoZ7PF1Al-s" />
            </PlayerWrap>
          ) : (
            ""
          )}
          {props.title == "KORTÁRS ZENE" ? (
            <PlayerWrap>
              <ReactPlayer url="https://www.youtube.com/watch?v=VoZ7PF1Al-s" />
            </PlayerWrap>
          ) : (
            ""
          )}
          {props.title == "DOCTORAL STUDIES AT THE ROYAL ACADEMY OF MUSIC" ? (
            <PlayerWrap>
              <ReactPlayer url="https://www.youtube.com/watch?v=3O5lij2cEps" />
            </PlayerWrap>
          ) : (
            ""
          )}
          {props.title ==
          "DOKTORI TANULMÁNYOK A LONDONI KIRÁLYI ZENEAKADÉMIÁN" ? (
            <PlayerWrap>
              <ReactPlayer url="https://www.youtube.com/watch?v=3O5lij2cEps" />
            </PlayerWrap>
          ) : (
            ""
          )}
        </Card>
      </CardWrapper>
    </CardContainer>
  );
};

export default BigSpecCardSection;
