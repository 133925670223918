import styled from "styled-components";

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #e5e5e5;
  font-family: playfairDisplay !important;

  @media screen and (max-width: 480px) {
    width: 100%;
  }
`;

export const CardWrapper = styled.div`
  position: relative;
  display: grid;
  grid-template-rows: minmax(400px,600px);
  transition: all 0.2s ease-in-out;
  z-index: 1;
  width: 100%;
  max-width: 1140px;

  @media screen and (max-width: 1024px) {
    max-width: 80%;
  }

  @media screen and (max-width: 980px) {
    max-width: unset;
    left: 0rem; 
    margin: 0;
    padding: 0.5rem;
    grid-template-rows: 30rem;
    grid-template-columns: 100%;
    &:hover {
      transform: scale(1);
      transition: all 0.2s ease-in-out;
    }
    @media screen and (max-width: 480px) {
    grid-template-rows: 15rem;  }
`;

export const Card = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 1140px;
  justify-content: flex-start;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  padding: 1rem;

  @media screen and (max-width: 980px) {
    width: 100%;
    max-width: unset;
  }
  @media screen and (max-width: 480px) {
    width: 100%;
    height: min-content;
  }
`;

export const CardDate = styled.h2`
  font-size: 1.3rem;
  margin: 0;
  margin-bottom: 0.5rem;
  font-weight: 100;
  color: #000;
  font-style: italic;
  font-family: playfairDisplay !important;

  @media screen and (max-width: 480px) {
    font-size: 1.2rem;
  }
`;
export const CardPlace = styled.h2`
  margin: 0;
  font-size: 1.3rem;
  font-weight: 100;
  color: #000;
  font-family: playfairDisplay !important;
  font-style: italic;

  @media screen and (max-width: 480px) {
    font-size: 1.2rem;
  }
`;

export const CardAbout = styled.div`
  font-size: 1.2rem;
  margin: 0 !important;
  font-family: playfairDisplay !important;

  @media screen and (max-width: 480px) {
    font-size: 1.2rem;
  }
`;

export const CardAboutTitle = styled.h2`
  font-size: 2rem;
  font-weight: 100;
  display: flex !important;
  text-align: right !important;
  margin: 0;
  margin-top: 2.5rem;
  margin-bottom: 0.5rem;
  font-family: playfairDisplay !important;
  @media screen and (max-width: 480px) {
    font-size: 2rem;
  }
`;

export const BtnWrap = styled.div`
  padding-top: 2rem;
  padding-bottom: 1rem;
  display: flex;
  justify-content: center;
  @media screen and (max-width: 480px) {
    padding-top: 2rem;
    padding-bottom: 1rem;
  }
`;
export const EmptyMargin = styled.div`
  margin: 1rem;
`;
