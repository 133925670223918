import React, { useContext, useEffect } from "react";
import navbar_logo_hu from "../images/navbar_logo_hu.png";
import navbar_logo_eng from "../images/navbar_logo_eng.png";
import * as service from "../../pages/Admin/services/languageService";
import { LanguageContext } from "../LanguageContext";
import {
  SidebarContainer,
  SidebarMenu,
  SidebarLink,
  SidebarWrapper,
  SidebarLogo,
  Icon,
  CloseIcon,
} from "./SidebarElements";
import { useHistory } from "react-router-dom";

const SidebarHome = ({ isOpen, toggle }) => {
  const [language, setLanguage] = useContext(LanguageContext);
  const history = useHistory();
  const getItems = () => {
    let get = service.GetAllItems();
    get.then((res) => {
      let data = res.data;
      setLanguage(data);
    });
  };

  useEffect(() => {
    (async () => {
      //getItems();
    })();
  }, []);
  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
      <Icon onClick={toggle}>
        <CloseIcon></CloseIcon>
      </Icon>
      <SidebarWrapper>
        <SidebarLogo>
          {language[0].eng === "true" ? (
            <img
              src={navbar_logo_eng}
              style={{ maxWidth: "60%" }}
              alt="navbar_logo_Hu"
              className="navbar-icon"
            />
          ) : (
            <img
              src={navbar_logo_hu}
              style={{ maxWidth: "55%" }}
              alt="navbar_logo_Hu"
              className="navbar-icon"
            />
          )}
        </SidebarLogo>
        <SidebarMenu>
          <SidebarLink
            to="about"
            onClick={toggle}
            smooth="true"
            duration={500}
            spy="true"
            exact="true"
            offset={-120}
          >
            {language[0].eng === "true" ? "About me" : "Rólam"}
          </SidebarLink>
          <SidebarLink
            to="doctoral"
            onClick={toggle}
            smooth="true"
            duration={500}
            spy="true"
            exact="true"
            offset={-120}
          >
            {language[0].eng === "true" ? "PhD" : "PhD"}
          </SidebarLink>
          <SidebarLink
            onClick={toggle}
            to="specialities"
            smooth="true"
            duration={500}
            spy="true"
            exact="true"
            offset={-220}
          >
            {language[0].eng === "true" ? "Specialities" : "Érdekességek"}
          </SidebarLink>
          <SidebarLink
            onClick={toggle}
            to="events"
            smooth="true"
            duration={500}
            spy="true"
            exact="true"
            offset={-220}
          >
            {language[0].eng === "true" ? "Events" : "Események"}
          </SidebarLink>
          <SidebarLink
            onClick={toggle}
            to="music"
            smooth="true"
            duration={500}
            spy="true"
            exact="true"
            offset={-160}
          >
            {language[0].eng === "true" ? "My music" : "Zene"}
          </SidebarLink>
          <SidebarLink
            onClick={toggle}
            to="media"
            smooth="true"
            duration={500}
            spy="true"
            exact="true"
            offset={-80}
          >
            {language[0].eng === "true" ? "Media" : "Média"}
          </SidebarLink>
          <SidebarLink
            onClick={() => {
              history.push("/#collection");
            }}
            smooth="true"
            duration={500}
            spy="true"
            exact="true"
            offset={-360}
          >
            {language[0].eng === "true" ? "Collection" : "Collection"}
          </SidebarLink>
          <SidebarLink
            onClick={toggle}
            to="bio"
            smooth="true"
            duration={500}
            spy="true"
            exact="true"
            offset={-360}
          >
            {language[0].eng === "true" ? "Bio" : "Életrajz"}
          </SidebarLink>
          <SidebarLink
            onClick={toggle}
            to="shop"
            smooth="true"
            duration={500}
            spy="true"
            exact="true"
            offset={-360}
          >
            {language[0].eng === "true" ? "Shop" : "Bolt"}
          </SidebarLink>
          <SidebarLink
            onClick={() => {
              history.push("/blog");
            }}
          >
            {language.eng === "true" ? "Blog" : "Blog"}
          </SidebarLink>
          <SidebarLink
            to="contact"
            smooth="true"
            duration={500}
            spy="true"
            exact="true"
            offset={-80}
          >
            {language[0].eng === "true" ? "Contact" : "Kapcsolat"}
          </SidebarLink>
          <SidebarLink
            onClick={() => {
              if (localStorage.getItem("eng") === "true") {
                localStorage.removeItem("eng");
                localStorage.setItem("eng", false);
              } else {
                localStorage.removeItem("eng");
                localStorage.setItem("eng", true);
              }

              service.UpdateItem(language[0]);
              window.location.reload();
            }}
            style={{ textTransform: "none", fontWeight: "bold" }}
          >
            {language[0].eng === "true" ? "hu/EN" : "HU/en"}{" "}
          </SidebarLink>
        </SidebarMenu>
      </SidebarWrapper>
    </SidebarContainer>
  );
};

export default SidebarHome;
