import React, { useContext, useEffect } from "react";
import HeroPageSection from "../../../components/HeroSection/HeroPage";
import {
  HeroText,
  Text,
  TextP,
  TextWrap,
  ProgramBox,
  ProgramWrap,
  HeroPositionContainerProposals,
} from "../../../components/ProgramSection";
import * as service from "../../../pages/Admin/services/programService";
import { ProgramContext } from "./ProgramContext";
import { LanguageContext } from "../../../components/LanguageContext";
import HeroBgImg from "../../../components/images/Noemi_5801.jpg";
import { HeroPositionContainerMedia } from "../../../components/InterviewSection";

const ProgramPage = () => {
  const [language] = useContext(LanguageContext);
  const [programs, setPrograms] = useContext(ProgramContext);

  const sortPrograms = () => {
    programs.sort(function (a, b) {
      if (a.titleENG > b.titleENG) return 1;
      else if (a.titleENG < b.titleENG) return -1;
      return 0;
    });
    setPrograms(programs);
  };

  useEffect(() => {
    (async () => {
      const getItems = () => {
        let get = service.GetAllItems();
        get.then((res) => {
          let data = res.data;
          setPrograms(data);
        });
      };
      getItems();
    })();
  }, [setPrograms]);
  sortPrograms();
  return (
    <>
      <HeroPageSection backgroundImage={HeroBgImg}>
        <HeroPositionContainerProposals>
          <HeroText>
            {language[0].eng === "true"
              ? "Program proposals"
              : "Programajánlatok"}
          </HeroText>
        </HeroPositionContainerProposals>
      </HeroPageSection>
      {programs.map((item) => {
        return (
          <ProgramBox>
            <ProgramWrap>
              <TextWrap>
                <Text>
                  {language[0].eng === "true" ? item.titleENG : item.title}
                </Text>
                <TextP>
                  {language[0].eng === "true"
                    ? item.subtitleENG
                    : item.subtitle}
                </TextP>
              </TextWrap>
            </ProgramWrap>
          </ProgramBox>
        );
      })}
    </>
  );
};

export default ProgramPage;
