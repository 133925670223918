import http from "./http-auth";

export function InsertItem(data) {
  return http.post("/shops", data).then((res) => {});
}

export function DeleteItem(data) {
  return http.delete(`/shops/${data}`).then((res) => {});
}

export function UpdateItem(data) {
  return http.put(`/shops/${data.id}`, data).then((res) => {});
}

export function GetItem(data) {
  return http.get(`/shops/${data.id}`, data).then((res) => {});
}

export const GetAllItems = () => {
  return http.get("/shops");
};
