import styled from "styled-components";

export const CardContainer = styled.div`
  min-height: 800px;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const CardWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 50% 50%;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    max-width: 100%;
    #a {
      order: 1;
    }
    #b {
      order: 2;
    }
    #c {
      order: 4;
    }
    #d {
      order: 3;
    }
  }
`;

export const Card = styled.div`
  background: transparent;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 400px;

  @media screen and (max-width: 768px) {
    max-height: 800px;
    height: 200px;
  }
`;

export const CardH1 = styled.p`
  font-size: 2rem;
  font-weight: 100;
  color: #000;
  margin-bottom: 4rem;
  text-transform: uppercase;

  @media screen and (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

export const CardP = styled.p`
  font-size: 1rem;
  text-align: center;
`;
