import styled from "styled-components";
import { Link as LinkR } from "react-router-dom";

export const PartnersContainer = styled.div`
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 2rem;

  @media screen and (max-width: 1024px) {
    padding-bottom: 0rem;
  }

  @media screen and (max-width: 480px) {
    height: auto;
    padding: 0;
    margin: 0;
  }
`;

export const PartnersWrapper = styled.div`
  height: auto;
  width: 60%;
  margin: 0;
  display: grid;
  text-align: center;

  @media screen and (max-width: 480px) {
    height: auto;
    width: 90%;
    padding: auto;
    margin: auto;
  }
`;

export const PartnersLogoContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(200px, 1fr)
  ); // Adjust minmax value to control logo size
  gap: 1rem; // Adjust gap between logos
  justify-items: center;
  align-items: center;
  margin-bottom: 1rem;

  @media screen and (max-width: 1024px) {
    grid-template-columns: repeat(
      auto-fill,
      minmax(150px, 1fr)
    ); // Adjust minmax value for smaller screens
    gap: 0.5rem; // Adjust gap for smaller screens
  }

  @media screen and (max-width: 480px) {
    grid-template-columns: repeat(
      auto-fill,
      minmax(100px, 1fr)
    ); // Adjust minmax value for smaller screens
    gap: 0.5rem; // Adjust gap for smaller screens
  }
`;

export const PartnersLink = styled.a`
  border: none !important;
  &:hover {
    background: #fff !important;
  }
`;

export const PartnersLogo = styled.img`
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  color: #fff;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  margin-left: 0px;
  margin: 0;
  padding: 0;
  text-decoration: none;
  border: none !important;
`;

export const PartnersH1 = styled.h1`
  margin: 0;
  font-size: 28px;
  font-weight: 300;
  color: #000;
  text-transform: uppercase;
  margin-top: 1rem !important;

  @media screen and (max-width: 480px) {
    min-height: 2.5rem;
    margin-top: 0 !important;
    max-height: 5rem;
    font-weight: 300;
  }
`;
