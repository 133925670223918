import styled from "styled-components";
import { Link } from "react-router-dom";

export const FooterContainer = styled.footer`
  background-color: #000;
  @media screen and (max-width: 768px) {
    height: 20rem;
  }
`;

export const FooterWrapper = styled.div`
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1100px;
  margin: 0 auto;
  @media screen and (max-width: 768px) {
    max-width: 100%;
  }
`;

export const FooterLinksContainer = styled.div`
  display: flex;
  justify-content: center;

  @media screen and (max-width: 768px) {
  }
`;

export const FooterLinksWrapper = styled.div`
  display: flex;

  @media screen and (max-width: 768px) {
  }
`;

export const FooterLinkItems = styled.div`
  display: flex;
  flex-direction: column;
  margin: 4px;
  text-align: center;
  width: 240px;
  box-sizing: border-box;
  color: #fff;

  @media screen and (max-width: 768px) {
  }
`;

export const FooterLinkTitle = styled.h1`
  font-size: 14px;
  margin-bottom: 12px;
  margin-top: 24px;
  text-align: center;
`;

export const FooterLink = styled.p`
  color: #fff;
  text-decoration: none;
  margin-bottom: 0.5rem;
  font-size: 14px;

  &:hover {
    color: #09bc8a;
    transition: 0.3s ease-out;
  }
`;

export const SocialMedia = styled.section`
  max-width: 1000px;
  width: 100%;
`;

export const SocialMediaWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1100px;
  margin: 0 auto 0 auto;

  @media screen and (max-width: 768px) {
  }
`;

export const SocialLogo = styled(Link)`
  color: #fff;
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  font-weight: bold;
`;

export const SocialIcons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 240px;
`;

export const SocialIconLink = styled.a`
  color: #fff;
  font-size: 24px;
`;

export const SocialIconLinkYT = styled.a`
  color: #fff;
  font-size: 24px;
  &:hover {
    color: red;
    transition: 0.3s ease-out;
  }
`;

export const SocialIconLinkFB = styled.a`
  color: #fff;
  font-size: 24px;
  &:hover {
    color: #4267b2;
    transition: 0.3s ease-out;
  }
`;

export const SocialIconLinkI = styled.a`
  color: #fff;
  font-size: 24px;
  &:hover {
    color: purple;
    transition: 0.3s ease-out;
  }
`;
