import { React, useState, useEffect, useContext } from "react";
import PageHeader from "../../../../components/Admin/PageHeader";
import CarouselFrom from "./CarouselForm";
import EventIcon from "@material-ui/icons/Event";
import AddIcon from "@material-ui/icons/Add";
import Controls from "../../controls/Controls";
import Popup from "../../Popup";
import useTable from "../../useTable";
import * as service from "../../services/carouselService";
import { Close, EditOutlined, Search } from "@material-ui/icons";
import {
  InputAdornment,
  makeStyles,
  Paper,
  TableBody,
  TableCell,
  TableRow,
  Toolbar,
} from "@material-ui/core";

import Notification from "../../controls/Notification";
import ConfirmDialog from "../../controls/ConfirmDialog";
import { CarouselContext } from "../../../../components/Carousel/CarouselContext";
import { useStylesPaper } from "../../theme.js";

const headCells = [
  { id: "title", label: "Title" },
  { id: "titleENG", label: "Title (ENG)" },
  { id: "subtitle", label: "Subtitle" },
  { id: "subtitleENG", label: "Subtitle (ENG)" },
  { id: "buttonLabel", label: "Button label" },
  { id: "buttonLabelENG", label: "Button label (ENG)" },
  { id: "link", label: "link" },

  { id: "actions", label: "Actions", disableSorting: true },
];

const Carousels = () => {
  const [carousels, setCarousels] = useContext(CarouselContext);
  const classes = useStylesPaper();
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const [openPopup, setOpenPopup] = useState(false);
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });

  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
    useTable(carousels, headCells, filterFn);

  const handleSearch = (e) => {
    let target = e.target;
    setFilterFn({
      fn: (items) => {
        if (target.value === "") return items;
        else
          return items.filter((x) =>
            x.titleENG.toLowerCase().includes(target.value)
          );
      },
    });
  };

  const addOrEdit = (item, resetForm) => {
    if (item.id == null) service.InsertItem(item);
    else service.UpdateItem(item);
    resetForm();
    setRecordForEdit(null);
    setOpenPopup(false);
    setNotify({
      isOpen: true,
      message: "Submitted Succesfully",
      type: "success",
    });
  };

  const openInPopup = (item) => {
    setRecordForEdit(item);
    setOpenPopup(true);
  };

  const onDelete = (id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    service.DeleteItem(id);
    setNotify({
      isOpen: true,
      message: "Deleted Successfully",
      type: "error",
    });
  };

  useEffect(() => {
    (async () => {
      const getItems = () => {
        let get = service.GetAllItems();
        get.then((res) => {
          let data = res.data;
          setCarousels(data);
        });
      };
      getItems();
    })();
  }, [setCarousels]);

  return (
    <>
      <PageHeader
        title="Slides"
        subtitle="Manage slides"
        icon={<EventIcon />}
      ></PageHeader>
      <Paper className={classes.pageContent}>
        <Toolbar>
          <Controls.Input
            label="Search Slides"
            className={classes.searchInput}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            onChange={handleSearch}
          ></Controls.Input>
          <Controls.Button
            text="Add New"
            variant="outlined"
            startIcon={<AddIcon />}
            className={classes.newButton}
            onClick={() => {
              setOpenPopup(true);
              setRecordForEdit(null);
            }}
          ></Controls.Button>
        </Toolbar>
        <TblPagination></TblPagination>
        <TblContainer>
          <TblHead></TblHead>
          <TableBody>
            {recordsAfterPagingAndSorting().map((item) => (
              <TableRow key={item.id}>
                <TableCell className={classes.narrowCell}>
                  {item.title}
                </TableCell>
                <TableCell className={classes.narrowCell}>
                  {item.titleENG}
                </TableCell>
                <TableCell className={classes.narrowCell}>
                  {item.subtitle}
                </TableCell>
                <TableCell className={classes.narrowCell}>
                  {item.subtitleENG}
                </TableCell>
                <TableCell className={classes.narrowCell}>
                  {item.buttonLabel}
                </TableCell>
                <TableCell className={classes.narrowCell}>
                  {item.buttonLabelENG}
                </TableCell>
                <TableCell className={classes.narrowCell}>
                  {item.link}
                </TableCell>
                <TableCell>
                  <Controls.ActionButton
                    color="primary"
                    onClick={() => {
                      openInPopup(item);
                    }}
                  >
                    <EditOutlined fontSize="small"></EditOutlined>
                  </Controls.ActionButton>
                  <Controls.ActionButton
                    color="secondary"
                    onClick={() => {
                      setConfirmDialog({
                        isOpen: true,
                        title: "Are you sure to delete this record?",
                        subTitle: "You can't undo this operation",
                        onConfirm: () => {
                          onDelete(item.id);
                          window.location.reload();
                        },
                      });
                    }}
                  >
                    <Close fontSize="small"></Close>
                  </Controls.ActionButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </TblContainer>
      </Paper>
      <Popup
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        title="New Slides"
      >
        <CarouselFrom
          recordForEdit={recordForEdit}
          addOrEdit={addOrEdit}
        ></CarouselFrom>
      </Popup>
      <Notification notify={notify} setNotify={setNotify}></Notification>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
};

export default Carousels;
