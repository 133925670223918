import http from "./http-auth";

export function InsertItem(data) {
  return http.post("/aboutpage", data).then((res) => {});
}
export function DeleteItem(data) {
  return http.delete(`/aboutpage/${data}`).then((res) => {});
}

export function UpdateItem(data) {
  return http.put(`/aboutpage/${data.id}`, data).then((res) => {});
}

export function GetItem(data) {
  return http.get(`/aboutpage/${data.id}`, data).then((res) => {});
}

export const GetAllItems = () => {
  return http.get("/aboutpage");
};
